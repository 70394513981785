import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import HFTab from '../../../../Components/HfTabs/HfTabs'
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../../assets/mark.png'
import ImageText from '../../../../Components/ImageText/ImageText'

import Stage from '../../../../assets/Services/PreventativeMaintainance/Inspections/Stage.jpg'
import HydraulicHose from '../../../../assets/Services/PreventativeMaintainance/Inspections/HydraulicHose.jpg'
import IndustrialHose from '../../../../assets/Services/PreventativeMaintainance/Inspections/IndustrialHose.jpg'
import PressureAccumulator from '../../../../assets/Services/PreventativeMaintainance/Inspections/PressureAccumulator.jpg'
import LiftingPlatForm from '../../../../assets/Services/PreventativeMaintainance/Inspections/LiftingPlatForm.jpg'
import SaferOperation from '../../../../assets/Services/PreventativeMaintainance/Inspections/SaferOperation.jpg'
import Scope from '../../../../assets/Services/PreventativeMaintainance/Inspections/Scope.jpg'
import Creation from '../../../../assets/Services/PreventativeMaintainance/Inspections/Creation.jpg'
import HoseManagement from '../../../../assets/Services/PreventativeMaintainance/Inspections/HoseManagement.jpg'
import ComprehensiveServices from '../../../../assets/Services/PreventativeMaintainance/Inspections/ComprehensiveServices.jpg'
import OnSafeSide from '../../../../assets/Services/PreventativeMaintainance/Inspections/OnSafeSide.jpg'
import AuthorisedPersons from '../../../../assets/Services/PreventativeMaintainance/Inspections/AuthorisedPersons.jpg'


const Inspection = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    //HfTab data 2
    const imageTextData2 = [
        {
            title: "Étendue de la documentation ",
            description: "Dans le cadre de l'inspection, nos experts documentent tous les résultats de manière claire et conforme à la loi pour vous. Toutes les données et informations du client concernant le système ou la machine sont recueillies sur nos fiches d'enregistrement standard afin qu'il n'y ait aucun doute ultérieur sur les détails de l'inspection. La documentation vous fournit des informations sur :  ",
            listItems: ["Toutes les tâches d'inspection effectuées",
                "La date de la prochaine inspection",
                "Tous les défauts ou anomalies"

            ],
            image: Scope
        },
        {
            title: " Création de la documentation ",
            description: "La documentation comprend des photographies du système ou de la machine à inspecter, telles que des photographies de la plaque d'identification ou des caractéristiques de construction particulières, comme preuve vérifiable de son état général. Tous les défauts sont photographiés et archivés. Les photographies font partie intégrante de la documentation et vous sont envoyées, avec les fiches d'inspection, sous forme numérique après achèvement. ",
            image: Creation
        },
        {
            title: "Capture et gestion des données numériques avec X-CODE Manager ",
            description: " La documentation de l'inspection des lignes de tuyaux hydrauliques est effectuée dans notre logiciel de gestion des tuyaux. Toutes les informations importantes concernant chaque ligne de tuyaux sont stockées là-bas :",
            listItems: [ "Identification unique par X-CODE",
                "Fabrication, installation et, si nécessaire, remplacement",
                "Évaluation des risques et intervalle d'inspection"
            ],
            image: HoseManagement
        },
    ];

    const tabTitles2 = [
        { title: "Scope", eventKey: "Scope" },
        { title: "Creation", eventKey: "Creation" },
        { title: "Hose management", eventKey: "Hose management" },
    ];

    const featuresData = [
        {
            title: " Prêt à être réutilisé " ,
            description:"Les experts en fluides révisent les composants hydrauliques défectueux tels que les vérins et les accumulateurs de pression.",
            image: imgMark
        },
        {
            title: "En bon état.",
            description: "Le service industriel HANSA-FLEX vous soutient dans vos travaux de maintenance et d'entretien.",
            image: imgMark
        },
        {
            title: "L'entretien de l'huile simplifié.",
            description: "Évitez les dommages liés à l'huile grâce aux connaissances des experts en fluides de HANSA-FLEX.",
            image: imgMark
        },
        {
            title: "Gestion intelligente des tuyaux.",
            description: "  Avec X-CODE Manager, vous disposez d'une vue d'ensemble complète de vos lignes de tuyaux, machines et dates d'inspection.",
            image: imgMark
        }
    ];

    const data1 = {
        image: ComprehensiveServices,
        title: "Des services complets, tous proposés par le même fournisseur.",
        description: "We are your service partner for everything to do with the inspection of hydraulic work equipment. We support you in the following tasks:",
        listItems: [
            "Réalisation d'inspections incluant la documentation",
            "Conseils et assistance dans la préparation des évaluations des risques",
            "Installation et retrait des composants à inspecter",
            "Remplacement et révision des composants défectueux",
            "Conseils sur l'optimisation des machines et des systèmes"

        ]

    }

    const data2 = {
        image: OnSafeSide,
        title: 'Toujours du bon côté de la sécurité.',
        description: "Avec les services de HANSA-FLEX, vous êtes mieux assuré de respecter la législation. Lors des inspections, nous veillons à respecter les exigences légales et à documenter les résultats dans un rapport d'inspection : ",
        listItems: [
            "Loi allemande sur la sécurité industrielle (BetrSichV)",
            "Directive Machines / Directive européenne sur l'utilisation des équipements de travail 2009/104/CE",
            "Directive européenne sur les équipements sous pression (PED)",
            "Organisme allemand d'assurance accidents du travail (DGUV)",
            "Règles techniques en matière de santé et sécurité au travail industriel (TRBS)"

        ]
    }

    const data3 = {
        image: AuthorisedPersons,
        description: ' Nos employés sont qualifiés en tant que personnes compétentes dans les domaines suivants : ',
        listItems: [ "Personnes qualifiées pour la technologie des lignes",
            "Personnes qualifiées pour le stockage sous pression",
            "Personnes qualifiées pour les éoliennes",
            "Personnes qualifiées pour les plates-formes élévatrices et les rampes de chargement",
            "Personnes qualifiées pour les flexibles industriels"

        ]
    }


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"HANSA-FLEX est votre partenaire fiable pour les inspections. "}
                    paragraph={"Les systèmes hydrauliques travaillent extrêmement dur. Des inspections régulières sont donc obligatoires afin de garantir un fonctionnement fiable et sûr des systèmes et des machines. Les spécialistes des fluides qualifiés en tant que personnes autorisées officiellement chez HANSA-FLEX vous soutiennent lors de l'inspection des lignes de tuyaux hydrauliques, des flexibles industriels, des accumulateurs de pression, des plates-formes élévatrices et des ponts de chargement conformément aux exigences légalement prescrites telles que définies dans la DGUV et la BetrSichV. "}
                />
            </div>

            <div>
                <SectionHeader
                    title={"Matériel de travail et équipements inspectés de manière fiable avec HANSA-FLEX. "}
                    subtitle={"Les experts formés et certifiés chez HANSA-FLEX effectuent les inspections régulièrement prescrites des systèmes hydrauliques, y compris la documentation. "}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={8}><ImageCard image={HydraulicHose}
                                title="Inspection des lignes de tuyaux hydrauliques. "
                                desc={"Les lignes de tuyaux hydrauliques sont des composants fondamentaux des machines et des systèmes. Elles nécessitent des soins complets et doivent être inspectées professionnellement par des personnes autorisées tous les 12 mois après leur première utilisation. "} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={IndustrialHose}
                                title="Inspection des flexibles industriels "
                                desc={"Nous inspectons les lignes de flexibles industriels transportant des produits chimiques, des denrées alimentaires, des produits pharmaceutiques et d'autres fluides, y compris la préparation du certificat d'inspection légalement requis. "} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={PressureAccumulator}
                                title=" Inspection des accumulateurs de pression. "
                                desc={" Les employés de HANSA-FLEX sont des personnes autorisées officiellement pour les accumulateurs de pression et réalisent l'inspection visuelle prescrite légalement de la pression. "} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={LiftingPlatForm}
                                title=" Inspection des plates-formes élévatrices et des ponts de chargement. "
                                desc={" Les experts de HANSA-FLEX sont des personnes autorisées officiellement pour les plates-formes élévatrices et les ponts de chargement et effectuent les inspections annuelles de cet équipement pour vous. "} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={SaferOperation}
                                title="  Des opérations plus sûres grâce à la maintenance préventive."
                                desc={"  La loi allemande sur la sécurité industrielle prévoit des inspections régulières en cours d'exploitation de l'équipement de travail tel que les machines, en plus de l'inspection réalisée avant la mise en service. "} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div>
                <SectionHeader
                    title={" Documentation légale conforme basée sur les rapports d'inspection. "}
                />
                <HFTab imageTextData={imageTextData2} tabTitles={tabTitles2} />
            </div>

            <div>
                <SectionHeader
                    title={"Service complet pour vos systèmes et machines. "}
                    subtitle={"Nos employés sont des spécialistes expérimentés des fluides et vous soutiennent dans toutes les tâches liées à l'entretien, à la maintenance et à la révision de vos systèmes pneumatiques et hydrauliques."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={" Connaissances en hydraulique et formation avancée dispensées par des experts. "}
                    subtitle={"En collaboration avec l'Académie internationale de l'hydraulique (IHA), HANSA-FLEX organise des séminaires pour les ingénieurs, les techniciens, les installateurs et le personnel de maintenance des systèmes hydrauliques. Des experts transmettent leur savoir-faire spécialisé sur l'exploitation sûre, l'entretien et la maintenance appropriés. "}
                />
            </div>

            <div>
                <SectionHeader
                    title={"UN CODE POUR CHAQUE INSTANCE"}
                    subtitle={" Le X-CODE unique combine toutes les informations sur une ligne de tuyaux spécifique, constitue la base pour commander un remplacement et la gestion des tuyaux. "}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"DES EXPERTS QUALIFIÉS CHEZ HANSA-FLEX "}
                    subtitle={"Les experts de HANSA-FLEX chargés de tester les équipements de travail hydrauliques et pneumatiques possèdent des connaissances spécialisées à jour certifiées par des institutions de test indépendantes."}
                />
                <ImageText
                    data={data3} />
            </div>

        </div>
    )
}

export default Inspection 