import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';

import Stage from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/Stage.jpg'
import Repair from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/Repair.jpg'
import imgExcellentRange from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/ExcellentRange.jpg'
import imgEfficientOrder from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/EfficientOrder.jpg'
import Repair2 from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/Repair2.jpg'
import Cylinder from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/Cylinder.jpg'
import Manufacture from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/Manufacture.jpg'
import Seals from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/Seals.jpg'
import imgMark from '../../../assets/mark.png'




const HydraulicCylinderRepair = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);


    const ProfessionalRepair = {
        image: Repair,
        title: 'Réparation professionnelle de vérins défectueux ou endommagés',
        description: "Nos experts expérimentés accomplissent rapidement et de manière fiable toutes les tâches liées à la réparation professionnelle des vérins, jusqu'à une longueur de 10 000 mm et un diamètre de 1 000 mm.",
        listItems: ["Alésage moyennant des frais supplémentaires jusqu'à une longueur de 6 000 mm et un diamètre d'environ 500 mm","Rechromage des tiges de piston","Tournage et fraisage des logements de joints","Joints fabriqués selon des spécifications dimensionnelles"]
    }

    const ExcellentRange = {
        image: imgExcellentRange,
        title: "Excellente gamme de machines et d'équipements",
        description: "Le centre de réparation des vérins HANSA‑FLEX dispose d'une gamme de machines et d'équipements modernes pour les réparations de vérins. Le sablage et les revêtements de peinture spéciaux sont pour nous une évidence.",
        listItems: ["Tours conventionnels et à commande numérique (CNC)","Trous de montage pour l'installation verticale et le retrait des vérins","Banc d'essai conforme à la norme DIN 10100 pour des tests fonctionnels complets","Machine à honer pour réviser les tubes de vérin endommagés" ]
    }


    const EfficientOrder = {
        image: imgEfficientOrder,
        title: 'Traitement efficace des commandes',
        description: "Nous vérifions l'état de chaque vérin avant de décider si la tige de piston doit simplement être rodée et polie ou si un nouveau vérin est nécessaire. Ensuite, les deux zones de production voisines, la réparation de vérins et la technologie d'étanchéité, travaillent ensemble dans une routine bien rodée pour compléter la maintenance du vérin, y compris le montage de nouveaux joints. ",
        listItems: ["Offre de coûts et de services contraignants ","L'inspection des vérins endommagés peut également être effectuée sur votre site","Les joints standard et spéciaux peuvent être obtenus rapidement "]
    }



    const featuresData = [
        {
            title: "Service complet",
            description: "Démontage, enlèvement, réparation, retour et installation ",
            image: imgMark
        },
        {
            title: "  Service express",
            description: "  Réparation en trois jours, électroplaquage au pinceau pour les réparations urgentes ",
            image: imgMark
        },
        {
            title: " Conseils complets ",
            description: "Réparation professionnelle, identification des potentiels d'optimisation  ",
            image: imgMark
        },
        {
            title: "Qualité éprouvée ",
            description: " Exécution de différents tests et préparation de rapports détaillés si nécessaire ",
            image: imgMark
        }
        // Add more objects for additional FeaturesCard data
    ];

    const data1 = {
        image: Repair2,
        description: "Nos experts possèdent de nombreuses années d'expérience, un savoir-faire pratique étendu et sont parfaitement familiers avec les exigences spécifiques des différents types de vérins. ",
        listItems: ["Vérins plongeurs et télescopiques","Vérins de presse, de grue et de coulée","Vérins de lutte contre l'incendie, d'ingénierie hydraulique, de mesure de gaz et de fermeture","Vérins hydrauliques et pneumatiques mobiles","Vérins standard et spéciaux"]
    }


    const isMobile = window.innerWidth <= 768;


    return (
        <div>

            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"RÉPARER UN VÉRIN EST UNE BONNE ALTERNATIVE À L'ACHAT D'UN NOUVEAU"}
                    paragraph={' '}
                />

            </div>

            <SectionHeader
                title={'EFFICIENT CYLINDER REPAIR BY EXPERTS'}
                subtitle={" Les vérins hydrauliques sont soumis à des contraintes extrêmes en raison de leur extension et de leur rétraction constantes. Le résultat est l'usure, ce qui met tout le processus de travail en danger de défaillance. La révision d'un vérin est une bonne alternative à l'achat d'un neuf. Le centre de réparation des vérins HANSA‑FLEX répare rapidement et avec un minimum d'arrêt les vérins hydrauliques de toutes tailles et de tous fabricants, ainsi que les pompes hydrauliques, les valves, les unités de puissance et les moteurs."}
            />


            <div className='div-gray'>


                <div >
                    <br />
                    <ImageText data={ProfessionalRepair} left={false} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={ExcellentRange} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={EfficientOrder} left={false} /> <br /><br />
                </div>

            </div>


            <div
                className='div-gray center-all-items'>
                <SectionHeader
                    title={" Une réparation de vérin HANSA‑FLEX présente de nombreux avantages. "}
                    subtitle={"Nous offrons une réparation rapide et professionnelle par des experts expérimentés avec un service complet, le tout auprès du même fournisseur. Vous bénéficiez de temps d'arrêt minimum ; nous bénéficions d'une satisfaction client élevée. "}
                />
                <FeatureCardCarousel featuresData={featuresData} />

            </div>

            <div>
                <SectionHeader
                    title={"RÉPARATION DE VÉRINS DE TOUS TYPES ET DE TOUTES MARQUES "}
                    paragraph={" Nos techniciens hydrauliques expérimentés réparent plus de 6 000 vérins hydrauliques de toutes tailles, fabricants et types chaque année."}
                />
                <ImageText
                    data={data1} />

            </div>

            <div>
                <SectionHeader
                    title={"Réparations professionnelles par HANSA‑FLEX "}
                    subtitle={" Avec HANSA‑FLEX, vous bénéficiez de réparations rapides et professionnelles effectuées par des experts expérimentés en technologie des fluides. "}
                />
            </div>

            <div>
                <Container className='custom-container'>
                    <Row>
                        <Col sm={12} md={6} lg={4} ><ImageCard image={Cylinder}
                            title="Installation de vérins par des professionnels "
                            desc={" Les spécialistes de HANSA‑FLEX réparent des vérins hydrauliques endommagés de toutes tailles, de tous fabricants et de tous types "}
                            buttonName="READ MORE" /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={Manufacture}
                            title="La fabrication de nouveaux cylindres. "
                            desc={"Les experts en fluides de HANSA-FLEX sont des personnes officiellement autorisées pour l'équipement sous pression et vous soutiennent dans toutes les tâches de maintenance requises. "}
                            buttonName="LIRE LA SUITE " /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={Seals}
                            title=" Joints sur mesure "
                            desc={"Le centre de réparation des cylindres HANSA-FLEX ne se contente pas de reconditionner les vérins hydrauliques, mais répare également rapidement d'autres composants hydrauliques pour garantir un temps d'arrêt minimal. "}
                            buttonName="READ MORE" /></Col>
                    </Row>
                </Container>

            </div>


        </div>








    )
}

export default HydraulicCylinderRepair
