import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgProduction_Centerspread from '../../../assets/Services/Production/Production/Production_Centerspread.jpg';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../Components/ImageText/ImageText';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook;
import imgProduction_Imagetext_01 from '../../../assets/Services/Production/Production/Production_Imagetext_01.jpg';
import imgProduction_Imagetext_02 from '../../../assets/Services/Production/Production/Production_Imagetext_02.jpg';
import imgProduction_Imagetext_03 from '../../../assets/Services/Production/Production/Production_Imagetext_03.jpg';
import imgProduction_Imagetext_04 from '../../../assets/Services/Production/Production/Production_Imagetext_04.jpg';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgProduction_Imagecard_01 from '../../../assets/Services/Production/Production/Production_Imagecard_01.jpg';
import imgProduction_Imagecard_02 from '../../../assets/Services/Production/Production/Production_Imagecard_02.jpg';
import imgProduction_Imagecard_03 from '../../../assets/Services/Production/Production/Production_Imagecard_03.jpg';
import imgProduction_Imagecard_04 from '../../../assets/Services/Production/Production/Production_Imagecard_04.jpg';
import imgProduction_Imagecard_05 from '../../../assets/Services/Production/Production/Production_Imagecard_05.jpg';
import imgProduction_Imagecard_06 from '../../../assets/Services/Production/Production/Production_Imagecard_06.jpg';
import imgProduction_Imagecard_07 from '../../../assets/Services/Production/Production/Production_Imagecard_07.jpg';
import imgProduction_Imagecard_08 from '../../../assets/Services/Production/Production/Production_Imagecard_08.jpg';
import imgProduction_Imagecard_09 from '../../../assets/Services/Production/Production/Production_Imagecard_09.jpg';
import imgProduction_Imagecard_10 from '../../../assets/Services/Production/Production/Production_Imagecard_10.jpg';
import imgProduction_Imagecard_11 from '../../../assets/Services/Production/Production/Production_Imagecard_11.jpg';
import imgProduction_Imagecard_12 from '../../../assets/Services/Production/Production/Production_Imagecard_12.jpg';
import { Container, Row, Col } from 'react-bootstrap';



const Production = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Production_Imagetext_01 = {
    image: imgProduction_Imagetext_01,
    title: 'Votre partenaire pour tout projet',
    listItems: ["Les installations de production de HANSA-FLEX peuvent répondre en toute confiance à un large éventail d'exigences des clients. Nous fabriquons des prototypes individuellement selon les spécifications du client et réalisons des projets spéciaux ou remettons à neuf des composants, en tant que pièces uniques ou dans le cadre d'une production en petite ou grande série. Comme notre savoir-faire et nos compétences internes couvrent tout le spectre de l'hydraulique, nous fabriquons et prémontons des combinaisons personnalisées de tuyaux, de tubes, de compensateurs, de vérins et de raccords spéciaux, souvent en les combinant pour former des groupes de composants complets. La plus grande fiabilité des processus et les machines les plus modernes nous permettent de fournir aux clients industriels, en tant que fournisseur OEM, de grandes séries de plusieurs dizaines de milliers d'articles par mois."],
  }

  const Production_Imagetext_02 = {
    image: imgProduction_Imagetext_02,
    title: "Compétences polyvalentes et grandes connaissances spécialisées",
    description: "Sur nos sites de production, nous maîtrisons parfaitement un large éventail de techniques de fabrication. En outre, nous disposons d'un personnel spécialisé qualifié et certifié et de machines ultramodernes",
    listItems: ["Fours de brasage pour des procédures de brasage et de brasure sophistiquées",  'Certified orbital and stainless steel welders, In-house paint shop, 5-axis machining centre for accurately machined parts',"Machines à plier CNC de précision, robots de pliage et de soudage","Assurance qualité sur nos propres bancs d'essai"],
  }

  const Production_Imagetext_03 = {
    image: imgProduction_Imagetext_03,
    title: "Conseils et assistance tout au long du processus de fabrication.",
    listItems: [" Nos activités de production ne se limitent pas à la fabrication de composants. En tant que partenaire système, nous sommes là pour aider et assister nos clients tout au long du processus de fabrication. Nous concevons et développons des produits en fonction des exigences spécifiques du client et les optimisons jusqu'à ce que tout soit parfait. Après la fabrication, nous nous occupons de l'assurance qualité et de la mise en service. Non seulement pour les petits lots de composants, mais aussi pour la production en série à l'échelle industrielle. Sur le plan logistique, nous aidons nos clients avec des kits prêts à installer, des livraisons Just-In-Time ou Just-In-Sequence et le système kanban."],
  }

const Production_Imagetext_04 = {
    image: imgProduction_Imagetext_04,
    title: '',
    description: "HANSA-FLEX dispose d'installations de production dans plusieurs pays. Ils sont étroitement coordonnés et travaillent selon les mêmes normes. Cette structure équilibrée, décentralisée et globale présente de nombreux avantages :",
    listItems: ["Risque de défaillance réduit et grande sécurité d'approvisionnement", "Réduction des coûts par l'utilisation de synergies","Les mêmes normes de qualité dans le monde entier"],
  }


  const featuresData = [
    {
      title: "Compétence dans tous les domaines",
      description: "",
      image: imgMark
    },
    {
      title: "Flexibilité pour répondre aux besoins des clients.",
      description: "Avec la conception, la production et la logistique toutes en interne, nous ne laissons aucun besoin du client non satisfait",
      image: imgMark
    },
    {
      title: "Reliable and rapid implementation",
      description: "Toujours dans les délais. Les commandes urgentes peuvent souvent être livrées dès le lendemain.",
      image: imgMark
    },
    {
      title: "Qualité éprouvée.",
      description: "Fiabilité du processus assurée et atteinte de la qualité éprouvée de HANSA-FLEX",
      image: imgMark
    },

    // Add more objects for additional FeaturesCard data
  ];

  return (
    <div>

      <CenterStage imageSrc={imgProduction_Centerspread} title={""}
        paragraph={"UNE FLEXIBILITÉ MAXIMALE AVEC UNE PRODUCTION INTERNE"}
      />

      <div>
        <SectionHeader
          title={"Partenaire pour systèmes hydrauliques avec production interne."}
          subtitle={"HANSA-FLEX n'est pas seulement le plus grand distributeur d'hydraulique en Europe, il est également un fabricant leader de composants de haute qualité tels que les unités de puissance, les tuyaux métalliques et spéciaux, les raccords spéciaux, les blocs de valves, les joints et les vérins. Dans nos installations de production, nous pouvons fabriquer aussi bien des pièces uniques que des composants en série en tant que OEM. Nos 450 employés et nos machines de pointe nous permettent d'atteindre une flexibilité maximale et la plus haute qualité."}
        />
        <div >
          <br />
          <ImageText data={Production_Imagetext_01} left={false} />
        </div>

        <div >
          <br />
          <ImageText data={Production_Imagetext_02} />
        </div>

        <div >
          <br />
          <ImageText data={Production_Imagetext_03} left={false} />
        </div>
      </div>


      <SectionHeader
        title={"Le processus complet de fabrication auprès d'un seul fournisseur."}
        subtitle={"HANSA-FLEX est un partenaire de systèmes hydrauliques et travaille de manière flexible selon les souhaits du client, fournissant des produits et des services d'une qualité constamment élevée."}
      />

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>

      <div>
        <SectionHeader
          title={"Installations de production de HANSA-FLEX."}
          subtitle={"HANSA-FLEX dispose de compétences de production étendues en interne. Nous pouvons réagir rapidement et de manière flexible"}
        />
      </div>

      <Container className="custom-container">
        <Row>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgProduction_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Production en série à grande échelle industrielle." desc="Nous fournissons aux entreprises en tant que fournisseur OEM des conduites et des flexibles prêts à être installés, des groupes de composants préassemblés et des kits en production en série de petites ou grandes séries." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                <ImageCard image={imgProduction_Imagecard_02} learnMoreLink="/metal-hoses" title="Fabrication de prototypes." desc="HANSA-FLEX fabrique des articles individuels selon les spécifications, dessins ou échantillons du client, et si nécessaire, conseille et assiste le produit sur le chemin de la production en série." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgProduction_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Fabrication d'unités personnalisées." desc="HANSA-FLEX développe et fabrique des unités de puissance hydraulique efficaces selon les exigences du client et propose des systèmes complets comprenant l'hydraulique." />
            </Col>

        </Row>
    </Container>


    <Container className="custom-container">
        <Row>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgProduction_Imagecard_04} learnMoreLink="/hydraulic-hoses" title="Fabrication de raccords spéciaux pour des besoins particuliers." desc="HANSA-FLEX conçoit et produit des raccords parfaitement adaptés au flexible. Nous fabriquons des raccords spéciaux en plus de nos composants de connexion standard." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                <ImageCard image={imgProduction_Imagecard_05} learnMoreLink="/metal-hoses" title="Technologie d'étanchéité sur mesure." desc="Les systèmes hydrauliques et pneumatiques ne sont aussi performants que leurs joints. Si des formes spéciales sont nécessaires, HANSA-FLEX les fabrique en pièces uniques ou en petites séries." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgProduction_Imagecard_06} learnMoreLink="/Industrial-hoses" title="Tuyaux métalliques pour des conditions exigeantes." desc="Nous fabriquons des tuyaux métalliques résistant aux charges mécaniques élevées et aux vides. Ils peuvent également être utilisés en combinaison avec des tuyaux en PTFE pour une large gamme de différents fluides." />
            </Col>

        </Row>
    </Container>

    <Container className="custom-container">
        <Row>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgProduction_Imagecard_07} learnMoreLink="/hydraulic-hoses" title="Des flexibles industriels pour un grand nombre d'applications." desc="Les flexibles industriels transportent une large gamme de fluides, allant de l'eau et de l'air aux substances chimiques, jusqu'aux carburants, aux gaz et aux denrées alimentaires." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                <ImageCard image={imgProduction_Imagecard_08} learnMoreLink="/metal-hoses" title="Tubes cintrés avec précision." desc="Nous produisons des joints de dilatation, des coudes de tuyaux et des raccords spéciaux en utilisant une technologie de fabrication de pointe pour une précision maximale et une qualité de première classe." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgProduction_Imagecard_09} learnMoreLink="/Industrial-hoses" title="Entretien et fabrication de vérins hydrauliques." desc="HANSA-FLEX révise les vérins hydrauliques défectueux fabriqués par n'importe quel fabricant et fabrique des remplacements appropriés à partir d'un échantillon, d'un dessin ou des exigences du client." />
            </Col>

        </Row>
    </Container>


    <SectionHeader
        title={"CES SUJETS PEUVENT ÉGALEMENT VOUS INTÉRESSER"}
        subtitle={"L'organisation mondiale et décentralisée de nos installations de production garantit la sécurité d'approvisionnement et une qualité constante."}
      />





    <div >
          <br />
          <ImageText data={Production_Imagetext_04} left={false} />
        </div>


        <SectionHeader
        title={"CES SUJETS POURRAIENT AUSSI VOUS INTÉRESSER"}
        subtitle={""}
      />


<Container className="custom-container">
        <Row>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgProduction_Imagecard_10} learnMoreLink="/hydraulic-hoses" title="Le service d'urgence mobile pour des actions urgentes." desc="Avec environ 200 véhicules de service à travers l'Allemagne, notre service hydraulique rapide mobile est toujours là pour vous, disponible 24h/24 et 7j/7, 365 jours par an, pour minimiser vos arrêts." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                <ImageCard image={imgProduction_Imagecard_11} learnMoreLink="/metal-hoses" title="Assemblage et installation par des techniciens expérimentés." desc="Les techniciens de service hautement qualifiés de HANSA-FLEX effectuent l'assemblage et l'installation sur vos systèmes hydrauliques et machines." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgProduction_Imagecard_12} learnMoreLink="/Industrial-hoses" title="Équipements de travail et matériaux d'exploitation inspectés de manière fiable." desc="HANSA-FLEX vous soutient avec l'inspection des systèmes hydrauliques et de leurs composants conformément aux exigences légales prescrites telles que les dispositions de" />
            </Col>

        </Row>
    </Container>






















    </div>
  )
}
export default Production;