import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png'

import Stage from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Stage.jpg'
import Overhauling from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Overhauling.jpg'
import Servising from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Servising.jpg'
import Reconditioning from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Reconditioning.jpg'
import Optimisation from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Optimisation.jpg'
import Retrofitting from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Retrofitting.jpg'
import Inspection from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Inspection.jpg'
import HoseManagement from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/HoseManagement.jpg'
import HFOffers from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/HFOffers.jpg'
import AllAboutOil from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/AllAboutOil.jpg'
import Availability from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Availability.jpg'
import YouCanCount from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/YouCanCount.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import HFTab from '../../../Components/HfTabs/HfTabs';


const RepairAndOverhaul = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);


    const featuresData = [
        {
            title: "Service professionnel ",
            description: " Tout le travail est effectué par des techniciens hautement compétents avec des années d'expérience",
            image: imgMark
        },
        {
            title: "Temps d'arrêt minimum ",
            description: " Traitement rapide des commandes et service express optionnel pour les réparations critiques en termes de délai",
            image: imgMark
        },
        {
            title: "Qualité éprouvée ",
            description: " Exécution de différents tests et préparation de rapports détaillés si nécessaire.",
            image: imgMark
        },
        {
            title: "Réconditionnement plutôt que remplacement ",
            description: " Particulièrement dans le cas de composants coûteux, le reconditionnement est souvent une solution économiquement plus avantageuse.",
            image: imgMark
        }
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: " Optimisation des systèmes hydrauliques",
            description: "Une augmentation de l'usure des composants hydrauliques est un signe qu'il y a un problème dans le système. Les analyses de défaillance systématiquement effectuées montrent précisément où se cachent les points faibles. Après que les spécialistes du service industriel et du service des fluides de HANSA‑FLEX ont examiné les composants et analysé le fluide, ils utilisent les résultats pour élaborer des recommandations spécifiques d'optimisation. Avec le travail d'optimisation terminé, les machines fonctionnent de manière plus fiable et leur efficacité s'améliore durablement. ",
            image: Optimisation
        },
        {
            title: "Rétrofit et rénovation ",
            description: " En tant que partenaire système, nous vous soutenons de manière exhaustive dans toutes les tâches requises pour la rénovation de vos machines et systèmes",
            listItems: [ "Analyse économique des projets de rénovation",
                "Planification et conseils concernant les mesures de rénovation",
                "Exécution de tous les travaux liés à la technologie des fluides" ],
            image: Retrofitting
        },
        {
            title: "Inspection de l'équipement hydraulique en fonctionnement ",
            description: "Les experts formés de HANSA‑FLEX sont des personnes officiellement autorisées pour les inspections de l'équipement et du matériel hydraulique conformément aux réglementations actuellement en vigueur. Ils réalisent les inspections et préparent les rapports d'inspection. ",
            image: Inspection
        },
        {
            title: "Gestion complète des flexibles avec HANSA‑FLEX ",
            description: "La gestion professionnelle de vos lignes de flexibles hydrauliques vous assure de bénéficier d'un entretien et d'une maintenance plus efficaces de vos machines et systèmes. ",
            listItems: [ "Étiquetage clair des lignes de flexible avec X-CODE","Logiciel de gestion de flexible X-CODE Manager facile à utiliser", "Divers packages de services pour la gestion des flexibles" ],
            image: Inspection
        },
    ];

    const tabTitles = [
        { title: "Optimisation", eventKey: "Optimisation" },
        { title: "Retrofitting", eventKey: "Retrofitting" },
        { title: "Inspections", eventKey: "Inspections" },
        { title: "Hose management", eventKey: "Hose management" },
    ];

    const data1 = {
        image: HFOffers,
        listItems: [ 
            "Détection des dommages et analyse des défauts",
            "Préparation d'un devis",
            "Exécution de tous les travaux associés à la réparation",
            "Démontage, enlèvement, retour et réinstallation",
            "Élimination écologique des composants défectueux",
            "Conseils sur l'optimisation des machines et des installations" ]
    }



    const isMobile = window.innerWidth <= 768;

    return (
        <div>
            <CenterStage
                imageSrc={Stage}
                title={"RÉPARATION ET ENTRETIEN DES SYSTÈMES ET COMPOSANTS HYDRAULIQUES "}
                paragraph={" Les systèmes hydrauliques sont continuellement sollicités et donc l'usure finira par se produire sur leurs composants. Si cela n'est pas détecté à temps, cela peut entraîner une défaillance complète du système hydraulique. Vos machines et systèmes sont de nouveau opérationnels en un rien de temps grâce aux réparations et à l'entretien professionnels de HANSA-FLEX. Nous vous soutenons également avec une analyse de système pour détecter les dommages éventuels à temps et éviter les temps d'arrêt imprévus."}
            />
            <div>
                <SectionHeader
                    title={"RÉPARATIONS PROFESSIONNELLES PAR HANSA-FLEX "}
                    subtitle={" Avec HANSA-FLEX, vous bénéficiez de réparations rapides et professionnelles effectuées par des experts expérimentés en technologie des fluides. "}
                />
            </div>
            <div>
                <Container className='custom-container'>
                    <Row>
                        <Col sm={12} md={6} lg={4} ><ImageCard image={Overhauling}
                            title="Révision des vérins hydrauliques défectueux "
                            desc={"Les spécialistes de HANSA-FLEX réparent les vérins hydrauliques endommagés de toutes tailles, de tous fabricants et de tous types. "} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={Servising}
                            title=" Entretien et réparation des accumulateurs de pression "
                            desc={"Les experts en fluides de HANSA-FLEX sont des personnes officiellement autorisées pour les équipements sous pression et vous soutiennent dans toutes les tâches de maintenance requises "} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={Reconditioning}
                            title="Reconditionnement de pompes, moteurs et unités de puissance "
                            desc={"Le centre de réparation des vérins de HANSA-FLEX ne se contente pas de reconditionner les vérins hydrauliques, mais répare également rapidement d'autres composants hydrauliques pour garantir un temps d'arrêt minimal. "} /></Col>
                    </Row>
                </Container>
            </div>

            <SectionHeader
                title={" LA GESTION DES FLUIDES PRÉVIENT LES DOMMAGES LIÉS À L'HUILE "}
                subtitle={"Les contaminants dans le fluide peuvent avoir des conséquences graves. Des soins méticuleux de l'huile sont donc essentiels pour garantir le bon fonctionnement sans faille des systèmes hydrauliques. Les experts en fluides de HANSA-FLEX vous soutiennent dans tout, de l'analyse de l'huile au nettoyage et à la surveillance continue. "}
            />

            <div>
                <SectionHeader
                    title={"Vous bénéficiez de nombreux avantages en utilisant le service de réparation HANSA-FLEX."}
                    subtitle={" Vous bénéficiez de plusieurs façons en utilisant le service de réparation HANSA-FLEX."}
                />
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"Services de réparation "}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"HANSA-FLEX propose un service complet à partir d'un seul fournisseur. "}
                    subtitle={" Nous vous proposons un service complet pour tout ce qui concerne la réparation et la révision de vos composants hydrauliques, et tout cela auprès d'un seul fournisseur. "}
                />
                <ImageText
                    data={data1}
                />
            </div>

            <div>
                <SectionHeader
                    title={"Quelque chose qui pourrait également vous intéresser "}
                />
            </div>

            <div>
                <Container className='custom-container'>
                    <Row>
                        <Col sm={12} md={6} lg={4} ><ImageCard image={AllAboutOil}
                            title="Tout sur l'huile : Service des fluides "
                            desc={"Le service des fluides HANSA-FLEX effectue des analyses d'huile directement sur site, effectue le nettoyage de l'huile ou le remplacement complet du fluide et donne des conseils sur tous les aspects des fluides. "}
                            buttonName=" Lisez la suite " /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={Availability}
                            title="Disponibilité 24/7 : Service hydraulique rapide "
                            desc={"  Nous sommes là pour vous en cas d'urgence, à toute heure : notre Service Hydraulique Rapide peut rapidement remplacer les composants défectueux tels que les lignes de tuyaux. "}
                            buttonName="READ MORE " /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={YouCanCount}
                            title=" Vous pouvez compter sur nous : Service industriel"
                            desc={"Nous vous offrons un service complet grâce à notre Service Industriel HANSA-FLEX. Les techniciens de service assurent l'entretien, la maintenance et l'installation des lignes de tuyaux hydrauliques et... "}
                            buttonName="Passer une commande de réparation " /></Col>

                    </Row>
                </Container>

            </div>



        </div>








    )
}

export default RepairAndOverhaul
