import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import Stage from '../../../assets/Services/FluidManagement/OilAnalysis/Stage.jpg'
import Determineinfo from '../../../assets/Services/FluidManagement/OilAnalysis/Determineinfo.jpg'
import SystematicDamage from '../../../assets/Services/FluidManagement/OilAnalysis/SystematicDamage.jpg'
import ComprehensiveManagement from '../../../assets/Services/FluidManagement/OilAnalysis/ComprehensiveManagement.jpg'
import Particles from '../../../assets/Services/FluidManagement/OilAnalysis/Particles.jpg'
import Water from '../../../assets/Services/FluidManagement/OilAnalysis/Water.jpg'
import Air from '../../../assets/Services/FluidManagement/OilAnalysis/Air.jpg'
import Temp from '../../../assets/Services/FluidManagement/OilAnalysis/Temp.jpg'
import Mixing from '../../../assets/Services/FluidManagement/OilAnalysis/Mixing.jpg'
import KeepAnEye from '../../../assets/Services/FluidManagement/OilAnalysis/KeepAnEye.jpg'
import EfficientOil from '../../../assets/Services/FluidManagement/OilAnalysis/EfficientOil.jpg'

const OilAnalysis = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    //ImageText Data
    const data1 = {
        image: Determineinfo,
        title: 'Déterminez toutes les informations sur le pétrole',
        description: " Un bilan de santé de votre système hydraulique, y compris une analyse d'huile professionnelle, fournit toutes les informations importantes sur votre fluide, telles que la contamination, le mélange et le vieillissement. Le niveau de propreté de l'huile est également mesuré conformément à la norme ISO 4406. L'analyse d'huile est réalisée par un laboratoire indépendant accrédité et certifié. Les spécialistes des fluides de HANSA‑FLEX interprètent les résultats pour fournir des recommandations spécifiques. De cette manière, vous pouvez rectifier les défauts avant qu'ils ne se développent pleinement et ne causent des dommages.",
    }

    const data2 = {
        image: SystematicDamage,
        title: "Détection systématique des dommages",
        description: "Lorsque des dommages surviennent, vous devez agir rapidement. Les spécialistes des fluides et de l'hydraulique expérimentés de HANSA‑FLEX recherchent les causes directement sur site et effectuent immédiatement une analyse d'huile professionnelle. Les résultats servent de base pour détecter les dommages aux composants tels que les pompes, les vérins, les lignes de tuyaux et les filtres. Ensuite, les experts entreprennent les mesures spécifiques nécessaires pour remettre votre système en état de fonctionnement. Nous pouvons également planifier et gérer d'autres travaux pour maintenir le bon fonctionnement de votre système à long terme, tels que l'installation de filtres ou le rinçage de l'hydraulique. Nous vous soutenons également dans la mise en œuvre de mesures de maintenance préventive.",
    }

    const data3 = {
        image: ComprehensiveManagement,
        title: "Gestion globale des fluides",
        description: "Votre analyse d'huile fournit la base pour optimiser votre système. Elle met en évidence les éventuels points faibles et vous aide à les rectifier en temps voulu. En plus de réaliser diverses mesures telles que le nettoyage ou le séchage de l'huile hydraulique, le Service Fluides de HANSA‑FLEX peut également mettre en œuvre d'autres processus dans le cadre de la gestion des fluides. De la définition du calendrier des processus de soins de l'huile à la décision des intervalles d'entretien, en passant par le suivi des résultats des mesures jusqu'à la formation des employés : les spécialistes des fluides et de l'hydraulique expérimentés de HANSA‑FLEX sont disponibles à tout moment pour répondre à vos questions et fournir des informations sur la gestion des fluides.",
    }

    const data4 = {
        image: KeepAnEye,
        listItems: ["Analyse de l'état actuel et planification de base", "Définition des valeurs limites pour répondre aux exigences du système hydraulique ", "Sélection et installation de capteurs de condition d'huile ",
            "Configuration et mise en place de chemins de transmission des données", "Évaluation et interprétation des valeurs mesurées", "Fabrication et installation de dispositifs d'alarme, etc."]
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Analyse parfaite",
            description: "Les analyses d'huile en laboratoire ou sur site fournissent des informations définitives sur l'état de l'huile et de la machine. ",
            image: imgMark
        },
        {
            title: "Détection fiable des défauts ",
            description: "Les défauts tels que le mélange d'huile, la contamination ou la présence d'eau dans l'huile sont détectés immédiatement. ",
            image: imgMark
        },
        {
            title: "Le moment du changement d'huile ",
            description: "L'intervalle optimal de changement d'huile est déterminé individuellement pour chaque machine ou installation.",
            image: imgMark
        },
        {
            title: "Qualité garantie ",
            description: " Contrôles de qualité après les changements d'huile et réalisation des services de garantie.  ",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Contamination par des particules solides ",
            description: "La contamination de l'huile hydraulique par des matériaux solides tels que la poussière ou des particules de métal ou de joints est l'une des causes de dommages les plus fréquentes. La source des particules pourrait être de l'huile fraîche non filtrée, une conception de filtre inadéquate, un réservoir mal rempli, l'érosion des composants ou l'entrée de particules de saleté lors du montage. La première étape pour rectifier ce problème consiste à examiner l'ensemble du système, y compris l'utilisation de compteurs de particules sur site si nécessaire. En procédant ainsi, vous pouvez être absolument certain de la cause des dommages et prendre précisément les mesures nécessaires pour prévenir l'entrée de saleté, telles que l'installation de systèmes de filtration. ",
            image: Particles
        },
        {
            title: "Contamination par l'eau ",
            description: "La contamination de l'huile hydraulique par l'eau entraîne la corrosion, la cavitation, la formation de boues ou le vieillissement prématuré de l'huile. La contamination par l'eau peut être causée par la condensation, les refroidisseurs huile-eau, le nettoyage avec des jets de vapeur, les fluctuations de température sévères, l'entrée d'eau par pulvérisation ou des joints de cylindre défectueux. Pour éviter la contamination de l'huile hydraulique par l'eau, le Service Fluides de HANSA‑FLEX effectue d'abord une inspection intensive de l'ensemble du système hydraulique à la recherche de causes possibles. Ensuite, les experts dressent une liste de mesures appropriées, telles que l'utilisation de filtres respiratoires dessicants ou de systèmes de déshydratation sous vide et, si vous le souhaitez, les mettent en œuvre. ",
            image: Water
        },
        {
            title: "Undissolved air",
            description: "Un niveau d'huile insuffisant dans le réservoir, des lignes d'aspiration fuyantes vers la pompe ou un purgé insuffisant : trop d'air non dissous dans l'huile hydraulique peut entraîner des dommages aux composants. L'huile hydraulique enrichie en air est sujette au vieillissement prématuré. Il existe également le risque que des bulles dans le mélange d'huile-gaz implosent (cavitation) en raison de pics de pression et de température ou explosent sous une compression excessive (effet diesel). De la conception du réservoir d'huile à la sélection du fluide adapté jusqu'à la vérification de l'efficacité des composants de séparation d'air : les experts en fluides et en hydraulique expérimentés de HANSA‑FLEX vous conseillent sur la manière d'éviter l'oxydation, la cavitation ou les effets micro-diesel. ",
            image: Air
        },
        {
            title: "Hautes températures et cisaillement ",
            description: "Les températures de fonctionnement excessives réduisent les performances de l'huile hydraulique. Les conséquences : des additifs inefficaces, un vieillissement accéléré de l'huile, la formation d'acide dans l'huile et, en dernier lieu, des dommages aux composants. Les pics de pression et de température extrêmes entraînent une surcharge du fluide. Un stress excessif décompose les chaînes de molécules dans l'huile, ce qui affecte négativement ses caractéristiques d'écoulement. Cela peut également entraîner la perte d'efficacité des additifs, des intervalles de changement d'huile plus courts et une usure plus élevée des composants. La surveillance de la température et de la pression, soit dans le cadre de l'analyse d'huile, soit à l'aide de capteurs, peut être utile pour prévenir les effets néfastes des températures élevées.",
            image: Temp
        },
        {
            title: "Mélange d'huile ",
            description: "Le mélange de différents types d'huiles hydrauliques entraîne souvent une détérioration de leurs propriétés physiques. Le mélange se produit souvent involontairement lors du complément, du retrait ou de la réinstallation d'équipements ou lors du changement d'huile. Même les combinaisons d'huile de même classification peuvent entraîner un gonflement des composants élastomères et des lignes de tuyaux hydrauliques, la formation de mousse, une séparation moins efficace de l'air ou des boues dans l'huile. Le mélange d'huiles hydrauliques entraîne également la perte de tous les droits en vertu de la garantie du fabricant de la machine. Aucune combinaison d'huiles ne peut garantir un fonctionnement sans problème à 100 %. Par conséquent, il est toujours préférable d'éviter de mélanger différentes huiles. ",
            image: Mixing
        },
    ];

    const tabTitles = [
        { title: "Particles", eventKey: "Particles" },
        { title: "Water", eventKey: "Water" },
        { title: "Air", eventKey: "Air" },
        { title: "Temperature", eventKey: "Temperature" },
        { title: "Mixing", eventKey: "Mixing" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"Analyse informative de l'huile "}
                    paragraph={" Contamination, mélange et vieillissement de l'huile : les analyses d'huile professionnelles permettent de détecter les problèmes liés à l'huile à un stade précoce afin qu'ils puissent être rectifiés avant qu'ils ne causent une usure et des arrêts de machines. Les spécialistes des fluides de HANSA‑FLEX effectuent leurs tests directement sur site ou en laboratoire, formulent leurs recommandations et mettent en œuvre les mesures appropriées. Ils peuvent également installer des capteurs de condition d'huile pour une surveillance continue des fluides. "}
                />
            </div>

            <div>
                <SectionHeader
                    title={"Analyses systématiques de l'huile"}
                    subtitle={"Adoptez une approche systématique basée sur des analyses d'huile approfondies."}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
            </div>

            <div>
                <SectionHeader
                    title={"Analyses d'huile professionnelles effectuées par des experts "}
                    subtitle={"L'analyse d'huile professionnelle du Service Fluides de HANSA‑FLEX révèle toutes les informations importantes contenues dans votre huile hydraulique. "}
                />

                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"Fluide contaminé "}
                    subtitle={"Ces cinq facteurs sont nuisibles à l'huile hydraulique.  "}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"Gardez un œil sur votre huile avec la surveillance de l'état"}
                />
                <ImageText
                    data={data4} />
            </div>

            <div>
                <SectionHeader
                    title={"Ce que tout le monde devrait savoir sur la gestion des fluides "}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={EfficientOil} learnMoreLink={"/oil-filtering-and-cleaning"}
                                title="Filtration efficace de l'huile  "
                                desc={"La filtration régulière de l'huile garantit le bon déroulement de vos processus de production et aide à éviter les dommages aux équipements, systèmes et machines.  "} /></Col>
                        </Row>
                    </Container>
                </div>

            </div>
        </div>
    )
}

export default OilAnalysis