import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import { Col, Container, Row } from 'react-bootstrap';

import imgStage from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/Stage.jpg'
import BladderAccumulator from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/BladderAccumulator.jpg'
import RobustDipharm from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/RobustDipharm.jpg'
import RequiredEquipment from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/RequiredEquipment.jpg'
import PressureAccumulator from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/PressureAccumulator.jpg'
import HowItWork from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/HowItWork.jpg'
import Inspection from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/Inspection.jpg'
import Repair from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/Repair.jpg'
import Stock from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/Stock.jpg'


const PressureAccumulators = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const Accumulator1 = {
        image: BladderAccumulator,
        title: "Accumulateurs à vessie pour de grands volumes d'huile",
        description: "Les accumulateurs à vessie se composent d'un récipient sous pression de haute résistance et d'une vessie scellée, pouvant être remplie de gaz. Ces accumulateurs se distinguent par leur grande vitesse de réaction et leur retour rapide du fluide dans le système.",
        listItems: ["Volume d'huile de 1 litre à 50 litres", "Pression de travail autorisée jusqu'à 350 bars", "Les fluctuations sont rapidement égalisées"],
        buttonName: "TOUS LES ACCUMULATEURS À VESSIE"
    }

    const Accumulator2 = {
        image: RobustDipharm,
        title: 'Accumulateurs à membrane robustes',
        description: "Les accumulateurs à membrane se composent d'un récipient sous pression sphérique et sont robustes face aux forces externes.Les accumulateurs à membrane sont utilisés pour des applications avec des volumes d'huile plus petits.",
        listItems: ["Volume d'huile de 0,075 litre à 1,4 litre", "Pression de travail de 100 à 350 bars", "Version pour application à basse température disponible pour une utilisation jusqu'à - 40 °C"],
        buttonName: "TOUS LES ACCUMULATEURS À MEMBRANE"
    }

    const Accumulator3 = {
        image: RequiredEquipment,
        title: 'Équipement requis et accessoires adaptés',
        description: 'La boutique en ligne HANSA‑FLEX propose de nombreux produits, tels que des adaptateurs, des écrous et des kits de réparation, pour le montage et le fonctionnement sécurisé de votre accumulateur de pression.',
        listItems: ["Bloc de sécurité et d'isolation pour la sécurisation et la dépressurisation", "Supports et pinces pour le montage de l'accumulateur", "Équipement d'inspection et de remplissage pour vérifier la pression d'azote"],
        buttonName: "ACCESSOIRES"
    }

    const Accumulator4 = {
        image: PressureAccumulator,
        listItems: ["Température admissible pour les accumulateurs à vessie : - 15 ℃ à + 80 ℃", "Température admissible pour les accumulateurs à membrane : - 40 °C à + 80 °C", "Matériau pour la vessie et la membrane : NBR de qualité standard", "Matériau pour la version à basse température de la membrane : ECO", "Connexions : filetages internes ou externes"],
    }

    const Accumulator5 = {
        image: HowItWork,
        title: "Fonctionnement d'un accumulateur de pression",
        description: "Les accumulateurs de pression utilisent la compressibilité de l'azote pour absorber et stocker de l'énergie. Une membrane ou une vessie élastomérique en expansion divise le récipient en volumes séparés pour le gaz et l'huile.Le côté gaz de l'accumulateur hydraulique est pressurisé avec du gaz à la pression requise avant le démarrage du système. Si le côté fluide du système devient soumis à une pression croissante, les pressions des deux côtés sont égalisées par le mouvement du composant de séparation et la compression du gaz. Le résultat est un équilibre entre la pression du gaz et du fluide, et le fluide est admis dans l'accumulateur.Dès que la pression hydraulique diminue, le gaz comprimé se dilate et pousse le fluide de nouveau dans le circuit hydraulique.",
    }

    // Données du carrousel de cartes de fonctionnalités
    const featuresData = [
        {
            title: "Haute qualité",
            description: "Tous les accumulateurs de pression sont fabriqués par des entreprises européennes de renom.",
            image: imgMark
        },
        {
            title: "Aucun compromis sur la sécurité",
            description: "Les accumulateurs de pression répondent aux exigences de la Directive européenne sur les équipements sous pression (PED).",
            image: imgMark
        },
        {
            title: "Produits certifiés",
            description: "Fournis avec les certificats d'approbation européens ou internationaux appropriés.",
            image: imgMark
        },
        {
            title: "Conseils d'experts",
            description: "Nous vous accompagnons dans la sélection et la conception d'accumulateurs adaptés à votre application",
            image: imgMark
        },
        // Ajoutez plus d'objets pour des données supplémentaires de cartes de fonctionnalités
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"LES ACCUMULATEURS HYDRAULIQUES SONT DES RÉSERVOIRS DE COMPENSATION DE PRESSION ET DES AMORTISSEURS D'ÉNERGIE"}
                    paragraph={"Les accumulateurs de pression sont utilisés comme réservoirs de compensation de pression et équilibrent les variations de volume dues aux changements de température et les pics de pression dans les systèmes hydrauliques. Agissant comme accumulateur tampon, ils absorbent également l'énergie causée par les changements de pression et la libèrent ultérieurement si nécessaire. Ils sont également utilisés pour compenser en toute sécurité les fuites, amortir les pulsations et absorber les chocs dans les systèmes et les machines."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"ACCUMULATEURS DE PRESSION GAZ DE HANSA‑FLEX"}
                    subtitle={"La gamme HANSA‑FLEX comprend de nombreux accumulateurs à membrane et à vessie dans diverses conceptions. Nous pouvons également vous fournir d'autres types d'accumulateurs, tels que des accumulateurs à piston ou des accumulateurs spéciaux fabriqués individuellement sur demande."}
                />
                <ImageText
                    data={Accumulator1} />
                <ImageText
                    data={Accumulator2}
                    left={false} />
                <ImageText
                    data={Accumulator3} />
            </div>

            <div>
                <SectionHeader
                    title={"LES AVANTAGES DE L'UTILISATION DES ACCUMULATEURS HYDRAULIQUES À HUILE DE HANSA‑FLEX"}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"ACCUMULATEURS DE PRESSION DE HAUTE QUALITÉ DES SPÉCIALISTES DES FLUIDES"}
                    subtitle={"Tous les accumulateurs de pression sont livrés prêts à être installés en stock. Sur demande, nous pouvons installer votre accumulateur et le remplir d'azote sur site."}
                />
                <ImageText
                    data={Accumulator4} />
                <ImageText
                    data={Accumulator5} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"FORMATION ET SÉMINAIRES SUR LES ACCUMULATEURS DE PRESSION DANS LES SYSTÈMES HYDRAULIQUES"}
                    subtitle={"En collaboration avec l'Académie internationale de l'hydraulique (IHA), HANSA‑FLEX organise des séminaires pour les installateurs et le personnel de maintenance des accumulateurs de pression. Des experts transmettent leur savoir-faire spécialisé sur le fonctionnement sécurisé, le remplissage correct et la réparation des accumulateurs de pression."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"CES PRODUITS ET SERVICES PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={Inspection}
                                title="Inspection de votre accumulateur de pression par une personne officiellement autorisée"
                                desc={"Les experts en fluides de HANSA‑FLEX sont des personnes officiellement autorisées pour les équipements sous pression. Ils effectuent des inspections visuelles externes et fournissent des rapports d'inspection."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Repair}
                                title="Réparation et révision des systèmes d'accumulateurs de pression"
                                desc={"Le service industriel de HANSA‑FLEX vous soutient dans tous les travaux de service et de maintenance sur vos systèmes d'accumulateurs de pression, y compris le remplissage d'azote ou leur remplacement."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Stock}
                                title="Plus de 4 500 composants hydrauliques toujours en stock"
                                desc={"HANSA‑FLEX propose une large gamme de composants hydrauliques : des pompes et moteurs aux vérins et filtres, des valves aux commutateurs et capteurs."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )

}

export default PressureAccumulators