import React, { useEffect } from 'react';
import FullPageCarousel from '../Components/Carousel/FullPageCarousel';
import ImageCard from '../Components/ImageCard/ImageCard';
import { Container, Row, Col } from 'react-bootstrap';
import './PageCss/HomePage.css';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import CenterStage from '../Components/CenterStage/CenterStage';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook


import imgHose from '../assets/hose.jpg';
import imgMetalHose22 from '../assets/metal-hose.jpg';
import imgIndustrialHose from '../assets/industrial-hose.jpg';
import imgHighPressureHose from '../assets/high-pressure-hose.jpg'
import qualitySticker from '../assets/QualityLogo.png';
import glob from '../assets/GlobLogo.png';
import hose from '../assets/HoseLogo.png';
import isoLogo from '../assets/ISO-Logo.png';
import imgCarton from '../assets/Hansa-Flex_Kartons_Shop.png';
import imgDigitilization from '../assets/DigilisationImg.jpg'
import imgLogistics from '../assets/LogisticsImg.jpg';
import imgTrainingCourse from '../assets/TrainingCourseImg.jpg';
import imgIree from '../assets/news-events/iree.jpg';
import imgGarry from '../assets/news-events/garry.jpg';
import imgTree from '../assets/news-events/tree.jpg';
import imgBauma from '../assets/news-events/bauma.jpg';
import imgMachine from '../assets/machine.jpg';
import imgHighPressurePipe from '../assets/high-pressure-pipe.jpg';
import imgIFP from '../assets/IFP1.jpg';
import imgMHP from '../assets/MHP1.jpg';
import imgSHP from '../assets/SHP1.jpg';


import Promoter from '../Components/Promoter/Promoter';
import FeatureCardCarousel from '../Components/FeaturesCardCarousal/FeatureCardCarousal'

import imgGoetheVisit from '../assets/news-events/goethe.jpg';

import AOS from 'aos';
import 'aos/dist/aos.css';

import HoseManagement from '../assets/Services/HoseManagement.jpg'
import FluidServices from '../assets/Services/fluidService.jpg'
import MobileService from '../assets/Services/MobileService.jpg'
import HydraulicAcademy from '../assets/Services/HydraulicAcademy.jpg'
import CredentialsImg from '../assets/Services/Credentials.jpg'

import imgHydraulicHose from '../assets/Products/HosesAndHoseLines/Hydraulic hose/steelOrTextile.jpg'
import imgHoseFitting from '../assets/Products/HydraulicConnectionTechnology/HoseFitting/FittingsForExtreme.jpg'
import imgPipebend from '../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagetext2.jpg'
import imgMetalHose from '../assets/Products/HosesAndHoseLines/Metal Hoses/topClassMetalHose.jpg'
import imgCylinderRepair from '../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/EfficientOrder.jpg'
import imgTailoredTube from '../assets/TailoredTube.jpg'
import imgEasyOrderCash from '../assets/EasyOrderScan.jpg'
import imgXCOdeManager from '../assets/XCodeManager.jpg'
import ShowcaseCarousel from '../Components/ShowcaseCarousel/ShowcaseCarousel';
import imgFST from '../assets/FranceUnits/Entzheim/Entree_bureau_ENTHEIM.jpg'
import imgFGA from '../assets/FranceUnits/Gemenos/Entree_GEMENOS.jpg'
import imgFVT from '../assets/FranceUnits/Vitrolles/Entree_VITROLLES.jpg'
import NumberGrid from '../Components/NumberGrid/NumberGrid';



function HomePage() {

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);


  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 320, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 300, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []);

  //hall of fame
  const numbers = ['10+', '27+', '4750+'];
  const subtitles = ["Flexxpress", "Employés", "Clients satisfaits"];


  const carouselItems = [
    {
      imgSrc: imgFST,
      title: 'ENTZHEIM Siège social et agence ',
      description: ' Notre équipe dévouée est prête à vous aider à trouver la solution hydraulique parfaite pour vos besoins, garantissant une performance et une efficacité optimales.',
      link: '/fst'
    },
    {
      imgSrc: imgFGA,
      title: 'GEMENOS Agence',
      description: 'Du diagnostic à la réparation, notre atelier ultramoderne offre un travail de précision et un service fiable pour tous vos composants hydrauliques.',
      link: '/fst'
    },
    {
      imgSrc: imgFVT,
      title: 'VITROLLES Agence',
      description: "Avec notre équipe mobile, nous apportons une assistance hydraulique experte directement à votre porte, minimisant ainsi les temps d'arrêt et maximisant la productivité.",
      link: '/fst'
    }
  ];


  const featuresData = [
    {
      title: "Qualité supérieure",
      description: "Tous les produits sont soumis à des tests approfondis dans le cadre du système d'assurance qualité de HANSA-FLEX.",
      image: qualitySticker
    },
    {
      title: "Leader mondial",
      description: "HANSA-FLEX est le fournisseur de systèmes pour les produits et services de technologie des fluides.",
      image: glob
    },
    {
      title: "Portefeuille diversifié",
      description: "Nous proposons une gamme complète de produits, avec tout disponible en ligne également.",
      image: hose
    },
    {
      title: "Approuvé par les normes ISO",
      description: "Nous sommes certifiés ! Nous garantissons la sécurité et la qualité.",
      image: isoLogo
    }
    // Add more objects for additional FeaturesCard data
  ];

  //promoter content
  const promoterTitle = 'PASSEZ VOTRE COMMANDE DÈS AUJOURD\'HUI !';
  const promoterChecklistItems = [
    'La gamme complète de produits HANSA-FLEX avec plus de 80 000 articles',
    'Des paramètres de filtre spécifiques à chaque catégorie permettent une navigation rapide',
    // 'Disponibilité actuelle affichée en temps réel pour une meilleure certitude de planification'
  ];
  const promoterButtonLabels = ['NOUS CONTACTER', 'EN SAVOIR PLUS !'];


  return (
    <div>
      <FullPageCarousel />

      <SectionHeader
        title="Explorez nos produits"
      />
      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={8} data-aos={isMobile ? "fade-up" : "fade-up"}>
            <ImageCard image={imgHydraulicHose} learnMoreLink="/hydraulic-hoses" title="Tuyaux hydrauliques fabriqués avec précision" desc="Les tuyaux hydrauliques HANSA-FLEX sont fabriqués avec une ingénierie de précision, garantissant des performances optimales et une grande durabilité, même dans les applications les plus exigeantes." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-up"}>
            <ImageCard image={imgHoseFitting} learnMoreLink="/hose-fitting" title="Des performances sans fuite grâce aux raccords" desc="Grâce à une technologie d'étanchéité supérieure et à un usinage précis, les raccords HANSA-FLEX assurent des connexions sans fuite, garantissant une efficacité et une sécurité maximales dans les systèmes hydrauliques." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-up"}>
            <ImageCard image={imgMetalHose} learnMoreLink="/metal-hoses" title="Tubes métalliques à haute résistance" desc="Les tuyaux en métal à haute résistance offrent une durabilité et une fiabilité supérieures, capables de résister à des pressions extrêmes et à des conditions de fonctionnement difficiles pour une durée de vie prolongée et des performances optimales." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-up"}>
            <ImageCard image={imgPipebend} learnMoreLink="/pipebends" title="Production de tubes cintrés sur mesure" desc="En tant que partenaire système, HANSA-FLEX propose à ses clients la construction et la production en série de tubes cintrés de tous types - une procédure rapide, coopérative et absolument précise." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-up"}>
            <ImageCard image={imgHose} learnMoreLink="/hoses-and-hose-lines" title="Composants hydrauliques " desc="Les composants hydrauliques comprennent une gamme variée de pièces essentielles pour les systèmes d'alimentation en fluide, conçues pour la précision, la durabilité et l'intégration transparente afin d'assurer un fonctionnement hydraulique efficace dans diverses industries et applications." />
          </Col>
        </Row>
      </Container>

      <SectionHeader
        title="Nos Services"
      />

      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={6} data-aos={isMobile ? "fade-up" : "fade-up"}>
            <ImageCard image={CredentialsImg} learnMoreLink="/mobile-rapid-hydraulic-service" title="Dépannage sur site 24/24 – 7/7 - Service mobile hydraulique" desc="Parce que chaque heure de travail perdue coûte de l'argent, les temps d'arrêt non planifiés doivent être réparés le plus rapidement possible. Grâce à FLEXXPRESS, HANSA-FLEX est en mesure d'offrir un service rapide qui peut intervenir immédiatement." />
          </Col>
          <Col sm={12} md={6} lg={6} data-aos={isMobile ? "fade-up" : "fade-up"}>
            <ImageCard image={imgCylinderRepair} learnMoreLink="/hydraulic-cyclinder-repair" title="Réparation de vérin hydraulique" desc="Les services de réparation des cylindres hydrauliques rétablissent rapidement leur fonctionnalité, garantissant un temps d'arrêt minimal et des performances optimales grâce à une inspection méticuleuse et à des techniques de remise à neuf expertes." />
          </Col>
          {/* <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgTailoredTube} learnMoreLink="/pipelines" title="Assemblages de tubes sur mesure" desc="Faites l'expérience de la précision et de la fiabilité grâce à nos assemblages de tubes sur mesure, conçus pour répondre à vos besoins spécifiques. Notre équipe d'experts garantit la qualité et la cohérence, en fournissant des solutions qui dépassent les attentes. Rationalisez vos opérations et améliorez..." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgEasyOrderCash} learnMoreLink="/Scannersolutions" title="Numérisation facile des commandes" desc="Simplifiez votre processus d'approvisionnement grâce à Easy-Order Scan, qui permet de commander rapidement et facilement des tuyaux et des composants hydrauliques. Numérisez, commandez et recevez vos produits en toute simplicité, réduisant ainsi les temps d'arrêt et augmentant la productivité. Faites l'expérience de la..." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgXCOdeManager} learnMoreLink="/x-code-manager" title="X-CODE Manager" desc="Exploitez la puissance de X-Code Manager pour suivre et gérer sans effort votre stock de tuyaux hydrauliques. Chaque tuyau se voit attribuer un X-Code unique, ce qui permet une visibilité et un contrôle en temps réel. Dites adieu aux maux de tête liés à l'inventaire..." />
          </Col> */}
        </Row>
      </Container>

      <div>
        <SectionHeader
          title="Découvrez nos unités!"
          paragraph="Notre unité de service en France offre un support hydraulique complet"
        />
        <ShowcaseCarousel carouselItems={carouselItems} />
      </div>
      <br /><br /><br />

      <div className='div-gray center-all-items' data-aos={isMobile ? "fade-up" : "fade-up"}>
        <SectionHeader
          title="Solutions hydrauliques et pneumatiques haut de gamme"
          subtitle="Choisissez parmi plus de 80 000 articles et trouvez toujours le bon produit pour vos besoins. Nous avons en stock tout, de A à Z."
        />
        <FeatureCardCarousel featuresData={featuresData} />
      </div>

      <br /><br />
      <div data-aos={isMobile ? "fade-up" : "zoom-in"}>
        <SectionHeader
          title={"Hansa-Flex France SARL en chiffres"} /><br />
        <NumberGrid numbers={numbers} subtitles={subtitles} />
      </div><br /><br />

      <div>
        {isLaptop && (
          <Promoter
            title={promoterTitle}
            checklistItems={promoterChecklistItems}
            btnLabel1={"Découvrir !"}
            btnLabel1Link={"/hoses-and-hose-lines"}
            imageUrl={imgCarton}
          />
          // TODO: contact us button
        )}
      </div>
      <br /><br />

      {/* <div>
        <SectionHeader
          title="Actualités et événements" />
        <Container className="custom-container">
          <Row>
            <Col sm={12} md={6} lg={8} data-aos="fade-up" >
              <ImageCard image={imgIree} learnMoreLink={'/iree'} title="Titre de l'actualité" desc={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sed ipsum eu sapien luctus consequat. Aliquam erat volutpat. Phasellus maximus quam ac orci consequat, et fermentum tortor aliquet. Maecenas id risus non lacus fermentum vestibulum. Nam ullamcorper magna vitae efficitur semper. "} />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up">
              <ImageCard image={imgBauma} learnMoreLink={'/bauma'} title="Titre de l'actualité" desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sed ipsum eu sapien luctus consequat. Aliquam erat volutpat. Phasellus maximus quam ac orci consequat, et fermentum tortor aliquet. Maecenas id risus non lacus fermentum vestibulum. Nam ullamcorper magna vitae efficitur semper. " />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up">
              <ImageCard image={imgTree} learnMoreLink={'/tree-plantation'} title="Titre de l'actualité" desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sed ipsum eu sapien luctus consequat. Aliquam erat volutpat. Phasellus maximus quam ac orci consequat, et fermentum tortor aliquet. Maecenas id risus non lacus fermentum vestibulum. Nam ullamcorper magna vitae efficitur semper. " />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up">
              <ImageCard image={imgGarry} learnMoreLink={'/product-training'} title="Titre de l'actualité" desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sed ipsum eu sapien luctus consequat. Aliquam erat volutpat. Phasellus maximus quam ac orci consequat, et fermentum tortor aliquet. Maecenas id risus non lacus fermentum vestibulum. Nam ullamcorper magna vitae efficitur semper. " />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos="fade-up">
              <ImageCard image={imgGoetheVisit} learnMoreLink={'/goethe-event'} title="Titre de l'actualité" desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sed ipsum eu sapien luctus consequat. Aliquam erat volutpat. Phasellus maximus quam ac orci consequat, et fermentum tortor aliquet. Maecenas id risus non lacus fermentum vestibulum. Nam ullamcorper magna vitae efficitur semper. " />
            </Col>
          </Row>
        </Container>
      </div> */}
    </div>

  );
}

export default HomePage;
