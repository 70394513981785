import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import imgFluidService from '../../../assets/Services/FluidManagement/fluidMangement.jpg'
import imgOilAnalysis from '../../../assets/Services/FluidManagement/oilAnalysis.jpg'
import imgOilCleaning from '../../../assets/Services/FluidManagement/oilCleaning.jpg'
import imgFluidCard3 from '../../../assets/Services/FluidManagement/fluid_service.jpg'
import imgAvailability from '../../../assets/Services/FluidManagement/24_7_availability.jpg'
import imgAllAboutOil from '../../../assets/Services/FluidManagement/all_about_oil.jpg'
import imgEverythingOilDo from '../../../assets/Services/FluidManagement/everything_oil_must_do.jpg'
import imgOilchnaging from '../../../assets/Services/FluidManagement/more_than_changing_oil.jpg'
import imgMonitoring from '../../../assets/Services/FluidManagement/monitoring.jpg'
import imgPromotorImage from '../../../assets/Services/FluidManagement/promotor.png'
// import pdf from '../../assets/Services/FluidManagement/'


const FluidService = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    // Données ImageText
    const oilAnalysis = {
        image: imgOilAnalysis,
        title: "Analyse complète de l'huile",
        description: "Que ce soit pour des systèmes hydrauliques mobiles ou stationnaires : l'analyse de l'huile constitue la base d'un traitement optimal de l'huile. Seulement si les problèmes liés au fluide sont détectés en temps opportun et que des mesures sont prises pour les résoudre, il est possible d'éviter des temps d'arrêt coûteux et de grandes réparations. Par conséquent, le service fluides de HANSA-FLEX propose des analyses complètes de l'huile sur site et en laboratoire.",
        buttonName: "EN SAVOIR PLUS",
        buttonLink: "/analyse-de-l-huile"
    }

    const oilCleaning = {
        image: imgOilCleaning,
        title: "Nettoyage efficace de l'huile",
        description: "En filtrant régulièrement votre huile, vous pouvez garantir que vos systèmes hydrauliques continuent de fonctionner correctement pendant des périodes plus longues. Les impuretés solides et liquides causent de l'abrasion, augmentent l'usure et favorisent la corrosion. Les experts en fluides de HANSA-FLEX nettoient professionnellement votre huile et trouvent des solutions de filtration parfaites pour votre domaine d'application.",
        buttonName: "EN SAVOIR PLUS",
        buttonLink: "/filtration-et-nettoyage-de-l-huile"
    }

    // Données pour le carousel de cartes de fonctionnalités
    const featuresData = [
        {
            title: "Tout fonctionne mieux",
            description: "Moins de dommages dus à la contamination, moins de pannes et moins de temps d'arrêt",
            image: imgMark
        },
        {
            title: "Durée de vie prolongée",
            description: "Vos machines et systèmes durent beaucoup plus longtemps",
            image: imgMark
        },
        {
            title: "Expertise professionnelle",
            description: "Nos experts en fluides formés prennent soin de votre huile et évitent les dommages à vos systèmes",
            image: imgMark
        },
        {
            title: "Savoir ce qui ne va pas",
            description: "Les diagnostics spécialisés révèlent ce qui ne va pas avant qu'il ne cause des dommages coûteux",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données supplémentaires de cartes de fonctionnalités
    ];

    // Données pour HFTab
    const imageTextData = [
        {
            title: "Tout ce que l'huile doit faire",
            description: "L'huile hydraulique doit résister à de fortes contraintes. La norme DIN 51524 sur les fluides sous pression spécifie les exigences minimales pour les fluides hydrauliques. Autres exigences :",
            listItems: ["Stabilité au cisaillement (résistance aux changements de viscosité)", "Capacité de filtration", "Faible hygroscopicité (tendance de l'huile à absorber l'eau) et résistance aux charges thermiques"],
            image: imgEverythingOilDo
        },
        {
            title: "Plus que de simples changements d'huile",
            description: "La gestion des fluides implique plus que de simples changements d'huile réguliers. Le nettoyage et l'analyse de l'huile peuvent aider à prévenir les pannes et les temps d'arrêt. Lorsqu'un système hydraulique a été soigneusement conçu pour atteindre des performances spécifiques, les exigences des composants utilisés, tels que les fluides et les solutions de filtration, sont particulièrement élevées. Avec les conseils professionnels des experts en fluides, vous pouvez jeter les bases de :",
            listItems: ["Fiabilité opérationnelle", "Longue durée de vie", "Fonctionnement économique"],
            image: imgOilchnaging
        },
        {
            title: "Surveillance de l'état du fluide",
            description: "Nos experts surveillent continuellement l'état de l'huile à l'aide de capteurs de condition d'huile. Cela permet de reconnaître et de rectifier les changements tels qu'une augmentation des niveaux de contamination. Les avantages :",
            listItems: ["Détecter les anomalies dans les systèmes pendant le fonctionnement", "Correction rapide des dommages", "Réduction du risque de pannes d'équipements, de machines et de systèmes", "Optimisation continue de la gestion des fluides"],
            image: imgMonitoring
        },
    ];

    const tabTitles = [
        { title: "Exigences", eventKey: "Exigences" },
        { title: "Gestion des fluides", eventKey: "Gestion des fluides" },
        { title: "Contrôle", eventKey: "Contrôle" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgFluidService}
                    title={"GESTION DES FLUIDES À 360° AVEC HANSA-FLEX"}
                    paragraph={"Le soin de l'huile est un facteur clé pour garantir le bon fonctionnement des systèmes et installations hydrauliques. Les contaminants dans le fluide peuvent avoir des effets graves. De l'analyse, en passant par le nettoyage du fluide, jusqu'à la surveillance continue de l'état pendant le fonctionnement, les experts en fluides de HANSA-FLEX vous soutiennent dans tous les aspects du traitement de l'huile pour vos installations et machines."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"SOINS COMPLETS POUR L'HUILE HYDRAULIQUE PAR DES EXPERTS"}
                    subtitle={"Avec l'avantage du savoir-faire des experts en fluides de HANSA-FLEX, vous pouvez éviter les dommages et les temps d'arrêt"}
                />
                <ImageText
                    data={oilAnalysis} />
                <ImageText
                    data={oilCleaning} left={false} />
            </div>

            {/* // TODO: Élément des 10 règles ---- Non réalisé */}
            {/* // TODO: Avis des clients */}

            <div>
                <SectionHeader
                    title={"SOINS DE L'HUILE - POURQUOI C'EST IMPORTANT"}
                    subtitle={"Tous les avantages en un coup d'œil"}
                />

                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"LES 3 FAITS LES PLUS IMPORTANTS SUR L'UTILISATION DE L'HUILE HYDRAULIQUE"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"DÉTECTION DES DOMMAGES ET OPTIMISATION DES SYSTÈMES LIÉS AUX FLUIDES"}
                    subtitle={"En cas de dommages, les experts en systèmes hydrauliques et fluides expérimentés de HANSA-FLEX effectuent une analyse sur site. Ils déterminent rapidement les causes de la panne et fournissent un soutien complet pour la mise en œuvre de toutes les mesures nécessaires pour éliminer les dommages. De plus, les experts élaborent un plan d'action pour votre gestion des fluides, de sorte que les pannes de système liées à l'huile deviennent de l'histoire ancienne."}
                />
                <Promoter
                    title={"Tout sur l'huile hydraulique propre en un clin d'œil"}
                    checklistItems={["Le fluide hydraulique est un composant système important et revêt une importance cruciale pour la conception, le fonctionnement et la maintenance de l'équipement hydraulique.", "Vous trouverez tout ce que vous devez savoir sur le composant sous-estimé qu'est l'huile hydraulique dans notre brochure."]}
                    imageUrl={imgPromotorImage}
                    btnLabel1={"Télécharger le PDF"}
                    btnLabel1Link={'pdfLink'}
                // ajouter le lien PDF
                />
            </div>

            <div>
                <SectionHeader
                    title={"QUELQUE CHOSE D'AUTRE QUI POURRAIT VOUS INTÉRESSER"}
                />

                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgAllAboutOil} learnMoreLink={"/fluid-service"}
                                title="Tout sur l'huile : Service fluides"
                                desc={"Le service fluides de HANSA-FLEX réalise des analyses d'huile directement sur site, effectue le nettoyage d'huile ou le remplacement complet du fluide, et fournit des conseils sur tous les aspects des fluides..."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgAvailability} learnMoreLink={"/mobile-rapid-hydraulic-service"}
                                title="Disponibilité 24/7 : Service hydraulique rapide"
                                desc={"Nous sommes là pour vous en cas d'urgence, tout le temps : notre service hydraulique rapide peut rapidement remplacer les composants défectueux, tels que les lignes de tuyaux."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgFluidCard3} learnMoreLink={"/assembly-and-installation"}
                                title="Vous pouvez compter sur nous : Service industriel"
                                desc={"Des spécialistes expérimentés vous soutiennent en tant que personnes qualifiées dans l'inspection de vos installations et équipements selon les réglementations légales actuelles..."} /></Col>
                        </Row>
                    </Container>
                </div>

            </div>

        </div>
    )

}

export default FluidService