import React, { useEffect } from 'react';
import CenterStage from '../../../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../../../Components/ImageText/ImageText';
import imgMark from '../../../../../assets/mark.png'
import FeatureCardCarousel from '../../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';

import Stage from '../../../../../assets/Services/PreventativeMaintainance/Licences/Stage.jpg'
import Stage1 from '../../../../../assets/Services/PreventativeMaintainance/Licences/1.jpg'
import Stage2 from '../../../../../assets/Services/PreventativeMaintainance/Licences/2.jpg'
import Stage3 from '../../../../../assets/Services/PreventativeMaintainance/Licences/3.jpg'
import Stage4 from '../../../../../assets/Services/PreventativeMaintainance/Licences/4.jpg'
import Stage5 from '../../../../../assets/Services/PreventativeMaintainance/Licences/5.jpg'
import Stage6 from '../../../../../assets/Services/PreventativeMaintainance/Licences/6.jpg'

import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../../Components/ImageCard/ImageCard';


const Licenses = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"Packages de licence pour X-CODE Manager"}
                    paragraph={"Utilisez X-CODE Manager dans le portail client My.HANSA-FLEX pour une gestion intelligente, rapide et facile de vos lignes de tuyaux hydrauliques et de tous les autres composants nécessitant une inspection, un entretien et un remplacement réguliers. Optimisez l'approvisionnement en pièces de rechange pour ces articles avec le X-CODE inimitable de HANSA-FLEX et configurez votre base de données de maintenance personnalisée en utilisant X-CODE Manager. "}
                />
            </div>

            <div>
                <SectionHeader
                    title={"QUELLE EST LA MEILLEURE LICENCE POUR VOTRE ENTREPRISE ?"}
                    subtitle={"Aucune entreprise n'est identique. C'est pourquoi nous proposons plusieurs options. Le nombre d'utilisateurs dont vous avez besoin, le nombre de machines que vous exploitez et que vous souhaitez inspecter conformément à la BetrSichV par un partenaire expérimenté, ou si vous préférez les gérer vous-même, font tous partie de la décision concernant le choix du package de licence. "}
                />
                <Container className="custom-container">
                    <Row>
                        {/* <Col sm={12} md={6} lg={12}><ImageCard image={Stage1}
                            title={"ddf"} desc={"dfjjhdsjfh"} /></Col>

                        <Col sm={12} md={6} lg={12}><ImageCard image={Stage2} title={"ddf"} desc={"dfjjhdsjfh"} /></Col>

                        <Col sm={12} md={6} lg={12}><ImageCard image={Stage3} title={"ddf"} desc={"dfjjhdsjfh"} /></Col>
                        <Col sm={12} md={6} lg={12}><ImageCard image={Stage4} title={"ddf"} desc={"dfjjhdsjfh"} /></Col>
                        <Col sm={12} md={6} lg={12}><ImageCard image={Stage5} title={"ddf"} desc={"dfjjhdsjfh"} /></Col>
                        <Col sm={12} md={6} lg={12}><ImageCard image={Stage6} title={"ddf"} desc={"dfjjhdsjfh"} /></Col> */}

                    </Row>
                </Container>
            </div>

            \
            <div>
                <SectionHeader
                    title={"Obtention d'une licence"}
                    subtitle={"Pour obtenir une licence, veuillez contacter votre conseiller client. Votre conseiller vous expliquera en détail le portail et travaillera avec vous pour choisir la licence appropriée. Si vous avez besoin à tout moment de plus d'utilisateurs que ceux autorisés par votre licence, vous pouvez ajouter un package supplémentaire de dix utilisateurs supplémentaires pour 30,00 EUR. Vous pouvez ajouter autant de packages supplémentaires que nécessaire. Dès que vous aurez choisi une licence particulière, votre conseiller client discutera avec vous du contrat de licence. Une licence est obtenue pour une période d'au moins un an et payée à l'avance. Vous avez la flexibilité de décider si vous souhaitez prendre la licence uniquement pour un an ou en acquérir une pour plusieurs années. Dès réception du contrat de licence signé de votre part, nous effectuerons les réglages nécessaires et, peu de temps après, vous pourrez utiliser les fonctions étendues de X-CODE Manager. Si vous n'avez pas encore de conseiller client personnel chez HANSA-FLEX, veuillez contacter notre service client."}
                />
                <div className='ms-5'>
                    Tel.: +49 421 48907 766 <br />
                    E-mail: my@hansa-flex.de
                </div>
            </div>

        </div>
    )
}

export default Licenses