import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageText from '../../../Components/ImageText/ImageText'

import Stage from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/Stage.jpg'
import ReliablyConnect from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/ReliablyConnect.jpg'
import BuiltForExtreme from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/BuiltForExtreme.jpg'
import TechnicalAdvice from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/TechnicalAdvice.jpg'
import BSPAdapter from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/BSPAdapter.jpg'
import JICAdapter from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/JICAdapter.jpg'
import ORFSAdapter from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/ORFSAdapter.jpg'
import MetricAdapter from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/MetricAdapter.jpg'
import WithNationalPipe from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/WithNationalPipe.jpg'
import Standardised from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/Standardised.jpg'
import AdapterWithJapanese from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/AdapterWithJapanese.jpg'
import HydraulicAdapter from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/HydraulicAdapter.jpg'
import StaLeakProofge from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/LeakProof.jpg'
import MadeToMeasure from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/MadeToMeasure.jpg'
import HydraulicConnection from '../../../assets/Products/HydraulicConnectionTechnology/Adapter/HydraulicConnection.jpg'
import HFTabs from '../../../Components/HfTabs/HfTabs'

const Adapters = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const featuresData = [
        {
            title: "Fonctionnalité fiable",
            description: "Conçu avec un facteur de sécurité 4 fois supérieur à la pression de service de conception",
            image: imgMark
        },
        {
            title: "Qualité de produit fiable",
            description: "Fabriqué selon la qualité HANSA‑FLEX éprouvée, y compris des tests approfondis",
            image: imgMark
        },
        {
            title: "Matériaux durables",
            description: "Joints toriques résistants à l'ozone et haute résistance à la corrosion contre la rouille rouge",
            image: imgMark
        },
        {
            title: "Assemblage facile",
            description: "Les adaptateurs sont conçus de manière à pouvoir être installés rapidement et en toute sécurité.",
            image: imgMark
        },
        // Ajoutez plus d'objets pour des données de FeaturesCard supplémentaires
    ];

    const Data1 = {
        title: 'Connectez de manière fiable différents types de filetage',
        description: "Des adaptateurs sont nécessaires lorsque des composants hydrauliques avec différents types de connexion doivent être reliés de manière fiable dans l'hydraulique mobile ou industrielle.Les adaptateurs créent une transition fiable et étanche entre différents types de connecteurs et permettent l'adaptation rentable de composants hydrauliques de différentes conceptions ou de différents fabricants. HANSA‑FLEX propose une large gamme d'adaptateurs.Ceux - ci permettent l'adaptation de tout connecteur disponible sur le marché, y compris les types de filetage en pouces et métriques. En plus des types de connexion tels que JIC (37° ISO 8434-2), ORFS (joint plat ISO 8434-3), BSP (60° ISO 8434-6/BS 5200) et NPT/F (SAE J514), HANSA‑FLEX propose également des types spéciaux, y compris des connexions SAE 45°.",
        image: ReliablyConnect
    }

    const Data2 = {
        title: "Conçu pour les environnements d'utilisation extrême et les charges élevées",
        description: "Les connecteurs hydrauliques tels que les adaptateurs sont utilisés dans des applications exigeantes et sont soumis à des charges extrêmes, notamment des oscillations et des vibrations. C'est pourquoi tous les produits HANSA‑FLEX sont fabriqués selon les normes de qualité les plus élevées.Dans le cadre de notre système d'assurance qualité, nous effectuons des tests approfondis et nous assurons ainsi que les adaptateurs peuvent remplir leur rôle de manière fiable. Tous les adaptateurs sont disponibles en acier avec un revêtement de surface au zinc-nickel en standard. Cela garantit une résistance élevée à la corrosion contre la rouille rouge jusqu'à 1 500 heures.Sur demande, ces adaptateurs sont également disponibles dans d'autres matériaux tels que l'acier inoxydable pour une durée de vie maximale, même dans les conditions d'utilisation les plus difficiles.",
        image: BuiltForExtreme
    }

    const Data3 = {
        title: "Conseils techniques d'experts en hydraulique",
        description: "HANSA‑FLEX vous offre non seulement une large gamme d'adaptateurs hydrauliques de haute qualité, mais également des conseils techniques approfondis [Lien vers les coordonnées]sur l'adaptation de vos systèmes hydrauliques. Nous veillons à ce que les adaptateurs soient non seulement mécaniquement adaptés, mais remplissent également de manière fiable leur fonction prévue. Dans cette optique, nos experts expérimentés en technologie des fluides examinent vos dessins d'ingénierie et produisent la solution technique précise qui couvre le plus efficacement vos besoins.Que vous souhaitiez connecter des systèmes européens et asiatiques, utiliser une combinaison d'adaptateurs différents ou développer une solution spéciale, aucun défi technique n'est trop grand pour nous.",
        image: TechnicalAdvice
    }

    const Data4 = {
        title: 'Adaptateurs avec filetage National Pipe Taper (NPT)',
        description: "L'étanchéité des adaptateurs avec le filetage NPT couramment utilisé sur le marché américain est obtenue en enveloppant les filetages externes avec du ruban de téflon avant l'installation pour fournir l'étanchéité maximale entre les flancs.Les adaptateurs NPT/ F ont un angle de filet de 60°.",
        listItems: ["Construction droite, coude (45° ou 90°), T, L ou en forme de croix", "Connecteurs à emboîtement, filetés, à travers-cloison, à visser ou à visser", "Disponible avec des filetages externes et internes coniques"],
        image: WithNationalPipe
    }

    const Data5 = {
        title: 'Filetages normalisés de la Society of Automotive Engineers (SAE)',
        description: "Les adaptateurs SAE ont des connexions coniques cylindriques avec une surface d'étanchéité de 45° et un joint torique au col de l'étanchéité. Les adaptateurs SAE avec un filetage externe, quant à eux, créent un joint métallique.",
        listItems: ["Formes de construction : droite, coude 90°", "Types de conception : connecteurs vissés, à visser et filetés", "Matériaux : acier et laiton"],
        image: Standardised
    }

    const Data6 = {
        title: 'Adaptateurs avec filetages de la norme industrielle japonaise (JIS)',
        description: "Le filetage JIS, qui était initialement principalement utilisé en Asie, présente des surfaces de flanc d'étanchéité inclinées à 30° (angle de filetage de 60°) et est conçu de manière similaire à la connexion JIC à 37° (filetage UNF).",
        listItems: ["Construction droite, coudes (45° ou 90°)", "Types de conception : Connecteur vissé, connecteur vissable", "Disponible en types de filetage métriques et en pouces"],
        image: AdapterWithJapanese
    }

    const Data7 = {
        listItems: [
            "Disponible dans divers matériaux tels que l'acier inoxydable et le laiton",
            "Construction droite, coude (45° ou 90°), T, L ou en forme de croix",
            "Divers types de filetage, par exemple BSP, BSPT, NPT/F, UNF, JIS, SAE et JIC",
            "Joint métallique, joint souple et joint torique",
            "Types de connexion incluent BSP 60°, JIC 37°, ORFS et NPT/"
        ],
        image: HydraulicAdapter
    }

    // Données de HfTab
    const imageTextData = [
        {
            title: 'BSP et BSPT selon la norme ISO 8434-6 (BS 520)',
            description: "Les adaptateurs conformes à la norme ISO 8434-6 (BS 5200) avec des filetages BSP ont un filetage impérial compatible avec le filetage de tuyau Whitworth conformément à la norme DIN 228. En plus des versions avec des filetages BSP parallèles (filetage de tuyau britannique standard), nous fournissons également des adaptateurs avec le filetage externe conique BSPT. La connexion est réalisée avec un cône d'étanchéité de 60°.",
            listItems: ["Construction droite, coude (45° ou 90°), T, L ou en forme de croix", "Réglable en direction ou installé comme tuyau cintré", "Connecteurs à emboîtement, filetés, à travers-cloison, à visser ou à visser"],
            buttonName: "TOUS LES PRODUITS",
            image: BSPAdapter
        },
        {
            title: 'Adaptateurs JIC conformes à la norme ISO 8434-2',
            description: "Les adaptateurs conformes à la norme ISO 8434-2 avec un cône d'étanchéité de 37° (74°) ont un filetage UNF (filetage fin national unifié) conformément à la norme ISO 263 (connexion conique à 37° (74°)). La connexion est réalisée par le cône d'étanchéité de 37° (74°).",
            listItems: [
                "Construction droite, coude (45° ou 90°), T, L ou en forme de croix",
                "Connecteurs à emboîtement, filetés, à travers-cloison, à visser ou à visser",
                "Réglable en direction"
            ],
            buttonName: "TOUS LES ADAPTATEURS JIC",
            image: JICAdapter
        },
        {
            title: 'Adaptateurs ORFS conformes à la norme ISO 8434-3',
            description: "Les adaptateurs conformes à la norme ISO 8434-3 ont un joint élastomère plat et un filetage UNF (filetage fin national unifié) conformément à la norme ISO 263.",
            listItems: [
                "Construction droite, coude (45° ou 90°), T, L ou en forme de croix",
                "Connecteurs à emboîtement, filetés, à travers-cloison, à visser ou à visser",
                "Réglable en direction"
            ],
            buttonName: "PRODUITS",
            image: ORFSAdapter
        },
        {
            title: 'Adaptateurs pour filetages métriques',
            description: "Avec les adaptateurs métriques de HANSA‑FLEX, vous pouvez connecter de manière sécurisée des composants hydrauliques avec des filetages purement métriques ou des filetages de type métrique à des composants avec des filetages externes BSP.",
            listItems: [
                "Formes de construction : droite, coude 90°",
                "Types de conception : raccords filetés, raccords à emboîtement et connecteurs",
            ],
            buttonName: "BOUTIQUE EN LIGNE",
            image: MetricAdapter
        }
    ];

    const tabTitles = [
        { title: "Adaptateurs BSP", eventKey: "Adaptateurs BSP" },
        { title: "Adaptateurs JIC", eventKey: "Adaptateurs JIC" },
        { title: "Adaptateurs ORFS", eventKey: "Adaptateurs ORFS" },
        { title: "Adaptateurs métriques", eventKey: "Adaptateurs métriques" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"LA BONNE CONNECTION AVEC LES ADAPTATEURS HYDRAULIQUES DE HANSA‑FLEX"}
                    paragraph={"Les adaptateurs créent une connexion sûre et étanche entre des composants avec des types de filetage différents. HANSA‑FLEX propose un vaste portefeuille d'adaptateurs hydrauliques, tels que des adaptateurs filetés ou de transition dans toutes les formes de connexion courantes et répondant à toutes les normes, ainsi que des formes spéciales, y compris des connexions SAE à 45°. Avec un revêtement de surface en zinc-nickel, les adaptateurs ont également une résistance extrêmement élevée à la corrosion contre la rouille rouge."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"COMPOSANTS HYDRAULIQUES CONNECTÉS FIABLEMENT ET EN TOUTE SÉCURITÉ"}
                    subtitle={"Il n'y a pas de limites aux possibilités qui s'offrent à vous pour tous types de connexions avec les adaptateurs de HANSA‑FLEX."}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
                <ImageText
                    data={Data3} />
            </div>

            <div>
                <SectionHeader
                    title={"CONNEXIONS À DÉBIT OPTIMAL ET ÉTANCHÉITÉ ANTI-FUITES AVEC DES CONNECTIONS POSITIVES"}
                    subtitle={"Les adaptateurs HANSA‑FLEX répondent aux exigences de qualité les plus élevées dans tous les domaines de l'hydraulique mobile et industrielle et garantissent des connexions sûres et fiables."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"LA GAMME D'ADAPTATEURS HANSA‑FLEX"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"AUTRES ADAPTATEURS DISPONIBLES CHEZ HANSA‑FLEX"}
                />
                <ImageText
                    data={Data4} />
                <ImageText
                    data={Data5}
                    left={false} />
                <ImageText
                    data={Data6} />
            </div>

            <div>
                <SectionHeader
                    title={"ADAPTATEURS HYDRAULIQUES POUR CHAQUE EXIGENCE TECHNIQUE"}
                    subtitle={"Le portefeuille de produits de HANSA‑FLEX comprend une large gamme d'adaptateurs pour tous les types de connexion et normes disponibles sur le marché, ainsi que des conceptions spéciales."}
                />
                <ImageText
                    data={Data7} />
            </div>

            <div>
                <SectionHeader
                    title={"CHANGER LES TUYAUX EN QUELQUES SECONDES AVEC L'ACCOUPLEMENT SSKV"}
                    subtitle={"Le raccordement à coquille à encliquetage développé par HANSA‑FLEX combine les avantages d'un raccord et d'un raccord. Une coquille de serrage sécurise la connexion entre la fiche et le manchon. De plus, l'accouplement SSKV n'ajoute pas de taille à la connexion et peut être fixé sans outils, un avantage inimaginable lorsque l'espace d'installation est restreint."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={StaLeakProofge}
                                title="Connexion positive étanche avec des raccords de tuyauterie normalisés"
                                desc={"Les raccords de tuyauterie HANSA‑FLEX reposent sur un ajustement positif étroit les uns avec les autres pour créer une connexion sûre, même lorsqu'ils sont soumis à des pressions de travail élevées, des pics de pression et..."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={MadeToMeasure}
                                title="Tuyaux cintrés sur mesure"
                                desc={"Les composants sont produits dans le centre de cintrage de tuyaux HANSA‑FLEX selon les normes de précision les plus élevées contrôlées par ordinateur pour l'OEM et les pièces de rechange..."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={HydraulicConnection}
                                title="Technologie de connexion hydraulique chez HANSA‑FLEX"
                                desc={"HANSA‑FLEX propose une large gamme de produits de technologie de connexion hydraulique tels que des raccords et des connexions filetées, pour la plupart disponibles directement depuis..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )

}

export default Adapters