import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import HFTab from '../../../../Components/HfTabs/HfTabs'
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../../assets/mark.png'
import ImageText from '../../../../Components/ImageText/ImageText'

import Stage from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/Stage.jpg'
import SafeOperation from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/SafeOperation.jpg'
import CertifiedManufacturing from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/CertifiedManufacturing.jpg'
import Inspection from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/Inspection.jpg'
import Documentation from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/Documentation.jpg'
import Marking from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/Marking.jpg'
import HoseManagement from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/HoseManagement.jpg'
import ManufacturerService from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/ManufacturerService.jpg'
import Instructions from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/Instructions.jpg'
import img01 from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/01.jpg'
import img02 from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/02.jpg'
import img03 from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/03.jpg'
import img04 from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/04.jpg'
import img05 from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/05.jpg'
import img06 from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/06.jpg'
import IndustrialHose from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/IndustrialHose.jpg'
import MetalHose from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/MetalHose.jpg'
import HoseManagement2 from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/HoseManagement2.jpg'
import ShowcaseCarousel from '../../../../Components/ShowcaseCarousel/ShowcaseCarousel';

const IndustrialHoseInspection = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        image: SafeOperation,
        title: 'Pour le fonctionnement sécurisé des lignes de tuyaux industriels.',
        description: "Pour garantir le fonctionnement sûr et fiable des lignes de tuyaux industriels, ils doivent être inspectés lors de leur fabrication et à intervalles réguliers dans le cadre d'inspections récurrentes conformément à la loi allemande sur la sécurité industrielle (BetrSichV) par une personne officiellement autorisée.",
    }

    const data2 = {
        image: CertifiedManufacturing,
        title: "Fabrication certifiée dans les centres d'excellence en technologie des tuyaux.",
        description: "Si vos lignes de tuyaux nécessitent d'être remplacées, nous sommes heureux de fabriquer des remplacements de haute qualité de toutes sortes dans nos installations spécialisées certifiées. HANSA-FLEX AG est une entreprise spécialisée conforme à la loi allemande sur les ressources en eau (WHG) et est certifiée pour la gestion de la qualité conformément à la norme DIN EN ISO 9001:2015, pour la gestion environnementale conformément à la norme DIN EN ISO 14001:2015 et pour la santé et la sécurité au travail conformément à OHSAS 18001:2007. ",
    }

    const data3 = {
        listItems: [
            "Des lignes de tuyaux pour la vapeur",
            "Des lignes de tuyaux chimiques",
            "Des lignes de tuyaux hydrauliques",
            "Des lignes de tuyaux composites",
            "Des lignes de tuyaux alimentaires",
            "Des lignes de tuyaux industriels",
            "Des lignes de tuyaux pour la pulvérisation de goudron",
            "Des lignes de tuyaux pour la livraison de coulis",
            "Des lignes de tuyaux pour la livraison de produits",
            "Des lignes de tuyaux pour équipement de nettoyage",
            "Des enrouleurs de tuyaux avec des lignes de tuyaux adaptées",
            "Des lignes de tuyaux pneumatiques",
            "D'autres tests sur demande. Nous attendons avec impatience votre demande."  ]
    }

    const data4 = {
        image: Instructions,
        description: " Un test utilise de l'azote et nécessite donc une discussion préalable. Le site et la documentation doivent être préparés comme suit : ",
        listItems: [
            "Bulletin de transport pour les lignes de tuyaux usagées conformément à la norme T 002",
            "Alimentation électrique 380V/16A",
            "Raccordement pour le milieu de test eau (eau douce ou eau de service)",
            "Raccordement à l'air comprimé (min. 8 bar)",
            "Installations de drainage pour le milieu de test contaminé après la fin du test de pression (séparateur chimique)",
            "Lieu de travail protégé contre le gel"
        ]
    }

    const featuresData = [
        {
            title: "Services disponibles n'importe où. ",
            description: "Le service professionnel complet assuré par des personnes officiellement autorisées pour l'inspection est disponible partout où vous le souhaitez. ",
            image: imgMark
        },
        {
            title: "Des arrêts plus courts. ",
            description: "Retrait, inspection et réinstallation des lignes de tuyaux planifiés selon vos convenances. ",
            image: imgMark
        },
        {
            title: " Pratique et rapide. ",
            description: "Pas de transport nécessaire, enregistrement officiel de l'inspection et autocollants disponibles immédiatement. ",
            image: imgMark
        },
        {
            title: "Un ensemble global attrayant. ",
            description: "Inspection mobile, gestion des tuyaux, conseils sur les stocks d'inventaire et conseils, le tout provenant de la même source. ",
            image: imgMark
        }
    ];


    //HfTab data
    const imageTextData = [
        {
            title: 'Inspections professionnelles. ',
            description: "Nous venons sur site avec notre véhicule d'inspection et effectuons toutes les inspections requises le jour de votre choix, y compris :",
            listItems: ["Inspection visuelle des lignes de tuyaux et des raccords",
            "Inspection interne à l'aide d'une caméra d'inspection",
            "Test de conductivité électrique",
            "Test de résistance."
            ],
            image: Inspection
        },
        {
            title: 'Complete documentation',
            description: "The inspection certificate for the recurring inspections in accordance with BetrSichV are produced directly in the inspection vehicle. Inspection reports are sent digitally. Paper versions are available on request.",
            image: Documentation
        },
        {
            title: 'Unique marking',
            description: "The prescribed labels are produced directly on the inspection vehicle in the form of inspection stickers and coloured identification label strips and immediately applied to the hose lines.",
            image: Marking
        },
        {
            title: 'Hose management included',
            description: "Every inspected hose line can be coded with X-CODE from HANSA‑FLEX. You are granted free-of-charge access to our X-CODE Manager hose management software (SMART licence for two users).",
            image: HoseManagement
        },
        {
            title: 'Manufacturer-independent service',
            description: "As you would expect, we inspect and code hoses made by other manufacturers if you wish. Our industrial hose facilities teams are also available at any time to perform inspections on site. Give us a call!",
            image: ManufacturerService
        },
    ];

    const tabTitles = [
        { title: "Inspections", eventKey: "Inspections" },
        { title: "Documentation", eventKey: "Documentation" },
        { title: "Marking", eventKey: "Marking" },
        { title: "Hose management", eventKey: "Hose management" },
        { title: "Manufacturer-independent service", eventKey: "Manufacturer-independent service" },
    ];

    const carouselItems = [
        {
            imgSrc: img01,
            description: "Unité mobile d'inspection des tuyaux HANSA-FLEX en action. Elle dispose de tout le nécessaire à bord pour l'inspection et le test de la technologie des lignes conformément à la BetrSichV."
        },
        {
            imgSrc: img02,
            description: "Une personne officiellement autorisée pour les inspections de l'équipe du Service Industriel de HANSA-FLEX effectue un test de conductivité dans un parc industriel chimique dans le nord de l'Allemagne.  "
        },
        {
            imgSrc: img03,
            description: " Le technicien utilise un endoscope pour effectuer l'inspection interne d'une ligne de tuyau chimique."
        },
        {
            imgSrc: img04,
            description: "Le véhicule d'inspection mobile a toujours un outil de gravure à bord pour marquer les composants. "
        },
        {
            imgSrc: img05,
            description: 'La marque appliquée sur une ligne de tuyau chimique est facile à voir.'
        },
        {
            imgSrc: img06,
            description: "Gestion des tuyaux avec X-CODE Manager. Le codage et les dates d'inspection sont saisis sur site par le technicien de service dans la base de données spécifique au client."
        },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"INSPECTION DE L'ÉQUIPEMENT DE LIGNE CONFORMÉMENT À LA BETRSICHV"}
                    paragraph={"HANSA-FLEX inspecte vos lignes de tuyaux industriels conformément à la loi allemande sur la sécurité industrielle (BetrSichV). Nous effectuons des inspections en tant que service sur site à partir de nos véhicules d'inspection mobiles ou dans nos centres d'excellence pour la technologie des tuyaux. Nous inspectons des lignes de tuyaux fabriquées à partir de divers matériaux utilisés pour transporter des aliments, des produits chimiques, des produits pharmaceutiques et d'autres fluides."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"NOTRE SERVICE COMPLET POUR VOTRE MAINTENANCE "}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"INSPECTION PROFESSIONNELLE ET DOCUMENTATION "}
                    subtitle={"Nous inspectons les lignes de tuyaux industriels en matériaux élastomères et thermoplastiques, les tuyaux composites, les tuyaux non métalliques lisses et ondulés, ainsi que les tuyaux métalliques ondulés. Nous inspectons également : "}
                />
                <ImageText
                    data={data3} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={" NOUS INSPECTONS SUR SITE AUX DATES CONVENANT À VOS BESOINS "}
                    subtitle={"Bénéficiez de notre service mobile complet. HANSA-FLEX se rend sur vos lieux avec un véhicule d'inspection entièrement équipé, conduit par une personne officiellement approuvée pour les inspections conformément à BetrSichV/TRBS 1203, et effectue toutes les inspections. "}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"INSTRUCTIONS POUR LA PRÉPARATION DES INSPECTIONS DE TUYAUX INDUSTRIELS MOBILES "}
                    subtitle={"Pour garantir le bon fonctionnement de notre service sur site, nous vous demandons de noter ce qui suit : les conduites de tuyaux à inspecter doivent être purgées et exemptes de résidus de média, la pression maximale de test du véhicule est de 100 bars, le milieu de test est..."}
                />
                <ImageText
                    data={data4} />
            </div>

            <div>
                <SectionHeader
                    title={"VOS AVANTAGES AVEC L'INSPECTION DE TUYAUX INDUSTRIELS MOBILES DE HANSA‑FLEX "}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"Services d'inspection de tuyaux industriels "}
                />
                <ShowcaseCarousel carouselItems={carouselItems} isExploreButton={false} descDisplay='block' carouselType={2} />
            </div>

            <div>
                <SectionHeader
                    title={"Codage et gestion des tuyaux pour les lignes de tuyaux industriels "}
                    subtitle={"Utilisez le système de codage, éprouvé des millions de fois dans le domaine de l'hydraulique, pour la maintenance préventive de vos lignes de tuyaux industriels. X-CODE et le logiciel X-CODE Manager vous offrent une vue d'ensemble optimale de toutes vos lignes de tuyaux, machines, dates programmées et documentation. "}
                />
            </div>

            <div>
                <SectionHeader
                    title={"PLUS DE SERVICES POUR VOS SYSTÈMES HYDRAULIQUES"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={IndustrialHose}
                                title="Tuyaux industriels pour de nombreux domaines d'utilisation"
                                desc={" Qu'il s'agisse de produits chimiques, de denrées alimentaires, de milieux abrasifs ou de carburants : nous avons un tuyau industriel adapté et pouvons préassembler des lignes de tuyaux personnalisées pour vous."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={MetalHose}
                                title=" Lignes de tuyaux métalliques répondant aux exigences les plus élevées."
                                desc={"Solutions personnalisées pour des applications particulièrement exigeantes et critiques en termes de sécurité, fabriquées avec la plus haute précision, de la pièce unique à la production en série. "} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={HoseManagement2}
                                title="Gestion des tuyaux avec X-CODE Manager "
                                desc={"Découvrez davantage sur le logiciel pratique pour une meilleure vue d'ensemble de vos lignes de tuyaux et des dates d'inspection. Essayez la version de démonstration gratuite.  "} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default IndustrialHoseInspection 