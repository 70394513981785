import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgWorks_Centerspread from '../../../assets/Services/Logistics solutions/Works in works/Works_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgWorks_Imagetext_01 from '../../../assets/Services/Logistics solutions/Works in works/Works_Imagetext_01.jpg'
import imgWorks_Imagetext_02 from '../../../assets/Services/Logistics solutions/Works in works/Works_Imagetext_02.jpg'
import imgWorks_Imagetext_03 from '../../../assets/Services/Logistics solutions/Works in works/Works_Imagetext_03.jpg'
import imgWorks_Imagetext_04 from '../../../assets/Services/Logistics solutions/Works in works/Works_Imagetext_04.jpg'
import imgWorks_Imagetext_05 from '../../../assets/Services/Logistics solutions/Works in works/Works_Imagetext_05.jpg'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import { Container, Row, Col } from 'react-bootstrap';
import imgWorks_Imagecard_01 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagecard_01.jpg'
import imgWorks_Imagecard_02 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagecard_02.jpg'
import imgWorks_Imagecard_03 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagecard_03.jpg'









const Worksinworks = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);



  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Works_Imagetext_01= {
    image: imgWorks_Imagetext_01,
    title: 'Service complet',
    description: ["Gamme complète de produits sur site : la grande disponibilité des matériaux assurée par notre service de fabrication en usine permet de fabriquer rapidement et sur place les composants requis. En outre, vous économisez sur les coûts de stockage, car les matériaux sont utilisés rapidement après leur livraison, directement sur le site, en fonction des besoins de la production. Tous les matériaux nécessaires sont traités directement sur place. Vous pouvez, par exemple, compter sur la disponibilité des composants nécessaires dès le début d'une équipe."],
  }

  const Works_Imagetext_02= {
    image: imgWorks_Imagetext_02,
    title: 'Des interlocuteurs compétents',
    description: ["Profitez de notre soutien sur place et faites confiance à nos collaborateurs expérimentés. Qu'il s'agisse d'un simple tuyau, d'une unité de puissance complexe ou de la fabrication d'un prototype, vous pouvez compter sur nous et sur notre vaste compétence en matière de fabrication de composants hydrauliques. Nous veillons à ce que nos processus soient parfaitement synchronisés et intégrés à vos processus de production."],
  }


  const Works_Imagetext_03= {
    image: imgWorks_Imagetext_03,
    title: 'Former vos employés',
    description: ["Dans le cadre de notre service de travaux en usine, nous pouvons dispenser une formation à vos employés. La formation détaille ce qui doit être considéré comme important lors du montage ou de l'installation de nos produits. Vos employés apprennent étape par étape comment fabriquer et installer correctement les produits. Vous pouvez ainsi prévenir les défaillances et éviter les réclamations des clients finaux."],
  }

  const Works_Imagetext_04= {
    image: imgWorks_Imagetext_04,
    title: "Une logistique optimisée",
    description: ["Outre la fabrication de conduites hydrauliques, nos collaborateurs s'occupent également de l'entreposage et de la logistique interne, afin que vous puissiez vous concentrer entièrement sur le montage ou l'installation. Nous commandons les matériaux nécessaires en fonction de la demande actuelle - si nécessaire, les conduites hydrauliques requises peuvent être livrées sur place dans les 24 heures. La commande est directement introduite dans le système ERP, ce qui permet de réduire considérablement les frais administratifs."],
  }

  const Works_Imagetext_05= {
    image: imgWorks_Imagetext_05,
    title: "Des conseils complets",
    description: ["Nous mettons un point d'honneur à travailler avec vous sur la base d'une confiance totale. Parce que nous nous concentrons entièrement sur votre production sur site, nous pouvons trouver la meilleure solution dans chaque situation. Nous relevons tous les défis et ne perdons jamais de vue la situation dans son ensemble, même en cas de processus complexes. Si vous souhaitez optimiser vos machines et équipements, nous sommes à votre disposition pour vous soutenir activement et vous présenter des propositions d'amélioration efficaces."],
  }













  
  return (
    <div>
      <CenterStage
        imageSrc={imgWorks_Centerspread}
        title={"TRAVAUX EN USINE : FABRICATION DIRECTEMENT SUR LE SITE"}
        paragraph={"Des matériaux et des composants immédiatement disponibles - c'est le grand avantage du service de fabrication en usine de HANSA-FLEX. Les ateliers de fabrication intégrés sont mis à disposition par nos collaborateurs sur place. Ils fournissent les composants nécessaires juste à temps pour vos lignes de production. Grâce à nos nombreuses années d'expérience, nous sommes en mesure de proposer des solutions efficaces pour la fabrication de tuyaux et de composants hydrauliques."}
      />



<SectionHeader
        title={"LE SERVICE TRAVAUX EN USINE - UNE VUE D'ENSEMBLE"}
        subtitle={"Le service complet pour votre atelier de production."}
      />


<div >
        <br />
        <ImageText data={Works_Imagetext_01} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Works_Imagetext_02} />
      </div>

      <div >
        <br />
        <ImageText data={Works_Imagetext_03} left={false} />
      </div>



      <SectionHeader
        title={"VOICI VOS AVANTAGES"}
        subtitle={"Le service de travaux en usine facilite considérablement vos tâches quotidiennes."}
      />

<SectionHeader
        title={"SERVICES INTÉGRÉS"}
        subtitle={"Le service de travaux en régie offre tout simplement plus."}
      />

<div >
        <br />
        <ImageText data={Works_Imagetext_04} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Works_Imagetext_05} />
      </div>

      
      <SectionHeader
        title={"YOU MAY ALSO BE INTERESTED IN THIS"}
        subtitle={""}
      />

<Container className="custom-container">
        <Row>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgWorks_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Conteneur d'atelier hydraulique" desc="Le conteneur de service hydraulique est la solution optimale pour les entreprises qui souhaitent bénéficier d'une certaine flexibilité lorsqu'elles travaillent au-delà des portes de l'usine." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                <ImageCard image={imgWorks_Imagecard_02} learnMoreLink="/metal-hoses" title="Ingénierie et gestion de la planification des projets" desc="Groupes électrogènes, vérins, solutions spéciales : Les équipes spécialisées de HANSA-FLEX fabriquent des composants sur mesure pour les systèmes hydrauliques, les groupes électrogènes et la construction d'installations." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgWorks_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Cours de formation" desc="Nous pouvons proposer à vos employés un programme de formation complet avec des sessions de formation en direct, en ligne ou en face à face." />
            </Col>

        </Row>
    </Container>






      








    </div>
  )
}



export default Worksinworks