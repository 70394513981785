import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import Promoter from '../../../Components/Promoter/Promoter';
import ImageText from '../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';
import HFTabs from '../../../Components/HfTabs/HfTabs';


import imgHose from '../../../assets/hose.jpg'
import imgHydraulicHoses from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/hydraulic-hoses.jpg'
import imgMetalHose from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/metalHose.jpg'
import imgBluePipe from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/bluePipe.jpg'
import imgjetSpray from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/jetSpray.jpg'
import imgIndustrailHoses from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/industrialHoses.jpg'
import imgperforemdHoses from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/performedHoses.jpg'
import imgaccessoresHoseLine from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/accessoriesHoseLines.jpg'
import imgTabletMobile from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/tabletMobile.png'
import imgHoseforcompressed from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/hoseForCompressed.jpg'
import imgThePremiumHose from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/thePremiumHose.jpg'
import imgSwitchHoseLine from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/switchHoseLines.jpg'
import imghosekit from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/hoseKits.jpg'
import imgModernSeries from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/modernSeries.jpg'
import imgCustomisedHose from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/customisedHose.jpg'
import imgFittings from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/Fittings.jpg'
import imgCouplings from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/Couplings.jpg'
import imgAdapters from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/Adapters.jpg'


//TODO: TextPoster

function HosesAndHoseLines() {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const dataPipeFittings = {
        image: imgHoseforcompressed,
        title: "Tuyaux pour la technologie de l'air comprimé",
        description: 'Notre boutique en ligne propose un large choix de tuyaux pneumatiques de différents diamètres, matériaux et longueurs.',
        listItems: ["Tuyaux de compresseur et de frein", "Tuyaux en PA, PE, PUR, PVDF et PVC", "Tubes, brides de tuyaux, raccords et connexions"]
    }

    // Données sur le développement de produits

    const dataProductDevelopment1 = {
        image: imgThePremiumHose,
        title: 'La ligne de tuyaux premium pour les hautes pressions',
        description: 'La ligne de tuyaux premium HD 700 LL de HANSA-FLEX a été spécialement développée pour une utilisation dans des conditions exigeantes. Elle combine des performances maximales avec une longue durée de vie.',
        listItems: ["Capacité de charge en pression excellente, même après 2 millions de cycles d'impulsion", "Disponible dans les tailles de DN 31 à DN 51", "Approbation DNV pour une utilisation maritime"],
        buttonName: "PLUS DE TUYAUX HYDRAULIQUES",
        buttonLink: "https://shop.hansa-flex.fr/fr_FR/hoses/hydraulic-hoses/spiral-hoses/hd-700-ll/p/MAS_HD_700_LL"

    }

    const dataProductDevelopment2 = {
        image: imgSwitchHoseLine,
        title: "Changer les lignes de tuyaux en quelques secondes à l'aide du raccord SSKV",
        description: 'Les connecteurs à coquille à encliqueter pour tuyaux hydrauliques combinent les avantages des raccords et des raccords conventionnels pour réduire la quantité de travail et le temps nécessaires au changement de lignes de tuyaux.',
        listItems: ["De plus, il est sécurisé par une vis crantée à action manuelle.", "Protégé contre un relâchement non intentionnel sous pression", "L'assemblage sans outil le rend idéal pour les espaces d'installation restreints"],
        buttonName: "PLUS DE RACCORDS SSNK",
        buttonLink: "https://shop.hansa-flex.fr/fr_FR/hose-fittings/push-in-clamping-shell-connections-sskv/c/webcat_HF_SAR_1320"

    }

    // Données du carrousel de cartes de fonctionnalités
    const featuresData = [
        {
            title: "Tubes flexibles pré-assemblés",
            description: "Nous pré-assemblons tous les types de tuyaux disponibles sur le marché en lignes de tuyaux prêtes à installer.",
            image: imgMark
        },
        {
            title: "Test de tuyaux",
            description: "En tant que personnes officiellement autorisées pour l'inspection des équipements hydrauliques, nos experts peuvent inspecter et tester les tuyaux hydrauliques et industriels.",
            image: imgMark
        },
        {
            title: "Gestion intelligente des tuyaux",
            description: "Avec le gestionnaire de codes X-CODE de HANSA-FLEX, vous disposez de toutes les informations sur les tuyaux à portée de main, telles que les intervalles d'inspection.",
            image: imgMark
        },

        // Ajouter plus d'objets pour des données de FeaturesCard supplémentaires
    ];

    // Données HfTab
    const imageTextData = [
        {
            title: "Accessoires pour tuyaux pour toutes les applications",
            description: "Les accessoires conçus et fabriqués par HANSA-FLEX sont parfaits pour une utilisation avec les types de tuyaux prévus. Ils garantissent une connexion sécurisée et étanche.",
            listItems: ["Sertissage, raccords et raccords spéciaux", "Toutes les tailles nominales et types de connexion disponibles sur le marché", "Revêtement de surface en zinc-nickel résistant à la corrosion"],
            buttonName: "EN SAVOIR PLUS",
            buttonLink: "/hose-fitting",
            image: imgFittings
        },
        {
            title: "Raccords hydrauliques sûrs et fiables",
            description: "Les raccords sont le meilleur choix dans les situations où les raccords doivent être rapidement changés, les machines connectées en toute sécurité ou les tuyaux fortement chargés dans des systèmes hydrauliques mobiles et stationnaires doivent être remplacés.",
            listItems: ["Un raccord rapide adapté à chaque application", "Disponible dans divers matériaux, tels que l'acier ou l'acier inoxydable", "Nombreuses tailles et types d'étanchéité"],
            buttonName: "EN SAVOIR PLUS",
            buttonLink: "/coupling",
            image: imgCouplings
        },
        {
            title: "Faites le bon raccord avec des adaptateurs hydrauliques",
            description: "Les adaptateurs créent une connexion sécurisée et étanche entre des composants avec différents types de filetage. HANSA-FLEX propose une large gamme d'adaptateurs hydrauliques.",
            listItems: ["Adaptateurs filetés et de transition", "Tous les types et normes de connexion disponibles sur le marché", "Types spéciaux, tels que les connexions SAE à 45°, sont également fournis"],
            buttonName: "EN SAVOIR PLUS",
            buttonLink: "/adapters",
            image: imgAdapters
        },
    ];

    const tabTitles = [
        { title: "Accessoires", eventKey: "Accessoires" },
        { title: "Raccords", eventKey: "Raccords" },
        { title: "Adaptateurs", eventKey: "Adaptateurs" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgHose}
                    title={"TUYAUX DE HAUTE QUALITÉ DE HANSA-FLEX"}
                    paragraph={"La gamme HANSA-FLEX comprend des tuyaux pour des applications hydrauliques et pneumatiques, ainsi que pour le transport de produits chimiques, alimentaires, d'air, d'eau et de médias abrasifs. En plus des tuyaux standards, nous fournissons également des tuyaux d'aspiration, des tuyaux sous pression, des accessoires adaptés et des lignes de tuyaux pré-assemblées fabriquées selon vos besoins individuels. En tant que fournisseur de services complets, nous soutenons également nos clients dans l'installation, la maintenance et l'inspection."}
                />
            </div>
            <SectionHeader
                title={"LA GAMME COMPLÈTE DES RACCORDS HANSA-FLEX"}
                subtitle={"HANSA-FLEX est reconnu comme un fournisseur de systèmes extrêmement compétent pour les tuyauteries hydrauliques de haute qualité. Mais notre expertise va bien au-delà :"}
            />
            <div>
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={8} ><ImageCard image={imgHydraulicHoses} learnMoreLink={"/hydraulic-hoses"}
                            title="Tuyaux hydrauliques pour toutes les applications"
                            desc={"Les tuyaux hydrauliques transfèrent en toute sécurité le fluide à travers le système. Leur flexibilité signifie qu'ils nécessitent très peu d'espace d'installation. La gamme HANSA-FLEX comprend des tuyaux élastomères et thermoplastiques avec des supports de pression avec des tresses textiles ou en acier ou différents renforcements en fil d'acier spiralé. Cela leur permet de transporter des fluides avec des pressions allant jusqu'à 500 bar. Les tuyaux sont utilisés dans de nombreuses applications dans les systèmes hydrauliques mobiles et stationnaires."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgMetalHose} learnMoreLink={"/metal-hoses"}
                            title="Tuyaux métalliques pour des conditions extrêmes"
                            desc={"Les matériaux utilisés dans les tuyaux métalliques les rendent extrêmement résistants à la corrosion et à l'abrasion. Une excellente tolérance à la température, une résistance au vide et une perméabilité extrêmement faible font des tuyaux métalliques le choix préféré pour le transport de substances solides, liquides et gazeuses dans des conditions particulièrement exigeantes. HANSA-FLEX propose des conseils complets pour sélectionner la meilleure solution de tuyau métallique."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgBluePipe} learnMoreLink={"/Ptfe-hoses"}
                            title="Tuyaux PTFE pour des applications chimiquement exigeantes"
                            desc={"Les tuyaux PTFE de HANSA-FLEX se distinguent par leur excellente résistance chimique à la plupart des médias, leur grande flexibilité et leur longue durée de vie. Dans les industries alimentaire et pharmaceutique, leur neutralité de goût et d'odeur, ainsi que leur sécurité bactériologique, en font un choix idéal pour le transport de produits potentiellement problématiques. Dans la construction navale ou l'industrie aérospatiale, les tuyaux PTFE transportent en toute sécurité du carburant ou de l'eau de refroidissement."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgjetSpray} learnMoreLink={"/ext-high-pfm-hoses"}
                            title="Tuyaux extrêmement haute pression pour des applications jusqu'à 3 200 bar"
                            desc={"Les tuyaux extrêmement haute pression de HANSA-FLEX sont toujours le premier choix dans les systèmes où des pressions élevées prévalent. En effet, ces derniers peuvent devoir supporter des pressions allant jusqu'à 4 000 bar dans l'industrie pétrolière et gazière, le jet d'eau, les applications hydrauliques et le secteur automobile. HANSA-FLEX fournit des tuyaux extrêmement haute pression spécialement adaptés à vos besoins et fournis en tant que lignes de tuyaux prêtes à installer."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgIndustrailHoses} learnMoreLink={"/Industrial-hoses"}
                            title="HANSA-FLEX est votre partenaire système pour les tuyaux industriels"
                            desc={"Les tuyaux industriels trouvent leur application lorsque le milieu n'est pas utilisé pour transmettre de l'énergie. Les tuyaux industriels transportent une large gamme de médias, de l'eau et de l'air, en passant par les produits chimiques, jusqu'aux carburants et gaz, dans presque tous les secteurs industriels. Dans son atelier spécialisé dans les tuyaux, HANSA-FLEX répond aux exigences des clients avec les normes de qualité les plus élevées et les délais de livraison les plus courts. Dans la boutique en ligne HANSA-FLEX, vous trouverez une large gamme de tuyaux industriels."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgperforemdHoses} learnMoreLink={"/performed-hoses"}
                            title="Tuyaux préformés HANSA-FLEX pour une grande flexibilité dans les espaces restreints"
                            desc={"Les tuyaux préformés sont généralement nécessaires lorsque des tuyaux conventionnels ne peuvent pas être utilisés en raison de l'espace d'installation limité. Par exemple, dans des situations où un tuyau transportant du carburant ou du liquide de refroidissement dans des moteurs ou des batteries devrait être fortement plié, les tuyaux préformés HANSA-FLEX offrent une solution efficace et économique. Ils sont très élastiques, très robustes et peuvent être fabriqués dans presque n'importe quelle forme."} /></Col>

                        <Col sm={12} md={6} lg={8} ><ImageCard image={imgaccessoresHoseLine} learnMoreLink={"/hose-accessories"}
                            title="Protection parfaite pour les tuyaux avec les accessoires HANSA-FLEX"
                            desc={"Les lignes de tuyaux HANSA-FLEX convainquent dans de nombreuses applications grâce à leur excellente qualité. Les produits du portefeuille d'accessoires HANSA-FLEX protègent non seulement les parties de tuyaux sensibles dans des situations où des charges mécaniques particulièrement élevées ou des effets de température élevés se produisent, mais protègent également les personnes, par exemple, contre les blessures causées par des jets d'huile. Une large gamme de produits et des conseils d'experts garantissent la sélection et la mise en œuvre de solutions de protection efficaces et efficientes."} /></Col>
                    </Row>
                </Container>
            </div>

            <div>
                <Promoter
                    title={"DÉCOUVREZ MAINTENANT LE CONFIGURATEUR DE LIGNES DE TUYAUX HANSA-FLEX"}
                    checklistItems={["Tuyaux compacts pour des espaces confinés, par exemple, systèmes hydrauliques mobiles", "Tuyaux thermoplastiques pour un poids particulièrement faible"]}
                    btnLabel1={"Configure now"}
                    btnLabel2={"Learn more"}
                    btnLabel1Link={"https://shop.hansa-flex.fr/fr_FR/"}
                    btnLabel2Link={"/hose-line-config"}
                    imageUrl={imgTabletMobile}
                />
            </div>

            <div>
                <ImageText
                    data={dataPipeFittings} />
            </div>

            {/* TODO: TextPoster */}

            <div>
                <SectionHeader
                    title={"SERVICES POUR LES LIGNES DE TUYAUX"}
                    subtitle={"HANSA-FLEX est un partenaire système qui fournit non seulement des tuyaux de haute qualité et des produits associés, mais soutient également les clients avec de nombreux services pour toutes les tâches liées aux tuyaux."}
                />

                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"PRODUITS POUR LES APPLICATIONS DANS LES LIGNES DE TUYAUX"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"DÉVELOPPEMENTS DE PRODUITS PAR HANSA-FLEX"}
                    subtitle={"Nous savons ce que nos clients veulent et développons les solutions parfaites."}
                />

                <ImageText
                    data={dataProductDevelopment1} />

                <ImageText
                    data={dataProductDevelopment2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"AUTRES SERVICES DE SPÉCIALISTES DES FLUIDES HANSA-FLEX"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imghosekit} learnMoreLink={"/Kitting"}
                                title="Kits de tuyaux pour une installation efficace"
                                desc={"En tant que partenaire système, HANSA-FLEX fournit des kits prêts à installer contenant des composants de fournisseurs tiers ainsi que de HANSA-FLEX. Les lignes de production garantissent un approvisionnement continu en composants. Nos clients gagnent du temps, augmentent leur efficacité et réduisent les taux d'échec."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgModernSeries} learnMoreLink={"/SeriesProduction"}
                                title="Production en série moderne pour les fournisseurs de composants OEM"
                                desc={"L'unité de production centrale de HANSAFLEX à Brême fabrique des lignes de tuyaux en grandes et petites séries. La gamme comprend des tuyaux haute pression, des tuyaux PTFE et des tuyaux industriels en"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCustomisedHose} learnMoreLink={"/Hydraulicworkshopcontainers"}
                                title="Pré-assemblage de tuyaux personnalisé n'importe où dans le monde."
                                desc={"Le conteneur de service hydraulique est un atelier hydraulique mobile pour la fabrication de tuyaux directement sur site, ce qui permet de gagner du temps et de minimiser les temps d'arrêt coûteux."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>

    );

}

export default HosesAndHoseLines;