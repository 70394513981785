import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import HFTab from '../../../../Components/HfTabs/HfTabs'
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../../assets/mark.png'
import ImageText from '../../../../Components/ImageText/ImageText'

import Stage from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Stage.jpg'
import Inspection from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Inspection.jpg'
import Inspection2 from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Inspection2.jpg'
import InspectionWorkFlow from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/InspectionWorkFlow.jpg'
import Document from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Document.jpg'
import BasicUnit from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/BasicUnit.jpg'
import FluidTech from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/FluidTech.jpg'
import ControlSystem from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/ControlSystem.jpg'
import Inspection3 from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Inspection3.jpg'
import Inspection4 from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Inspection4.jpg'
import Inspection5 from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Inspection5.jpg'
import Reliable from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Reliable.jpg'
import Prevent from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Prevent.jpg'

const LiftingPlatformsAndLoadingBridges = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        image: Inspection,
        title: 'Inspection des ponts de chargement et des rampes de cour',
        description: "Nous inspectons vos ponts de chargement, rampes de camion, rampes de canaux, rampes de cour et rampes de chargement mobiles et nous assurons que toutes les exigences en matière de sécurité de la règle DGUV 308-002 (anciennement BGG 945) sont remplies au moment de l'inspection.",
        listItems: [
            "Inspection visuelle et test de fonctionnement avec documentation",
            "Inspection des ponts de chargement, rampes de camion, de canal et de cour",
            "Conformité avec toutes les exigences de la norme DIN EN 1398"
        ]
    };

    const data2 = {
        image: Inspection2,
        title: 'Inspection des plates-formes élévatrices',
        description: "Les employés qualifiés chez HANSA‑FLEX possèdent une vaste expérience avec les plates-formes élévatrices et vous assistent avec leur savoir-faire et leur support pratique lors de l'inspection régulière de votre équipement conformément aux exigences légales prescrites.",
        listItems: [
            "Inspection visuelle et test de fonctionnement avec documentation",
            "Inspection des plates-formes de travail élévatrices, plates-formes de chargement, inclinables et élévatrices de véhicules",
            "Effectué conformément à la règle DGUV 108-006 (anciennement BGR 233)"
        ]
    };

    const data3 = {
        image: Inspection3,
        title: 'Inspections relevantes pour le fabricant',
        description: "L’inspection avant la première mise en service comprend la pré-inspection, l’inspection de construction et l’inspection finale d’acceptation et est de la responsabilité du fabricant. Au cours de cette inspection, il est vérifié que l’équipement répond aux exigences essentielles de santé et de sécurité et qu’il ne mettra pas en danger les personnes pendant sa période d’utilisation envisagée. De plus, le fabricant doit documenter toutes les étapes de l'inspection. Ensuite, l'équipement reçoit le marquage CE requis pour son fonctionnement.",
    };

    const data4 = {
        image: Inspection4,
        title: "Inspections relevantes pour l'exploitant",
        description: "Si une plate-forme élévatrice ou une rampe n'a pas été livrée prête à l'emploi, l'exploitant doit vérifier qu'elle est prête à l'emploi, y compris l'installation et l'équipement, avant sa première mise en service. L'exploitant est également responsable de la réalisation d'inspections extraordinaires et des inspections régulières annuelles. Toutes les inspections doivent être effectuées par des personnes qualifiées et correctement documentées.",
    };

    const featuresData = [
        {
            title: "Experts qualifiés",
            description: "Les personnes autorisées pour les plates-formes élévatrices et les ponts de chargement effectuent les inspections.",
            image: imgMark
        },
        {
            title: "Aucun compromis sur la sécurité",
            description: "Assurer la conformité avec toutes les exigences de la Loi allemande sur la sécurité industrielle.",
            image: imgMark
        },
        {
            title: "Support complet",
            description: "Préparation de plans d'inspection mutuellement convenus et réalisation de services de maintenance.",
            image: imgMark
        },
        {
            title: "Clairement documenté",
            description: "Préparation et fourniture de documentation d'inspection conformément aux exigences légales.",
            image: imgMark
        }
    ];

    // Données HfTab 2
    const imageTextData2 = [
        {
            title: "Nos procédures",
            description: "En plus de l'inspection visuelle et du test de fonctionnement, une inspection complète correctement réalisée comprend également une vérification des documents associés à l'équipement. Tous les défauts constatés dans le cadre de l'inspection sont consignés dans la documentation. L'inspection détermine également si l'équipement est prêt à l'emploi ou s'il doit être inspecté à nouveau après rectification des problèmes. Après la réussite de l'inspection, la date de la prochaine inspection régulière est notée dans le journal d'inspection. Ensuite, l'équipement reçoit la marque de certification d'inspection. Cela sert de confirmation pour l'exploitant et l'utilisateur que l'équipement répond à toutes les exigences légales pour son utilisation en toute sécurité.",
            image: InspectionWorkFlow
        },
        {
            title: "Les documents et certificats suivants sont vérifiés",
            description: "Les documents sont examinés brièvement avant l'inspection visuelle et le test de fonctionnement. En plus de vérifier la présence des documents, nous confirmons également que les documents et les marquages sont complets et corrects.",
            listItems: [
                "Journal d'inspection",
                "Plaque d'identification / Marquage CE",
                "Instructions d'utilisation",
                "Record d'inspection finale d'acceptation (rampes)",
                "Autocollant de capacité de charge (plates-formes élévatrices)"
            ],
            image: Document
        },
        {
            title: "L'unité de base est minutieusement testée",
            description: "L'usure des composants sur l'unité de base des plates-formes élévatrices, des ponts de chargement et des rampes de cour est une conséquence naturelle d'une utilisation régulière. Nous vérifions que les composants suivants fonctionnent correctement :",
            listItems: [
                "Cadre/paliers",
                "Goupilles/fixations",
                "Tampons d'arrêt et goupilles/suspensions (rampes)",
                "Roues/châssis et dispositifs de sécurité (rampes)",
                "Blocage de roue, détecteur de jeu d'essieu et protection de marche (plates-formes élévatrices)"
            ],
            image: BasicUnit
        },
        {
            title: "Les composants de la technologie des fluides sont également inspectés",
            description: "Certains composants des systèmes hydrauliques et pneumatiques dirigent le fluide et l'utilisent pour diverses fonctions. Ils sont inspectés visuellement et testés dans le cadre de l'inspection régulière.",
            listItems: [
                "Unité de puissance et vérin/piston de levage",
                "Canalisations et tuyaux flexibles",
                "Vérins/pistons d'extension (rampes)",
                "Types d'huile, état, quantité et dispositif de descente d'urgence (plates-formes élévatrices)",
                "État et performance d'étanchéité des éléments pneumatiques (plates-formes élévatrices)"
            ],
            image: FluidTech
        },
        {
            title: "Ces composants sont testés dans le système de commande",
            description: "Le système de commande est un composant clé de l'équipement et fait l'objet d'une inspection tout aussi rigoureuse et complète que les composants structurels pour transférer et contrôler les forces. Les éléments de commande suivants sont inspectés ou testés :",
            listItems: [
                "Bouton d'arrêt d'urgence",
                "Protection contre la mise en marche accidentelle",
                "Dispositifs de commande",
                "Fin de course (rampes)",
                "Barrières lumineuses et verrouillage/déverrouillage/engagement des levées (plates-formes élévatrices)"
            ],
            image: ControlSystem
        },
    ];

    const tabTitles2 = [
        { title: "Flux de travail de l'inspection", eventKey: "Flux de travail de l'inspection" },
        { title: "Documents", eventKey: "Documents" },
        { title: "Unité de base", eventKey: "Unité de base" },
        { title: "Technologie des fluides", eventKey: "Technologie des fluides" },
        { title: "Système de commande", eventKey: "Système de commande" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"INSPECTIONS DES PLATES-FORMES ÉLÉVATRICES ET DES PONTS DE CHARGEMENT"}
                    paragraph={"Les plates-formes élévatrices à commande électrique et manuelle, les rampes de cour et les ponts de chargement doivent être inspectés au moins une fois par an pour leur état sécuritaire par un expert qualifié. L'exploitant est responsable de la réalisation de l'inspection. Les experts qualifiés chez HANSA‑FLEX effectuent les inspections régulièrement prescrites de cet équipement conformément à la DGUV, y compris la documentation conformément à la loi allemande sur la sécurité industrielle (BetrSichV)."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"INSPECTIONS RÉCURRENTES"}
                    subtitle={"Le fabricant effectue l'inspection avant que le composant ne soit mis en service pour la première fois. L'opérateur du système est responsable des inspections professionnelles ultérieures à intervalles réguliers. Les personnes officiellement autorisées pour l'inspection des."}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"LES SERVICES HANSA‑FLEX OFFRENT TOUS CES AVANTAGES"}
                    subtitle={"Avec HANSA‑FLEX, du personnel spécialisé expérimenté effectue chaque inspection. Ils sont qualifiés en tant que personnes officiellement autorisées pour inspecter les plates-formes élévatrices, les ponts de chargement et les rampes de cour."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"DOCUMENTATION LÉGALE CONFORME BASÉE SUR LES ENREGISTREMENTS D'INSPECTION"}
                />
                <HFTab imageTextData={imageTextData2} tabTitles={tabTitles2} />
            </div>

            <div>
                <SectionHeader
                    title={"INSPECTIONS AVANT LA MISE EN SERVICE"}
                    subtitle={"Une inspection doit être effectuée avant la mise en service des plates-formes élévatrices et des rampes de chargement pour vérifier la construction et l'installation défectueuses. Le fabricant ou le fabricant et l'opérateur effectuent l'inspection."}
                />
                <ImageText
                    data={data3} />
                <ImageText
                    data={data4} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT ÉGALEMENT VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Inspection5}
                                title="Inspections par des experts qualifiés"
                                desc={"Les employés expérimentés chez HANSA‑FLEX effectuent également des inspections de lignes de tuyaux hydrauliques et industriels et d'accumulateurs de pression conformément à la..."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={Reliable}
                                title="Entretien fiable de vos systèmes et machines"
                                desc={"Le Service Industriel HANSA‑FLEX vous soutient avec tous les travaux de maintenance et d'entretien, tels que le dépannage, la révision des composants défectueux ou l'élimination."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={Prevent}
                                title="Prévenir l'usure"
                                desc={"La propreté du fluide hydraulique affecte la durée de vie des composants. Le nettoyage de l'huile et les analyses régulières vous aident à éviter les pannes du système."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    );

}

export default LiftingPlatformsAndLoadingBridges