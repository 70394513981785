import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageText from '../../../Components/ImageText/ImageText'

import imgStage from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/stage.jpg'
import HoseFitting from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/HoseFitting.jpg'
import PipeFitting from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/PipeFitting.jpg'
import Adapter from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/Adapter.jpg'
import BallValves from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/BallValves.jpg'
import Flanges from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/Flanges.jpg'
import Couplings from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/ouplings.jpg'
import Pipeline from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/Pipeline.jpg'
import SuitableHose from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/SuitableHose.jpg'
import Stock from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/Stock.jpg'
import Design from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/Design.jpg'
import SealingTech from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/SealingTech.jpg'



const HydraulicConnectionTechnology = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const featuresData = [
        {
            title: "Système de formage de tuyaux HF-Form",
            description: "Connexions de tuyaux durables et sécurisées - sans bague de coupe, pièces spéciales ou outils spéciaux",
            image: imgMark
        },
        {
            title: "Qualité éprouvée",
            description: "Tous les produits HANSA‑FLEX répondent aux normes de qualité les plus élevées et font l'objet de tests approfondis",
            image: imgMark
        },
        {
            title: "Installation facile",
            description: "Avec le service industriel HANSA‑FLEX pour machines et systèmes de toutes tailles",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données supplémentaires de FeaturesCard
    ];

    const Data1 = {
        title: 'Un pipeline pour chaque situation',
        description: 'Acier, acier inoxydable, cuivre ou aluminium : des tuyaux cintrés en tube de précision dans divers matériaux et finitions de surface sont disponibles chez HANSA‑FLEX.',
        listItems: ["Conception et fabrication en série de tuyaux cintrés de tous types", "Haute précision et livraison Just-In-Sequence", "Tuyaux simples, lots, groupes de composants et assemblages de tuyaux prêts à être installés"],
        buttonName: "EN SAVOIR PLUS",
        image: Pipeline
    };

    const Data2 = {
        title: 'Un tuyau adapté pour chaque application',
        description: "Pour les basses, moyennes et hautes pressions : la large gamme de tuyaux hydrauliques HANSA‑FLEX comprend différentes versions, telles que des tuyaux spiralés et textiles, et couvre tous les domaines d'utilisation.",
        listItems: ["Des applications mobiles aux systèmes hydrauliques stationnaires", "Conçus et assemblés selon vos besoins individuels", "Raccords de tuyau parfaitement assortis - y compris en acier inoxydable"],
        buttonName: "EN SAVOIR PLUS",
        image: SuitableHose
    };

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"TOUT SE PASSE BIEN : TECHNOLOGIE DE CONNEXION HYDRAULIQUE"}
                    paragraph={"Les éléments de connexion hydrauliques, tels que les adaptateurs, les raccords et les connecteurs filetés, sont un moyen essentiel de relier les composants dans les systèmes hydrauliques. Ils garantissent que l'énergie peut être transférée en toute sécurité entre différents composants. HANSA‑FLEX propose un vaste portefeuille dans le domaine de la technologie de connexion - la plupart des articles étant généralement disponibles en stock en tout temps. Tous les produits répondent aux normes de qualité les plus élevées, confirmées par des tests de produit."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"CONNECTÉ EN TOUTE SÉCURITÉ"}
                    subtitle={"Nos experts en fluides et hydraulique sont là pour vous conseiller sur le choix des composants adaptés. HANSA‑FLEX peut produire des solutions spéciales personnalisées pour répondre aux besoins individuels car elle dispose de ses propres installations de fabrication."}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={HoseFitting}
                                title="Raccords de tuyau"
                                desc={"Pour garantir que les composants restent solidement connectés, même sous haute pression, les raccords de tuyau HANSA‑FLEX sont conçus pour correspondre parfaitement aux tuyaux hydrauliques. Un zinc-"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={PipeFitting}
                                title="Raccords de tuyau"
                                desc={"Les raccords de tuyau s'appuient sur un ajustement positif étanche les uns avec les autres pour créer une connexion sûre, même lorsqu'ils sont soumis à des pressions de travail élevées, des pics de pression et des vibrations."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Adapter}
                                title="Adaptateurs"
                                desc={"Adaptateurs filetés, de transition ou d'extrémité de tuyau : les adaptateurs créent une connexion sûre et étanche entre des composants avec différents types de filetage."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={BallValves}
                                title="Vannes à boisseau sphérique"
                                desc={"Les vannes à boisseau sphérique sont utilisées pour fermer, contrôler et réguler le débit dans les systèmes de tuyauterie jusqu'à des pressions de 500 bars et évitent ainsi la nécessité de convertir ou de désinstaller des composants."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Flanges}
                                title="Brides"
                                desc={"Les brides peuvent être simplement installées sans nécessité d'appliquer de grandes quantités de force et dans des endroits encombrés, même pour des composants de grand diamètre."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Couplings}
                                title="Accouplements"
                                desc={"Échangez des pièces jointes, connectez des machines : les accouplements sont idéaux lorsque les composants doivent être rapidement connectés et déconnectés sur mobile et fixe"} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"TECHNOLOGIE FILETÉE DE QUALITÉ HANSA‑FLEX ÉPROUVÉE "}
                    subtitle={"Les connexions entre différents composants doivent être robustes et durables sous des pressions de travail élevées et des charges extrêmes."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"LIGNES DE TUYAUX HYDRAULIQUES ET PIPELINES DE HANSA‑FLEX"}
                    subtitle={"En tant que fournisseur de systèmes, HANSA‑FLEX peut fournir des lignes de tuyaux hydrauliques pour tout, des applications mobiles sur les chantiers de construction jusqu'aux systèmes hydrauliques stationnaires dans l'industrie alimentaire."}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={Stock}
                                title="Stocks de composants bien gérés"
                                desc={"La bonne quantité au bon moment et au bon endroit : avec HANSA‑FLEX, vous bénéficiez d'une gestion de stock optimisée, y compris une livraison directe à votre"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Design}
                                title="Conception de système optimisée"
                                desc={"Nos spécialistes en hydraulique vous conseillent et vous aident à planifier votre système hydraulique et veillent à ce que l'ensemble du système soit conçu pour être efficace."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={SealingTech}
                                title="Technologie d'étanchéité fiable"
                                desc={"Les installations de production de technologie d'étanchéité HANSA‑FLEX fabriquent des joints selon les exigences individuelles des clients jusqu'à un diamètre de 2 500 mm en tant que production unique ou en série..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )

}

export default HydraulicConnectionTechnology