import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../Components/HfTabs/HfTabs';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';

import imgStage from '../../../assets/Products/DriveAndControlTechnology/Cylinders/Stage.jpg'
import imgStandardCylinder from '../../../assets/Products/DriveAndControlTechnology/Cylinders/StandardCylinder.jpg'
import imgCustomerSpecific from '../../../assets/Products/DriveAndControlTechnology/Cylinders/CustomerSpecific Cylinder.jpg'
import imgTesting from '../../../assets/Products/DriveAndControlTechnology/Cylinders/Testing.jpg'
import imgChoiceOfColour from '../../../assets/Products/DriveAndControlTechnology/Cylinders/ChoiceOfColour.jpg'
import imgCorrosionProtection from '../../../assets/Products/DriveAndControlTechnology/Cylinders/CorrosionProtection.jpg'
import imgServices from '../../../assets/Products/DriveAndControlTechnology/Cylinders/Services.jpg'
import imgHydraulicCylinder from '../../../assets/Products/DriveAndControlTechnology/Cylinders/HydraulicCylinder.jpg'
import imgCylinderWithInductive from '../../../assets/Products/DriveAndControlTechnology/Cylinders/CylinderWithInductive.jpg'
import imgCylinderWithValveTech from '../../../assets/Products/DriveAndControlTechnology/Cylinders/CylinderWithValveTech.jpg'
import imgHydraulicCylinderComponent from '../../../assets/Products/DriveAndControlTechnology/Cylinders/HydraulicCylinderComponent.jpg'
import imgCylinderRepair from '../../../assets/Products/DriveAndControlTechnology/Cylinders/CylinderRepair.jpg'
import imgSealingTech from '../../../assets/Products/DriveAndControlTechnology/Cylinders/SealingTech.jpg'
import imgHydraulicUnit from '../../../assets/Products/DriveAndControlTechnology/Cylinders/HydraulicUnit.jpg'


const Cylinder = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const cylinder1 = {
        image: imgStandardCylinder,
        title: 'Commandez dès maintenant des vérins standard dans la boutique en ligne HANSA‑FLEX',
        description: 'Notre boutique en ligne propose de nombreux vérins simple et double effet jusqu’à 200 bars avec des dimensions standardisées et, si nécessaire, des éléments de montage sur la tige et la base.',
        listItems: ["Température admissible : -15 °C à +80 °C", "Divers matériaux d'étanchéité (NBR, Viton, PTFE)", "Tige de piston en acier haute résistance (résistance de 200 h au test NSS)"],
    }

    const cylinder2 = {
        image: imgCustomerSpecific,
        title: 'Fabrication spécifique aux besoins du client de nouveaux vérins',
        description: "HANSA‑FLEX fabrique des vérins hydrauliques en tant que pièces de rechange, prototypes ou nouvelles lignes de produits selon vos besoins individuels en tant que pièces fabriquées en une seule fois, en petites séries ou en série. Les vérins, conçus pour l'application prévue et utilisant des composants standardisés, offrent un rapport qualité- prix attrayant combiné à une acquisition facile de pièces de rechange.",
        listItems: ["Diverses gammes de pression jusqu'à un maximum de 350 bars", "Conseils complets, création de modèles 3D", "Matériaux, traitements de surface et finitions peinture spécifiques au client"],
    }

    const cylinder3 = {
        image: imgHydraulicCylinder,
        title: 'Vérins hydrauliques avec systèmes de mesure de déplacement intégrés',
        description: "Nous équipons les vérins de systèmes de mesure de déplacement pour un positionnement précis, des mouvements d'actionnement et de contrôle.Un signal de position continu fournit des données en temps réel précises sur le mouvement du vérin.",
        listItems: ["Signalement précis de la position et de la vitesse du vérin", "Systèmes de mesure de déplacement provenant d'un fabricant renommé", "Large choix de types de capteurs (numériques, analogiques, capacitifs)", "Les vérins de toutes tailles peuvent être fabriqués", "Génération de signal fiable dans toutes les conditions"],
    }

    const cylinder4 = {
        image: imgCylinderWithInductive,
        title: 'Vérins hydrauliques avec interrupteurs de fin de course inductifs',
        description: "Les interrupteurs de fin de course sont une alternative simple et économique aux capteurs de déplacement linéaire lorsque le mouvement d'une tige de piston doit être déterminé. Des commutateurs de proximité inductifs, résistants à la pression, sont fixés dans la position finale du vérin hydraulique. Si le piston atteint l'une des deux positions finales, l'interrupteur de proximité pertinent transmet un signal électronique.",
        listItems: ["Détermination de position fiable et précise", "Commutation sans contact et sans usure", "Interrogation précise des positions finales et haute précision de répétition"],
    }

    const cylinder5 = {
        image: imgCylinderWithValveTech,
        title: 'Vérins hydrauliques avec technologie de soupape',
        description: "Protégez les personnes et les machines ! Nous proposons des vérins hydrauliques avec une technologie de soupape intégrée pour une sécurité encore meilleure. Les mesures de sécurité peuvent garantir de manière fiable le freinage du mouvement du vérin et empêcher une descente involontaire, même en cas de défaillance d'une conduite flexible.",
        listItems: ["Les soupapes peuvent être installées dans la base ou sur le vérin", "Diverses soupapes de maintien de charge et de libération", "Les spécialistes des fluides expérimentés configurent la technologie de soupape en interne"],
    }

    const cylinder6 = {
        image: imgHydraulicCylinderComponent,
        listItems: ["Courses de vérin jusqu'à 5 000 mm, diamètre de piston jusqu'à 600 mm", "Diverses pressions de travail de 200 à 350 bars", "Vérins standard avec ou sans fixations", "Vérins simple et double effet", "Vérins différentiels, en tandem, synchronisés et à piston", "Éléments de montage, tubes de vérin, tiges de piston et accessoires"],
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Poésie en mouvement",
            description: "Le fonctionnement à faible frottement produit un démarrage en douceur et des mouvements fluides",
            image: imgMark
        },
        {
            title: "Longue durée de vie",
            description: "Les tiges de piston haute résistance fabriquées à partir d'un alliage d'acier au carbone absorbent les chocs les plus durs",
            image: imgMark
        },
        {
            title: "Entretien facile",
            description: "Des douilles d'étanchéité remplaçables permettent un entretien rapide et une productivité élevée",
            image: imgMark
        },
        {
            title: "Haute disponibilité",
            description: "Les joints toriques de tube de vérin parfaitement adaptés empêchent les fuites, même en cas de chocs de pression",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Vérins de haute qualité testés de manière exhaustive",
            description: "Fabriqués en acier haute résistance ou, si nécessaire, en acier inoxydable, tous les vérins HANSA‑FLEX répondent aux normes de qualité les plus élevées et font l'objet de tests approfondis sur des bancs d'essai modernes.",
            listItems: ["Processus de test complet comprenant un rapport de test", "Essais de pression, de fuite et de fonction conformes aux normes", "Réglage des capteurs et de la technologie des soupapes", "Fourni prêt à être installé"],
            image: imgTesting
        },
        {
            title: "Votre vérin hydraulique dans la couleur de votre choix",
            description: "Nos vérins personnalisés non seulement répondent à vos exigences techniques ; nous les peignons également pour qu'ils se fondent parfaitement avec le reste de votre machine ou de votre système.",
            listItems: ["Peint dans diverses couleurs au choix du client", "Catégorie de corrosivité jusqu'à C5-M", "Disponible en produits fabriqués en une seule fois, en petites séries ou en série"],
            image: imgChoiceOfColour
        },
        {
            title: "Traitement de surface pour une protection contre la corrosion maximale",
            description: "Le matériau de tige de piston utilisé pour les vérins hydrauliques HANSA‑FLEX a une résistance à la corrosion allant jusqu'à 1000 h vérifiée dans des tests de brouillard salin (brouillard) effectués conformément à ISO 3768, classe 9 ISO 4540, en fonction de la qualité du matériau et du revêtement de surface.",
            listItems: ["Acier (Ck45) chromé dur", "Acier (20MnV6) chromé dur", "Acier inoxydable (1.4057) chromé dur", "Acier (20MnV6) nickelé et chromé dur", "Acier (42CrMo4V) trempé superficiellement et chromé dur"],
            image: imgCorrosionProtection
        },
        {
            title: "Tout d'un seul coup",
            description: "HANSA‑FLEX fabrique des systèmes et des vérins sous un même toit. Cela nous permet d'offrir à nos clients un système global parfaitement adapté avec un rapport qualité-prix attrayant.",
            listItems: ["Vérins conçus pour correspondre à l'unité hydraulique", "Fabrication de systèmes hydrauliques comprenant tous les composants", "Installation par le Service Industriel HANSA‑FLEX si nécessaire", "Tests effectués pendant et après la mise en service"],
            image: imgServices
        },
    ];

    const tabTitles = [
        { title: "Tests", eventKey: "Tests" },
        { title: "Choix de couleurs", eventKey: "Choix de couleurs" },
        { title: "Protection contre la corrosion", eventKey: "Protection contre la corrosion" },
        { title: "Services", eventKey: "Services" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"VENTE ET FABRICATION DE VÉRINS HYDRAULIQUES DE TOUTES TAILLES"}
                    paragraph={"Les vérins hydrauliques effectuent un travail extrêmement lourd. En tant qu'éléments d'entraînement les plus importants en hydraulique, ils transforment l'énergie de pression du fluide hydraulique en énergie mécanique. Les vérins peuvent transmettre des forces très élevées tout en produisant des mouvements uniformes et précis. HANSA‑FLEX propose une large gamme de vérins standard simple et double effet et fabrique des vérins hydrauliques sur mesure jusqu'à 8 000 mm de hauteur."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"VÉRINS HYDRAULIQUES POUR TOUS LES DOMAINES D'APPLICATION"}
                />
                <ImageText
                    data={cylinder1} />
                <ImageText
                    data={cylinder2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"ACHETEZ DES VÉRINS HYDRAULIQUES AUPRÈS DES SPÉCIALISTES DES FLUIDES"}
                    subtitle={"Dans ses propres installations de fabrication, qui bénéficient d'un haut niveau d'intégration verticale, HANSA‑FLEX fabrique des vérins hydrauliques de haute qualité et propose des rapports qualité-prix très attractifs avec des délais de livraison impressionnants, même pour les articles spéciaux."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"AUTRES COMPOSANTS D'UNITÉS HYDRAULIQUES"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"CAPTEURS INTÉGRÉS ET TECHNOLOGIE DE SOUPAPE"}
                    subtitle={"Nous équipons les vérins hydrauliques de technologie de soupape intégrée et de capteurs pour des fonctions de sécurité supplémentaires, un positionnement précis, une conduite sans problème de différents axes de mouvement ou pour l'automatisation de séquences de mouvement."}
                />
                <ImageText
                    data={cylinder3} />
                <ImageText
                    data={cylinder4}
                    left={false} />
                <ImageText
                    data={cylinder5} />
            </div>

            <div>
                <SectionHeader
                    title={"VÉRINS HYDRAULIQUES ET COMPOSANTS DE VÉRIN DE HANSA‑FLEX"}
                    subtitle={"Notre boutique en ligne offre un large choix de vérins simple et double effet, de divers composants et accessoires. Nous fabriquons également des vérins spéciaux selon les plans ou les exigences du client."}
                />
                <ImageText
                    data={cylinder6} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCylinderRepair}
                                title="Réparations de vérins « made in Königshofen »"
                                desc={"Le centre de réparation de vérins HANSA‑FLEX révise des vérins hydrauliques de toutes tailles jusqu'à 8 000 mm et répare des pompes hydrauliques, des vannes et des moteurs hydrauliques."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgSealingTech}
                                title="Technologie d'étanchéité pour toutes les situations"
                                desc={"HANSA‑FLEX a toujours la solution d'étanchéité parfaite. Plus de 8 000 joints standard disponibles en stock et un centre de fabrication interne pour les joints spéciaux."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgHydraulicUnit}
                                title="Unités hydrauliques construites selon les besoins du client"
                                desc={"De la planification, de la gestion de projet à la mise en service de projets d'unités hydrauliques et d'ingénierie de systèmes, HANSA‑FLEX tire parti de ses connaissances et de son expertise pour aider.."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )

}

export default Cylinder