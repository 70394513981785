import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import img_Product_Pipeend_Centerspread from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_centerstage.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import img_Products_Pipeend_imagetext1 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext1.jpg'
import img_Products_Pipeend_imagetext2 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext2.jpg'
import img_Products_Pipeend_imagetext3 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext3.jpg'
import img_Products_Pipeend_imagetext4 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext4.jpg'
import img_Products_Pipeend_imagetext5 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext5.jpg'
import img_Products_Pipeend_imagetext6 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext6.jpg'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import { Container, Row, Col } from 'react-bootstrap';
import imgProductPipeendimagecard1 from '../../../assets/Products/Pipelines/Pipe-end processing/Pipeend_Imagecard1.jpg'
import imgProductPipeendimagecard2 from '../../../assets/Products/Pipelines/Pipe-end processing/Pipeend_Imagecard2.jpg'
import imgProductPipeendimagecard3 from '../../../assets/Products/Pipelines/Pipe-end processing/Pipeend_Imagecard3.jpg'
import AllConnections from '../../../assets/Products/Pipelines/Pipe-end processing/AllConnections.jpg'

import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import ImageText from '../../../Components/ImageText/ImageText';
import imgMark from '../../../assets/mark.png'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'

const PipeendProcessing = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;


  const ProductPipebendimagetext1 = {
    image: img_Products_Pipeend_imagetext1,
    title: 'Le centre de cintrage des tubes et les agences travaillent main dans la main',
    description: ["Les experts du centre de cintrage des tubes HANSA‑FLEX possèdent les compétences et les connaissances nécessaires en matière de cintrage des tubes et de technologie des fluides pour relever les défis les plus complexes. Ils travaillent en étroite collaboration avec les spécialistes des agences HANSA‑FLEX. Vous pouvez vous attendre au meilleur conseil possible et à un chemin optimal de la conception à la fabrication. Sur demande, HANSA‑FLEX est heureux de fabriquer des ensembles de tuyaux prêts à installer et de les livrer Just-In-Time directement sur le site d'installation du client, de sorte que l'ensemble du processus après le cintrage des tubes se déroule sans problème."],
  }

  const ProductPipebendimagetext2 = {
    image: img_Products_Pipeend_imagetext2,
    title: 'Vérification de la forme des coudes de tuyaux',
    description: ['Les experts en cintrage chez HANSA‑FLEX déterminent toutes les données requises pour le tuyau cintré à partir de la simulation informatique. Si la simulation montre que le degré d\'ovalisation dans la zone de courbure dépasse la limite de 10 % appliquée en hydraulique, alors un mandrin interne est utilisé pendant le cintrage pour empêcher la section transversale du tuyau de se rétrécir. Réduire l\'ovalisation réduit les turbulences dans le système de tuyauterie et augmente l\'efficacité.'],
  }

  const ProductPipebendimagetext3 = {
    image: img_Products_Pipeend_imagetext3,
    title: 'Atelier de production bien équipé',
    description: ['Pour répondre aux diverses exigences en matière de géométrie des tuyaux, il faut bien plus qu\'un seul type de machine. La vaste gamme de machines du centre de cintrage des tubes permet à HANSA‑FLEX d\'effectuer une grande variété de tâches de cintrage et de produire des résultats de la plus haute qualité. HANSA‑FLEX acquiert continuellement de nouvelles machines pour garantir non seulement la plus haute précision, mais aussi une flexibilité maximale sur le plancher de production. Environ 20 autres machines CNC viennent compléter une cellule de cintrage entièrement automatisée.'],
  }

  const ProductPipebendimagetext4 = {
    image: img_Products_Pipeend_imagetext4,
    title: 'Raccords spéciaux au lieu de tours de raccords vissés',
    description: ['HANSA‑FLEX fabrique des raccords spéciaux pour une grande variété d\'industries et d\'applications. Des raccords pliés en plusieurs exemplaires, toute forme d\'adaptateur avec une variété de filetages, y compris des collecteurs et des collecteurs complexes. La conception est toujours décidée en consultation avec le client, enregistrée sur un dessin puis fabriquée. Les raccords spéciaux évitent d\'avoir à connecter plusieurs adaptateurs et pièces de connexion les uns après les autres. Cela réduit considérablement la consommation de matière, le poids et le risque de fuites.'],
  }

  const ProductPipebendimagetext5 = {
    image: img_Products_Pipeend_imagetext5,
    title: 'Procédures spéciales pour le brasage et le soudage',
    description: ['HANSA‑FLEX propose tous les processus de brasage disponibles pour former des connexions sûres. Four de brasage continu pour brasage dur et à haute température sous gaz protecteur, par exemple brasage d\'une longueur de 16 m à un débit d\'environ 100 kg/h à une température maximale de 1 130 °C. HANSA‑FLEX est certifié pour réaliser des processus de soudage conformément à GSI, DIN EN 15085 2, DIN 2303 Q2 BK1 et DIN EN ISO 3834. HANSA‑FLEX dispose également d\'une machine de soudage orbital. Cette technique produit une qualité de soudure constamment élevée et reproductible, quelle que soit la cadence.'],
  }

  const ProductPipebendimagetext6 = {
    image: img_Products_Pipeend_imagetext6,
    title: 'Conseils en face à face sur site',
    description: ['Une large gamme d\'options de connexion, de matériaux et de traitements de surface sont pris en considération pour différentes applications. HANSA‑FLEX considère donc un conseil complet en face à face sur site comme très important pour trouver la solution parfaite dans chaque domaine d\'utilisation. Ainsi, chaque client bénéficie des décennies d\'expérience acquise par nos techniciens en technologie des fluides et nos spécialistes dans de nombreux secteurs industriels.'],
  }

  const ProductPipebendimagetext7 = {
    image: AllConnections,
    listItems: ["90° ORFS, ISO 8434-3, processus de retournement et formage rotatif", "Cône d'étanchéité à 24°, ISO 8434-1/DIN 2353", "Bague de coupe à double bord, DIN 3861", "Raccord de conduite de frein et raccords de couplage de type D-E-F, DIN 74234", "Cône d'étanchéité 37° JIC (Triple-Lok), ISO 8434-2/SAE J 514", "Rehaussement en tant que perle pour collier de serrage à ressort DIN 3021-3"],
  }

  // FeatureCardData

  const featuresData = [
    {
      title: "Qualité et précision optimales",
      description: "HANSA‑FLEX s'appuie sur des machines de pointe et des processus de fabrication contrôlés par ordinateur",
      image: imgMark
    },
    {
      title: "Disponibilité rapide",
      description: "Les commandes de composants fabriqués en série ou en pièce unique sont traitées également rapidement.",
      image: imgMark
    },
    {
      title: "Toutes les formes couramment disponibles",
      description: "Les clients peuvent obtenir toutes les variantes de connexion couramment disponibles pour leurs raccordements de tuyaux auprès de HANSA‑FLEX.",
      image: imgMark
    },
    {
      title: "Fabrication personnalisée",
      description: "HANSA‑FLEX fabrique des raccords spéciaux pour les systèmes et les machines précisément selon les exigences du client.",
      image: imgMark
    }
    // Ajouter plus d'objets pour des données supplémentaires de FeaturesCard
  ];
  return (
    <div>
      <div>
        <CenterStage
          imageSrc={img_Product_Pipeend_Centerspread}
          title={"TUYAUX CINTRÉS POUR LA TECHNOLOGIE DES FLUIDES DE HANSA‑FLEX"}
          paragraph={"Les tuyaux cintrés sont utilisés non seulement dans des situations d'installation serrées, mais aussi pour des systèmes de tuyauterie complexes. HANSA‑FLEX a mis en place un centre de cintrage des tubes accessible à toutes les agences de l'entreprise. Le centre de cintrage des tubes HANSA‑FLEX fabrique des tuyaux cintrés de précision à partir de divers matériaux pour toutes nos agences. Les tuyaux sont disponibles avec tous les types de connexion courants et dans la qualité HANSA‑FLEX éprouvée..."}
        />
      </div>

      <SectionHeader
        title={"LES SPÉCIALISTES DU TRAITEMENT DES EXTREMITÉS DE TUYAUX ET DES RACCORDS"}
        subtitle={"HANSA‑FLEX possède le savoir-faire et la technologie pour mettre en œuvre de nombreuses exigences différentes en matière de traitement des extrémités de tuyaux, y compris des solutions standard et spéciales en pièce unique ou en production en série"}
      />


      <div >

        <ImageText data={ProductPipebendimagetext1} left={false} />
      </div>

      <div >

        <ImageText data={ProductPipebendimagetext2} />
      </div>

      <div >

        <ImageText data={ProductPipebendimagetext3} left={false} />
      </div>

      <SectionHeader
        title={"TUYAUX TRAITÉS AVEC PRÉCISION POUR RÉPONDRE À VOS EXIGENCES"}
        subtitle={"En tant que principal fabricant de composants pour le secteur de la technologie des fluides, HANSA‑FLEX possède le savoir-faire et les ressources de production pour fabriquer des extrémités de tuyaux personnalisées à l'aide de divers processus."}
      />


      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>

      <SectionHeader
        title={"RACCORDS SPÉCIAUX FABRIQUÉS PAR HANSA‑FLEX"}
        subtitle={""}
      />

      <div >

        <ImageText data={ProductPipebendimagetext4} left={false} />
      </div>

      <div >

        <ImageText data={ProductPipebendimagetext5} />
      </div>

      <div >
        <ImageText data={ProductPipebendimagetext6} left={false} />
      </div>

      <SectionHeader
        title={"TOUTES LES CONNEXIONS DU MÊME FOURNISSEUR"}
        subtitle={"Les clients peuvent acheter toutes les connexions commercialement disponibles auprès de HANSA‑FLEX. Il s'agit notamment de la bague de coupe traditionnelle, de diverses solutions métalliques formées, et même de connexions spécialement conçues sur demande."}
      />
      <ImageText data={ProductPipebendimagetext7} />


      <SectionHeader
        title={"CES SUJETS POURRAIENT AUSSI VOUS INTÉRESSER"}
      />
      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgProductPipeendimagecard1} learnMoreLink="/hydraulic-hoses" title="Les raccords de bride HANSA‑FLEX sont les raccords idéaux pour les grands" desc="Les raccords de bride HANSA‑FLEX sont idéaux pour les grands diamètres, les applications haute pression et les situations d'installation restreintes." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgProductPipeendimagecard2} learnMoreLink="/metal-hoses" title="Tuyaux cintrés sur mesure" desc="Des tuyaux de précision de qualité éprouvée HANSA‑FLEX sont fabriqués sur mesure à partir de divers matériaux dans le centre de cintrage des tubes HANSA‑FLEX." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgProductPipeendimagecard3} learnMoreLink="/Industrial-hoses" title="Connecté par des raccords de tuyaux" desc="Les transitions entre différents composants sont toujours un point à considérer attentivement dans les systèmes hydrauliques. Les raccords de tuyaux de HANSA‑FLEX créent une connexion fiable." />
          </Col>

        </Row>
      </Container>



    </div>
  )

}

export default PipeendProcessing