import React, { useEffect } from 'react'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgWarehouse_Centerspread from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Centerspread.jpg'
import ImageText from '../../../Components/ImageText/ImageText'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgWarehouse_Imagetext_01 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagetext_01.jpg'
import imgWarehouse_Imagetext_02 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagetext_02.jpg';
import imgWarehouse_Imagetext_03 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagetext_03.jpg';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import { Container, Row, Col } from 'react-bootstrap';
import imgWarehouse_Imagecard_01 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagecard_01.jpg'
import imgWarehouse_Imagecard_02 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagecard_02.jpg'
import imgWarehouse_Imagecard_03 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagecard_03.jpg'



const Warehousemanagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);



  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Warehouse_Imagetext_01= {
    image: imgWarehouse_Imagetext_01,
    title: 'Optimiser votre inventaire',
    description: ["Nous créons et gérons pour vous une liste d'articles standardisée. Cette liste constitue la base d'une gestion efficace de l'entrepôt. La liste d'articles définit l'utilisation continue du matériel et permet une utilisation efficace de vos zones de stockage. Nous identifions tous les groupes d'articles dont vous avez besoin pour assurer un flux de production régulier. En tant que fournisseur unique, nous pouvons vous fournir de manière fiable le matériel standard défini. Cela vous permet de consolider votre liste de fournisseurs et d'économiser des frais d'administration lors de la commande de marchandises. Avec HANSA-FLEX, vous pouvez choisir des intervalles de livraison flexibles en fonction de vos besoins."],
  }

  const Warehouse_Imagetext_02= {
    image: imgWarehouse_Imagetext_02,
    title: 'Stockage adéquat des composants',
    description: ["Les composants doivent être stockés de manière à ce que leur qualité et leur durée de conservation ne soient pas affectées par la poussière, les liquides ou les fortes variations de température. Le risque de dommages mécaniques causés par des objets tombant ou roulant sur les composants doit être réduit au minimum. Nos collaborateurs hautement compétents sont toujours disponibles pour vous fournir des conseils et une assistance pratique afin de vous aider grâce à leurs vastes connaissances et compétences éprouvées dans la pratique. Il s'agit par exemple de positionner systématiquement les systèmes de stockage et les marchandises de manière à réduire les distances à parcourir par vos employés et le temps qu'ils consacrent à la recherche des articles souhaités. Ainsi, plus rien ne vient entraver l'accès aux composants et aux machines."],
  }


  const Warehouse_Imagetext_03= {
    image: imgWarehouse_Imagetext_03,
    title: 'Solutions pour un débit élevé de marchandises',
    description: ["Les grandes entreprises ont besoin d'un plus grand nombre de pièces et l'automatisation des processus de commande et d'entreposage devient de plus en plus importante. Il arrive un moment où l'enregistrement écrit des besoins en matériaux et la saisie manuelle des commandes prennent trop de temps pour que l'ensemble du processus reste efficace. Consultez votre conseiller clientèle HANSA-FLEX habituel pour connaître les possibilités de numérisation de vos processus de commande, par exemple avec une solution de scanner, ou d'automatisation avec un système Kanban. Un autre moyen d'optimiser un débit de marchandises élevé serait d'adopter une approche en kit pour votre stock de pièces."],
  }



  const featuresData = [
    {
      title: "Réduction des coûts de stockage et de matériel",
      description: "Une gestion efficace de l'entrepôt peut minimiser votre capital immobilisé et les pièces mises au rebut.",
      image: imgMark
    },
    {
      title: "Un gain de temps substantiel",
      description: "Des trajets à pied plus courts, des frais administratifs réduits pour les commandes et pas de temps perdu à chercher, grâce à une optimisation systématique.",
      image: imgMark
    },
    {
      title: "Tout à partir d'une source unique",
      description: "HANSA-FLEX est votre fournisseur unique et fiable de tous les composants nécessaires.",
      image: imgMark
    },
    {
      title: "Solutions numériques",
      description: "Nous préparons le terrain et fournissons l'équipement pour votre entrepôt numérisé.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];








  return (
    <div>

      <CenterStage
        imageSrc={imgWarehouse_Centerspread}
        title={"GESTION OPTIMALE DE L'ENTREPÔT AVEC HANSA-FLEX"}
        paragraph={"En tant que partenaire système pour tout ce qui concerne la technologie des fluides, notre objectif est de réduire le temps que vous consacrez aux activités commerciales de routine, afin que vous puissiez vous concentrer sur les activités créatrices de valeur. Nous vous aidons notamment à optimiser systématiquement vos processus d'entreposage afin de réduire le temps inutile consacré à la recherche d'articles et de concevoir un flux de matériaux plus efficace. En collaboration avec vous, nous trouvons la solution qui répond le mieux à vos besoins."}
      />



      <SectionHeader
        title={"DES PROCESSUS DE COMMANDE ET D'ENTREPOSAGE EFFICACES"}
        subtitle={"Nous ne sommes pas seulement un fournisseur fiable de composants, nous vous soutenons également avec notre expérience et nos connaissances spécialisées, toujours dans le but d'augmenter la rentabilité de vos processus de commande et d'entreposage."}
      />




      <div >
        <br />
        <ImageText data={Warehouse_Imagetext_01} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Warehouse_Imagetext_02} />
      </div>

      <div >
        <br />
        <ImageText data={Warehouse_Imagetext_03} left={false} />
      </div>


      <SectionHeader
        title={"GESTION D'ENTREPÔT AXÉE SUR LE CLIENT AVEC HANSA-FLEX"}
        subtitle={"Nous garantissons la disponibilité des marchandises pour vous et vous offrons un soutien proactif pour concevoir les processus associés de manière à ce qu'ils soient aussi pratiques et rentables que possible pour vous."}
      />


      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>



      <SectionHeader
        title={"LE PAQUET DE SERVICES IDÉAL POUR CHAQUE ENTREPRISE"}
        subtitle={"HANSA-FLEX propose une vaste gamme de services, tous adaptables à vos besoins, de sorte que la solution globale soit parfaite pour votre entreprise."}
      />

      <SectionHeader
        title={"AUTRES SERVICES D'ENTREPOSAGE ET DE LOGISTIQUE"}
        subtitle={""}
      />

{/* TODO: Redbanner_Carrousel */}




<Container className="custom-container">
        <Row>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgWarehouse_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Solutions de numérisation pour la gestion numérique de la demande" desc="Commandez vos composants pour la technologie des fluides directement dans votre entrepôt ou sur votre ligne de production, rapidement et facilement par scanner et grâce à l'application Easy Order de HANSA-FLEX." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                <ImageCard image={imgWarehouse_Imagecard_02} learnMoreLink="/metal-hoses" title="Des processus de fabrication efficaces avec des pièces fournies en kits" desc="Les kits d'assemblage de pièces prêtes à l'emploi minimisent les coûts initiaux, offrent une qualité maximale et contribuent à une augmentation durable de la productivité." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgWarehouse_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Livraison Kanban de HANSA-FLEX" desc="Avec la livraison Kanban basée sur le principe de la traction de HANSA-FLEX, vous bénéficiez d'une livraison juste à temps, précisément quand vous en avez besoin, y compris directement dans votre entreprise." />
            </Col>

        </Row>
    </Container>












    </div>
  )
}

export default Warehousemanagement