import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageText from '../../../Components/ImageText/ImageText'

import Stage from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/stage.jpg'
import PressFitting from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/PressFitting.jpg'
import InterlockFitting from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/InterlockFitting.jpg'
import SpecialFitting from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/SpecialFitting.jpg'
import FittingsForExtreme from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/FittingsForExtreme.jpg'
import FittingForLowPressure from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/FittingForLowPressure.jpg'
import EasyToInstall from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/EasyToInstall.jpg'
import TheWide from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/TheWide.jpg'
import PreAssembled from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/PreAssembled.jpg'
import HydraulicHoses from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/HydraulicHoses.jpg'
import RightConnections from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/RightConnections.jpg'

const HoseFittings = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const featuresData = [
        {
            title: "Performance maximale",
            description: "Chaque raccord est parfaitement adapté au tuyau et offre ainsi la plus haute protection contre les fuites.",
            image: imgMark
        },
        {
            title: "Service complet",
            description: "Tous les raccords sont disponibles individuellement. Nous sommes également ravis de les fournir montés sur des lignes de tuyau.",
            image: imgMark
        },
        {
            title: "Sécurité maximale",
            description: "Des tests approfondis garantissent la fonctionnalité des raccords jusqu'à 500 bars.",
            image: imgMark
        },
        {
            title: "Excellente protection contre la corrosion",
            description: "Grâce à un revêtement de zinc-nickel, les raccords à pression sont résistants à la rouille rouge pendant 1 200 h.",
            image: imgMark
        },
        // Ajoutez plus d'objets pour des données supplémentaires de FeaturesCard
    ];

    const Data1 = {
        title: 'Raccords à pression pour applications moyennes et haute pression',
        description: "Pour des applications standard dans des environnements à pression moyenne et élevée, nous proposons une vaste gamme de ferrules à sertir et de mamelons de tuyau avec des filetages de connexion métriques, UNF, NPT et impériaux, des têtes de bride SAE et d'autres systèmes de bride.En plus des ferrules à sertir sans dénudage généralement plus rapides à assembler, dans lesquelles le manchon est poussé sur le tuyau non pelé puis serti, la boutique en ligne HANSA‑FLEX propose également des raccords de tuyau pour tuyaux tressés avec des ferrules à sertir avec dénudage jusqu'à une taille nominale de DN 76.",
        image: PressFitting
    };

    const Data2 = {
        title: 'Raccords de tuyau à verrouillage avec protection contre le tirage',
        description: "Pour garantir une connexion sûre entre le tuyau et le raccord, même sous des charges extrêmes et des pressions élevées jusqu'à 500 bars, HANSA‑FLEX propose une large gamme de raccords de verrouillage jusqu'à une taille nominale de DN 50 en tant que solution système avec protection contre le tirage. Les couches intérieure et extérieure du tuyau sont dénudées (double dénudage) pour permettre au mamelon à sertir d'obtenir un ajustement positif hautement efficace avec le tuyau et le raccord.Les charges sur l'élastomère dans la zone du sertissage sont optimisées, ce qui prolonge la durée de vie de la ligne de tuyau.",
        image: InterlockFitting
    };

    const Data3 = {
        title: 'Raccords spéciaux sur mesure',
        description: "En plus des raccords à sertir standard, nous concevons et fabriquons des raccords spéciaux pour des champs d'application spécifiques dans tous les diamètres et même en grande série selon les spécifications individuelles du client.Nos experts fournissent des conseils complets en tenant compte de tous les aspects de l'ensemble du système pour arriver à la solution optimale. Ensuite, nous fabriquons les raccords dans notre centre de production à Dresden-Weixdorf avec la plus haute précision.",
        image: SpecialFitting
    };

    const Data4 = {
        title: "Raccords pour conditions d'utilisation extrêmes",
        description: "Les applications extrêmes et les pressions les plus élevées nécessitent des solutions spéciales. Les raccords à sertir HANSA‑FLEX pour les tuyaux élastomères et thermoplastiques couvrent la plage de pression extrêmement élevée et offrent une solution fiable et sûre pour les applications impliquant des pressions élevées dans l'hydraulique industrielle et mobile, telles que les plates- formes de levage, les machines agricoles et de construction.",
        listItems: ["Raccords à sertir pour charges dynamiques et hautes pressions", "Convient pour les tuyaux élastomères et thermoplastiques", "Systèmes sûrs et durables avec des composants conçus pour fonctionner parfaitement ensemble"],
        image: FittingsForExtreme
    };

    const Data5 = {
        title: 'Raccords pour tuyaux à faible pression de poussée',
        description: "Avec les raccords à pousser, le tuyau est simplement poussé sur le mamelon à pousser, ce qui le fixe en place. Il n'est pas nécessaire de préparer le tuyau de manière fastidieuse car, contrairement aux tuyaux haute pression, ils peuvent être coupés à la longueur avec des outils de coupe simples au lieu de machines de découpe.",
        listItems: ["Raccords à pousser en laiton", "Assemblage simple sans outils spéciaux", "Idéal pour les applications à basse pression"],
        image: FittingForLowPressure
    };

    const Data6 = {
        title: 'Raccords vissés faciles à installer',
        description: "Les raccords vissés sont reconnus pour leur facilité d'assemblage. La ferrule est vissée sur le tuyau, puis le mamelon est vissé dans la pièce de connexion pour créer la connexion requise. Ces raccords vissés sont généralement assemblés sans outils spéciaux et peuvent être réutilisés.",
        listItems: ["Facilité de fabrication des lignes de tuyau", "Aucun outil spécial tel que des presses à tuyaux n'est nécessaire", "Le raccord peut être démonté et remonté"],
        image: EasyToInstall
    };

    const Data7 = {
        listItems: ["Raccords de tuyau droits", "Raccords coudés avec coudes (45°, 90° et 180°)", "Tous les diamètres nominaux couramment disponibles et des solutions fabriquées individuellement", "De nombreux types de connexions (métriques, pouces, BSP, UNF, NPT, JIS, SAE)", "Raccords haute résistance jusqu'à 500 bars testés à 2 000 000 d'impulsions"],
        image: TheWide
    };

    const Data8 = {
        listItems: [
            "Respect de toutes les normes et standards internationaux",
            "Excellente compatibilité avec les autres composants",
            "Conçu pour les hautes pressions, températures et charges",
            "Polyvalence grâce à une grande variété de solutions de produits",
            "Excellente disponibilité grâce à nos propres installations de production et à notre réseau de revendeurs",
            "Jusqu'à 1 500 heures de résistance à la corrosion pour les articles non déformés"
        ],
    };

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"RACCORDEMENTS DE TUYAU POUR L'HYDRAULIQUE"}
                    paragraph={"Les raccords conçus et fabriqués par HANSA‑FLEX sont parfaits pour une utilisation avec le type de tuyau approprié. Ils garantissent une connexion sûre et sans fuite. La gamme comprend tous les diamètres nominaux et types de connexion couramment demandés et est conforme aux normes internationales (BS, SAE, DIN et NF). Les raccords sont revêtus de zinc-nickel et répondent aux exigences les plus élevées en matière de durabilité et de résistance à la corrosion."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"RACCORDS À PRESSION DE HANSA‑FLEX"}
                    subtitle={"Systèmes et solutions de raccords à sertir pour l'auto-assemblage"}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
                <ImageText
                    data={Data3} />
            </div>

            <div>
                <SectionHeader
                    title={"RACCORDS DE TUYAU DU FOURNISSEUR DE SYSTÈMES HANSA‑FLEX"}
                    subtitle={"HANSA‑FLEX considère la qualité des produits comme extrêmement importante car la qualité du raccord est cruciale pour la performance de la ligne de tuyau."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"RACCORDS DE TUYAU POUR TOUTES LES APPLICATIONS"}
                />
                <ImageText
                    data={Data8} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"RACCORDS DE TUYAU POUR TOUTES LES APPLICATIONS"}
                />
                <ImageText
                    data={Data4} />
                <ImageText
                    data={Data5}
                    left={false} />
                <ImageText
                    data={Data6} />
            </div>

            <div>
                <SectionHeader
                    title={"LA LARGE GAMME DE RACCORDS HANSA‑FLEX :"}
                    subtitle={"En tant que partenaire unique, nous proposons une gamme diversifiée de raccords à sertir et vissés en acier revêtu de zinc-nickel, en acier inoxydable et des raccords enfichables en laiton."}
                />
                <ImageText
                    data={Data7} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT ÉGALEMENT VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={PreAssembled}
                                title="Lignes de tuyau préassemblées"
                                desc={"En plus de fournir des raccords, nos experts expérimentés peuvent préassembler la ligne de tuyau spécifique dont vous avez besoin"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={HydraulicHoses}
                                title="Tuyaux hydrauliques pour tous les domaines d'utilisation"
                                desc={"Notre large gamme de tuyaux tressés, spiralés, textiles et thermoplastiques couvre toutes les applications en hydraulique basse, moyenne et haute pression."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={RightConnections}
                                title="La bonne connexion avec des adaptateurs hydrauliques"
                                desc={"HANSA‑FLEX propose un vaste portefeuille d'adaptateurs hydrauliques, tels que des adaptateurs filetés ou de transition pour tous les types de connexion et normes courants."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )

}

export default HoseFittings