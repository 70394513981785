import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgSeries_Centerspread from '../../../assets/Services/Production/Series Production/Series_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import ImageText from '../../../Components/ImageText/ImageText'
import imgSeries_Imagetext_01 from '../../../assets/Services/Production/Series Production/Series_Imagetext_01.jpg'
import imgSeries_Imagetext_02 from '../../../assets/Services/Production/Series Production/Series_Imagetext_02.jpg'
import imgSeries_Imagetext_03 from '../../../assets/Services/Production/Series Production/Series_Imagetext_03.jpg'
import imgSeries_Imagetext_04 from '../../../assets/Services/Production/Series Production/Series_Imagetext_04.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgSeries_Imagecard_01 from '../../../assets/Services/Production/Series Production/Series_Imagecard_01.jpg'
import imgSeries_Imagecard_02 from '../../../assets/Services/Production/Series Production/Series_Imagecard_02.jpg'
import imgSeries_Imagecard_03 from '../../../assets/Services/Production/Series Production/Series_Imagecard_03.jpg'
import { Container, Row, Col } from 'react-bootstrap';

const SeriesProduction = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
      }, []);


    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;


    const Series_Imagetext_01 = {
        image: imgSeries_Imagetext_01,
        title: 'Configurer une interface EDI avec HANSA-FLEX.',
        listItems: ["Dans le cadre d'un partenariat à long terme, HANSA-FLEX propose à ses clients ayant des exigences telles que l'intégration complète des fournisseurs et des processus étroitement intégrés ou des volumes de transfert de données très élevés, une connexion à notre système EDI. La complexité de la programmation des interfaces EDI signifie que le service n'est disponible que pour certains clients sélectionnés qui, en raison de volumes de ventes élevés avec HANSA-FLEX, consacrent beaucoup de ressources à la gestion de leurs processus d'approvisionnement en marchandises. Lors de la mise en place de l'EDI, HANSA-FLEX peut utiliser des formats standard pour le transfert de données tels que EDIFACT, ODETTE, VDA, X12 et XML ainsi que des solutions spéciales pour répondre à vos besoins spécifiques."],
    }

    const Series_Imagetext_02 = {
        image: imgSeries_Imagetext_02,
        title: 'Configuration de BMEcat par HANSA-FLEX.',
        listItems: ["BMEcat est un format d'échange de données de catalogue standardisé conforme aux directives du Bundesverband Materialwirtschaft, Einkauf und Logistik e. V. Les données de catalogue sont extraites du système de données produit chez HANSA-FLEX et intégrées dans le système de votre entreprise. HANSA-FLEX installe cette technologie pour les clients qui souhaitent disposer de données produit numériques et de besoins individualisés dans leur catalogue produit. Un autre avantage est que vous pouvez continuer à utiliser votre portail d'achat habituel à partir duquel vous achetez tous les composants spéciaux et HANSA-FLEX vous livre directement les composants hydrauliques ou pneumatiques que vous avez commandés"],
    }


    const Series_Imagetext_03 = {
        image: imgSeries_Imagetext_03,
        title: 'Intégration numérique en trois phases.',
        listItems: ["La mise en œuvre d'un EDI peut prendre quelques jours ou plusieurs mois, en fonction de sa complexité. Le processus d'installation se déroule toujours en trois phases. Pendant la première phase, les paramètres nécessaires tels que le format de données et la version des normes de formatage sont déterminés. Ensuite, il y a un premier test d'intégration et une comparaison très minutieuse des ensembles de données maîtres et des données matérielles entre votre entreprise et HANSA-FLEX. Dans la phase de test, nous effectuons des tests approfondis, ce qui implique l'échange de données de test. Dans certains cas, des corrections doivent être apportées et la comparaison des données matérielles doit être répétée. L'intégration est terminée avec la phase de mise en service, comprenant la surveillance du flux de données."],
    }


    const Series_Imagetext_04 = {
        image: imgSeries_Imagetext_04,
        title: '',
        description: "Nos installations de production en série sont conçues pour que nous puissions fournir à la fois des entreprises traditionnelles de taille moyenne et des corporations industrielles en tant que fournisseur OEM avec des conduites et des flexibles prêts à être installés, y compris des raccords spéciaux spécifiques aux clients. Des processus précisément synchronisés, un personnel expérimenté et des inspections de qualité fiables ont déjà impressionné de nombreuses entreprises en France et à l'étranger, y compris des fabricants renommés de véhicules ferroviaires et municipaux, de machines de construction et agricoles. Nous travaillons avec nos clients pour promouvoir l'innovation et ainsi améliorer la qualité du produit final et les paramètres de fabrication."

    }

    const featuresData = [
        {
            title: "Fabrication sur mesure",
            description: "Nous préassemblons des lignes de flexibles et des conduites individuellement pour répondre aux besoins du client.",
            image: imgMark
        },
        {
            title: "Fiabilité élevée du processus.",
            description: "La fiabilité élevée du processus nous permet d'atteindre la qualité éprouvée de HANSA-FLEX en production en série",
            image: imgMark
        },
        {
            title: "Livraison selon les délais convenus.",
            description: "Les méthodes de fabrication de pointe garantissent une disponibilité rapide et une livraison dans les délais.",
            image: imgMark
        },
        {
            title: "Conseils professionnels.",
            description: "Nous vous conseillons de manière approfondie et mettons en œuvre la solution optimale en fonction de vos besoins.",
            image: imgMark
        }
        // Add more objects for additional FeaturesCard data
    ];





    return (
        <div>
            <CenterStage imageSrc={imgSeries_Centerspread} title={"Production en série de conduites et de flexibles."}
                paragraph={"Notre centre de production en série de conduites hydrauliques à Bremen et notre centre de cintrage de tubes à Schönebeck livrent des dizaines de milliers d'articles, y compris des conduites hydrauliques haute pression, des conduites PTFE et industrielles prêtes à être installées, sur mesure et en petites ou grandes séries, aux OEM chaque mois. Avec nous, vous pouvez vous attendre à une excellente fiabilité des processus et au respect des normes de qualité. Nous adaptons également nos procédures de gestion des produits en entrepôt et de logistique pour répondre à vos exigences."}
            />


            <SectionHeader
                title={"PRODUCTION IN FULL FLOW"}
                subtitle={"Processus parfaitement synchronisés pour la solution optimale."}
            />

            <div >
                <br />
                <ImageText data={Series_Imagetext_01} left={false} />
            </div>

            <div >
                <br />
                <ImageText data={Series_Imagetext_02} />
            </div>

            <div >
                <br />
                <ImageText data={Series_Imagetext_03} left={false} />
            </div>

            <SectionHeader
                title={"Avantages de la production en série."}
                subtitle={"De l'atelier de production au département d'expédition, notre équipe de production en série offre un service complet parfaitement adapté à vos besoins."}
            />

            <div>
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <SectionHeader
                title={"Vous pouvez toujours compter sur nous."}
                subtitle={"Nous vous fournissons des conduites et des flexibles de haute qualité selon les délais convenus et dans les quantités que vous souhaitez."}
            />

            <div >
                <br />
                <ImageText data={Series_Imagetext_04} left={false} />
            </div>
 
{/* TODO: Video_Videocenter*/}

<SectionHeader
                title={"NOTRE PORTFOLIO DE SERVICES EN DÉTAIL"}
                subtitle={""}
            />

            {/* TODO: Redbanner_Carrousel */}
            

            

            <SectionHeader
                title={"N'hésitez pas à partager ces sujets !"}
                subtitle={""}
            />

<Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgSeries_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Un tuyau adapté à chaque application." desc="Avec nous, vous pouvez choisir parmi une large gamme de tuyaux hydrauliques, industriels, préformés, ainsi que des tuyaux de pression et d'aspiration de haute qualité fabriqués à partir de différents matériaux." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgSeries_Imagecard_02} learnMoreLink="/metal-hoses" title="Raccords de tuyaux avec revêtement de surface zinc-nickel." desc="" />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgSeries_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Des pipelines sur mesure" desc="Les pipelines coudés de HANSA-FLEX sont disponibles dans différents matériaux, tels que l'acier, l'acier inoxydable, le cuivre ou l'aluminium, et avec différents traitements de surface." />
                    </Col>

                </Row>
            </Container>









        </div>
    )
}

export default SeriesProduction