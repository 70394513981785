import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgHfPartner from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/HfPartner.jpg'
import imgHoseLines from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/hoseLines.jpg'
import imgHFcan from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/HFcan.jpg'
import imgStrict from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/strictTesting.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import imgAdviceAndDelivery from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/AdviceAndDelivery.jpg'
import HFTabs from '../../../Components/HfTabs/HfTabs';
import imgFood from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/Food.jpg'
import imgChemicals from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/Chemicals.jpg'
import imgMedia from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/Media.jpg'
import imgWater from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/Water.jpg'
import imgOil from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/Oil.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgPtfeHose from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/PtfeHose.jpg'
import imgMetalHose from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/MetalHose.jpg'
import imgOperationOfHoseLines from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/OperationOfHoseLines.jpg'




function IndustrialHoses() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const AnExtensive1 = {
        image: imgHoseLines,
        title: 'Tuyaux disponibles rapidement',
        description: "Nous pouvons traiter les commandes de tuyaux industriels pour répondre rapidement et précisément à vos besoins depuis notre entrepôt central moderne. Les produits standard sont généralement livrés dans un délai maximal de deux jours après réception de la commande. Nous pouvons fournir la gamme complète de tuyaux industriels pour couvrir toutes les applications, de la construction à la transformation des aliments, ainsi que tous les accessoires, y compris les systèmes de connexion.",
        listItems: ["Notre entrepôt propose toujours plus de 1400 articles différents", "La gamme complète de tuyaux et d'accessoires peut être fournie rapidement", "La logistique d'entrepôt moderne assure un traitement fluide"]
    };

    const AnExtensive2 = {
        image: imgHFcan,
        title: 'HANSA-FLEX trouve toujours une solution',
        description: "En plus des tuyaux standard pour le transport de produits chimiques, de denrées alimentaires, d'air, d'eau et de milieux abrasifs, HANSA-FLEX propose également des tuyaux d'aspiration et de pression positive dans toutes les variantes. Les articles non disponibles dans notre gamme habituelle peuvent être inclus dans notre liste de produits fournis et fabriqués dans notre centre de spécialistes des tuyaux selon les spécifications du client.",
        listItems: ["Lignes de tuyaux pré-assemblées selon les besoins du client", "Si nous sommes informés de la nécessité d'articles, nous les stockons à l'avance", "Livraison de conteneurs entièrement garnis pour les grands chantiers de construction"]
    };

    const AnExtensive3 = {
        image: imgStrict,
        title: 'Tests rigoureux et critères de haute qualité',
        description: "Tous les tuyaux auxquels s'applique la T002 (fiche technique de l'industrie chimique allemande « Tuyaux - utilisation sûre ») sont soumis à des tests intensifs chez HANSA-FLEX. Ces tests comprennent la conductivité électrique, les essais de pression jusqu'à cinq fois la pression de service et l'identification unique des tuyaux.",
        listItems: ["Fabriqué en conformité avec la Directive Equipements Sous Pression, DGUV et T002", "HANSA-FLEX est certifié selon la norme DIN EN ISO 9001", "Tous les composants proviennent du même fournisseur pour une sécurité maximale du système"]
    };

    const AnExtensive4 = {
        image: imgAdviceAndDelivery,
        description: "Lors du choix du tube de tuyau le plus approprié pour votre application industrielle, les experts de HANSA-FLEX considèrent toujours les aspects plus larges de toute votre installation, ainsi que le milieu, la pression et la température.",
        listItems: ["Le centre de tuyaux industriels de HANSA-FLEX propose plus de 1400 articles", "Gamme complète d'accessoires, tels que buses et raccords", "Atelier de spécialistes des tuyaux à haute capacité", "Normes de qualité rigoureuses conformes à la DGUV et à la T002", "Composants standard fournis avec une vitesse spéciale"]
    };

    // Données HfTab
    const imageTextData = [
        {
            title: "Tuyaux alimentaires et pour l'eau potable pour une propreté maximale",
            description: "Les tuyaux utilisés dans l'industrie alimentaire doivent être extrêmement résistants à l'abrasion et garantir les normes de propreté les plus élevées. Les tuyaux alimentaires HANSA-FLEX répondent à ces exigences et sont exempts d'odeurs, de saveurs et de toxines, ce qui les rend adaptés aux exigences de la FDA, de la BFR et du MHLW japonais pour le transport et le traitement de liquides, de solides et de produits alimentaires gras.",
            buttonName: "PLUS D'INFORMATIONS",
            buttonLink: "https://shop.hansa-flex.fr/fr_FR/hoses/industrial-hoses/food-hoses/c/webcat_HF_SCH_1060_3790",
            image: imgFood
        },
        {
            title: "Tuyaux chimiques inerte pour des charges extrêmement élevées",
            description: "Les acides, les alcalis et les solutions salines sont très réactifs et corrodent les tuyaux. Grâce à leur construction multicouche et à leurs couches internes durables, les tuyaux chimiques HANSA-FLEX sont très résistants aux produits chimiques et aux solvants, ce qui les rend idéaux pour une utilisation dans les industries chimique et pharmaceutique.",
            listItems: ["Transport d'acides, d'alcalis, de peintures, de vernis et d'alcools", "Tuyaux de distribution chimique et tuyaux de pulvérisation de peinture", "Variantes résistantes au vide également disponibles"],
            buttonName: "TUYAUX CHIMIQUES",
            buttonLink: "https://shop.hansa-flex.fr/fr_FR/hoses/industrial-hoses/chemical-hoses/c/webcat_HF_SCH_1060_3760",
            image: imgChemicals
        },
        {
            title: "Tuyaux robustes pour milieux abrasifs",
            description: "Les milieux granulaires tels que le béton et le sable sont particulièrement abrasifs pour les tuyaux. Grâce à leur grande résistance à l'abrasion et à leur excellente flexibilité, les tuyaux industriels HANSA-FLEX excellent dans le transport de milieux abrasifs et granulaires.",
            listItems: ["Aspiration, distribution et sablage", "Y compris l'utilisation avec des matériaux en vrac lourds", "Construction résistante aux intempéries pour une utilisation en extérieur"],
            buttonName: "TUYAUX RÉSISTANTS À L'ABRASION",
            buttonLink: "https://shop.hansa-flex.fr/fr_FR/hoses/industrial-hoses/hoses-for-abrasive-media/c/webcat_HF_SCH_1060_3830",
            image: imgMedia
        },
        {
            title: "Tuyaux d'eau polyvalents",
            description: "L'eau joue un rôle important dans de nombreuses applications industrielles, par exemple pour le refroidissement ou le nettoyage. Le portefeuille de produits HANSA-FLEX contient une large sélection de tuyaux d'eau pour diverses applications.",
            listItems: ["Tuyaux pour le nettoyage des égouts, la lutte contre l'incendie et la construction.", "Tuyaux industriels, radiateurs et équipements de nettoyage", "Tuyaux souples en PVC"],
            buttonName: "TOUS LES TUYAUX D'EAU",
            buttonLink: "https://shop.hansa-flex.fr/fr_FR/hoses/industrial-hoses/water-hoses/c/webcat_HF_SCH_1060_3840",
            image: imgWater
        },
        {
            title: "Tuyaux résistants à l'huile et aux carburants",
            description: "La protection de l'environnement et la sécurité sur le lieu de travail jouent un rôle important dans le transport d'essence, de diesel ou de kérosène, ainsi que d'huiles moteur, de silicone et brutes. Les tuyaux spéciaux de HANSA-FLEX sont conçus pour offrir les meilleures performances et une sécurité maximale et sont disponibles dans une large gamme de matériaux élastomères et plastiques.",
            listItems: ["Pompes pour le ravitaillement en carburant des stations-service et tuyaux de camions-citernes avec ou sans hélice", "Testé pour la résistance à l'huile, à l'essence et au diesel."],
            buttonName: "TOUS LES TUYAUX DE CARBURANT",
            buttonLink: "https://shop.hansa-flex.fr/fr_FR/hoses/industrial-hoses/oil-and-fuel-hoses/c/webcat_HF_SCH_1060_3810",
            image: imgOil
        },
    ];

    const tabTitles = [
        { title: "Alimentaire", eventKey: "Alimentaire" },
        { title: "Chimiques", eventKey: "Chimiques" },
        { title: "Milieux abrasifs", eventKey: "Milieux abrasifs" },
        { title: "Eau", eventKey: "Eau" },
        { title: "Huiles et carburants", eventKey: "Huiles et carburants" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgHfPartner}
                    title={"HANSA-FLEX EST VOTRE PARTENAIRE SYSTÈME POUR LES TUYAUX INDUSTRIELS"}
                    paragraph={"Les tuyaux industriels trouvent leur application là où le milieu n'est pas utilisé pour transmettre de l'énergie. Les tuyaux industriels transportent une grande variété de médias, de l'eau et de l'air aux produits chimiques, aux carburants et aux gaz, dans presque tous les secteurs industriels. Dans son atelier de spécialistes des tuyaux, HANSA-FLEX répond aux exigences des clients avec les normes de qualité les plus élevées et les délais de livraison les plus courts. Dans la boutique en ligne de HANSA-FLEX, vous trouverez une large gamme de tuyaux industriels."}
                />
                {/*TODO button*/}
            </div>

            <div>
                <SectionHeader
                    title={"UNE LARGE GAMME ET UNE HAUTE QUALITÉ D'UN SEUL FOURNISSEUR"}
                />
                <div>
                    <ImageText
                        data={AnExtensive1} />
                    <ImageText
                        data={AnExtensive2}
                        left={false} />
                    <ImageText
                        data={AnExtensive3} />
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"HANSA-FLEX SIGNIFIE CONSEIL FIABLE ET LIVRAISON RAPIDE"}
                    subtitle={"Dans son centre de tuyaux industriels, HANSA-FLEX dispose de toutes les ressources pour fournir des conseils d'experts et livrer rapidement les produits."}
                />
                <ImageText data={AnExtensive4} />
            </div>

            <div>
                <SectionHeader
                    title={"TUYAUX D'ASPIRATION ET DE DISTRIBUTION POUR APPLICATIONS INDUSTRIELLES"}
                    subtitle={"HANSA-FLEX fournit des tuyaux de haute qualité pour l'eau, l'air et le gaz, ainsi que des tuyaux pour les huiles, les carburants, les solides, les milieux chauds, les produits chimiques, les solvants et les aliments."}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            {/* TODO: TextPoster */}

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgPtfeHose} learnMoreLink={"/Ptfe-hoses"}
                                title="Tuyaux PTFE avec une haute résistance chimique"
                                desc={"En raison de leur haute résistance chimique à la plupart des milieux, les tuyaux PTFE de HANSA-FLEX sont le premier choix pour toutes les applications difficiles."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgMetalHose} learnMoreLink={"/metal-hoses"}
                                title="Tuyaux métalliques pour toutes les conditions d'utilisation"
                                desc={"La tolérance aux températures élevées et la résistance aux milieux agressifs sont à l'origine des performances spéciales des tuyaux métalliques."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgOperationOfHoseLines} learnMoreLink={"/hydraulic-academy"}
                                title="Utilisation sûre et appropriée des tuyaux"
                                desc={"Les tuyaux industriels sont soumis à de nombreuses exigences de sécurité. HANSA-FLEX fournit les détails essentiels de ces exigences dans les informations techniques sur les produits."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );

}

export default IndustrialHoses;
