import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './MainNavbarMenu.css';
import logo from '../../assets/logo.svg';
import { FaMapMarkerAlt, FaPhone, FaLanguage, FaCode, FaShoppingCart } from 'react-icons/fa'; // Importing icons




function MainNavbarMenu() {

  const [isSticky, setIsSticky] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDropdownClose = () => {
    setExpanded(false);

  };


  return (
    <Navbar className="sec-navbar" bg="light" expand="lg" expanded={expanded}>
      <Navbar.Brand className={` nav-brand-main`} as={Link} to="/">
        <img
          src={logo}
          className={`d-inline-block align-top logo-nav-main ${isSticky ? "logo-stuck" : "logo-not-stuck"}`}
          alt="Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav navbar-toggle-hamburger" onClick={() => setExpanded(!expanded)}></Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto margin-l basic-navbar-main-menu">
          <div className="nav-wrapper">
            <NavDropdown title="HANSA-FLEX France" id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>
              {/* <NavDropdown title="Qui sommes-nous" id="nested-nav-dropdown">
                <NavDropdown.Item as={Link} to="/fst">ENTZHEIM - Siège social et agence</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/fga">GEMENOS - Agence</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/fvt">VITROLLES - Agence</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown.Divider /> */}
              {/* <NavDropdown.Item as={Link} to="/management">Gestion</NavDropdown.Item> */}
              {/* <NavDropdown.Item as={Link} to="/journey">Parcours</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/fst">Qui sommes-nous</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/certificates">Certificats</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/contact-us">Contacter</NavDropdown.Item>
              {/* <Nav.Link href="#contact-us-section" className='fs-6 ms-3'>Contacter</Nav.Link> */}
              {/* <NavDropdown.Item as={Link} to="/careers">Carrières</NavDropdown.Item> */}
            </NavDropdown>

            <div className="nav-line"></div>
          </div>
          <div className="nav-wrapper">
            <NavDropdown title="Groupe HANSA-FLEX" id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>
              <NavDropdown.Item as={Link} to="/hf-group-management">Gestion</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/hf-group-history">Histoire</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/hf-group-mission-statement">Déclaration de mission</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/hf-60-years-of-hansaflex">60 ans de HANSA-FLEX</NavDropdown.Item>
              <Nav.Link href="https://www.hansa-flex.de/fr/entreprise/" target="_blank" rel="noopener noreferrer" className='drop-menu-item-link' style={{ fontSize: '1rem', marginLeft: '10%', fontWeight: 400, paddingLeft: 0 }}>
                En savoir plus sur le groupe HANSA-FLEX
              </Nav.Link>
            </NavDropdown>
            <div className="nav-line"></div>
          </div>
          <div className="nav-wrapper">
            <NavDropdown title="Produits" id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>
              <NavDropdown title="Tuyaux et conduites flexibles" id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/hoses-and-hose-lines">Tuyaux et conduites flexibles</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hose-line-config">Configurateur de conduites de tuyaux</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hydraulic-hoses">Tuyaux hydrauliques</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/metal-hoses">Tuyaux métalliques</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Industrial-hoses">Tuyaux industriels</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/performed-hoses">Tuyaux préformés</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/ext-high-pfm-hoses">Tuyaux à très haute pression</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Ptfe-hoses">Tuyaux enPTFE</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hose-accessories">Accessoires</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Pipelines" id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/pipelines">Pipelines</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pipebends">Pipebends</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pipeend-processing">Traitement des extrémités de tuyaux</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Expansionjoints">Joints de dilatation</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Technologie d'entraînement et de contrôle" id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/drive-and-control-tech">Technologie d'entraînement et de contrôle</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pumps">Pompes</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hydraulic-motor">Moteurs hydrauliques</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/cylinder">Cylindres</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/switches-and-sensors">Interrupteurs et capteurs</NavDropdown.Item>
                <NavDropdown title="Technologie des vannes" id="nested-nav-dropdown" drop='start'>
                  <NavDropdown.Item as={Link} to="/valve-tech">Technologie des vannes</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/pipeline-installation-valve">Vannes d'installation de canalisations</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/cartridge-valve">Vannes à cartouche</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/cetop-valve">Vannes CETOP</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown.Item as={Link} to="/measuring-tech">Technologie de mesure</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/seven-hundred-hydraulics">Hydraulique pour charges lourdes 700 bars</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pressure-accumulators">Accumulateurs de pression</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title='Technologie de connexion hydraulique' id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/hydraulic-connection-technology">Technique de raccordement hydraulique</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hose-fitting">Raccords de tuyaux</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pipe-fitting">Tuyauterie</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/adapters">Adaptateurs</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/coupling">Couplages</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/ball-valves">Vannes à bille</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/flanges">Flanges</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown.Item as={Link} to="/sealing-tech">Technologie d'étanchéité</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/filtration">Filtration</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/pneumatique">Pneumatique</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/technical-information">Informations techniques</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/approvals">Approbations</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/catalogues">Catalogues</NavDropdown.Item>

            </NavDropdown>
            <div className="nav-line"></div>
          </div>


          <div className="nav-wrapper">
            <NavDropdown title="Services" id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>
              <NavDropdown.Item as={Link} to="/mobile-rapid-hydraulic-service">Service hydraulique rapide mobile</NavDropdown.Item>

              <NavDropdown title="Gestion des fluides" id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/fluid-service">Gestion des fluides</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/oil-analysis">Analyse des huiles</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/oil-filtering-and-cleaning">Filtrage et nettoyage de l'huile</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Assemblage et installation" id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/assembly-and-installation">Assemblage et installation</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/modernisation-and-optimisation">Modernisation et optimisation</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/plant-project-planning-and-management">Planification et gestion de projet d'usine</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/installation-of-pipe-and-hoseline-systems">Installation de systèmes de canalisations et de tuyaux flexibles</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Maintenance préventive" id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/preventative-maintainance">Maintenance préventive</NavDropdown.Item>
                <NavDropdown title="Gestion des tuyaux" id="nested-nav-dropdown" drop='start'>
                  <NavDropdown.Item as={Link} to="/hose-management">Gestion des tuyaux</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/x-code-hose-identification-system">Système d'identification des tuyaux X-CODE</NavDropdown.Item>
                  <NavDropdown title="X-CODE Manager" id="nested-nav-dropdown" drop='start'>
                    <NavDropdown.Item as={Link} to="/x-code-manager">Gestionnaire X-CODE</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/licenses">Licences</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown.Item as={Link} to="/service-packages">Service-packages</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Inspections" id="nested-nav-dropdown" drop='start'>
                  <NavDropdown.Item as={Link} to="/inspection">Inspections</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/dguv-inspection">Inspection DGUV</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/industrial-hose-inspection">Inspection des tuyaux industriels</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/lifting-platforms-and-loading-bridges">Plateformes de levage et ponts de chargement</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/Pressureaccumulatorinspection">Inspection des accumulateurs de pression</NavDropdown.Item>
                </NavDropdown>
              </NavDropdown>

              <NavDropdown title="Réparation et révision" id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/repair-and-overhaul">Réparation et révision</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hydraulic-cyclinder-repair">Réparation de vérins hydrauliques</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pressure-accumulators-and-associated-systems">Accumulateurs de pression et systèmes associés</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Services numériques" id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/DigitalServices">Services numériques</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Scannersolutions">Solutions scanner</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/EDIandBMEcat">EDI et BMEcat</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Production" id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/Production">Production</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/SeriesProduction">Séries Production</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Customisedassembly">Assemblage personnalisé</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Solution logistique " id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/Logisticssolutions">Solutions logistiques</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Warehousemanagement">Gestion d'entrepôt</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Kanbandelivery">Livraison Kanban</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Kitting">Kitting</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Hydraulicworkshopcontainers">Conteneurs d'atelier hydraulique</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Worksinworks">Works-in-works</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Ingénierie " id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/Engineering">Ingénierie</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/EngineeredbyHansaflex">Engineered by HANSA-FLEX</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Hydraulicunit">Fabrication d'unités hydrauliques</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Prototypemanufacture">Fabrication de prototypes</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown.Item as={Link} to="/hydraulic-academy">Formation et séminaires</NavDropdown.Item>
            </NavDropdown>
            <div className="nav-line"></div>
          </div>


          {/* <div className="nav-wrapper">
            <NavDropdown title="Actualités et événements" id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>
              <NavDropdown.Item as={Link} to="/iree">HANSA-FLEX at IREE </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/bauma">Bauma Conexpo India 2023 </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/tree-plantation">Tree Plantation on Environment Day</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/product-training">Product training session</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/goethe-event">Goethe Event</NavDropdown.Item> 
            </NavDropdown>
            <div className="nav-line"></div>
          </div> */}

          {/* <div className="nav-wrapper">
            <NavLink as={Link} to="/careers">Carrières</NavLink>
            <div className="nav-line"></div>
          </div> */}


          <hr />
          <div className="additional-nav-mobile-menu">
            <Nav.Link href="https://shop.hansa-flex.fr/fr_FR/" target="_blank" rel="noopener noreferrer">
              <FaShoppingCart />  Catalogue en ligne

            </Nav.Link>
            <Nav.Link href="https://my.hansa-flex.in/en_GB/login" target="_blank" rel="noopener noreferrer">
              <FaCode />  Gestionnaire X-CODE

            </Nav.Link>
          </div>

        </Nav>
      </Navbar.Collapse>
    </Navbar >
  );
}

export default MainNavbarMenu;
