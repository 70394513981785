import React, { useEffect } from 'react';
import imgExeHigPresHose from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/ExtHighPresHose.jpg'
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgOilAndGas from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/oilAndGas.jpg'
import imgApplication from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/application.jpg'
import imgwaterJet from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/waterJet.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';
import imghoseProfessional from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/hoseProfessional.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgHydraulicUpto700Bar from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/HydraulicUpto700Bar.jpg'
import imgHoseFittingForApplication from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/HoseFittingForApplication.jpg'
import imgCompatibleAccessories from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/CompatibleAccessories.jpg'
import imgPtfeHoses from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/PtfeHoses.jpg'
import imgMetalHoseHighTemp from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/MetalHoseHighTemp.jpg'
import IndustrialHose from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/IndustrialHose.jpg'



function ExtremelyHighPerformanceHoses() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const ExtremelyHigh1 = {
        image: imgOilAndGas,
        title: "Tuyaux à pression extrêmement élevée pour l'industrie pétrolière et gazière",
        description: "Les tuyaux thermoplastiques haute pression sont très courants dans l'industrie pétrolière et gazière.En plus d'une grande résistance aux produits chimiques, les tuyaux thermoplastiques présentent une faible expansion volumétrique et sont résistants à la pression externe. Ils sont généralement utilisés pour le transport de gaz et d'azote, ainsi que pour l'injection de produits chimiques et de graisses."
    }

    const ExtremelyHigh2 = {
        image: imgApplication,
        title: 'Tuyaux à pression extrêmement élevée pour applications hydrauliques',
        description: "Les tuyaux à pression extrêmement élevée pour applications hydrauliques sont utilisés, par exemple, pour la commande de composants hydrauliques, les outils de serrage de boulons et d'application de couple, ainsi que dans les équipements d'instrumentation. Les tuyaux hydrauliques à pression extrêmement élevée de HANSA-FLEX combinent une grande résistance avec une grande flexibilité.",
        listItems: ["Léger et flexible", "Grande résistance à la charge", "Résistant au vieillissement, à l'ozone et aux UV"]
    }
    const ExtremelyHigh3 = {
        image: imgwaterJet,
        title: "Tuyaux à pression extrêmement élevée pour applications de jet d'eau",
        description: "Le jet d'eau haute pression est utilisé pour le nettoyage des égouts et des tuyaux, ainsi que pour le nettoyage général des surfaces.D'autres domaines d'application importants sont la découpe par jet d'eau et la rénovation des surfaces en béton. HANSA-FLEX propose une gamme spécialement développée de tuyaux à pression extrêmement élevée pour des pressions allant jusqu'à 3 200 bar.",
        listItems: ["Grande résistance à la charge", "La couverture extérieure du tuyau, très résistante à l'abrasion, assure une longue durée de vie", "Grande résistance à la charge d'impulsion"]
    }

    const ExtremelyHigh4 = {
        image: imghoseProfessional,
        description: "Vous pouvez compter sur les produits et services de haute qualité habituels du personnel bien formé de HANSA-FLEX, qui peut également vous conseiller sur le choix des matériaux, des dimensions, des accessoires et de la mise en service de vos systèmes.",
        listItems: ["Gamme complète de machines et équipements de production", "Fabrication sur mesure par des spécialistes expérimentés", "Contrôle qualité à plusieurs étapes, y compris les essais de pression"]
    }

    const certifiedQuality = [
        {
            title: "Variantes disponibles",
            description: "Disponibles avec des diamètres internes de 3 mm à 25 mm, ces tuyaux peuvent être utilisés pour des plages de pression allant jusqu'à 4 000 bar",
            image: imgMark
        },
        {
            title: "Robuste et flexible",
            description: "Deux à huit couches de tresse de fil d'acier, version renforcée et à spirale ouverte.",
            image: imgMark
        },
        {
            title: "Solutions personnalisées",
            description: "Raccords et accessoires appropriés de toute longueur",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgExeHigPresHose}
                    title={"TUYAUX À PRESSION EXTRÊMEMENT ÉLEVÉE POUR DES APPLICATIONS JUSQU'À 3 200 BAR"}
                    paragraph={"Les tuyaux à pression extrêmement élevée de HANSA-FLEX sont toujours le premier choix dans les systèmes où les pressions élevées prévalent. En effet, ils peuvent avoir à supporter des pressions allant jusqu'à 4 000 bar dans l'industrie pétrolière et gazière, le jet d'eau, les applications hydrauliques et le secteur automobile. HANSA-FLEX fournit des tuyaux à pression extrêmement élevée spécialement adaptés à vos besoins et fournis sous forme de lignes de tuyaux prêtes à être montées."}
                //TODO button
                />
            </div>

            <div>
                <SectionHeader
                    title={"DOMAINES D'APPLICATION DES TUYAUX À PRESSION EXTRÊMEMENT ÉLEVÉE HANSA-FLEX"}
                />
                <div>
                    <ImageText
                        data={ExtremelyHigh1} />

                    <ImageText
                        data={ExtremelyHigh2}
                        left={false} />

                    <ImageText
                        data={ExtremelyHigh3} />
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"TUYAUX À HAUTE PRESSION DE QUALITÉ CERTIFIÉE"}
                    subtitle={"Les tuyaux thermoplastiques de haute qualité avec support de pression en fil d'acier enroulé en spirale impressionnent par leur qualité, leur durée de vie et leur fiabilité."}
                />
                <FeatureCardCarousel
                    featuresData={certifiedQuality} />
            </div>

            <div>
                <SectionHeader
                    title={"TUYAUX À PRESSION EXTRÊMEMENT ÉLEVÉE DES PROFESSIONNELS DES TUYAUX"}
                    subtitle={"Nous savons ce qui est important lorsqu'il s'agit de travailler avec des pressions extrêmement élevées. Chez HANSA-FLEX, la plus haute qualité de matériaux et de fabrication est garantie."}
                />
                <ImageText data={ExtremelyHigh4} />
            </div>

            {/* TODO: TextPoster */}
            {/* TODO: Carousal */}

            <div>
                <SectionHeader
                    title={"CES PRODUITS DE HANSA-FLEX PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHydraulicUpto700Bar} learnMoreLink={"/seven-hundred-hydraulics"}
                                title="Composants pour hydraulique haute pression jusqu'à 700 bar"
                                desc={"HANSA-FLEX propose une gamme sélectionnée de produits pour l'hydraulique à service intensif avec des pressions allant jusqu'à 700 bar et peut fournir des composants personnalisés jusqu'à 1 000 bar."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHoseFittingForApplication} learnMoreLink={"https://shop.hansa-flex.fr/fr_FR/hose-fittings/c/webcat_HF_SAR"}
                                title="Raccords pour applications hydrauliques"
                                desc={"Les raccords de tuyaux HANSA-FLEX sont conçus pour s'adapter parfaitement aux tuyaux hydrauliques. Ils reçoivent également un revêtement de surface en zinc-nickel en standard."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgCompatibleAccessories} learnMoreLink={"/hose-accessories"}
                                title="Accessoires compatibles pour lignes de tuyaux"
                                desc={"En plus des tuyaux de première classe, le portefeuille d'accessoires de HANSA-FLEX comprend des dispositifs de protection contre le frottement, la chaleur et la torsion, ainsi que des équipements de sécurité, tels que des tuyaux..."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgPtfeHoses} learnMoreLink={"/Ptfe-hoses"}
                                title="Tuyaux en PTFE avec une haute résistance chimique"
                                desc={"En raison de leur haute résistance chimique contre la plupart des milieux, les tuyaux en PTFE de HANSA-FLEX sont le premier choix pour toutes les applications exigeantes."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgMetalHoseHighTemp} learnMoreLink={"/metal-hoses"}
                                title="Tuyaux métalliques avec une haute tolérance à la température"
                                desc={"Les tuyaux métalliques se distinguent notamment par leur capacité à supporter des charges mécaniques élevées, leur résistance au vide et leur haute tolérance à la température."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={IndustrialHose} learnMoreLink={"/Industrial-hoses"}
                                title="Tuyaux industriels de la plus haute qualité"
                                desc={"HANSA-FLEX peut produire des tuyaux industriels selon les besoins individuels du client rapidement et de manière compétente dans son propre atelier spécialisé dans les tuyaux."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );


}

export default ExtremelyHighPerformanceHoses;