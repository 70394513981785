import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgMetalHoseForExtreme from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/metalHosesForExtreme.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import imgHighQualityMaterials from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/highQualityMaterials.jpg'
import imgTheMostSuitable from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/theMostSuitable.jpg'
import imgExtensiveAdvice from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/extensiveadvice.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgPTFEHoses from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/ptfeHoses.jpg'
import imgExpansion from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/expansionJoints.jpg'
import imgOneOff from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/oneOffSpecial.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgTopClassMetalHose from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/topClassMetalHose.jpg'




function MetalHoses() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const HighQuality1 = {
        image: imgHighQualityMaterials,
        title: 'Matériaux de haute qualité et savoir-faire professionnel',
        description: "Des tuyaux en acier inoxydable ondulés de première qualité constituent la base de la qualité des tuyaux métalliques HANSA-FLEX. Cependant, seuls des processus d'assemblage certifiés par du personnel qualifié les transforment en pipelines vraiment robustes. En utilisant les méthodes les plus modernes, nous testons non seulement les pièces individuelles, mais également jusqu'à 100 % des composants produits en série pour les fuites.",
        listItems: ["Normes de qualité et de précision les plus élevées", "Processus d'assemblage certifiés", "Tests de fuite à 100 %"]
    }

    const HighQuality2 = {
        image: imgTheMostSuitable,
        title: 'Le raccord le plus adapté à chaque situation',
        description: "Les tuyaux métalliques sont largement utilisés, notamment en raison de leur large plage de température, de leur haute résistance au vide et de leur disponibilité dans de nombreuses tailles différentes. En plus des acides et des alcalis, ils peuvent également transporter des liquides ou des gaz chauds.",
        listItems: ["Large plage de température de -196°C à +550°C", "Convient pour des pressions jusqu'à 300 bars et le vide", "Diamètres nominaux de DN 6 à DN 250 mm"]
    }

    const HighQuality3 = {
        image: imgExtensiveAdvice,
        title: 'Conseils complets de HANSA-FLEX',
        description: "HANSA-FLEX propose une large gamme de produits avec une variété de types de tuyaux différents. Les consultants expérimentés de HANSA-FLEX utilisent leur expertise en technologie des fluides pour trouver le produit idéal pour vous. De plus, ils fournissent un soutien flexible et réactif tout au long de la phase de conception du système.",
        listItems: ["Large gamme de services pour l'inspection, les tests et l'analyse des défaillances des tuyaux", "Mise en œuvre fiable des exigences de propreté de l'huile spécifiques au client", "Excellente disponibilité des matériaux et solutions personnalisées pour le client"]
    }

    const HighQuality4 = {
        image: imgTopClassMetalHose,
        listItems: ["Construction en ondulation parallèle pour des performances élevées", "Différentes constructions (simple paroi et double paroi)", "Soudé longitudinalement", "Divers matériaux pour les tuyaux, par exemple, acier inoxydable 1.4404", "Renforcement par tresse, par exemple, acier inoxydable 1.4301", "Composants conçus pour fonctionner parfaitement ensemble"]
    }

    const hfMetalHoses = [
        {
            title: "De nombreuses variantes",
            description: "Les tuyaux métalliques sont disponibles sous forme de tuyaux à double paroi ou de tuyaux enroulés en bande",
            image: imgMark
        },
        {
            title: "Tests de qualité complets",
            description: "Chaque ligne de tuyaux subit des tests rigoureux, avec des certificats de test de pression disponibles sur demande",
            image: imgMark
        },
        {
            title: "Propreté maximale",
            description: "Nous fournissons des tuyaux métalliques qui répondent aux exigences des clients en matière d'absence d'huile, de graisse et de LABS",
            image: imgMark
        },
        {
            title: "Livraison rapide",
            description: "Nous fournissons tous les tuyaux HANSA-FLEX de manière fiable et rapidement.",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données de FeaturesCard supplémentaires
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgMetalHoseForExtreme}
                    title={"TUYAUX MÉTALLIQUES POUR DES CONDITIONS EXTRÊMES"}
                    paragraph={"Les matériaux utilisés dans les tuyaux métalliques les rendent extrêmement résistants à la corrosion et à l'abrasion. Une excellente tolérance à la température, une résistance au vide et une perméabilité extrêmement faible font des tuyaux métalliques le choix privilégié pour le transport de substances solides, liquides et gazeuses dans des conditions particulièrement exigeantes. HANSA-FLEX offre des conseils complets pour sélectionner la meilleure solution de tuyau métallique."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"QUALITÉ ET FIABILITÉ MAXIMALES AVEC HANSA-FLEX"}
                />
            </div>

            <div>
                <ImageText
                    data={HighQuality1} />

                <ImageText
                    data={HighQuality2}
                    left={false} />

                <ImageText
                    data={HighQuality3} />
            </div>

            <div>
                <div>
                    <SectionHeader
                        title={"TUYAUX MÉTALLIQUES HANSA-FLEX DE PREMIÈRE CLASSE AVEC DES CARACTÉRISTIQUES DE PERFORMANCE EXCEPTIONNELLES"}
                        subtitle={"Les tuyaux métalliques HANSA-FLEX sont fabriqués à partir de matériaux de haute qualité à l'aide de méthodes de traitement de pointe. Cela garantit que nos tuyaux métalliques répondent aux exigences de toutes les normes et homologations pertinentes, telles que la DIN EN ISO 15085-2 CL 1, 2014/68/UE (PED) et le DNV."}
                    />
                    <FeatureCardCarousel
                        featuresData={hfMetalHoses} />
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"TUYAUX MÉTALLIQUES DE PREMIÈRE CLASSE DES SPÉCIALISTES DES FLUIDES"}
                    subtitle={"La tolérance aux températures élevées et la résistance aux milieux agressifs sont à la base des excellentes caractéristiques de performance des tuyaux métalliques HANSA-FLEX."}
                />
                <ImageText data={HighQuality4} />
            </div>

            {/* TODO: Carousal */}
            {/* TODO: Video */}

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgPTFEHoses} learnMoreLink={"/Ptfe-hoses"}
                                title="Tuyaux PTFE avec une haute résistance chimique"
                                desc={"Les tuyaux PTFE de HANSA-FLEX se distinguent par leur excellente résistance chimique à la plupart des milieux, leur grande flexibilité et leur longue durée de vie. Dans les industries alimentaire et pharmaceutique, la neutralité gustative et olfactive, ainsi que la sécurité bactériologique, en font des tuyaux idéaux pour le transport de produits potentiellement problématiques. Dans la construction navale ou l'industrie aérospatiale, les tuyaux PTFE transportent en toute sécurité du carburant ou de l'eau de refroidissement."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgExpansion} learnMoreLink={"/Expansionjoints"}
                                title="Joints de dilatation pour tuyaux et conduites."
                                desc={"Les joints de dilatation accommodent de manière fiable les variations de longueur et d'angle causées par les différences de température, le tassement des fondations ou l'installation incorrecte. Ils protègent les tuyaux flexibles, les machines et les équipements contre les dommages. Les joints de dilatation jouent également un rôle clé dans l'amortissement des vibrations des moteurs et des turbines, empêchant leur transmission le long des tuyaux d'échappement ou des conduites d'air comprimé."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgOneOff} learnMoreLink={"/Customisedassembly"}
                                title="Solutions spéciales sur mesure"
                                desc={"Nous pré-assemblons des lignes de tuyaux hydrauliques personnalisées à partir d'échantillons et selon les exigences spécifiques du client pour des applications à basse, moyenne et haute pression avec des raccords associés dans nos succursales. Nos spécialistes des tuyaux gardent toujours à l'esprit la sécurité des personnes et de l'environnement lors de la sélection des matériaux et de la méthode de raccordement tuyau/raccord. Quel que soit votre besoin, nous pouvons conseiller et fabriquer une ligne de tuyau adaptée."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    );

}
export default MetalHoses;
