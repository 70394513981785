import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imghistory from '../../assets/History/imgHistory.jpg'
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import img1962 from '../../assets/60YearHistory/img1962.jpg'
import img1989 from '../../assets/60YearHistory/img1989.jpg'
import img2009 from '../../assets/60YearHistory/img2009.jpg'
import ImageText from '../../Components/ImageText/ImageText';
import imgMark from '../../assets/mark.png'
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import ImageCard from '../../Components/ImageCard/ImageCard';
import imgtraining from '../../assets/60YearHistory/imgTraining.png';
import imgRetirement from '../../assets/60YearHistory/imgRetirement.jpg';
import imgsaddle from '../../assets/60YearHistory/imgSaddle.jpg';
import { Container, Row, Col } from 'react-bootstrap';
function SixtyYearOfHansaflex() {


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const img1962history = {
    image: img1962,
    title: 'Les débuts 1962-1989',
    description: "Au début des années 1960, l'Allemagne était confrontée à des défis pour trouver des composants hydrauliques.Joachim Armerding a reconnu l'opportunité et a commencé à assembler des flexibles hydrauliques dans son garage. En les livrant personnellement aux clients, il a posé les bases de HANSA-FLEX. En 1988, avec le partenariat de Günter Buschmann et la participation du fils du fondateur, l'entreprise s'est étendue à 21 succursales à l'échelle nationale."
  };

  const img1989history = {
    image: img1989,
    title: 'Croissance forte et internationalisation 1989-2009 ',
    description: "Pendant l'ère de la réunification allemande et de la chute du rideau de fer, HANSA- FLEX a connu une croissance rapide.Il a établi sa première succursale étrangère à Elst, aux Pays-Bas, en 1989, suivi d'expansions à travers l'Europe.Sous une nouvelle direction en 1995, il s'est développé à l'échelle mondiale, avec des succursales en Turquie, en Amérique, en Afrique et en Asie d'ici 2009. "
  };

  const img2009history = {
    image: img2009,
    title: "À l'ère de la numérisation – de 2009 à aujourd'hui",
    description: "Au milieu de la numérisation mondiale, HANSA-FLEX renforce son investissement dans l'infrastructure et les services numériques.L'application HANSA-FLEX, la boutique en ligne et le portail client My.HANSA-FLEX offrent une valeur substantielle. De nouvelles succursales internationales émergent dans des pays comme l'Australie, le Royaume- Uni et l'Inde. En 2019, l'initiative de numérisation PHOENIX est lancée, et d'ici 2020, HANSA-FLEX AG compte plus de 150 000 clients dans le monde."
  };

  //  FeatureCardData

  const featuresData = [
    {
      title: "4208 employés",
      description: "Nous travaillons avec passion chaque jour pour nos clients",
      image: imgMark
    },
    {
      title: "449 emplacements",
      description: "Nous offrons un service complet pour l'hydraulique dans chaque succursale – rapide et individuel.",
      image: imgMark
    },
    {
      title: "401 véhicules de service",
      description: "Le service hydraulique mobile rapide est disponible 24 heures sur 24, 365 jours par an.",
      image: imgMark
    },
    {
      title: "41 pays",
      description: "Nos succursales régionales collaborent étroitement avec notre siège à Brême",
      image: imgMark
    }
    // Ajoutez plus d'objets pour des données de cartes de fonctionnalités supplémentaires
  ];

  return (
    <div>
      <div>
        <CenterStage
          imageSrc={imghistory}
          title={"PASSIONNÉ PAR LES SOINS AUX CLIENTS DEPUIS 1962"}
          paragraph={"Le commerçant de Brême Joachim Armerding a fondé l'entreprise HANSA-FLEX il y a 60 ans dans le garage de sa maison près de Brême. Son concept d'entreprise était opportun, son slogan aussi simple qu'ingénieux : Satisfaire les souhaits de vos clients plus rapidement, plus flexible et plus complet que tous vos concurrents et ne jamais oublier l'aspect humain et le plaisir de votre travail. HANSA-FLEX a grandi avec cette passion, qui est aussi évidente aujourd'hui qu'elle l'était à l'époque."}
        />
      </div>

      <div className='div-gray'>
        <SectionHeader
          title={"NOTRE IMPULSION EST DANS NOTRE ADN"}
          subtitle={"Les valeurs et les principes de notre déclaration de mission illustrent l'engagement de HANSA-FLEX envers ses clients, nous guidant dans nos opérations quotidiennes tant internes qu'externes."}
        />

        <div >
          <br />
          <ImageText data={img1962history} left={false} /> <br /><br />
        </div>

        <div >
          <br />
          <ImageText data={img1989history} /> <br /><br />
        </div>

        <div >
          <br />
          <ImageText data={img2009history} left={false} /> <br /><br />
        </div>

      </div>


      {/* FeatureCardCarousal */}

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>


      <div className='div-gray'>
        <SectionHeader
          title={"LA PASSION PREND DE NOMBREUSES FORMES"}
          subtitle={"Bien formés, prêts à aider, spirituels d'équipe et professionnels - c'est ainsi que vous trouverez les employés de HANSA-FLEX dans leur environnement de travail. Dans notre série « Les gens chez HANSA-FLEX », ils montrent quelque chose de leur vie en dehors du travail."}
        />

        <Container className="custom-container">
          <Row>
            <Col sm={12} md={6} lg={4} ><ImageCard image={imgtraining} title="Formation chez HANSA-FLEX - les premiers 60 jours" desc={"Chez HANSA-FLEX, le spécialiste en logistique d'entrepôt et la variante de deux ans du spécialiste en entrepôt sont les cours de formation les plus courants, avec un peu moins de 50% de nos stagiaires apprenant ce métier."} /></Col>
            <Col sm={12} md={6} lg={4} ><ImageCard image={imgRetirement} title="60 jours jusqu'à la retraite" desc="Après 25 ans chez HANSA-FLEX, Falk Simon s'apprête maintenant à prendre sa retraite bien méritée. Il revient sur les 60 derniers jours et raconte son expérience chez HANSA-FLEX." /></Col>
            <Col sm={12} md={6} lg={4} ><ImageCard image={imgsaddle} title="Fermement en selle" desc="Que ce soit le saut d'obstacles, le dressage, la balade dans la mer des Wadden, l'entraînement des chevaux ou l'encadrement de jeunes cavaliers : pour Marie Abel, le sport équestre fait partie intégrante de sa vie depuis son enfance." /></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}



export default SixtyYearOfHansaflex;
