import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'

import imgStage from '../../assets/Products/Catalogues/stage.jpg'

const Catalogues = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"APERÇU DES PRODUITS"}
                    paragraph={"Tous les catalogues HANSA‑FLEX en PDF en allemand et en anglais peuvent être trouvés sur cette page. Vous êtes également invité à utiliser notre boutique en ligne comme liste numérique de tous les produits."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"LES CATALOGUES DE PRODUITS HANSA‑FLEX"}
                    subtitle={"Tous les catalogues disponibles en PDF en allemand et en anglais"}
                />
                {/* TODO: steps */}
            </div>

        </div>

    )
}


export default Catalogues