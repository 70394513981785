import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import stage from '../../assets/FranceUnits/Entzheim/Entree_bureau_ENTHEIM.jpg'
import { MdEmail, MdFax, MdLocationOn, MdPhone } from 'react-icons/md';
import { Col, Row } from 'react-bootstrap';
import ImageText from '../../Components/ImageText/ImageText';
import imgTeam from '../../assets/FranceUnits/Entzheim/Team_ENTZHEIM.jpg'
import imgStore from '../../assets/FranceUnits/Entzheim/Atelier_ENTZHEIM.jpg'
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgServiceVan from '../../assets/servicevan.png'
import imgHoseLogo from '../../assets/HoseLogo.png'
import imgCustomerService from '../../assets/customer-support.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import imgMobileVehicle from '../../assets/FranceUnits/Vitrolles/HoseManufacturing.jpg'
import imgWorkshop from '../../assets/FranceUnits/Vitrolles/Atelier_VITROLLES.jpg'
import ShowcaseCarousel from '../../Components/ShowcaseCarousel/ShowcaseCarousel';
import imgFlexxpress from '../../assets/flexxpress.jpg'
import imgExperienceExpert from '../../assets/experience+Expert2.png'
import imgCompleteRange from '../../assets/HoseLogo.png'
import imgOnSIteConvenience from '../../assets/OnSIteConve.png'
import imgGemenosWorkshop from '../../assets/FranceUnits/Gemenos/Entree_GEMENOS.jpg'



const FST = () => {

    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    useEffect(() => {
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 320, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 300, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
        });
    }, []);

    const fst1 = {
        image: imgTeam,
        title: "L'équipe de l'atelier Responsive Mobile - FLEXXPRESS",
        description: "HANSA-FLEX France dispose d'une équipe d'ateliers mobiles disponible 24 heures sur 24, 7 jours sur 7, qui fournit des solutions hydrauliques sur site adaptées aux besoins des clients, garantissant un temps d'immobilisation minimal et une productivité maximale.",
        listItems: ["Réponse immédiate", "Expertise sur roues", "Service personnalisé"]
    }

    const fst2 = {
        image: imgStore,
        title: "Carrefour des solutions hydrauliques : L'atelier HANSA-FLEX France",
        description: "L'atelier HANSA-FLEX France est un centre pratique pour les besoins hydrauliques, offrant une large gamme de produits, des conseils d'experts et une assistance client rapide, garantissant des opérations sans faille et une assistance fiable.",
        listItems: ["Gamme de produits étendue", "Assistance compétente", "Service efficace"]
    }

    const fvt2 = {
        image: imgMobileVehicle,
        title: "Atelier sur roues",
        description: "Les véhicules de la flotte mobile de Rapid Hydraulic Service sont des ateliers sur roues. Ils transportent tout l'équipement nécessaire à la fabrication de conduites hydrauliques sur mesure, y compris des machines à couper, à dénuder et à sertir. Le générateur placé sous le plancher constitue une source d'énergie fiable et indépendante sur les chantiers ou dans les zones reculées et permet aux techniciens de travailler la nuit. Le générateur de 400 volts rend l'atelier sur roues totalement indépendant des conditions du site.",
    }

    const fvt1 = {
        image: imgWorkshop,
        title: "Atelier sur roues",
        description: "L'atelier de HANSA-FLEX à Vitrolles, en France, sert de centre stratégique pour l'expertise hydraulique, offrant des services et des solutions spécialisés pour optimiser les systèmes hydrauliques et assurer l'efficacité opérationnelle.",
        listItems: ["Services hydrauliques experts", "Solutions sur mesure", "Équipements de pointe"]
    }

    const fga3 = {
        image: imgFlexxpress,
        description: "FLEXXPRESS, le service de réparation hydraulique immédiate sur l'ensemble du réseau de distribution, intervient jour et nuit.",
        listItems: ["Intervention 24/7, 365 jours/an", "Sur simple appel gratuit", "Délais d'intervention courts"]
    }

    //features card data
    const featuresData = [
        {
            title: "Soutien à la clientèle",
            description: "Offrir une assistance et des conseils spécialisés à nos clients en France",
            image: imgCustomerService
        },
        {
            title: "Service hydraulique mobile",
            description: "Dépannage sur site 24/24 – 7/7 - Service mobile hydraulique",
            image: imgServiceVan
        },
        {
            title: "Expertise et expérience",
            description: "Profitez de nos techniciens et ingénieurs qualifiés qui apportent des années d'expérience et des connaissances spécialisées à chaque projet.",
            image: imgExperienceExpert
        },
        {
            title: "Tuyaux de première qualité",
            description: "Découvrez notre qualité inégalée",
            image: imgHoseLogo
        },
        // {
        //     title: "Gamme complète de produits",
        //     description: "Accédez à une large sélection de produits et de composants hydrauliques de haute qualité, pour vous assurer de trouver la solution adaptée à vos besoins.",
        //     image: imgCompleteRange
        // },
        {
            title: "Commodité sur place",
            description: "Profitez de la commodité de nos services d'atelier mobile, qui fournissent une assistance rapide sur site pour les problèmes hydrauliques urgents, minimisant ainsi les temps d'arrêt.",
            image: imgOnSIteConvenience
        },
        // Add more objects for additional FeaturesCard data
    ];


    const carouselItems = [
        {
            imgSrc: imgMobileVehicle,
            title: 'Atelier sur roues',
            description: "Les véhicules de la flotte mobile de Rapid Hydraulic Service sont des ateliers sur roues. Ils transportent tout l'équipement nécessaire à la fabrication de conduites hydrauliques sur mesure, y compris des machines de coupe, de dénudage et de sertissage."
        },
        {
            imgSrc: imgWorkshop,
            title: 'Atelier à Vitrolles',
            description: "L'atelier de HANSA-FLEX à Vitrolles, en France, sert de centre stratégique pour l'expertise hydraulique, offrant des services et des solutions spécialisés pour optimiser les systèmes hydrauliques et assurer l'efficacité opérationnelle."
        },
        {
            imgSrc: imgStore,
            title: 'Atelier à Entzheim',
            description: "L'atelier HANSA-FLEX France est un centre pratique pour les besoins hydrauliques, offrant une large gamme de produits, des conseils d'experts et une assistance client rapide, garantissant des opérations sans faille et une assistance fiable."
        },
        {
            imgSrc: imgGemenosWorkshop,
            title: 'Atelier à Gemenos',
            description: "L'atelier Genemos assure la fabrication de composants hydrauliques de précision grâce à un équipement et une expertise de pointe, offrant des solutions sur mesure pour une performance optimale des systèmes."
        },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={stage}
                    title="Bienvenue à HANSA-FLEX FRANCE SARL"
                    paragraph="Hansa-Flex France SARL : votre portail vers des solutions hydrauliques de pointe ! En tant que filiale de la société Hansa-Flex AG, nous sommes spécialisés dans la fourniture de tuyaux, de connecteurs et de systèmes de fluides de haute qualité adaptés à diverses industries. Grâce à notre engagement constant en faveur de l'innovation et de la satisfaction de nos clients, nous sommes votre partenaire de confiance dans le domaine de la technologie des fluides, permettant aux entreprises de France et d'ailleurs de prospérer. Bienvenue dans l'avenir de l'excellence hydraulique avec Hansa-Flex France SARL." />
            </div>

            <div>
                <SectionHeader title={"Le service de réparation hydraulique immédiate FLEXXPRESS"}
                    subtitle={"Votre source complète pour les besoins hydrauliques, offrant un service à la demande et une expertise en magasin pour des opérations sans faille et un soutien fiable."} />
                <>
                    <div data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <br />
                        <ImageText data={fst1} /> <br /><br />
                    </div>
                    {/* <div data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <br />
                        <ImageText data={fga3} left={false} /><br /><br />
                    </div> */}
                </>
            </div><br /><br /><br />

            <div data-aos={isMobile ? "fade-up" : "zoom-in"}>
                <SectionHeader title={"Pourquoi nous choisir ?"} subtitle={"Nous sommes l'incarnation de l'excellence, réputés pour notre engagement inébranlable en faveur de la qualité et de la satisfaction du client. Nous fournissons des solutions inégalées adaptées à vos besoins."} />
                <FeatureCardCarousel featuresData={featuresData} />
            </div><br />

            <div data-aos={isMobile ? "fade-up" : "zoom-in"}>
                <SectionHeader title={"Nos ateliers"}
                    subtitle={"HANSA-FLEX France dispose d'un atelier dédié à Vitrolles et d'une équipe mobile réactive, garantissant des solutions hydrauliques complètes adaptées aux besoins des clients, que ce soit sur site ou dans nos locaux."} />

                <ShowcaseCarousel carouselItems={carouselItems} isExploreButton={false} descDisplay='block' carouselType={2} />
            </div><br /><br /><br />

            {/* <div>
                <SectionHeader title={"Nos ateliers"}
                    subtitle={"HANSA-FLEX France dispose d'un atelier dédié à Vitrolles et d'une équipe mobile réactive, garantissant des solutions hydrauliques complètes adaptées aux besoins des clients, que ce soit sur site ou dans nos locaux."} />
                <>
                    <div data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <br />
                        <ImageText data={fvt1} /> <br /><br />
                    </div>
                    <div data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <br />
                        <ImageText data={fvt2} left={false} /><br /><br />
                    </div>
                </>
            </div><br /><br /><br /> */}




            <div style={{ backgroundColor: '#30557d', padding: '20px', color: 'white', paddingLeft: '10%' }}>
                <Row>
                    <Col xs={12} md={6}>
                        <h2>Coordonnées:</h2>
                        <div>
                            <p>
                                Pour toute demande de renseignements, d'assistance ou de collaboration, n'hésitez pas à nous contacter :
                            </p>
                            <p>
                                <MdLocationOn />Adresse: HANSA-FLEX FRANCE SARL, Aéroparc 1 – 5, rue Pégase – 67960 ENTZHEIM
                            </p>
                            <p>
                                <MdPhone /> Téléphone: +33 (0) 3 88 55 50 41
                            </p>
                            <p>
                                <MdEmail /> E-mail: <a href="mailto: fst@hansa-flex.com" style={{ color: 'white' }}> fst@hansa-flex.com</a>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default FST