import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgEngineeredby_Centerspread from '../../../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import imgEngineeredby_Imagetext_01 from '../../../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagetext_01.jpg'
import imgEngineeredby_Imagetext_02 from '../../../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagetext_02.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'

import ImageText from '../../../Components/ImageText/ImageText'

import { Container, Row, Col } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard'
import imgEngineeredby_Imagecard_01 from '../../../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagecard_01.jpg'
import imgEngineeredby_Imagecard_02 from '../../../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagecard_02.jpg'
import imgEngineeredby_Imagecard_03 from '../../../assets/Services/Engineering/Engineered by Hansaflex/Engineeredby_Imagecard_03.jpg'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook


const EngineeringbyHansaflex = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;



  const Engineeredby_Imagetext_01 = {
    image: imgEngineeredby_Imagetext_01,
    title: "Notre équipe de développement fournit tous les services dont vous avez besoin,",
    description: ['HANSA-FLEX coordonne un processus de développement complexe au terme duquel vous recevez un produit de qualité éprouvée. Ce processus comporte cinq étapes'],
    listItems: ["Analyse et conseils", "Dessin et simulation","Tests sur le prototype" , "Contrôles de l'échantillonnage initial"],
  }

  



  const featuresData = [
    {
      title: "Système cohésif",
      description: "Les composants HANSA-FLEX sont parfaitement adaptés les uns aux autres pour une performance optimale.",
      image: imgMark
    },
    {
      title: "Des dimensions cohérentes",
      description: "Les articles produits en série ont un aspect et des dimensions cohérents, ce qui signifie que le système installé est à la hauteur de ce que le concepteur avait prévu.",
      image: imgMark
    },
    {
      title: "Qualité durable",
      description: "Nos exigences de fabrication et nos tests internes garantissent un niveau de qualité élevé et durable.",
      image: imgMark
    },
    {
      title: "Normes et agréments",
      description: "Nous respectons environ 1 500 normes et disposons d'agréments industriels spéciaux.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];




  return (
    <div>

      <CenterStage
        imageSrc={imgEngineeredby_Centerspread}
        title={"COMPOSANTS CONÇUS PAR HANSA-FLEX"}
        paragraph={"HANSA-FLEX développe en interne des composants de haute qualité selon ses propres exigences de qualité. De cette manière, nous sommes en mesure de fournir des produits de haute qualité de manière fiable, indépendamment des fournisseurs. C'est ce que nous appelons 'engineered by HANSA-FLEX'. Nos spécifications techniques sont cohérentes et la qualité de nos produits dépasse celle exigée par les normes légales en vigueur. Nos ingénieurs hautement qualifiés fabriquent des produits répondant aux exigences spécifiques du client dans nos propres installations de production"}
      />

      <div >
        <SectionHeader
          title={"INGÉNIERIE INTERNE, DE L'ANALYSE À LA PRODUCTION EN SÉRIE"}
          subtitle={"Le vaste savoir-faire de nos employés et l'utilisation d'équipements de pointe constituent la base de la conception de composants de haute qualité conçus par HANSA-FLEX."}
        />


      </div>


      <div >
        <br />
        <ImageText data={Engineeredby_Imagetext_01} left={false} />
      </div>






      <div >
        <SectionHeader
          title={"LES COMPOSANTS DÉVELOPPÉS PAR HANSA-FLEX PRÉSENTENT LES AVANTAGES SUIVANTS"}
          subtitle={"Les développements internes de HANSA-FLEX offrent de nombreux avantages à nos clients. Nos propres capacités de conception et de fabrication nous permettent d'être indépendants des fournisseurs et de garantir une qualité de produit élevée et de respecter les promesses de livraison. "}
        />


      </div>


      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>

      {/* TODO: redbanner_carrousel */}
      {/* TODO: Imagetext_navigation bar */}

      <div >
        <SectionHeader
          title={"UNE ASSURANCE QUALITÉ SOPHISTIQUÉE"}
          subtitle={"HANSA-FLEX soumet ses produits à un processus complet d'assurance qualité. Pour ce faire, nous utilisons des équipements de haute précision."}
        />


      </div>


      <div >
        <br />
        <ImageText data={Engineeredby_Imagetext_01} />
      </div>


      <div >
        <SectionHeader
          title={"CES THÈMES PEUVENT ÉGALEMENT VOUS INTÉRESSER"}
          subtitle={""}
        />


      </div>


      <Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgEngineeredby_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="" desc="Tuyaux hydrauliques HANSA-FLEX" />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgEngineeredby_Imagecard_02} learnMoreLink="/metal-hoses" title="Raccords de tuyauterie de haute qualité" desc="Les raccords de tuyauterie ont été l'un des premiers développements internes de HANSA-FLEX. Aujourd'hui encore, ces composants hydrauliques permettent de raccorder les canalisations en toute sécurité." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgEngineeredby_Imagecard_03} learnMoreLink="/hydraulic-hoses" title="Une technologie d'étanchéité fiable" desc="La qualité d'un système hydraulique dépend de celle de ses joints. Un seul joint défectueux peut entraîner de longs temps d'arrêt. HANSA-FLEX permet d'éviter cela." />
                    </Col>


                </Row>
            </Container>












    </div>
  )
}

export default EngineeringbyHansaflex