import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../Components/ImageCard/ImageCard';
import ImageText from '../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../assets/mark.png';

import imgHydraulicSeminars from '../../assets/Services/HydraulicAcademy/HydraulicAcademy.jpg'
import imgInHouseSeminars from '../../assets/Services/HydraulicAcademy/InHouseSeminars.jpg'
import imgRightFromStart from '../../assets/Services/HydraulicAcademy/RightFromStart.jpg'
import imgRulesOfOilCare from '../../assets/Services/HydraulicAcademy/RulesOfOilCare.jpg'
import imgTheoryToPractical from '../../assets/Services/HydraulicAcademy/TheoryToPractical.jpg'
import imgTrainingFromFirst from '../../assets/Services/HydraulicAcademy/TrainingFromFirst.jpg'
import imgTrainingOnLatestTech from '../../assets/Services/HydraulicAcademy/TrainingOnLatestTech.jpg'

//TODO: TextPoster
//TODO: TextPoster
//TODO: Client review

const HydraulicAccademy = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    // Données du texte de l'image
    const TrainingFromFirst = {
        image: imgTrainingFromFirst,
        title: 'Formation des premiers principes aux cours de recyclage',
        description: "La gamme de cours et de séminaires proposés par HANSA-FLEX et IHA s'adresse aux personnes dont le travail quotidien implique l'hydraulique. La formation hydraulique proposée va du niveau de base à des connaissances spécialisées beaucoup plus approfondies sur des sujets individuels, tels que la planification de projets ou la maintenance de systèmes. La formation pratique offre la possibilité de mettre à l'épreuve la théorie apprise lors des cours précédents.Une qualification essentielle pour les personnes travaillant dans le domaine de l'hydraulique est la formation pour devenir des personnes officiellement autorisées conformément à la BetrSichV et à la TRBS 1203-2019. Pour les employés expérimentés, il existe des cours de recyclage pour actualiser leurs connaissances sur l'état actuel de la technologie en matière d'ingénierie, d'application et de sécurité.Toutes les sessions de formation sont animées par des experts certifiés.",
    }

    const TrainingOnLatestTech = {
        image: imgTrainingOnLatestTech,
        title: 'Formation et utilisation des dernières technologies',
        description: "L'IHA dispose de salles de séminaires modernes et entièrement équipées dans toutes ses installations et de bancs d'essai hydrauliques avec les dernières technologies à Linz, Stockstadt et Dresde. Ici, les participants apprennent les tâches quotidiennes des systèmes hydrauliques et comment les concevoir pour qu'ils soient plus efficaces, plus sûrs et mieux adaptés à leur usage.Il convient de mentionner la formation disponible au Coreum à Stockstadt am Rhein.Dans le centre Coreum, qui dispose de plusieurs petits chantiers spécialement aménagés pour la formation, HANSA- FLEX et IHA sont accompagnés par des fabricants de machines.Les participants peuvent appliquer directement les connaissances hydrauliques acquises en pratique dans des conditions réelles, ce qui contribue à consolider les connaissances acquises."
    }

    const InHouseSeminars = {
        image: imgInHouseSeminars,
        title: "Séminaires internes à l'IHA ou sur les lieux du client",
        description: "En plus de la formation dans divers endroits en Allemagne et à l'étranger, l'IHA peut également dispenser une formation interne. Cette formation peut avoir lieu dans une succursale de HANSA-FLEX à proximité ou dans les installations du client. Si vous êtes intéressé par la formation de vos employés ou si vous avez des questions sur la formation, n'hésitez pas à nous contacter. Nous serons heureux de vous aider.",
        buttonName: "Nous Contacter",
        buttonLink: "#contact-us-section"
    }

    // Données pour le carousel de cartes de fonctionnalités
    const featuresData = [
        {
            title: "Reconnaissance du marché pour votre expertise",
            description: "Le personnel correctement formé et qualifié impressionne les clients.",
            image: imgMark
        },
        {
            title: "Rentabilité accrue",
            description: "Une plus grande connaissance et des compétences réduisent le nombre d'erreurs, augmentant simultanément la productivité et l'efficacité des processus.",
            image: imgMark
        },
        {
            title: "Sécurité opérationnelle renforcée",
            description: "Les participants se familiarisent avec les dispositions légales régissant la responsabilité des individus et des collectifs.",
            image: imgMark
        },
        {
            title: "Certificat de fin de formation",
            description: "La formation réussie est certifiée par l'IHA et reconnue au niveau national.",
            image: imgMark
        },

        // Ajoutez plus d'objets pour des données supplémentaires de cartes de fonctionnalités
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgHydraulicSeminars}
                    title={"FORMATION EN TECHNOLOGIE DES FLUIDES ET DES CANALISATIONS"}
                    paragraph={"La formation est le meilleur moyen de contrer la pénurie de personnel qualifié. HANSA-FLEX utilise le transfert actif des connaissances des experts expérimentés aux stagiaires, aux employés et aux clients. HANSA-FLEX propose des formations et des cours dans le domaine de la technologie des fluides et des canalisations, ainsi que des programmes de formation pour les spécialistes en hydraulique, qu'elle offre en collaboration avec son partenaire, l'International Hydraulics Academy (IHA)."}
                />
            </div>

            <div>
                <SectionHeader
                    title={'CONNAISSANCES SPÉCIALISÉES DE LA THÉORIE À LA PRATIQUE'}
                />
                <ImageText
                    data={TrainingFromFirst} />
                <ImageText
                    data={TrainingOnLatestTech} left={false} />
                <ImageText
                    data={InHouseSeminars} />
            </div>

            <div>
                <SectionHeader
                    title={"LES QUALIFICATIONS SONT LA BASE DU SUCCÈS"}
                    subtitle={"Les cours et les séminaires organisés conjointement par HANSA-FLEX et l'IHA dispensent des connaissances techniques de première classe, dont les entreprises hydrauliques et leurs clients bénéficient en termes de sécurité et de qualité."}
                />

                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"VOUS POURRIEZ AUSSI ÊTRE INTÉRESSÉ PAR"}
                />

                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgTheoryToPractical} learnMoreLink={"https://www.coreum.de/en"}
                                title="De la théorie aux applications pratiques"
                                desc={"Le centre de formation Coreum propose des chantiers de construction et des bancs d'essai où les participants aux cours peuvent découvrir la puissance et les fonctions..."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgRulesOfOilCare} learnMoreLink={"/fluid-service"}
                                title="Les 10 règles d'or du soin des huiles"
                                desc={"Découvrez ce qu'il faut observer dans la gestion des fluides et comment HANSA-FLEX vous soutient avec des services complets pour garantir le bon fonctionnement de tout."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgRightFromStart} learnMoreLink={"/Engineering"}
                                title="Professionnel et cohérent dès le début"
                                desc={"Notre tâche est de garantir votre succès. HANSA-FLEX est votre partenaire expérimenté, toujours à vos côtés, vous aidant et vous conseillant de la conception du projet à..."} /></Col>
                        </Row>

                    </Container>
                </div>

            </div>

        </div>
    )

}

export default HydraulicAccademy