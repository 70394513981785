import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageText from '../../../Components/ImageText/ImageText'

import Stage from '../../../assets/Products/HydraulicConnectionTechnology/PipeFitting/Stage.jpg'
import SealingCone from '../../../assets/Products/HydraulicConnectionTechnology/PipeFitting/SealingCone.jpg'
import Rotary from '../../../assets/Products/HydraulicConnectionTechnology/PipeFitting/Rotary.jpg'
import SafelyConnect from '../../../assets/Products/HydraulicConnectionTechnology/PipeFitting/SafelyConnect.jpg'
import OptimumFlow from '../../../assets/Products/HydraulicConnectionTechnology/PipeFitting/OptimumFlow.jpg'
import LowWear from '../../../assets/Products/HydraulicConnectionTechnology/PipeFitting/LowWear.jpg'
import EasyAssembly from '../../../assets/Products/HydraulicConnectionTechnology/PipeFitting/EasyAssembly.jpg'
import ExcellentCompatibility from '../../../assets/Products/HydraulicConnectionTechnology/PipeFitting/ExcellentCompatibility.jpg'
import FormPipe from '../../../assets/Products/HydraulicConnectionTechnology/PipeFitting/FormPipe.jpg'
import BentPipe from '../../../assets/Products/HydraulicConnectionTechnology/PipeFitting/BentPipe.jpg'
import HFTabs from '../../../Components/HfTabs/HfTabs'


const PipeFittings = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const featuresData = [
        {
            title: "Performance maximale",
            description: "Chaque raccord est parfaitement adapté au tuyau et offre ainsi une protection maximale contre les fuites.",
            image: imgMark
        },
        {
            title: "Service complet",
            description: "Tous les raccords sont disponibles individuellement. Nous sommes également ravis de les fournir montés sur des lignes de tuyaux.",
            image: imgMark
        },
        {
            title: "Sécurité maximale",
            description: "Des tests approfondis garantissent la fonctionnalité des raccords jusqu'à 500 bars.",
            image: imgMark
        },
        {
            title: "Excellente protection contre la corrosion",
            description: "Grâce à un revêtement de zinc-nickel, les raccords pressés sont résistants à la rouille rouge pendant 1 200 heures.",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données supplémentaires de FeaturesCard
    ];

    const Data1 = {
        title: "Cône d'étanchéité à 24° et raccord à bride",
        description: "Les raccords de tuyaux HANSA‑FLEX avec un cône d'étanchéité à 24° et un joint torique offrent une double protection contre les fuites: en plus de leur joint élastomère, les raccords ont également un joint métallique créé par la forme conique du cône d'étanchéité. Les raccords à bride HANSA‑FLEX sont particulièrement utiles dans les applications à haute pression où il y a de fortes vibrations, des pics de pression et des charges élevées. Du côté du raccord, l'étanchéité est assurée par le joint torique ; du côté du tuyau par les surfaces métalliques entre le cône de bride et la bague intermédiaire.",
        image: SealingCone
    };

    const Data2 = {
        title: 'Raccords rotatifs et soudés',
        description: "Les raccords rotatifs de HANSA‑FLEX relient des lignes de tuyaux à un point fixe sur des systèmes avec des composants de machine rotatifs ou pivotants. Ils évitent les contraintes de torsion sur le tuyau et évitent ainsi une usure prématurée. Les raccords soudés peuvent être soudés directement à l'endroit où ils sont nécessaires.Le raccord conique soudé HANSA‑FLEX a un joint torique et est formé de manière à s'adapter précisément à l'intérieur du contrepartie du connecteur fileté phosphaté et huilé.",
        image: Rotary
    };

    const Data3 = {
        listItems: [
            "Compatible avec les connexions filetées normalisées d'autres fabricants, certifié DNV GL et testé DVGW",
            "Grandes faces en contact avec la clé assurent une prise sécurisée lors du serrage",
            "Le réglage de la direction permet une flexibilité maximale",
            "Les pressions de travail admissibles sont supérieures aux plages de pression indiquées dans la norme DIN EN ISO 8434-1",
            "Résistance élevée à la rouille rouge grâce au revêtement de zinc-nickel HANSA‑FLEX",
            "Joints toriques résistants à l'ozone évitant les défaillances prématurées des composants"
        ],
        image: SafelyConnect
    };

    const Data4 = {
        listItems: [
            "Large gamme de types de raccords filetés",
            "Différentes fonctions d'étanchéité peuvent être fournies : des joints métalliques et des joints toriques aux joints souples",
            "Disponible dans différents matériaux, y compris l'acier inoxydable et le laiton",
            "Des raccords à bague de coupe avec des bagues de coupe préinstallées en usine peuvent être fournis",
            "Raccords avec types de filetage tels que BSP, BSPT, UNF, NPT, JIS ou SAE",
            "Raccords de tuyaux droits, coudés à 45° et 90° en versions en forme de T, L et croix",
            "Fonctions d'étanchéité pour les côtés vissés : joint torique, joint souple, joints métalliques et coniques"
        ]
    };

    // Données pour les onglets HFTab
    const imageTextData = [
        {
            description: "Les raccords de tuyaux HANSA‑FLEX sont conçus pour atteindre des débits optimaux. Les turbulences, les pertes de pression et de performance associées et la cavitation due aux pics de pression sont évités. Cela augmente la durée de vie de chaque composant du système de fluide.",
            listItems: ["Débits extrêmement bons", "Direction volumétrique optimale", "Évitement de la cavitation"],
            image: OptimumFlow
        },
        {
            description: "HANSA‑FLEX établit de nouvelles normes en matière de protection contre la corrosion : les surfaces du corps, des écrous de liaison et des bagues de coupe du raccord de tuyau ont un revêtement de zinc-nickel sans CrVI en standard. Cela garantit une résistance élevée à la corrosion conformément à la norme DIN 50979 jusqu'à 1 500 heures.",
            listItems: [
                "Fabriqué à partir de matériaux étirés à froid ou forgés",
                "Revêtement de surface en zinc-nickel",
                "Autres matériaux tels que l'acier inoxydable ou le laiton sur demande"
            ],
            image: LowWear
        },
        {
            description: "Tous les raccords HANSA‑FLEX ont de grandes faces de contact plates pour donner aux clés une prise sécurisée. Pendant l'installation, le raccord est maintenu en toute sécurité dans la clé. La plus haute sécurité d'installation est assurée par une protection contre le serrage excessif et une augmentation de force clairement détectable.",
            listItems: [
                "La fixation de l'écrou assure une connexion sécurisée contre l'arrachement",
                "Scellé de manière fiable avec des joints métalliques et élastomères lors de la réinstallation",
                "Vérifié lors de l'installation à l'aide d'une jauge de bague de coupe brevetée"
            ],
            image: EasyAssembly
        },
        {
            description: "Les raccords de tuyaux HANSA‑FLEX sont disponibles avec des filetages externes conformes à diverses normes afin qu'ils puissent être combinés avec tous les filetages de raccordement disponibles dans le commerce. L'interchangeabilité avec les raccords métriques est assurée car la connexion côté tuyau des raccords à bague de coupe HANSA‑FLEX est conforme à la norme DIN 3861 type de raccordement W et DIN EN ISO 8434-1.",
            listItems: [
                "Filetages externes et internes métriques et en pouces conformes aux normes",
                "Connexions filetées US cylindriques conformes à l'ISO 11926-2/3",
                "Filetages NPT conformes à ANSI/ASME B1.20.1, SAE J 476"
            ],
            image: ExcellentCompatibility
        }
    ];

    const tabTitles = [
        { title: "Débit optimal", eventKey: "Débit optimal" },
        { title: "Faible usure", eventKey: "Faible usure" },
        { title: "Assemblage facile", eventKey: "Assemblage facile" },
        { title: "Compatibilité excellente", eventKey: "Compatibilité excellente" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"CONNECTEZ SÉCURITAIREMENT LES COMPOSANTS HYDRAULIQUES AVEC DES RACCORDS DE TUYAUX"}
                    paragraph={"Les transitions entre les composants des systèmes hydrauliques sont toujours un point à considérer attentivement. Les raccords de tuyaux de HANSA‑FLEX sont normalisés conformément à la norme DIN EN ISO 8434-1 et DIN 2353. Leur ajustement positif serré assure une connexion fiable même sous des pressions de travail élevées, des pics de pression et des vibrations. Ils sont résistants aux fuites de liquides et de gaz, ont des transitions optimisées pour l'écoulement et sont disponibles dans différents types, tailles et matériaux."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"RACCORDS DE TUYAUX POUR TOUTES LES UTILISATIONS"}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"CONNECTEZ SÉCURITAIREMENT LES TUYAUX"}
                    subtitle={"Les raccords de tuyaux HANSA‑FLEX prennent leur place là où des pressions élevées et la sécurité sont essentielles."}
                />
                <ImageText
                    data={Data3} />
            </div>

            <div>
                <SectionHeader
                    title={"LA SOLUTION DE FILTRATION PARFAITE POUR TOUTES LES APPLICATIONS HYDRAULIQUES"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"LA GAMME DE RACCORDS DE TUYAUX HANSA‑FLEX"}
                />
                <ImageText
                    data={Data4} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={FormPipe}
                                title="Système de formage de tuyaux HF-Form"
                                desc={"HF-Form est un système de formage de tuyaux développé par HANSA‑FLEX qui intègre des joints redondants pour connecter les tuyaux sans bagues de coupe, pièces spéciales ou outils spéciaux."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={BentPipe}
                                title="Tuyaux coudés"
                                desc={"Le centre de cintrage de tuyaux de HANSA‑FLEX peut produire des tuyaux coudés individuellement ou par lots à partir d'une large gamme de matériaux différents. Ses services comprennent également"} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )

}

export default PipeFittings