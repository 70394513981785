import React, { useEffect } from 'react';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import CenterStage from '../../Components/CenterStage/CenterStage';
// import imgStage from '../../assets/ContactUsFrance.png'
import imgEntzheimLocation from '../../assets/FranceUnits/Entzheim/ENTZHEIN_Location.png'
import imgGemenosLocation from '../../assets/FranceUnits/Gemenos/GEMENOS_Location.png'
import imgVitrollesLocation from '../../assets/FranceUnits/Vitrolles/VITROLLES_Location.png'
import imgStage from '../../assets/symbols-paris-sunset-summer-evening.jpg'
// import imgStage from '../../assets/France option-01.png'
// import imgStage from '../../assets/view-paris-tower.jpg'



const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const units = [
        {
            name: 'HANSA-FLEX FRANCE SARL, ENTZHEIM ',
            location: 'Aéroparc 1 – 5, rue Pégase – 67960 ENTZHEIM',
            telephone: '+33 (0) 3 88 55 50 41',
            // fax: "jhgyuyuii",
            mail: 'fst@hansa-flex.com',
            image: imgEntzheimLocation
        },
        {
            name: 'HANSA-FLEX FRANCE SARL, GEMENOS  ',
            // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            location: '480 avenue du Château de Jouques - 13420 GEMENOS',
            telephone: ' +33 (0) 4 42 36 81 90',
            mail: 'magasin.fga@hansa-flex.com',
            image: imgGemenosLocation
        },
        {
            name: 'HANSA-FLEX FRANCE SARL, VITROLLES  ',
            // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            location: 'ZI les Estroublans - 17-23 rue d’Athènes – 13127 VITROLLES',
            telephone: '+33 (0) 4 42 09 45 84',
            mail: 'magasin.fvt@hansa-flex.com',
            image: imgVitrollesLocation
        },
        // Add more units as needed
    ];

    return (
        <div className="">
            <div>
                <CenterStage imageSrc={imgStage} title={"Connectez-vous avec HANSA-FLEX France : Ensemble, favorisons votre réussite hydraulique"} paragraph={"Plongez dans un monde d'expertise hydraulique et de service personnalisé. Contactez-nous dès aujourd'hui et voyons comment HANSA-FLEX France peut porter vos opérations hydrauliques vers de nouveaux sommets."} />
            </div>


            <div className=''>
                <ContactUsCard units={units} />
            </div>
            <br /><br /><br />
        </div>
    );
};

export default ContactUs;
