import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import Hydraulicunit_Centerspread from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgHydraulicunit_Imagetext_01 from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Imagetext_01.jpg'
import imgHydraulicunit_Imagetext_02 from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Imagetext_02.jpg'
import imgHydraulicunit_Imagetext_03 from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Imagetext_03.jpg'
import imgHydraulicunit_Imagetext_04 from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Imagetext_04.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png';
import { Container, Row, Col } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard'
import imgHydraulicunit_Imagecard_01 from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Imagecard_01.jpg'
import imgHydraulicunit_Imagecard_02 from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Imagecard_02.jpg'
import imgHydraulicunit_Imagecard_03 from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Imagecard_03.jpg'

const Hydraulicunit = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);




    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;
  
  
  
    const Hydraulicunit_Imagetext_01 = {
      image: imgHydraulicunit_Imagetext_01,
      title: "Groupes électrogènes de haute qualité fabriqués à l'unité ou en série",
      description: ["HANSA-FLEX coordonne un processus de développement complexe au terme duquel vous recevez un produit de qualité éprouvée. Ce processus comprend cinq étapes :."],
      listitems: ['Analyse et conseils', 'Dessin et simulation', 'Tests sur le prototype', "Contrôles de l'échantillonnage initial"],
    }
  
    const Hydraulicunit_Imagetext_02 = {
      image: imgHydraulicunit_Imagetext_02,
      title: 'Conseils spécifiques et planification de projets par des experts',
      description: ["Les outils d'inspection que nous utilisons comprennent des mesureurs d'épaisseur de revêtement, qui vérifient par exemple l'application correcte d'un revêtement ou d'une peinture. Nous utilisons des scanners laser 3D de haute technologie pour vérifier les dimensions. Nos scanners laser automatiques comparent automatiquement le composant avec le dessin correspondant, ce qui permet d'effectuer des contrôles rapides, fiables et précis, même en cas de volumes de production importants."],
  
    }
  
    const Hydraulicunit_Imagetext_03 = {
        image: imgHydraulicunit_Imagetext_03,
        title: 'Installation et autres services par des experts',
        description: ["Nos techniciens installateurs installent et vérifient de manière professionnelle les fonctions de la centrale hydraulique achevée et, si nécessaire, vous aident à la mettre en service sur le site. Nos techniciens de service se tiennent à votre disposition pour effectuer l'entretien régulier aux intervalles prescrits. Ils interviennent également en cas de dysfonctionnement ou de panne de l'installation et réparent les groupes hydrauliques, les groupes de composants ou les installations complètes. Le réseau dense de filiales HANSA-FLEX et l'excellente disponibilité des pièces détachées dans nos deux entrepôts centraux modernes nous permettent d'être rapidement sur place et de réduire les temps d'immobilisation."],
    
      }

      const Hydraulicunit_Imagetext_04 = {
        image: imgHydraulicunit_Imagetext_04,
        title: '',
        description: ["Une centrale hydraulique assure l'entraînement des consommateurs hydrauliques tels que les cylindres, les moteurs et autres. Elle se compose d'éléments tels qu'un réservoir d'huile hydraulique, un moteur électrique ou pneumatique ou un moteur à combustion interne, des pompes hydrauliques et des soupapes. Les pompes sont entraînées par le moteur pour maintenir le flux d'huile. Ce flux d'huile est dirigé vers les consommateurs (actionneurs) par des composants de commande (valves de contrôle directionnel) et est responsable de leurs mouvements. Plus la pression est élevée, plus la force produite par le consommateur est importante. Plus le débit est élevé, plus le consommateur se déplace rapidement."],
    
      }



      const featuresData = [
        {
          title: "Tous de la même source",
          description: "Du conseil à l'entretien, avec HANSA-FLEX, tout vient de la même source",
          image: imgMark
        },
        {
          title: "Fabriqué individuellement selon les souhaits du client",
          description: "Conseil, fabrication selon des exigences spécifiques et mise en œuvre pour répondre aux demandes particulières du client.",
          image: imgMark
        },
        {
          title: "Choix neutre pour le fabricant",
          description: "Accès à une large gamme de composants et acquisition des composants optimaux pour votre solution.",
          image: imgMark
        },
        {
          title: "Production à l'unité et en série",
          description: "Fabrication d'unités de puissance dans tous les volumes, de la pièce unique à la production en grande série.",
          image: imgMark
        }
        // Add more objects for additional FeaturesCard data
      ];



  return (
    <div>


<CenterStage
        imageSrc={Hydraulicunit_Centerspread}
        title={"LA CENTRALE HYDRAULIQUE OPTIMALE POUR VOTRE SYSTÈME"}
        paragraph={"Les centrales hydrauliques sont la force motrice au cœur de nombreux systèmes hydrauliques. Elles sont composées d'un moteur qui fournit l'énergie, d'un réservoir de fluide, de pompes et de valves. HANSA-FLEX développe des solutions individuelles parfaitement adaptées aux besoins de ses clients et fabrique des unités à l'unité ou en série. Nous vous conseillons en détail sur le dimensionnement de l'appareil et le choix des composants, et nous assurons le service après-vente."}
      />

<div >
        <SectionHeader
          title={"LA FABRICATION DE CENTRALES HYDRAULIQUES POUR TOUS LES CAS D'UTILISATION"}
          subtitle={"Avec les centrales hydrauliques de HANSA-FLEX, tout, de la première ébauche au produit fini, provient d'une seule et même source."}
        />


      </div>

      <div >
                <br />
                <ImageText data={Hydraulicunit_Imagetext_01} left={false} />
            </div>

            <div >
                <br />
                <ImageText data={Hydraulicunit_Imagetext_02} />
            </div>
            <div >
                <br />
                <ImageText data={Hydraulicunit_Imagetext_03} left={false} />
            </div>





            <div >
        <SectionHeader
          title={"PRINCIPES DE FONCTIONNEMENT DES CENTRALES HYDRAULIQUES"}
          subtitle={"Les centrales hydrauliques sont une combinaison de plusieurs composants conçus pour maintenir l'huile hydraulique en mouvement."}
        />


      </div>





            <div >
                <br />
                <ImageText data={Hydraulicunit_Imagetext_04} />
            </div>

            <div >
        <SectionHeader
          title={"AVANTAGES DES CENTRALES HYDRAULIQUES HANSA-FLEX"}
          subtitle={"Profitez de notre vaste gamme de pompes hydrauliques, de notre grande disponibilité et des conseils avisés de nos experts."}
        />


      </div>

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>


      <div >
        <SectionHeader
          title={"CENTRALES HYDRAULIQUES SUR MESURE"}
          subtitle={"HANSA-FLEX ne se contente pas de fournir des centrales hydrauliques. Les connaissances techniques approfondies de nos spécialistes sont mises à profit dans le développement de chaque nouvelle solution. Les conseils d'experts font la différence dans ces situations."}
        />


      </div>




      <div >
        <SectionHeader
          title={"LA REMISE EN ÉTAT DES COMPOSANTS HYDRAULIQUES DÉFECTUEUX"}
          subtitle={"Nos experts expérimentés effectuent des réparations sur des vérins hydrauliques endommagés de toutes tailles, de tous fabricants et de tous types, et ce dans les plus brefs délais. Le centre de réparation de cylindres HANSA-FLEX dispose d'une variété de machines et d'équipements modernes pour réviser les cylindres de manière professionnelle."}
        />


      </div>


      <div >
        <SectionHeader
          title={"CES THÈMES PEUVENT ÉGALEMENT VOUS INTÉRESSER"}
          subtitle={""}
        />


      </div>

      <Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgHydraulicunit_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Tuyaux hydrauliques HANSA-FLEX" desc="Les tuyaux hydrauliques HANSA-FLEX, dont certains sont développés en interne, répondent aux normes de qualité les plus élevées de l'industrie." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgHydraulicunit_Imagecard_02} learnMoreLink="/metal-hoses" title="Raccords de tuyauterie de haute qualité" desc="Les raccords de tuyauterie ont été l'un des premiers développements internes de HANSA-FLEX. Aujourd'hui encore, ces composants hydrauliques permettent de raccorder les canalisations en toute sécurité." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgHydraulicunit_Imagecard_03} learnMoreLink="/hydraulic-hoses" title="Une technologie d'étanchéité fiable" desc="La qualité d'un système hydraulique dépend de celle de ses joints. Un seul joint défectueux peut entraîner de longs temps d'arrêt. HANSA-FLEX permet d'éviter cela." />
                    </Col>


                </Row>
            </Container>














    </div>
  )
}

export default Hydraulicunit