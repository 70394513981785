import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import image1 from '../../assets/home2.jpg';
import stage1 from '../../assets/stage-1.jpg';
import './FullPageCarousel.css'; // Import CSS file
import { Link } from 'react-router-dom';

function FullPageCarousel() {

  const imageContainerStyle = {
    position: 'relative', // Make the container relative for absolute positioning of overlay
    maxHeight: '100%', // Set max height to auto
    maxWidth: '100%', // Set max width to auto
    overflow: 'hidden', // Hide overflow to crop image
    margin: '0 auto', // Center the container horizontally
    width: '100vw', // Set width to fill the viewport width
    display: 'flex', // Set display to flex
    justifyContent: 'center', // Center content horizontally
    alignItems: 'center' // Center content vertically
  };

  const imageStyle = {
    width: '100%', // Set width to fill container
    height: 'auto', // Maintain aspect ratio
    objectFit: 'cover' // Crop the image to cover the entire container
  };

  const titleStyle = {
    marginBottom: '50px',
  };

  const descriptionStyle = {
    marginBottom: '30px', // Set bottom margin for description
    marginLeft: '20%', // Set left margin for description
    marginRight: '20%' // Set right margin for description
  };

  const buttonStyle = {
    backgroundColor: 'rgb(212,51,75)', // Button background color
    color: 'white', // Button text color
    padding: '10px 20px', // Button padding
    borderRadius: '5px', // Button border radius
    border: 'none', // Remove button border
    cursor: 'pointer', // Add cursor pointer on hover
    textDecoration: 'none', // Remove underline from button text
    width: '200px', // Adjust button width to fit content

  };

  const buttonWrapperStyle = {
    textAlign: 'center', // Center content horizontally within the wrapper
    marginBottom: '60px', // Set bottom margin for button wrapper

  };

  return (
    <Carousel>
      <Carousel.Item interval={2500} className='main-carousel-item'>
        <div style={imageContainerStyle} >
          <img className='main-carousel-image'
            src={stage1}
            alt="First slide"
            style={imageStyle}
          />
          <div className="image-overlay"> {/* Ajouter le superposition */}
            <h3 className='c-slide-title' style={titleStyle}>HANSA-FLEX représente l'hydraulique</h3>
            <p className='c-slide-desc' style={descriptionStyle}> HANSA-FLEX France est une fière filiale du groupe HANSA-FLEX AG, un fournisseur renommé de solutions de transmission hydraulique à l'échelle mondiale. Aujourd'hui, HANSA-FLEX est le principal fournisseur européen dans le domaine de la technologie des fluides et s'établit ainsi comme un partenaire créateur de valeur pour les entreprises de toutes tailles recherchant la performance. </p>
            <div style={buttonWrapperStyle}> {/* Enveloppe du bouton */}
              <Link to="/hoses-and-hose-lines" style={buttonStyle}>En savoir plus</Link>
            </div>
          </div>

        </div>
      </Carousel.Item>
      <Carousel.Item interval={2500} className='main-carousel-item'>
        <div style={imageContainerStyle} >
          <img className='main-carousel-image'
            src={image1}
            alt="Second slide"
            style={imageStyle}
          />
          <div className="image-overlay"> {/* Ajouter le superposition */}
            <h3 className='c-slide-title' style={titleStyle}>Explorez notre ligne de produits distinguée !</h3>
            <p className='c-slide-desc' style={descriptionStyle}>Vivez l'excellence avec notre ligne de produits distinguée ! Explorez une sélection minutieusement choisie de biens de qualité supérieure, soigneusement conçus pour améliorer votre style de vie. Des gadgets technologiques innovants aux essentiels luxueux de la vie quotidienne, chaque article de notre collection incarne la qualité, la sophistication et la fonctionnalité. Découvrez l'apogée de l'excellence dès aujourd'hui.</p>
            <div style={buttonWrapperStyle}> {/* Enveloppe du bouton */}
              <Link to="/hoses-and-hose-lines" style={buttonStyle}>Découvrir davantage</Link>
            </div>
          </div>
        </div>
      </Carousel.Item>
      {/*add more carousel items here*/}
    </Carousel>
  );
}

export default FullPageCarousel;
