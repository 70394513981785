import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../Components/HfTabs/HfTabs';
import { Col, Container, Row } from 'react-bootstrap';

import imgStage from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/Stage.jpg'
import PowerfullPump from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/PowerfullPump.jpg'
import CompactCylinder from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/CompactCylinder.jpg'
import ValveTech from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/ValveTech.jpg'
import BallTech from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/BallTech.jpg'
import HoseLines from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/HoseLines.jpg'
import Accessories from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/Accessories.jpg'
import HydraulicTools from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/HydraulicTools.jpg'
import VascoDaGamaBridge from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/VascoDaGamaBridge.jpg'
import SafetyInstruction from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/SafetyInstruction.jpg'
import SafetyInstruction2 from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/SafetyInstruction2.jpg'
import dyas from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/dyas.jpg'
import Installation from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/Installation.jpg'
import Over from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/Over.jpg'



const SevenHundredBarHydraulics = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const BarHydraulics1 = {
        image: PowerfullPump,
        title: 'Puissantes pompes haute pression jusqu\'à 700 bars',
        listItems: ["Pompes manuelles jusqu'à une capacité de réservoir de 2,6 l", "Unités d'alimentation hydraulique électriques de 700 bars (sur demande)", "Systèmes avec plage de pression maximale réduite (par exemple jusqu'à 400 bars)"],
    }

    const BarHydraulics2 = {
        image: CompactCylinder,
        title: 'Cylindres haute pression compacts',
        listItems: ["Cylindres à charge simple et double et cylindres de traction à charge lourde", "Course jusqu'à 150 mm et charge jusqu'à 100 t", "Revêtements de surface : peint, brunis"],
    }

    const BarHydraulics3 = {
        image: VascoDaGamaBridge,
        title: 'Le pont Vasco da Gama est protégé contre les tremblements de terre par des composants hydrauliques',
        description: 'Avec une longueur de 17,2 km, le pont Vasco da Gama à Lisbonne est le plus long pont d\'Europe. Jusqu\'à 50 millions de véhicules par an utilisent ce pont à six voies pour traverser non seulement l\'embouchure du fleuve Tejo, mais également une zone particulièrement sujette aux tremblements de terre. En collaboration avec des mesures structurelles classiques, les amortisseurs hydrauliques jouent un rôle clé dans la protection du pont contre les tremblements de terre, les tempêtes et autres charges.',
    }

    const BarHydraulics4 = {
        image: SafetyInstruction,
        listItems: ["Ne pas utiliser plus de 80 % de la capacité de l'équipement.", "Éviter de surcharger l'équipement.", "Utiliser un manomètre pour vérifier les pressions.", "Ne pas actionner les leviers à la main avec des outils ou des rallonges.", "Nettoyer les coupleurs à déconnexion rapide avant de les accoupler.", "Protéger toujours les flexibles contre les dommages."],
    }

    const BarHydraulics5 = {
        image: SafetyInstruction2,
        listItems: ["Portez toujours un équipement de protection individuelle pour les mains, les yeux et les pieds.", "Utilisez l'équipement hydraulique uniquement sur une surface plane et suffisamment résistante", "Nettoyez l'équipement après utilisation.", "Sécurisez toujours mécaniquement les charges.", "Préparez adéquatement les zones de dépose de la charge.", "Ne restez pas ou ne marchez pas sous les charges levées."],
    }

    // données du carrousel de cartes de fonctionnalités
    const featuresData = [
        {
            title: "Sécurité éprouvée",
            description: "Tous les rapports d'inspection et d'essai vous sont fournis directement.",
            image: imgMark
        },
        {
            title: "Performances maximales",
            description: "Nous nous assurons que tous les composants, tels que les pompes et les cylindres, sont parfaitement adaptés les uns aux autres.",
            image: imgMark
        },
        {
            title: "L'effort supplémentaire",
            description: "Nous sommes heureux de fournir tous vos composants sous forme d'ensembles préconfigurés prêts à être installés immédiatement.",
            image: imgMark
        },
        {
            title: "Utilisation flexible",
            description: "Tous les produits peuvent être utilisés dans des systèmes hydrauliques mobiles et stationnaires.",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données supplémentaires de cartes de fonctionnalités
    ];

    // données HfTab
    const imageTextData = [
        {
            title: "Vannes d'arrêt et d'étranglement",
            listItems: ["Vannes d'arrêt et d'étranglement de vérification", "Soupapes de décharge de pression", "Vannes à pointeau"],
            buttonName: "VERS LES VALVES",
            image: ValveTech
        },
        {
            title: "Vannes à boisseau sphérique à faible entretien",
            listItems: ["Bille flottante libre de se déplacer entre les joints", "Grand alésage pour réduire les pertes de pression", "Débit volumétrique maximal : 27 l/min"],
            buttonName: "VERS LES VANNES À BOISSEAU SPHÉRIQUE",
            image: BallTech
        },
        {
            title: "Flexibles durables",
            description: "Les flexibles conçus pour des pressions allant jusqu'à 700 bars doivent résister aux charges les plus élevées. HANSA‑FLEX, avec ses nombreuses années d'expérience en tant que spécialiste des fluides, peut préassembler des flexibles et des raccords de haute qualité en lignes de flexibles haute pression durables. Nos succursales possèdent toutes les homologations et les bancs d'essai nécessaires pour effectuer ce travail. Nous nous assurons que le flexible et le raccord sont parfaitement adaptés pour maximiser les performances. Le flexible que vous recevez remplit en toute sécurité ses fonctions dans les plages de pression requises.",
            buttonName: "VOIR PLUS",
            image: HoseLines
        },
        {
            title: "Large gamme d'accessoires",
            description: "Les éléments de connexion hydrauliques, tels que les adaptateurs, les distributeurs et les connecteurs filetés, sont un moyen essentiel de joindre les composants dans les systèmes hydrauliques. HANSA‑FLEX propose un large portefeuille de composants de technologie de connexion pour l'hydraulique haute pression. Nous pouvons proposer des systèmes complets, conçus, fournis et installés tout cela par la même source.",
            buttonName: "VERS LES ACCESSOIRES",
            image: Accessories
        },
        {
            title: "Outils hydrauliques",
            description: "Presses d'atelier mobiles avec cadres soudés à haute résistance. Nous serions heureux de fournir d'autres outils hydrauliques tels que des vérins de véhicule, des clés dynamométriques ou des fendeuses d'écrous sur demande.",
            buttonName: "VERS LES OUTILS HYDRAULIQUES",
            image: HydraulicTools
        },
    ];

    const tabTitles = [
        { title: "Technologie des vannes", eventKey: "Technologie des vannes" },
        { title: "Vannes à boisseau sphérique", eventKey: "Vannes à boisseau sphérique" },
        { title: "Flexibles", eventKey: "Flexibles" },
        { title: "Accessoires", eventKey: "Accessoires" },
        { title: "Outils hydrauliques", eventKey: "Outils hydrauliques" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"COMPOSANTS HANSA‑FLEX POUR L'HYDRAULIQUE HAUTE PRESSION JUSQU'À 700 BAR"}
                    paragraph={"Le déplacement de charges lourdes impose les exigences les plus élevées aux composants hydrauliques utilisés. HANSA‑FLEX offre une gamme sélectionnée de produits pour l'hydraulique de charge lourde avec des pressions allant jusqu'à 700 bars et peut fournir des composants personnalisés jusqu'à 1 000 bars sur demande. Nous vous soutenons également dans la sélection, la conception et la combinaison des composants pour des performances maximales et les plus hauts niveaux de sécurité au travail."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"EXCELLENTES PERFORMANCES À HAUTE PRESSION"}
                    subtitle={"Tous les produits HANSA‑FLEX destinés aux applications hydrauliques haute pression sont conformes aux règles techniques en vigueur et sont largement testés par les fabricants pour garantir leur bon fonctionnement et leur sécurité."}
                />
                <ImageText
                    data={BarHydraulics1} />
                <ImageText
                    data={BarHydraulics2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"LE PORTFOLIO COMPLET DES PRODUITS HANSA‑FLEX POUR LES APPLICATIONS HAUTE PRESSION"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"PRODUITS DE HAUTE QUALITÉ PAR LES SPÉCIALISTES DES FLUIDES"}
                    subtitle={"Avec HANSA‑FLEX, vous pouvez compter sur la qualité de première classe des produits et des services."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <ImageText
                    data={BarHydraulics3} />
            </div>

            {/* TODO: Carrousel */}

            <div>
                <SectionHeader
                    title={"INSTRUCTIONS DE SÉCURITÉ IMPORTANTES POUR L'EXPLOITATION DES SYSTÈMES"}
                    subtitle={"La sécurité est primordiale lors du travail avec des pressions élevées. Ce résumé concis expose les instructions de sécurité les plus importantes pour l'utilisation des composants hydrauliques en haute pression."}
                />
                <ImageText
                    data={BarHydraulics4} />
                <ImageText
                    data={BarHydraulics5}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"RÉVISION DES COMPOSANTS HAUTE PRESSION"}
                    subtitle={"En tant que partenaire système, HANSA‑FLEX est là pour vous aider et vous conseiller dans l'entretien des composants haute pression. Nous effectuons également des réparations professionnelles, soit en interne soit avec le soutien de partenaires spécialisés."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"CES PRODUITS ET SERVICES PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={dyas}
                                title="Nous sommes là pour vous 365 jours par an, 24 heures sur 24"
                                desc={"En cas de pannes ou d'arrêts non planifiés, les techniciens de service du service hydraulique rapide HANSA‑FLEX peuvent être sur place immédiatement dans leur..."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Installation}
                                title="Service d'installation de HANSA‑FLEX"
                                desc={"Les techniciens expérimentés du Service industriel HANSA‑FLEX fournissent un soutien rapide et fiable pour l'installation de composants dans des systèmes hydrauliques stationnaires ou mobiles..."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Over}
                                title="Plus de 4 500 composants hydrauliques différents en stock"
                                desc={"HANSA‑FLEX fournit une vaste gamme de composants hydrauliques : des pompes et moteurs aux vérins et filtres, des vannes aux interrupteurs et capteurs..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )

}

export default SevenHundredBarHydraulics