import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgKanban_Centerspread from '../../../assets/Services/Logistics solutions/Kanban delivery/Kanban_Centerspread.jpg'
import imgKanban_Imagetext_01 from '../../../assets/Services/Logistics solutions/Kanban delivery/Kanban_Imagetext_01.jpg'
import imgKanban_Imagetext_02 from '../../../assets/Services/Logistics solutions/Kanban delivery/Kanban_Imagetext_02.jpg'
import imgKanban_Imagetext_03 from '../../../assets/Services/Logistics solutions/Kanban delivery/Kanban_Imagetext_03.jpg'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import ImageText from '../../../Components/ImageText/ImageText'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import { Container, Row, Col } from 'react-bootstrap';
import imgKanban_Imagecard_01 from '../../../assets/Services/Logistics solutions/Kanban delivery/Kanban_Imagecard_01.jpg'
import imgKanban_Imagecard_02 from '../../../assets/Services/Logistics solutions/Kanban delivery/Kanban_Imagecard_02.jpg'
import imgKanban_Imagecard_03 from '../../../assets/Services/Logistics solutions/Kanban delivery/Kanban_Imagecard_03.jpg'


const Kanbandelivery = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);


  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Kanban_Imagetext_01 = {
    image: imgKanban_Imagetext_01,
    title: 'Stockage et livraison directement sur la ligne de production',
    listItems: ["Le système Kanban fonctionne selon le principe du flux tiré. Deux conteneurs de stockage identiques remplis sont utilisés. Les marchandises ne sont prélevées que dans l'un des conteneurs. Ce n'est que lorsque ce conteneur est vide qu'un ordre de réapprovisionnement est donné et que les marchandises sont prélevées dans le deuxième conteneur de stockage, qui arrive à temps avant que les marchandises du deuxième conteneur ne soient épuisées. Les marchandises ne doivent pas être stockées dans un entrepôt central, ce qui nécessiterait plus de surface de stockage."],
  }

  const Kanban_Imagetext_02 = {
    image: imgKanban_Imagetext_02,
    title: 'Détermination mobile des besoins par scanner',
    listItems: ["Les besoins en marchandises sont signalés à la succursale HANSA-FLEX la plus proche en scannant les conteneurs de stockage vides en temps réel via le système de gestion des stocks du client. Les collaborateurs de la succursale HANSA-FLEX préparent alors la marchandise souhaitée dans la quantité exacte requise. Dès que le conteneur vide du client est à nouveau rempli, il est à nouveau scanné et enregistré comme rempli. Les marchandises sont toujours remplies selon le principe du premier entré, premier sorti, de sorte qu'aucun nouvel article ne soit stocké trop longtemps. Ceci est particulièrement important pour les marchandises dont la durée de conservation est limitée. Les tuyaux hydrauliques, par exemple, ne peuvent être stockés que pendant deux ans maximum. Passé ce délai, ils ne peuvent plus être utilisés."],
  }


  const Kanban_Imagetext_03 = {
    image: imgKanban_Imagetext_03,
    title: 'Conseil et collaboration axés sur les solutions',
    listItems: ["Les experts en logistique de HANSA-FLEX vous conseillent de manière approfondie pour savoir si et comment un système Kanban peut être mis en place dans votre production. Grâce à un regard exercé sur les potentiels cachés et à un savoir-faire étendu, les professionnels expérimentés déterminent sur place la solution la plus efficace pour vous : sur demande, HANSA-FLEX prend en charge l'ensemble du stockage et de la logistique pour toutes les marchandises du système Kanban, y compris le tri et la livraison directement sur la ligne de production."],
  }


  const featuresData = [
    {
      title: "Plus de temps pour votre activité principale",
      description: "HANSA-FLEX veille à ce que la bonne marchandise soit disponible au bon moment et au bon endroit",
      image: imgMark
    },
    {
      title: "Capital minimum obligatoire",
      description: "Die Komponenten werden bedarfsgerecht geliefert, und es werden keine Überschüsse auf Lager gehalten.",
      image: imgMark
    },
    {
      title: "Produits fournis en kits",
      description: "Les groupes de composants pré-équipés peuvent être gérés comme des pièces individuelles dans l'inventaire Kanban.",
      image: imgMark
    },
    {
      title: "Faible empreinte écologique",
      description: "Conteneurs réutilisables, pas d'utilisation de boîtes en carton, réduction des besoins de transport et du poids.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];





  return (
    <div>
      <CenterStage
        imageSrc={imgKanban_Centerspread}
        title={"LES PIÈCES ET LES COMPOSANTS ARRIVENT EXACTEMENT AU MOMENT VOULU GRÂCE À LA LIVRAISON KANBAN"}
        paragraph={"HANSA-FLEX propose des livraisons kanban dans le cadre d'un système logistique personnalisé et configuré de manière optimale. La livraison selon le principe du kanban convient parfaitement aux entreprises dont le système de production est organisé de manière cohérente et qui ont besoin d'une large gamme de pièces. Des itinéraires de transport courts et une livraison juste à temps sur la ligne de production, au moment où l'on en a besoin, permettent de réduire considérablement les coûts d'approvisionnement."}
      />

      <div >
        <br />
        <ImageText data={Kanban_Imagetext_01} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Kanban_Imagetext_02} />
      </div>

      <div >
        <br />
        <ImageText data={Kanban_Imagetext_03} left={false} />
      </div>

      <SectionHeader
        title={"VOS AVANTAGES AVEC LE SYSTÈME KANBAN"}
        subtitle={"Avec le système Kanban HANSA-FLEX, vous pouvez compter sur la disponibilité du matériel quand vous en avez besoin, économiser de l'espace de stockage et réduire les coûts d'approvisionnement."}
      />

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>

      <SectionHeader
        title={"PLUS DE SERVICES D'ENTREPOSAGE ET DE LOGISTIQUE DE HANSA-FLEX"}
        subtitle={""}
      />

<Container className="custom-container">
        <Row>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgKanban_Imagetext_02} learnMoreLink="/hydraulic-hoses" title="Des processus de fabrication efficaces avec des pièces fournies en kits" desc="Les kits d'assemblage de pièces prêtes à l'emploi minimisent les coûts initiaux, offrent une qualité maximale et contribuent à une augmentation durable de la productivité." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                <ImageCard image={imgKanban_Imagecard_02} learnMoreLink="/metal-hoses" title="Fabrication en usine directement sur le site" desc="Les kits d'assemblage de pièces prêtes à l'emploi minimisent les coûts initiaux, offrent une qualité maximale et contribuent à une augmentation durable de la productivité." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgKanban_Imagetext_03} learnMoreLink="/Industrial-hoses" title="Gestion d'entrepôt spécifique au client" desc="Vous souhaitez optimiser la gestion de votre entrepôt ? HANSA-FLEX vous conseille et vous aide à atteindre cet objectif en trouvant avec vous la solution la mieux adaptée à vos besoins." />
            </Col>

        </Row>
    </Container>

    <div >
        <br />
        <ImageText data={imgKanban_Imagetext_01} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={imgKanban_Imagetext_02} />
      </div>












    </div>
  )
}

export default Kanbandelivery