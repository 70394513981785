import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgDigital_Centerspread from '../../../assets/Services/Digital Services/Digital Services/Digital_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgDigital_Imagetext_01 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagetext_01.jpg'
import imgDigital_Imagetext_02 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagetext_02.jpg'
import imgDigital_Imagetext_03 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagetext_03.jpg'
import imgDigital_Imagetext_04 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagetext_04.jpg'
import imgDigital_Imagetext_05 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagetext_05.jpg'
import imgDigital_Imagetext_06 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagetext_06.jpg'


import { Container, Row, Col } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard'
import imgDigital_Imagecard_01 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagecard_01.jpg'
import imgDigital_Imagecard_02 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagcard_02.jpg'
import imgDigital_Imagecard_03 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagecard_03.jpg'
import imgDigital_Imagecard_04 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagcard_04.jpg'
import imgDigital_Imagecard_05 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagcard_05.jpg'
import imgDigital_Imagecard_06 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagcard_06.jpg'

import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'


const DigitalServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);


  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Digital_Imagetext_01 = {
    image: imgDigital_Imagetext_01,
    title: 'Commander en ligne des articles sur la technologie des fluides',
    listItems: ["Visitez la boutique en ligne conviviale de HANSA-FLEX, qui contient plus de 80 000 articles relatifs à la technologie des fluides. Téléchargez des informations importantes sur chaque article, telles que les normes applicables, les fiches de données de sécurité et les dessins techniques. Deux entrepôts centraux redondants garantissent une grande disponibilité des produits et une réduction des temps d'arrêt. Des lignes d'emballage automatisées garantissent l'expédition rapide des articles commandés."],
  }

  const Digital_Imagetext_02 = {
    image: imgDigital_Imagetext_02,
    title: 'La gestion de la maintenance des systèmes de fluides facilitée',
    listItems: ["Le portail de service X-CODE Manager facilite grandement la gestion de l'entretien de vos conduites hydrauliques. Créez et administrez votre propre base de données contenant toutes les informations pertinentes sur vos conduites hydrauliques, de sorte que vous ayez toujours une vue d'ensemble de vos machines, de leurs composants et des dates prévues. Vous pouvez ainsi optimiser la planification de vos tâches d'entretien. Des informations détaillées sur X-CODE Manager sont disponibles ici. "],
  }

  const Digital_Imagetext_03 = {
    image: imgDigital_Imagetext_03,
    title: "Conseils d'experts expérimentés",
    listItems: ["Il est impossible d'éviter la numérisation. Dans un premier temps, votre conseiller HANSA-FLEX identifie avec vous les potentiels d'optimisation intéressants qui pourraient être soutenus ou optimisés par nos solutions numériques. La solution à laquelle nous parvenons ensemble est celle qui convient le mieux à vos besoins"],
  }

  const Digital_Imagetext_04 = {
    image: imgDigital_Imagetext_04,
    title: "Y compris l'infrastructure si nécessaire",
    listItems: ["Notre objectif est de vous faire bénéficier d'un maximum d'avantages pour un minimum d'efforts. Par exemple, avec nos solutions de scannage, nous sommes heureux de vous offrir tout ce dont vous avez besoin pour la gestion de la demande numérique : 1.scanners 2. Étiquettes codées et 3. Tablettes et conteneurs."],
  }

  const Digital_Imagetext_05 = {
    image: imgDigital_Imagetext_05,
    title: "Développé en collaboration avec le client",
    listItems: ["Dans nos services numériques, l'accent est toujours mis sur la convivialité. Chaque programme et application numérique de HANSA-FLEX est soumis à plusieurs phases de test où l'accent est mis sur les souhaits et suggestions de nos clients.Sur demande, votre interlocuteur se fera un plaisir de vous former, vous et votre équipe, à nos applications numériques et de répondre à toutes vos questions, afin que vous puissiez démarrer rapidement et avec succès"],
  }

  const Digital_Imagetext_06 = {
    image: imgDigital_Imagetext_06,
    title: '',
    listItems: ["Développement et programmation en interne par les experts de HANSA-FLEX Conseils compétents par des spécialistes qualifiés Conseils cohérents par une personne de contact nommée Mise en œuvre de toutes les mises à jour nécessaires par le biais de HANSA-FLEX"],
  }






  const featuresData = [
    {
      title: "Utiliser les mêmes données de connexion",
      description: "Vos données de connexion en tant que client HANSA-FLEX peuvent également être utilisées pour la boutique en ligne et toutes les applications.",
      image: imgMark
    },
    {
      title: "Apprentissage rapide des applications",
      description: "Les interfaces utilisateur intuitives rendent nos applications numériques très faciles à apprendre.",
      image: imgMark
    },
    {
      title: "Économiser les ressources",
      description: "Les services numériques HANSA-FLEX vous permettent d'économiser des étapes opérationnelles, du temps et du papier.",
      image: imgMark
    },

    // Add more objects for additional FeaturesCard data
  ];







  return (
    <div>
      <CenterStage
        imageSrc={imgDigital_Centerspread}
        title={"SOLUTIONS INTELLIGENTES POUR LA TECHNOLOGIE DES FLUIDES"}
        paragraph={"Découvrez les services numériques de HANSA-FLEX ! Commandez rapidement et confortablement les composants de la technologie des fluides dans notre boutique en ligne et facilitez l'organisation de l'entretien des conduites avec le portail de service X-CODE Manager. Profitez d'un transfert de données plus rapide et sans erreur grâce à une technologie de scanner ultramoderne, à des applications conviviales ou à une interface EDI entre votre système de gestion des marchandises et le nôtre."}
      />

      <SectionHeader
        title={"BOUTIQUE EN LIGNE HANSA-FLEX ET PORTAIL DE SERVICE X-CODE"}
        subtitle={""}
      />

      <div >
        <br />
        <ImageText data={Digital_Imagetext_01} /> <br /><br />
      </div>

      <div >
        <br />
        <ImageText data={Digital_Imagetext_02} left={false} /> <br /><br />
      </div>

      <SectionHeader
        title={"DES PROCESSUS PLUS EFFICACES GRÂCE AUX SERVICES NUMÉRIQUES"}
        subtitle={"Les solutions intelligentes de HANSA-FLEX vous permettent de passer des commandes directement, sans demande préalable, de déterminer les besoins en matériel en quelques secondes et d'intégrer les livraisons avec précision dans vos processus."}
      />


      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgDigital_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Tout sur la boutique en ligne HANSA-FLEX" desc="La boutique en ligne HANSA-FLEX permet de sélectionner et de commander des produits rapidement, facilement et confortablement. Découvrez dès aujourd'hui ses nombreuses fonctions et avantages !" />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgDigital_Imagecard_02} learnMoreLink="/metal-hoses" title="Des solutions de scanner pour faciliter les commandes de marchandises" desc="Utilisez les solutions de scanner HANSA-FLEX pour passer des commandes de réapprovisionnement pour les composants de la technologie des fluides ou pour demander l'état de vos tuyaux lorsque vous êtes sur la route." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgDigital_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Interface numérique avec HANSA-FLEX via EDI et BMEcat" desc="Nous offrons à certains clients importants la possibilité d'interfacer électroniquement les données de base et les commandes afin de rendre vos processus de commerce électronique encore plus efficaces." />
          </Col>

        </Row>
      </Container>


      <SectionHeader
        title={"LA NUMÉRISATION SANS OBSTACLES"}
        subtitle={"Les applications numériques de HANSA-FLEX sont conçues pour être extrêmement pratiques et conviviales afin que les utilisateurs trouvent le passage de l'analogique au numérique aussi simple et direct que possible."}
      />

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>


      <SectionHeader
        title={"SERVICES NUMÉRIQUES SYSTÉMATIQUES"}
        subtitle={"HANSA-FLEX est un fournisseur de systèmes pour tout ce qui concerne la technologie des fluides. Cela signifie que nous vous proposons des solutions personnalisées et bien pensées, de la conception à la réalisation, ainsi que des conseils d'experts. Il en va de même pour nos services numériques."}
      />


      <div >
        <br />
        <ImageText data={Digital_Imagetext_03} left={false} /> <br /><br />
      </div>

      <div >
        <br />
        <ImageText data={Digital_Imagetext_04} /> <br /><br />
      </div>

      <div >
        <br />
        <ImageText data={Digital_Imagetext_05} left={false} /> <br /><br />
      </div>

      <SectionHeader
        title={"DES SERVICES NUMÉRIQUES DANS LA MEILLEURE QUALITÉ HANSA-FLEX"}
        subtitle={"HANSA-FLEX investit autant de savoir-faire et d'expérience dans ses services numériques que dans ses composants de technologie des fluides. Vous pouvez compter sur nous."}
      />

      <div >
        <br />
        <ImageText data={Digital_Imagetext_06} /> <br /><br />
      </div>


      <SectionHeader
        title={"AUTRES SERVICES DE HANSA-FLEX"}
        subtitle={""}
      />



      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgDigital_Imagecard_04} learnMoreLink="/hydraulic-hoses" title="Les solutions logistiques de HANSA-FLEX" desc="Pendant que vous fabriquez les produits, nous assurons un réapprovisionnement fiable en matériaux et vous aidons à optimiser vos canaux d'approvisionnement à l'aide de solutions numériques." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgDigital_Imagecard_05} learnMoreLink="/metal-hoses" title="" desc="Formation avec HANSA-FLEX et IHA" />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgDigital_Imagecard_06} learnMoreLink="/Industrial-hoses" title="" desc="Technologie des capteurs pour une gestion efficace des fluides" />
          </Col>

        </Row>
      </Container>




    </div>
  )
}

export default DigitalServices;