import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../Components/HfTabs/HfTabs';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';

import imgStage from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Stage.jpg'
import imgAnalogue from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Analogue.jpg'
import imgPrecisionTemp from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/PrecisionTemp.jpg'
import imgCompactLevel from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/CompactLevel.jpg'
import imgAdnice from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Adnice.jpg'
import imgServicing from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Servicing.jpg'
import imgOptimization from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Optimization.jpg'
import imgRebundency from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Rebundency.jpg'
import imgChoosingSensor from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/ChoosingSensor.jpg'
import imgPreventWear from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/PreventWear.jpg'
import imgMeasuringTech from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/MeasuringTech.jpg'
import imgStock from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Stock.jpg'
import imgUnits from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Units.jpg'


const SwitchesAndSensors = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const switchAndSecsor1 = {
        image: imgAnalogue,
        title: 'Interrupteurs et capteurs de pression analogiques et numériques',
        description: "Notre boutique en ligne propose un vaste choix d'interrupteurs de pression mécaniques et de capteurs de pression électroniques pour le suivi et le contrôle automatisé des niveaux de pression dans les systèmes hydrauliques.",
        listItems: ["Interrupteurs de pression mécaniques pour des plages de pression de 5 à 630 bars", "Capteurs de pression électroniques fournis en option avec des affichages numériques", "Unité compacte comprenant un interrupteur de pression et un capteur de pression avec affichage LED"],
        buttonName: "TOUS LES PRODUITS"
    }

    const switchAndSecsor2 = {
        image: imgPrecisionTemp,
        title: 'Interrupteurs de température de précision avec différents points de commutation',
        description: "Avoir la température d'huile correcte est crucial pour le bon fonctionnement d'un système hydraulique. Les interrupteurs de température vous permettent de surveiller de manière fiable la température de votre huile hydraulique.",
        listItems: ["Pour l'installation dans les conduites ou les réservoirs hydrauliques", "Longueurs de tube plongeur variées", "Hystérésis de commutation de 10 Kelvin (+/- 5 Kelvin)"],
        buttonName: "VOIR MAINTENANT"
    }

    const switchAndSecsor3 = {
        image: imgCompactLevel,
        title: 'Interrupteurs de niveau et de température compacts',
        description: 'Les interrupteurs de niveau et de température combinés de HANSA‑FLEX fournissent un moyen très simple de contrôler le niveau de remplissage et la température des fluides hydrauliques.',
        listItems: ["Boîtier en plastique ou en aluminium", "Variantes programmables avec affichages LED disponibles en option", "Hystérésis de commutation : +/- 1 % de la valeur d'échelle complète"],
        buttonName: "VERS LA BOUTIQUE EN LIGNE"
    }

    const switchAndSecsor4 = {
        image: imgRebundency,
        title: "Redondance dans l'équipement des systèmes",
        description: "Des défauts ou des pannes peuvent survenir dans n'importe quel système électronique.Pour cette raison, il est judicieux, dans certains cas, d'installer plus d'un interrupteur dans un système hydraulique.Avec la redondance intégrée à la conception de l'électronique de commande, votre système est protégé de manière fiable contre les défauts de commutation en cas de défaillance d'un interrupteur.De plus, le fait de prendre deux mesures donne une deuxième valeur à des fins de comparaison.Cela permet également de détecter un interrupteur défaillant.La sécurité peut être encore augmentée en ajoutant un affichage visuel supplémentaire, purement visuel.",
    }

    const switchAndSecsor5 = {
        image: imgChoosingSensor,
        title: 'Choisir le bon capteur',
        description: "Le choix des capteurs dépend de la situation d'installation, des propriétés du milieu et des conditions ambiantes.L'application détermine également les exigences conformément aux normes pertinentes en matière de résistance à la température, de type IP et de résistance aux chocs et aux vibrations. Vous devez tenir compte des éléments suivants lors de la sélection de votre capteur :",
        listItems: ["Précision de mesure, plage de mesure, quantité mesurée et force du signal de sortie", "Connexions électriques et hydrauliques", "Intégration dans les commandes de machine et de système"],
    }

    const switchAndSecsor6 = {
        image: imgPreventWear,
        title: "Prévenir l'usure",
        description: "Comme tous les autres composants du système, les capteurs ne sont pas protégés à 100 % contre l'usure.Cependant, vous pouvez considérablement prolonger la durée de vie des capteurs en suivant quelques règles simples: ",
        listItems: ["Ne pas dépasser les plages de mesure spécifiées.", "Ne pas utiliser les capteurs dans des milieux pour lesquels ils n'ont pas été conçus.", "S'assurer que les matériaux dont ils sont fabriqués sont adaptés aux conditions d'utilisation.", "Faire fonctionner les capteurs avec les bonnes connexions électriques et les bons paramètres.", "Éviter la surtempérature, les chocs et les vibrations."],
    }

    // données du carrousel de cartes de fonctionnalités
    const featuresData = [
        {
            title: "Portefeuille de produits complet",
            description: "Interrupteurs analogiques et numériques pour la surveillance de la pression, de la température et du niveau de remplissage.",
            image: imgMark
        },
        {
            title: "Disponibilité élevée",
            description: "Des entrepôts bien approvisionnés garantissent des délais de livraison courts pour vos composants requis.",
            image: imgMark
        },
        {
            title: "Configuration personnalisée",
            description: "Les interrupteurs et les capteurs peuvent être fournis avec diverses connexions pour répondre à vos besoins spécifiques.",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données supplémentaires de cartes de fonctionnalités
    ];

    // données HfTab
    const imageTextData = [
        {
            title: "Conseils complets",
            description: "Les experts en fluides de HANSA‑FLEX se feront un plaisir de répondre à vos questions sur la technologie des interrupteurs et des capteurs et de vous fournir des conseils techniques complets sur les sujets suivants :",
            listItems: ["Choix et optimisation de la conception", "Installation dans les systèmes hydrauliques et les commandes", "Fonctionnement efficace et entretien régulier"],
            image: imgAdnice
        },
        {
            title: "Entretien des composants hydrauliques et des machines",
            description: "Avec notre savoir-faire complet et notre vaste gamme de produits dans tous les domaines de l'hydraulique, nous pouvons effectuer tout l'entretien de vos machines et systèmes selon vos besoins. Cela peut soulager la charge de travail de vos employés et réduire les arrêts de maintenance préventive.",
            listItems: ["Réalisation de tâches d'entretien ponctuelles et récurrentes", "Effectuées par des techniciens de service expérimentés et formés", "Remplacement rapide et simple des composants"],
            image: imgServicing
        },
        {
            title: "Optimisation des machines et des systèmes",
            description: "En tant que partenaire système, nous vous apportons un soutien complet pour tous les aspects de l'optimisation et de la modernisation de vos machines et systèmes, dans le but d'augmenter l'efficacité et la fiabilité opérationnelle.",
            listItems: ["Surveillance continue de l'état", "Analyse efficace des pannes et rectification compétente des pannes", "Identification de nouveaux potentiels d'amélioration"],
            image: imgOptimization
        },
    ];

    const tabTitles = [
        { title: "Conseils", eventKey: "Conseils" },
        { title: "Entretien", eventKey: "Entretien" },
        { title: "Optimisation", eventKey: "Optimisation" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"INTERRUPTEURS ET CAPTEURS POUR L'HYDRAULIQUE"}
                    paragraph={"Les interrupteurs et les capteurs pour divers paramètres de processus, tels que la pression, le niveau de remplissage et la température, sont utilisés pour réguler, contrôler et surveiller les systèmes hydrauliques. HANSA‑FLEX est un partenaire unique fournissant une large sélection d'interrupteurs de pression, de température et de niveau robustes et de capteurs de pointe. De plus, nos experts vous conseillent sur la sélection de la solution la plus adaptée et vous aident à optimiser vos machines et systèmes."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"INTERRUPTEURS ET CAPTEURS POUR LE CONTRÔLE DE LA PRESSION, DE LA TEMPÉRATURE ET DU NIVEAU"}
                    subtitle={"Le portefeuille HANSA‑FLEX comprend des interrupteurs hydrauliques analogiques robustes et des capteurs de pointe pour interconnecter les systèmes hydrauliques avec des commandes électroniques pour toutes les industries et applications."}
                />
                <ImageText
                    data={switchAndSecsor1} />
                <ImageText
                    data={switchAndSecsor2}
                    left={false} />
                <ImageText
                    data={switchAndSecsor3} />
            </div>

            <div>
                <SectionHeader
                    title={"INTERRUPTEURS ET CAPTEURS ANALOGIQUES ET NUMÉRIQUES DE HANSA‑FLEX"}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"NOTRE MISSION EST DE SATISFAIRE VOS EXIGENCES"}
                    subtitle={"Vous n'avez pas trouvé la bonne solution pour votre application dans notre boutique en ligne ? En plus des produits répertoriés là-bas, nous avons également accès à de nombreux autres interrupteurs, capteurs et systèmes de mesure de course pour vérins disponibles ailleurs sur le marché. Appelez-nous ! Nous trouverons la meilleure solution pour vous."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"CONSEILS ET SOUTIEN D'EXPERTS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"BON À SAVOIR"}
                    subtitle={"Ce que vous devez prendre en compte lors de la sélection, de l'installation et du fonctionnement des capteurs et interrupteurs dans les systèmes hydrauliques."}
                />
                <ImageText
                    data={switchAndSecsor4} />
                <ImageText
                    data={switchAndSecsor5}
                    left={false} />
                <ImageText
                    data={switchAndSecsor6} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgMeasuringTech}
                                title="Technologie de mesure et instruments d'affichage pour les systèmes hydrauliques”"
                                desc={"HANSA‑FLEX dispose d'instruments de mesure de fabricants bien connus pour surveiller et afficher la pression, le débit, la température et la densité des particules."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgStock}
                                title="Plus de 4 500 composants hydrauliques différents toujours en stock"
                                desc={"HANSA‑FLEX offre un portefeuille étendu de composants hydrauliques de haute qualité tels que des pompes, des moteurs, des vérins, des filtres, des valves, des interrupteurs et des capteurs."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgUnits}
                                title="Unités hydrauliques construites selon les exigences spécifiques du client"
                                desc={"HANSA‑FLEX utilise son savoir-faire étendu pour soutenir les clients dans la conception, la planification de projets et la fabrication d'unités hydrauliques en petites quantités et en petites séries et avec..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )

}


export default SwitchesAndSensors