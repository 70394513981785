import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'

import imgStage from '../../assets/Products/TechnicalInformation/stage.jpg'
import SectionHeader from '../../Components/SectionHeader/SectionHeader';

const TechnicalInformation = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"LE BON MOTEUR HYDRAULIQUE HANSA‑FLEX POUR VOTRE APPLICATION"}
                    paragraph={"Les moteurs hydrauliques transforment l'énergie hydraulique en énergie mécanique. Nous proposons une large gamme de moteurs à geroller et à gerotor adaptés aux données de performance de vos machines et systèmes, dans une qualité éprouvée en tant que variantes premium ou économiques pour de nombreuses applications dans l'hydraulique mobile et industrielle. Nous pouvons fournir d'autres types de moteurs hydrauliques tels que les moteurs axiaux, à engrenages et à piston radial sur demande."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"INFORMATIONS TECHNIQUES À TÉLÉCHARGER"}
                    subtitle={"Ici, vous pouvez trouver toutes les présentations, informations techniques et notre brochure de référence rapide à télécharger en allemand et en anglais au format PDF."}
                // TODO : Steps
                />
            </div>
        </div>

    )
}


export default TechnicalInformation