import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../Components/HfTabs/HfTabs';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';


import imgStage from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/Stage.jpg'
import imgGerollerMotors from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/GerollerMotors.jpg'
import imgGerotor from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/Gerotor.jpg'
import imgAccessories from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/Accessories.jpg'
import imgSystemCompatibleMotor from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/SystemCompatibleMotor.jpg'
import imgTraining from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/Training.jpg'
import imgRepairAndOverhaul from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/RepairAndOverhaul.jpg'
import imgValveTech from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/ValveTech.jpg'



const HydraulicMotor = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const motor1 = {
        image: imgGerollerMotors,
        title: 'Les moteurs Geroller fonctionnent de manière fiable sous pression.',
        description: 'Les moteurs Geroller HANSA‑FLEX sont une solution fiable pour fournir des vitesses de rotation basses sans avoir besoin de engrenages supplémentaires. Les moteurs Geroller sont utilisés lorsque des couples élevés sont nécessaires dans un espace d\'installation réduit.',
        listItems: ["Grand volume de déplacement pour des dimensions relativement petites", "Vitesse : 50 à 800 tr/min, couple : 240 à 850 Nm", "Haute efficacité même sous une pression élevée continue"],
        buttonName: "TOUS LES MOTEURS GEROLLER"
    }

    const motor2 = {
        image: imgGerotor,
        title: 'Les moteurs Gerotor sont peu exigeants en maintenance, robustes et polyvalents.',
        description: 'Les moteurs Gerotor de HANSA‑FLEX se distinguent par leur longue durée de vie. Les moteurs compacts sont utilisés dans des situations nécessitant des vitesses de rotation élevées.',
        listItems: ["Vitesses de rotation élevées combinées à des dimensions compactes", "Vitesse : 240 à 1 900 tr/min, couple : 11 à 380 Nm", "Adapté à une gamme variée de fluides hydrauliques"],
        buttonName: "TOUS LES MOTEURS GEROTOR"
    }

    const motor3 = {
        image: imgAccessories,
        title: 'Accessoires et pièces de rechange - tout d\'un seul fournisseur',
        description: "Le remplacement rapide des pièces d'usure et des accessoires assure une longue durée de vie pour vos moteurs hydrauliques et réduit les temps d'arrêt pour l'entretien et la maintenance.",
        listItems: ["Technologie de vanne de choc pour montage en bride directe", "Ensembles de joints spéciaux pour les plus petits moteurs", "Brides pour les moteurs Gerotor"],
        buttonName: "VERS LES ACCESSOIRES"
    }

    const motor4 = {
        image: imgSystemCompatibleMotor,
        description: "Les moteurs orbitaux conviennent à un large éventail d'applications en hydraulique mobile telles que les chasse- neige, les engins de construction, les machines agricoles et les machines de moulage par injection de plastique.",
        listItems: ["Large portefeuille de produits de moteurs hydrauliques", "Produits haut de gamme d'Europe ou variantes économiques à prix avantageux", "Axe de différents diamètres (16, 25, 32 et 40 mm)", "Brides à quatre ou deux trous avec joint haute pression"],
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Qualité éprouvée",
            description: "Chez HANSA‑FLEX, vous pouvez compter sur la réception de moteurs hydrauliques durables, éprouvés en pratique à maintes reprises.",
            image: imgMark
        },
        {
            title: "Matériaux de haute qualité",
            description: "Acier moulé robuste dans un design compact, revêtements de surface spéciaux sur demande.",
            image: imgMark
        },
        {
            title: "Conseils des experts en hydraulique",
            description: "Nos spécialistes en hydraulique vous conseillent sur toutes les questions, en s'appuyant sur leur savoir-faire complet.",
            image: imgMark
        },
        {
            title: "Excellente disponibilité des pièces",
            description: "Notre concept d'entrepôt et de logistique peut fournir des moteurs et des accessoires sur une base Just-In-Time.",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données supplémentaires de FeaturesCard
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"LE BON MOTEUR HYDRAULIQUE HANSA‑FLEX POUR VOTRE APPLICATION"}
                    paragraph={"Les moteurs hydrauliques transforment l'énergie hydraulique en énergie mécanique. Nous proposons une large gamme de moteurs Geroller et Gerotor adaptés aux données de performance de vos machines et systèmes dans une qualité éprouvée sous forme de variantes premium ou économiques pour de nombreuses applications en hydraulique mobile et industrielle. Nous pouvons également fournir d'autres types de moteurs hydrauliques tels que les moteurs axiaux, à engrenages et à piston radial sur demande."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"MOTORS PLANÉTAIRES ET ACCESSOIRES ADAPTÉS FOURNIS PAR UN FOURNISSEUR SYSTÈME"}
                />
                <ImageText
                    data={motor1} />
                <ImageText
                    data={motor2}
                    left={false} />
                <ImageText
                    data={motor3} />
            </div>

            <div>
                <SectionHeader
                    title={"SOLUTIONS SPÉCIALES PARFAITES POUR VOTRE APPLICATION"}
                    subtitle={"HANSA‑FLEX fournit des moteurs hydrauliques configurés pour vos applications avec des valves spéciales, des arbres, des sorties d'huile pour divers champs d'utilisation et des moteurs axiaux, à engrenages et à piston radial. Tous nos moteurs hydrauliques peuvent également être fournis prêts à l'installation et peints selon les exigences du client sur demande."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"AVANTAGES DE PERFORMANCE DES MOTEURS HYDRAULIQUES HANSA‑FLEX"}
                    subtitle={"Large portefeuille de produits de moteurs hydrauliques avec différents couples et plages de vitesses."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"MOTORS HYDRAULIQUES COMPATIBLES AVEC LE SYSTÈME DE HANSA‑FLEX"}
                    subtitle={"Les moteurs planétaires HANSA‑FLEX se distinguent par leurs couples élevés et leurs plages de vitesses."}
                />
                <ImageText
                    data={motor4} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgTraining}
                                title="Formation par les experts"
                                desc={"En collaboration avec l'Académie internationale d'hydraulique (AIH), HANSA‑FLEX propose une large gamme de cours et de séminaires sur la technologie des fluides."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgRepairAndOverhaul}
                                title="Réparation et révision des composants hydrauliques"
                                desc={"Les experts de HANSA‑FLEX peuvent réparer et réviser des moteurs, des pompes et des vérins de toutes tailles et de tous fabricants."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgValveTech}
                                title="Technologie des valves pour des pressions jusqu'à 350 bar"
                                desc={"Avec des vannes de haute qualité de HANSA‑FLEX, vous bénéficiez d'une excellente dynamique et de temps de réaction courts dans une large gamme d'applications et de situations d'installation."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )

}

export default HydraulicMotor