import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../../Components/ImageText/ImageText'
import imgMark from '../../../../assets/mark.png';
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../../Components/HfTabs/HfTabs';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import { Col, Container, Row } from 'react-bootstrap';

import imgStage from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/Stage.jpg'
import imgComplexvalve from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/Complexvalve.jpg'
import imgHighlyEfficient from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/HighlyEfficient.jpg'
import imgEfficientAndRobust from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/EfficientAndRobust.jpg'
import imgHousing from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/Housing.jpg'
import imgValveBlock from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/ValveBlock.jpg'
import imgScrewIn from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/ScrewIn.jpg'
import imgReplaceSolenoid from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/ReplaceSolenoid.jpg'
import imgIntermediatePlate from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/IntermediatePlate.jpg'
import imgSeals from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/Seals.jpg'
import imgReliable from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/Reliable.jpg'
import imgIndividualised from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/Individualised.jpg'
import imgValveTech from '../../../../assets/Products/DriveAndControlTechnology/CartridgeValve/ValveTech.jpg'


const CartridgeValves = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const CartridgeValve1 = {
        image: imgComplexvalve,
        title: 'Blocs de soupapes complexes au format compact',
        description: "Les soupapes à cartouche vissées exécutent les mêmes fonctions de commande que les soupapes d'installation de conduites.Cependant, dans le cas des soupapes à cartouche, la soupape et le boîtier sont deux composants séparés.Cela permet de combiner les boîtiers avec diverses soupapes fonctionnelles selon les besoins.Des commandes complexes peuvent être installées dans les plus petits espaces en vissant plusieurs soupapes fonctionnelles différentes dans un seul bloc de soupapes.La normalisation ISO des soupapes fonctionnelles et des alésages de cartouche permet une flexibilité dans leur configuration.",
    }

    const CartridgeValve2 = {
        image: imgHighlyEfficient,
        title: 'Très efficace, diversifié dans son application',
        description: "Les boîtiers de haute qualité et les alésages de profil très précis pour les soupapes à cartouche vissées minimisent les fluctuations de pression dans les machines et les systèmes et augmentent leur efficacité. Leur compacité et leur faible poids permettent aux soupapes à cartouche d'augmenter l'efficacité des systèmes, notamment dans l'hydraulique mobile.Les matériaux utilisés pour construire les composants peuvent être choisis en fonction des exigences et des conditions dans leurs domaines d'application.",
    }

    const CartridgeValve3 = {
        image: imgEfficientAndRobust,
        title: 'Soupapes à cartouche vissées efficaces et robustes',
        description: 'Les soupapes à cartouche vissées sont utilisées pour la régulation du débit et de la pression, entre autres. Elles sont connues pour leur grande vitesse de commutation, leur fuite très faible, leur bon contrôle des débits élevés et leur capacité à supporter les charges résultant de pressions élevées.',
        listItems: ["Débits jusqu'à 230 litres par minute", "Pression de travail autorisée jusqu'à 350 bars"],
    }

    const CartridgeValve4 = {
        image: imgHousing,
        title: 'Boîtiers pour chaque application',
        description: 'Vous pouvez obtenir différents types de boîtiers pour les soupapes à cartouche chez HANSA‑FLEX. Fabriqués en fonte, en acier inoxydable ou en aluminium avec le revêtement de surface approprié, ils peuvent être utilisés dans presque tous les environnements. HANSA‑FLEX propose également des soupapes spécialement conçues pour les atmosphères potentiellement explosives conformément à la directive ATEX, avec la possibilité de boîtiers peints aux couleurs de votre entreprise.',
        listItems: ["Boîtiers en acier coulé (jusqu'à 350 bars), boîtiers en aluminium (jusqu'à 250 bars)", "Non traités, brunis, phosphatés, anodisés ou thermolaqués", "Construction en acier inoxydable pour les milieux agressifs"],
    }

    const CartridgeValve5 = {
        image: imgValveBlock,
        title: 'Blocs de soupapes fabriqués précisément selon les souhaits du client',
        description: "En tant que l'un des principaux partenaires système dans le domaine de l'hydraulique, HANSA‑FLEX possède un savoir-faire exceptionnel sur la construction de blocs de soupapes. Les clients peuvent donc compter sur la réception de blocs de soupapes conçus et fabriqués précisément pour répondre à leurs exigences techniques.",
        listItems: ["Assistance clientèle par des spécialistes", "Les dessins 3D montrent tous les détails techniques", "Fabriqués à l'aide de technologies CNC de pointe"],
    }

    const CartridgeValve6 = {
        image: imgScrewIn,
        listItems: ["Soupapes de régulation de débit 2 voies et 3 voies", "Soupapes de siège à commande électromagnétique 2/2 voies", "Soupapes de sécurité et de réduction de pression", "Clapets anti-retour", "Soupapes de centrage", "Diviseurs / combineurs de débit"],
    }

    // données du carrousel de cartes de fonctionnalités
    const featuresData = [
        {
            title: "Polyvalent et efficace",
            description: "Les blocs de soupapes avec des soupapes à cartouche vissées offrent une flexibilité maximale dans un espace minimal.",
            image: imgMark
        },
        {
            title: "Haute qualité de produit",
            description: "HANSA‑FLEX vend des soupapes à cartouche vissées de haute qualité de fabricants bien connus.",
            image: imgMark
        },
        {
            title: "Disponibilité maximale",
            description: "Grâce à sa grande capacité d'entrepôt, HANSA‑FLEX peut fournir des produits de manière fiable et dans les meilleurs délais.",
            image: imgMark
        },
        {
            title: "Boîtiers individualisés",
            description: "Vous souhaitez des boîtiers de soupape dans les couleurs de votre entreprise ? Ce n'est pas un problème chez HANSA‑FLEX",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données de carte de fonctionnalités supplémentaires
    ];

    // Données des onglets HfTab
    const imageTextData = [
        {
            title: "Bobines de solénoïde de remplacement pour soupapes",
            description: "Ces bobines de solénoïde CC ou CA sont capables de performances de commutation élevées et disponibles en stock. Commandez dès maintenant sur la boutique en ligne de HANSA‑FLEX !",
            listItems: ["Tensions nominales 12, 25 et 230 V", "Consommation électrique 22 et 25 W"],
            buttonName: "TOUS LES BOBINES DE SOLÉNOÏDE",
            image: imgReplaceSolenoid
        },
        {
            title: "Plaques intermédiaires pour fonctions complexes",
            description: "En plus des soupapes à cartouche, le spécialiste de la technologie des fluides HANSA‑FLEX fournit également des plaques intermédiaires CETOP pour fournir des fonctions de commutation complexes dans vos systèmes.",
            listItems: ["Plaques intermédiaires et soupapes de montage de plaque prêtes à installer", "Soupapes empilées", "Parfaitement conçu pour les alésages standard iso"],
            buttonName: "EN SAVOIR PLUS",
            image: imgIntermediatePlate
        },
        {
            title: "Joints pour tous les domaines d'utilisation",
            description: "En plus des joints en NBR, nous pouvons également fournir d'autres matériaux d'étanchéité pour les soupapes à cartouche sur demande. Il s'agit notamment de joints adaptés à une utilisation à des températures plus élevées ou avec des milieux agressifs.",
            listItems: ["Joints pour applications haute température", "Joints pour une utilisation avec des milieux agressifs"],
            buttonName: "COMMANDER MAINTENANT",
            image: imgSeals
        },
    ];

    const tabTitles = [
        { title: "Bobines de solénoïde de remplacement", eventKey: "Bobines de solénoïde de remplacement" },
        { title: "Plaques intermédiaires", eventKey: "Plaques intermédiaires" },
        { title: "Joints", eventKey: "Joints" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"SOUPAPES À CARTOUCHE VISSÉES POUR BLOCS DE COMMANDE HYDRAULIQUES"}
                    paragraph={"Les soupapes à cartouche sont vissées directement dans les blocs de soupapes ou les boîtiers de soupapes. Cela permet de combiner des commandes fluides complexes et de les installer dans le plus petit espace. Jusqu'à présent, les soupapes à cartouche vissées étaient principalement utilisées dans l'hydraulique mobile. Cependant, elles sont maintenant également utilisées dans les systèmes hydrauliques stationnaires car leurs conceptions de plus en plus compactes présentent des avantages surtout dans le domaine de l'automatisation."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"PLUS DE FLEXIBILITÉ DE COMMANDE DANS LE PLUS PETIT ESPACE"}
                />
                <ImageText
                    data={CartridgeValve1} />

                <ImageText
                    data={CartridgeValve2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"SOUPAPES À CARTOUCHE VISSÉES DE HANSA‑FLEX"}
                    subtitle={"HANSA‑FLEX peut fournir une large sélection de soupapes à cartouche vissées de haute qualité avec diverses fonctions et tous les composants associés du même fournisseur."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"SOUPAPES À CARTOUCHE VISSÉES DANS LA BOUTIQUE EN LIGNE DE HANSA‑FLEX"}
                    subtitle={"HANSA‑FLEX propose une large gamme de soupapes à cartouche vissées pour des applications dans l'hydraulique mobile et stationnaire. Trouvez des soupapes adaptées à vos fonctions de commutation requises dans notre boutique en ligne. Nos experts en technologie des fluides se feront toujours un plaisir de vous conseiller."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"COMPOSANTS EFFICACES ET SOLUTIONS SUR MESURE"}
                />
                <ImageText
                    data={CartridgeValve3} />
                <ImageText
                    data={CartridgeValve4}
                    left={false} />
                <ImageText
                    data={CartridgeValve5} />
            </div>

            <div>
                <SectionHeader
                    title={"SOUPAPES À CARTOUCHE VISSÉES DANS LA GAMME DE PRODUITS DE HANSA‑FLEX"}
                    subtitle={"Chez HANSA‑FLEX, les clients ont un large choix de soupapes à cartouche vissées pour fournir une large gamme de fonctions dans de nombreux designs et variantes."}
                />
                <ImageText
                    data={CartridgeValve6} />
            </div>

            <div>
                <SectionHeader
                    title={"SOUPAPES D'INSTALLATION DE CONDUITES HANSA‑FLEX POUR TOUTES LES APPLICATIONS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                    subtitle={"Autres produits, services et sujets intéressants concernant la technologie des soupapes chez HANSA‑FLEX."}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgReliable}
                                title="Experts hydrauliques fiables disponibles sur place"
                                desc={"Le Service industriel HANSA‑FLEX installe des soupapes à cartouche vissées et assure le bon fonctionnement à long terme des systèmes."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgIndividualised}
                                title="Solutions individualisées pour blocs de soupapes"
                                desc={"En consultation avec vous et après une analyse approfondie des besoins, HANSA‑FLEX fabrique vos composants de commande exactement selon vos exigences."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgValveTech}
                                title="Technologie des soupapes au plus haut niveau de qualité"
                                desc={"Avec la technologie des soupapes de HANSA‑FLEX, vous bénéficiez de matériaux de haute qualité, d'une construction robuste et d'une longue durée de vie."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )

}

export default CartridgeValves