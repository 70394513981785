import React from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import { FaPhoneVolume } from "react-icons/fa6";
import { MdOutlineEmail, MdOutlineFax } from "react-icons/md";
import { BiLogoFacebookCircle } from "react-icons/bi";
import Imgxing from '../../assets/xingImg.png'
import Imglinkidin from '../../assets/Linkdin.png'
import Imgyoutube from '../../assets/youtube.png'
import Imgkaggle from '../../assets/kaggleImg.png'
import Imgfacebook from '../../assets/Facebook.png'
import { Link } from 'react-router-dom';
import './HfFooter.css'

const HfFooter = () => {
  return (
    <footer className='hfFooter-wrapper' id='contact-us-section'>
      <Container className='hfFooter-container'>
        <Row className="justify-content-center " style={{ marginLeft: 'auto' }}>
          <Col xs={4} lg={4} >
            <div className='footer-column-div'>
              <h>How to reach us</h>
              <ul>
                <li><span style={{ fontSize: '1rem' }}><FaPhoneVolume /></span> +33 (0) 3 88 55 50 41 (FST)</li>
                <li><span style={{ fontSize: '1rem' }}><FaPhoneVolume /></span> +33 (0) 4 42 36 81 90 (FGA)</li>
                <li><span style={{ fontSize: '1rem' }}><FaPhoneVolume /></span> +33 (0) 4 42 09 45 84 (FVT)</li>
                <li> <span style={{ fontSize: '1.2rem' }}><MdOutlineEmail /></span>fst@hansa-flex.com (Siège social, ENTZHEIM)</li>
              </ul>
            </div>

          </Col>

          <Col xs={4} lg={4}>
            <div className='footer-column-div'>
              <h>A propos de HANSA‑FLEX</h>
              <ul>
                {/* <li><Link to="/management" className="footer-link">Gestion</Link></li> */}
                <li><Link to="/certificates" className="footer-link">Certificats</Link></li>
                <li><Link to="/contact-us" className="footer-link">Contacter</Link></li>
              </ul>
            </div>

          </Col>


          {/* <Col xs={4} lg={3}>
          <div className='footer-column-div'>
          <h>Service</h>
          <ul>
                <li>Mobile rapid hydraulic service</li>
                <li>Fluid management</li>
                <li>Assembly and installation</li>
                <li>Hydarulic cylinder repair</li>
                <li>Preventive maintenance</li>
            </ul>
          </div>
          </Col> */}

          <Col xs={4} lg={4}>
            <div className='footer-column-div'>
              <h>Catégories populaires</h>
              <ul>
                <li><Link to="/hoses-and-hose-lines" className="footer-link">Produits</Link></li>
                <li><a href="https://my.hansa-flex.fr/fr_FR/login" target="_blank" rel="noopener noreferrer" className="footer-link">Gestionnaire X-CODE</a></li>
              </ul>
            </div>
          </Col>

        </Row>

        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <h>Suivez-nous</h>
          </Col>
        </Row>


        <Row className="custom-3row ">

          {/*<Col className="circle-col"><div><img src={Imgkaggle} alt="" /></div></Col>
          <Col className="circle-col"><div><img src={Imgxing} alt="" /></div></Col> */}
          <Col className="circle-col"><div> <a href="https://www.linkedin.com/company/hansa-flex-france/" target="_blank" rel="noopener noreferrer">< img src={Imglinkidin} alt="" /></a></div></Col>
          <Col className="circle-col"><div><a href="https://www.youtube.com/@HANSAFLEXAG" target="_blank" rel="noopener noreferrer"><img src={Imgyoutube} alt="" /></a></div></Col>
          <Col className="circle-col"><div><a href="https://www.facebook.com/hansaflexag" target="_blank" rel="noopener noreferrer"><img src={Imgfacebook} alt="" /></a></div></Col>

        </Row>

        <Row className="line-row">
          <Col className="line-col"></Col>
        </Row>

        <div className='HfFooter-link-div'>
          <a className="hf-footer-link" href="">© Droits d'auteur, Tous droits réservés</a><span>  |  </span>
          <Link to="/hansa-flex-france-privacy-policy" className="footer-link">Protection Donnees Personnelles (RGDP)</Link><span>  |  </span>
          <Link to="/hansa-flex-france-terms-and-conditions" className="footer-link">Conditions generales de Ventes</Link>

          {/*<a className="hf-footer-link" href="">GT&C</a><span>  |  </span>
          <a className="hf-footer-link" href="">Purchasing and logistics</a><span>  |  </span>
        <a className="hf-footer-link" href="">Data protection</a>*/}

        </div>



      </Container>
    </footer >

  );
};

export default HfFooter;
