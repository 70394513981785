import React, { useEffect } from 'react'
import imgProduct_Pipelines_Centerstage from '../../../assets/Products/Pipelines/Pipelines/Product_Pipelines_Centerstage.jpg';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgPipeline_imagecard1 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_Imagecard1.jpg'
import imgPipeline_imagecard2 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_Imagecard2.jpg'
import imgPipeline_imagecard3 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_Imagecard3.jpg'
import imagePipelineimagecard4 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_Imagecard4.jpg'
import imagePipelineimagecard5 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_Imagecard5.jpg'
import imagePipelineimagecard6 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_Imagecard6.jpg'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import { Container, Row, Col } from 'react-bootstrap';
import imgPipelineImagetext1 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_imagetext_1.jpg'
import imgPipelineImagetext2 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_imagetext_2.jpg'
import imgPipelineImagetext3 from '../../../assets/Products/Pipelines/Pipelines/Pipelines_imagetext_3.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import imgMark from '../../../assets/mark.png'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTab from '../../../Components/HfTabs/HfTabs';
import fasteningTech from '../../../assets/Products/Pipelines/Pipelines/fasteningTech.jpg'
import HosePipeComb from '../../../assets/Products/Pipelines/Pipelines/HosePipeComb.jpg'
import SeriesProd from '../../../assets/Products/Pipelines/Pipelines/SeriesProd.jpg'
import ComponentGroups from '../../../assets/Products/Pipelines/Pipelines/ComponentGroups.jpg'
import AtHome from '../../../assets/Products/Pipelines/Pipelines/AtHome.jpg'


const Pipelines = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    // features card carousel data
    const featuresData = [
        {
            title: "Normes de qualité les plus élevées",
            description: "Composants de canalisation répondant aux exigences du client, fabriqués avec précision et conformément aux normes.",
            image: imgMark
        },
        {
            title: "Technologies de pointe",
            description: "HANSA‑FLEX fabrique des produits à l'aide de machines de haute technologie et développe ses propres solutions innovantes",
            image: imgMark
        },
        {
            title: "Solutions système, tout d'un seul fournisseur",
            description: "Un seul partenaire pour tout et tous les composants sont conçus pour fonctionner parfaitement ensemble.",
            image: imgMark
        },
        {
            title: "Des décennies d'expérience",
            description: "Conseils et fabrication par des experts expérimentés possédant une expertise dans de nombreux secteurs industriels.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    const imgPipeline_imagetext1 = {
        image: imgPipelineImagetext1,
        title: 'Capture de géométrie sans contact et transfert direct des données',
        description: "Un des équipements utilisés par HANSA‑FLEX pour la conception et le contrôle est un bras de mesure. La numérisation sans contact capture les géométries à l'aide de la technologie laser ou infrarouge.Les modèles 3D créés à partir des résultats sont comparés aux données 3D du client et utilisés pour la conception supplémentaire.La communication directe avec les logiciels CAO couramment disponibles permet de traiter rapidement les données.Les solutions logicielles internes permettent d'effectuer rapidement des vérifications de faisabilité. Les données peuvent également être directement transférées vers des applications CAO via des interfaces.",
    }

    const imgPipeline_imagetext2 = {
        image: imgPipelineImagetext2,
        title: 'Toujours à la pointe de la technologie',
        description: ["Hautes performances dans les espaces d'installation restreints, articles sur mesure, délais de livraison rapides et traçabilité complète sont de plus en plus attendus des pipelines et de leurs fournisseurs.HANSA‑FLEX ne ménage aucun effort pour permettre aux fournisseurs d'équipements de répondre aux exigences croissantes de leurs marchés. Par exemple, nous ajoutons constamment de nouvelles machines à nos installations de production, améliorons la mise en réseau des données et étendons nos entrepôts et centres de production pour garantir une flexibilité et une disponibilité maximales..', 'Nous remettons en question les choses de manière critique et respectons les opinions des autres..', 'Nous sommes conscients de nos responsabilités envers nos collègues et partenaires et mettons en œuvre ces responsabilités."],
    }


    const imgPipeline_imagetext3 = {
        image: imgPipelineImagetext3,
        title: 'Support technique',
        description: ["HANSA‑FLEX est toujours disponible pour fournir des conseils techniques et un support dans la conception de pipelines. Les années d'expérience de l'entreprise et son expertise dans de nombreux secteurs industriels bénéficient grandement aux clients lors de la conception d'échantillons initiaux, de la construction de prototypes ou de la planification de nouveaux projets.', 'Nous remettons en question les choses de manière critique et respectons les opinions des autres..', 'Nous sommes conscients de nos responsabilités envers nos collègues et partenaires et mettons en œuvre ces responsabilités."],
    }

    const imgPipeline_imagetext4 = {
        image: AtHome,
        listItems: ["Énergie éolienne", "Électronique", "Ingénierie ferroviaire", "Matériel de construction", "Technologie agricole", "Hydraulique stationnaire"],
    }

    //HfTab data
    const imageTextData = [
        {
            title: "Colliers de serrage pour un assemblage rapide et facile des lignes",
            description: ["Une large sélection de produits pour fixer les conduites et les tuyaux pendant l'installation et pour une fixation permanente est disponible dans la boutique en ligne HANSA‑FLEX. Colliers de tuyaux de série légère et lourde Colliers SRS modulaires de HANSA‑FLEX Colliers de tuyaux en acier et doubles colliers de tuyaux"],
            buttonName: "VERS LA BOUTIQUE EN LIGNE",
            image: fasteningTech
        },
        {
            title: "Pré-assemblage de tuyaux combinés",
            description: "En plus des lignes de tuyaux et de tuyaux, HANSA‑FLEX fabrique également des lignes de tuyaux combinées pour une large gamme d'applications en technologie des fluides. Les clients peuvent également obtenir des conseils complets sur ces produits auprès de spécialistes expérimentés. De plus, nos experts HANSA‑FLEX sont une source de précieux conseils sur la conception correcte et l'installation professionnelle. La gamme de services comprend:",
            listItems: ["Installation de géométries de ligne flexibles", "Conseils pour la planification des systèmes et des machines hydrauliques", "Traitement de haute qualité utilisant des procédures de soudage certifiées"],
            buttonName: "EN SAVOIR PLUS",
            image: HosePipeComb
        },
        {
            title: "Qualité optimale tout au long de la ligne",
            description: ["La production en série chez HANSA‑FLEX incarne la plus haute précision, des opérateurs expérimentés, des tests de qualité fiables et un service parfait tout au long de la production jusqu'à l'expédition. Les lignes de tuyaux et de tuyaux pré-assemblées spécifiques au client sont fabriquées dans un environnement de production à haute fiabilité de processus. Les méthodes de production de pointe de HANSA‑FLEX garantissent une disponibilité rapide et un respect maximal des délais de livraison."],
            buttonName: "EN SAVOIR PLUS",
            image: SeriesProd
        },
        {
            title: "Groupes de composants personnalisés",
            listItems: ["HANSA‑FLEX préassemble les tuyaux en groupes de composants pour répondre aux exigences spécifiques du client et les livre prêts à être installés. Les groupes de composants peuvent être, par exemple, diverses combinaisons de tuyaux droits, cintrés ou ramifiés ou impliquer la préinstallation de connexions, de joints de dilatation ou de lignes de tuyaux. Cela permet au client d'économiser du temps d'assemblage et de l'espace de stockage pour les composants individuels. Les experts de la succursale HANSA‑FLEX traitant le projet sont toujours disponibles pour des conseils spécialisés."],
            buttonName: "PRENEZ CONTACT",
            image: ComponentGroups
        },
    ];

    const tabTitles = [
        { title: "Technologie de fixation", eventKey: "Technologie de fixation" },
        { title: "Combinaison tuyau-tuyau", eventKey: "Combinaison tuyau-tuyau" },
        { title: "Production en série", eventKey: "Production en série" },
        { title: "Groupes de composants", eventKey: "Groupes de composants" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgProduct_Pipelines_Centerstage}
                    title={"VOTRE SPÉCIALISTE POUR TOUT CE QUI CONCERNE LES PIPELINES"}
                    paragraph={"La gamme de produits de canalisation HANSA‑FLEX comprend des joints de dilatation, des tuyaux coudés et des raccords spéciaux pour liquides, gaz et eau de refroidissement. Les technologies de fabrication de pointe de HANSA‑FLEX garantissent une précision absolue, la plus haute qualité et une production rapide pour répondre exactement aux spécifications du client. HANSA‑FLEX fixe de nouvelles normes en matière de technologie de connexion avec son propre système de formage de tuyaux innovant HF-Form, qui sera bientôt disponible.."}

                />
            </div>

            <div>
                <SectionHeader
                    title={"VOTRE SPÉCIALISTE POUR TOUT CE QUI CONCERNE LES PIPELINES"}
                    subtitle={"HANSA‑FLEX offre aux clients tout ce dont ils ont besoin pour équiper leurs machines et leurs systèmes de canalisations sûres et de haute qualité.."}
                />
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                            <ImageCard image={imgPipeline_imagecard1} learnMoreLink="/hydraulic-hoses" title="Tuyaux coudés fabriqués avec précision selon les exigences du client" desc="HANSA‑FLEX possède des décennies d'expérience dans le cintrage de tuyaux de précision en acier, acier inoxydable, cuivre et autres matériaux. Longueurs allant jusqu'à 6 000 mm et diamètres..." />
                        </Col>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                            <ImageCard image={imgPipeline_imagecard2} learnMoreLink="/metal-hoses" title="Traitement personnalisé des extrémités de tuyau et raccords spéciaux" desc="HANSA‑FLEX offre des options de connexion personnalisées pour chaque raccord de tuyau. Tous les types de processus de formage, tels que le moletage ou le laminage, en plus de la bague de coupe, du filetage..." />
                        </Col>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                            <ImageCard image={imgPipeline_imagecard3} learnMoreLink="/Industrial-hoses" title="Protégez les systèmes avec des joints de dilatation HANSA‑FLEX" desc="Les joints de dilatation HANSA‑FLEX en silicone, PTFE, NBR ou acier inoxydable protègent les machines et les équipements contre les dommages dus aux chocs, à l'expansion et aux vibrations..." />
                        </Col>

                    </Row>
                </Container>
            </div>

            <div>
                <SectionHeader
                    title={"BONNES RAISONS D'UTILISER LES PIPELINES DE HANSA‑FLEX"}
                    subtitle={"Ces points distinguent HANSA‑FLEX des autres en tant que spécialiste des pipelines de haute qualité."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"BONNES RAISONS D'UTILISER LES PIPELINES DE HANSA‑FLEX"}
                    subtitle={"Ces points distinguent HANSA‑FLEX des autres en tant que spécialiste des pipelines de haute qualité"}
                />
                <ImageText data={imgPipeline_imagetext1} />

                <ImageText data={imgPipeline_imagetext2} left={false} />

                <ImageText data={imgPipeline_imagetext3} />
            </div>

            <div>
                <SectionHeader
                    title={"TECHNOLOGIE DE L'AIR COMPRIMÉ DE HANSA‑FLEX DANS LA BOUTIQUE EN LIGNE"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"À L'AISE DANS DE NOMBREUX SECTEURS INDUSTRIELS"}
                    subtitle={"HANSA‑FLEX possède une connaissance approfondie de nombreux domaines industriels, que nous utilisons pour assurer de réels avantages concurrentiels à nos clients."}
                />
                <ImageText data={imgPipeline_imagetext4} />
            </div>

            <div>
                <SectionHeader
                    title={"PLUS DE PRODUITS DE HANSA‑FLEX"}
                />
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                            <ImageCard image={imagePipelineimagecard4} learnMoreLink="/hydraulic-hoses" title="Connectez en toute sécurité les composants hydrauliques avec des raccords de tuyaux" desc="Les raccords de tuyaux reposent sur un ajustement positif étanche pour créer une connexion sûre, même soumise à des pressions de travail élevées, des pics de pression et des vibrations...." />
                        </Col>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                            <ImageCard image={imagePipelineimagecard5} learnMoreLink="/metal-hoses" title="Brides pour applications à haute pression" desc="Les raccords de bride HANSA‑FLEX sont idéaux pour des connexions étanches sur des systèmes impliquant de grands diamètres, des pressions élevées et des situations d'installation restreintes..." />
                        </Col>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                            <ImageCard image={imagePipelineimagecard6} learnMoreLink="/Industrial-hoses" title="Tuyaux de haute qualité pour de nombreux domaines d'application" desc="Le choix parfait pour les connexions mobiles en technologie des fluides ou pour le transport de produits chimiques, de denrées alimentaires, d'air, d'eau et de milieux abrasifs...." />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )

}

export default Pipelines
