import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import imgPressure_Centerspread from '../../assets/Services/Pressure accumulator inspection/Pressure_Centerspread.jpg'
import ImageText from '../../Components/ImageText/ImageText';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgPressure_Imagetext_01 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagetext-01.jpg'
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../assets/mark.png'
import imgPressure_Imagetext_02 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagetext-02.jpg'
import imgPressure_Imagetext_03 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagetext-03.jpg'
import imgPressure_Imagetext_04 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagetext-04.jpg'
import imgPressure_Imagetext_05 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagetext-05.jpg'
import imgPressure_Imagetext_06 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagetext-06.jpg'
import imgPressure_Imagetext_07 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagetext-07.jpg'
import ImageCard from '../../Components/ImageCard/ImageCard';
import imgPressure_Imagecard_01 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagecard-01.jpg'
import imgPressure_Imagecard_02 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagecard-02.jpg'
import imgPressure_Imagecard_03 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagecard-03.jpg'
import { Container, Row, Col } from 'react-bootstrap';

const Pressureaccumulatorinspection = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

    
    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    const Pressure_Imagetext_01 = {
        image: imgPressure_Imagetext_01,
        title: 'Inspection des accumulateurs hydrauliques par des personnes officiellement autorisées',
        description: ["Chez HANSA-FLEX, un grand nombre d'employés à travers l'Allemagne sont qualifiés en tant que personnes compétentes pour les équipements sous pression. En tant qu'experts en fluides, ils possèdent également une vaste expérience avec les accumulateurs de pression, fournissent des conseils d'experts sur toutes les questions et sont toujours prêts à vous aider. HANSA-FLEX propose à ses clients les services suivants :"],
        listItems: ["Exécution des inspections récurrentes', 'Soutien dans la préparation des évaluations des risques', 'Documentation des résultats d'inspection conformément à la BetrSichV"],
    }


    const Pressure_Imagetext_02 = {
        image: imgPressure_Imagetext_02,
        title: 'Grands accumulateurs de pression avec une pression de fonctionnement supérieure à 1 bar.',
        description: ["Tous les réservoirs de stockage d'hydrogène avec un volume de gaz supérieur à 10 litres sont considérés comme des systèmes nécessitant une surveillance et sont soumis à des obligations d'inspection spéciales et à des inspections régulières conformément au règlement allemand sur la sécurité et la santé au travail (BetrSichV) lors de la mise en service. L'acceptation après l'installation ainsi que tous les tests périodiques sur ces accumulateurs de pression ne peuvent être effectués que par des experts d'un organisme de surveillance approuvé (ZÜS) ou par une entreprise mandatée par ce dernier, telle que le TÜV ou DEKRA. Les délais pour les inspections périodiques sont basés sur l'évaluation de la sécurité et doivent être déterminés au plus tard 6 mois après la mise en service et vérifiés par un ZÜS."],
    }
    const Pressure_Imagetext_03 = {
        image: imgPressure_Imagetext_03,
        title: 'Les accumulateurs à moyenne pression avec une pression de fonctionnement ne dépassant pas 1 bar.',
        description: ["Les accumulateurs de pression hydropneumatiques avec un volume de gaz compris entre 1 et 10 litres doivent être testés à la fois avant la mise en service et à des intervalles récurrents. Tous les tests doivent être effectués par une personne qualifiée pour les dangers liés à la pression (TRBS 1203 No. 3.2). L'exploitant détermine indépendamment les périodes de test sur la base des informations fournies par le fabricant, du mode de fonctionnement et de sa propre expérience. Les intervalles de test spécifiés dans la BetrSichV ne sont donnés qu'à titre indicatif."],
        
    }

    const Pressure_Imagetext_04 = {
        image: imgPressure_Imagetext_04,
        title: 'Les petits accumulateurs de pression avec une pression de fonctionnement ne dépassant pas 0,5 bar.',
        description: ["Les accumulateurs de pression avec un volume de gaz compris entre 0,1 litre et 1 litre sont classés comme des systèmes de pression ne nécessitant pas de surveillance et sont considérés comme des équipements de travail au sens de la BetrSichV. Les tests avant la mise en service ne sont pas obligatoires, mais l'exploitant doit néanmoins déterminer des mesures de protection appropriées conformément à l'évaluation des risques établie. Cela inclut également la réalisation d'une inspection périodique conformément à l'article 10 de la BetrSichV par une personne qualifiée pour les dangers liés à la pression conformément au TRBS 1203 No. 2"],
    }
    const Pressure_Imagetext_05 = {
        image: imgPressure_Imagetext_05,
        title: 'Inspection avant la première utilisation',
        description: ["Avant la mise en service d'un accumulateur de pression, en plus de la fonctionnalité technique, on vérifie si un marquage CE, des instructions d'utilisation, le numéro d'identification de l'organisme notifié et les équipements de sécurité nécessaires sont disponibles. Selon la catégorie de l'accumulateur, le test est effectué par différents organismes :"],
        listItems: ["Plus de 10 litres de volume de gaz : Organisme de surveillance approuvé (ZÜS) Jusqu'à 10 litres de volume de gaz : Personne qualifiée pour les risques liés à la pression selon TRBS 1203 No. 3.2 Moins de 1 litre de volume de gaz : Personne qualifiée selon TRBS 1203 No. 2"]
    }
    const Pressure_Imagetext_06 = {
        image: imgPressure_Imagetext_06,
        title: '',
        description: ['As part of the periodic inspections, it is ensured that the CE marking and operating instructions are available and that the equipment with safety function is still present, functional and correctly adjusted. In addition, it is checked whether the prerequisites for the next test interval are still correct. You should definitely have the following documents ready:'],
        listItems: ['Instruction manual and risk assessment','All documents of the manufacturer of the hydraulic accumulator','The operational documents of the commissioning test']
    }


    const Pressure_Imagetext_07 = {
        image: imgPressure_Imagetext_07,
        title: 'Inspections périodiques',
        description: [''],
        listItems: [" Conseils sur la sélection et la conception des accumulateurs de pression, Vente d'accumulateurs de pression, d'accessoires et de kits de réparation, Installation d'accumulateurs de pression, Maintenance et réparation par des personnes officiellement autorisées, Formation et séminaires sur l'entretien et le fonctionnement des accumulateurs de pression."]
    }



    const featuresData = [
        {
          title: "Personnel spécialisé qualifié",
          description: "Inspections effectuées par des personnes officiellement autorisées (TRBS 1203 No. 3.2 et No. 2).",
          image: imgMark
        },
        {
          title: "Aucun compromis sur la sécurité.",
          description: " Assurer le respect de toutes les exigences de la loi allemande sur la sécurité industrielle.",
          image: imgMark
        },
        {
          title: "Un soutien complet.",
          description: "Nous vous soutenons dans la préparation des évaluations des risques ainsi que dans l'élaboration de vos plans d'inspection et de test.",
          image: imgMark
        },
        {
          title: "Conseils d'experts.",
          description: "Nos experts vous conseillent sur le choix, la conception et le fonctionnement de votre système d'accumulateur de pression.",
          image: imgMark
        }
        // Add more objects for additional FeaturesCard data
      ];










    return (
        <div>

            <CenterStage
                imageSrc={imgPressure_Centerspread}
                title={"Les inspections des accumulateurs de pression sont obligatoires."}
                paragraph={"Les accumulateurs de pression font partie des systèmes qui doivent être surveillés par la loi et doivent donc être vérifiés régulièrement par du personnel spécialisé formé qui sont des personnes officiellement autorisées conformément à la loi allemande sur la sécurité industrielle (BetrSichV). De plus, une évaluation de sécurité (évaluation des risques) doit être préparée pour garantir le fonctionnement sécurisé des machines et des systèmes équipés d'accumulateurs hydrauliques intégrés."}
            />



            <div >
                <br />
                <ImageText data={Pressure_Imagetext_01} />
            </div>


            <div >
        <SectionHeader
          title={"Les services de HANSA‑FLEX offrent tous ces avantages."}
          subtitle={"Avec HANSA‑FLEX, les experts leaders en technologie des fluides, vous avez toujours un partenaire expérimenté en technologie des équipements sous pression à vos côtés."}
        />
      </div>


      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>


      <div >
        <SectionHeader
          title={"Classification des accumulateurs de pression"}
          subtitle={"La portée des inspections dépend de la catégorie de l'accumulateur de pression. Les accumulateurs hydrauliques sont divisés en systèmes nécessitant une surveillance et en systèmes ne nécessitant pas de surveillance conformément à la Directive européenne sur les équipements sous pression."}
        />
      </div>
      <div >
        <br />
        <ImageText data={Pressure_Imagetext_02} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Pressure_Imagetext_03} />
      </div>

      <div >
        <br />
        <ImageText data={Pressure_Imagetext_04} left={false} />
      </div>

      <div >
        <SectionHeader
          title={"Conseils d'experts"}
          subtitle={"Une vessie défectueuse indique qu'il y avait trop ou trop peu de pression de gaz dans le système hydraulique. Les spécialistes de HANSA‑FLEX sont toujours heureux de vous conseiller."}
        />
      </div>

{/* TODO: Quote_NAME desc */}

      <div >
        <SectionHeader
          title={"Les intervalles d'inspection pour les systèmes d'accumulateurs de pression"}
          subtitle={"Les intervalles d'inspection pour les systèmes qui doivent être surveillés sont définis dans la loi allemande sur la sécurité industrielle (BetrSichV). Le paramètre déterminant pour cette classification est la pression de fonctionnement autorisée protégée par l'équipement de sécurité."}
        />
      </div>

      {/* redbanner: carrousel */}



      <div >
        <SectionHeader
          title={"Processus et étendue des inspections des accumulateurs de pression"}
          subtitle={""}
        />
      </div>


      <div >
        <br />
        <ImageText data={Pressure_Imagetext_05} />
      </div>

      <div >
        <br />
        <ImageText data={Pressure_Imagetext_06} left={false} />
      </div>

      <div >
        <SectionHeader
          title={"Formation et séminaires sur les accumulateurs de pression dans les systèmes hydrauliques"}
          subtitle={"En collaboration avec l'Académie internationale de l'hydraulique (IHA), HANSA-FLEX organise des séminaires pour les installateurs et le personnel de maintenance des accumulateurs de pression. Des experts transmettent leurs connaissances spécialisées sur le fonctionnement sécurisé, le remplissage correct et la réparation des accumulateurs de pression."}
        />
      </div>

      [4/2 10:39 AM] 90000692
{/* TODO: imagetext_Carrousel */}


<div >
        <SectionHeader
          title={"Service sans souci complet des spécialistes des fluides"}
          subtitle={"HANSA-FLEX est votre partenaire compétent pour toutes les tâches nécessaires à l'installation et au fonctionnement des accumulateurs de pression."}
        />
      </div>

<div >
        <br />
        <ImageText data={Pressure_Imagetext_07} left={false} />
      </div>

      <div >
        <SectionHeader
          title={"N'hésitez pas à partager ! Je suis là pour aider avec tous les sujets qui vous intéressent."}
          subtitle={""}
        />
      </div>


      <Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgPressure_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Réparation et révision des systèmes d'accumulateurs de pression" desc="Le service industriel de HANSA-FLEX vous soutient dans tous les travaux d'entretien et de maintenance sur vos systèmes d'accumulateurs de pression, y compris le remplissage en azote ou leur remplacement." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgPressure_Imagecard_02} learnMoreLink="/metal-hoses" title="Les accumulateurs de pression remplis de gaz de HANSA-FLEX" desc="La gamme HANSA-FLEX comprend de nombreux accumulateurs à membrane et à vessie de haute qualité de différents designs provenant de fabricants renommés." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgPressure_Imagecard_03} learnMoreLink="/hydraulic-hoses" title="Prévenir l'usure" desc="La propreté du fluide hydraulique affecte la durée de vie des composants. Le nettoyage de l'huile et les analyses régulières vous aident à éviter les pannes du système." />
                    </Col>


                </Row>
            </Container>



















        </div>
    )
}

export default Pressureaccumulatorinspection