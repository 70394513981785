import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgKitting_Centerspread from '../../../assets/Services/Logistics solutions/Kitting/Kitting_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgKitting_Imagetext_01 from '../../../assets/Services/Logistics solutions/Kitting/Kitting_Imagetext_01.jpg'
import imgKitting_Imagetext_02 from '../../../assets/Services/Logistics solutions/Kitting/Kitting_Imagetext_02.jpg'
import imgKitting_Imagetext_03 from '../../../assets/Services/Logistics solutions/Kitting/Kitting_Imagetext_03.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import { Container, Row, Col } from 'react-bootstrap';
import imgKitting_Imagecard_01 from '../../../assets/Services/Logistics solutions/Kitting/Kitting_Imagecard_01.jpg'
import imgKitting_Imagecard_02 from '../../../assets/Services/Logistics solutions/Kitting/Kitting_Imagecard_02.jpg'



const Kitting = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
      }, []);


    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    const Kitting_Imagetext_01 = {
        image: imgKitting_Imagetext_01,
        title: 'Optimiser votre inventaire',
        description: ["Nous créons et gérons pour vous une liste d'articles standardisée. Cette liste constitue la base d'une gestion efficace de l'entrepôt. La liste d'articles définit l'utilisation continue du matériel et permet une utilisation efficace de vos zones de stockage. Nous identifions tous les groupes d'articles dont vous avez besoin pour assurer un flux de production régulier. En tant que fournisseur unique, nous pouvons vous fournir de manière fiable le matériel standard défini. Cela vous permet de consolider votre liste de fournisseurs et d'économiser des frais d'administration lors de la commande de marchandises. Avec HANSA-FLEX, vous pouvez choisir des intervalles de livraison flexibles en fonction de vos besoins."],
    }

    const Kitting_Imagetext_02 = {
        image: imgKitting_Imagetext_02,
        title: "Stockage adéquat des composants",
        description: ["Les composants doivent être stockés de manière à ce que leur qualité et leur durée de conservation ne soient pas affectées par la poussière, les liquides ou les fortes variations de température. Le risque de dommages mécaniques causés par des objets tombant ou roulant sur les composants doit être réduit au minimum. Nos collaborateurs hautement compétents sont toujours disponibles pour vous fournir des conseils et une assistance pratique afin de vous aider grâce à leurs vastes connaissances et compétences éprouvées dans la pratique. Il s'agit par exemple de positionner systématiquement les systèmes de stockage et les marchandises de manière à réduire les distances à parcourir par vos employés et le temps qu'ils consacrent à la recherche des articles souhaités. Ainsi, plus rien ne vient entraver l'accès aux composants et aux machines."],
    }


    const Kitting_Imagetext_03 = {
        image: imgKitting_Imagetext_03,
        title: "Solutions pour un débit élevé de marchandises",
        description: ["Les grandes entreprises ont besoin d'un plus grand nombre de pièces et l'automatisation des processus de commande et d'entreposage devient de plus en plus importante. Il arrive un moment où l'enregistrement écrit des besoins en matériaux et la saisie manuelle des commandes prennent trop de temps pour que l'ensemble du processus reste efficace. Consultez votre conseiller clientèle HANSA-FLEX habituel pour connaître les possibilités de numérisation de vos processus de commande, par exemple avec une solution de scanner, ou d'automatisation avec un système Kanban. Un autre moyen d'optimiser un débit de marchandises élevé serait d'adopter une approche en kit pour votre stock de pièces."],
    }




    const featuresData = [
        {
            title: "Réduction des coûts d'entreposage",
            description: "Avec HANSA-FLEX, vous pouvez réduire vos propres coûts d'entreposage.",
            image: imgMark
        },
        {
            title: "Voies d'approvisionnement courtes",
            description: "HANSA-FLEX possède de nombreuses succursales et est donc toujours assez proche pour assurer une livraison rapide.",
            image: imgMark
        },
        {
            title: "Conseils personnalisés",
            description: "Vous disposez d'un interlocuteur permanent sur place qui connaît bien la situation locale.",
            image: imgMark
        },
        {
            title: "Flexibilité maximale",
            description: "Avec HANSA-FLEX, vous recevez des kits sur mesure, même à court terme.",
            image: imgMark
        }
        // Add more objects for additional FeaturesCard data
    ];




    return (
        <div>
            <CenterStage
                imageSrc={imgKitting_Centerspread}
                title={"LE KITTING OPTIMISE LA LOGISTIQUE ET L'INSTALLATION"}
                paragraph={"En tant que partenaire système, HANSA-FLEX fournit des kits prêts à l'emploi contenant des composants provenant de fournisseurs tiers ainsi que de HANSA-FLEX. Les lignes de production sont assurées d'un approvisionnement ininterrompu en composants. Nos clients gagnent du temps, augmentent leur rendement et réduisent le taux de défaillance."}
            />


            <SectionHeader
                title={"KITS D'INSTALLATION CONFIGURÉS SELON LES SOUHAITS DU CLIENT"}
                subtitle={"HANSA-FLEX configure et fournit des kits précisément adaptés à vos exigences et à vos souhaits. Les avantages pour vous sont nombreux et variés."}
            />

            <div >
                <br />
                <ImageText data={Kitting_Imagetext_01} left={false} />
            </div>

            <div >
                <br />
                <ImageText data={Kitting_Imagetext_02} />
            </div>

            <div >
                <br />
                <ImageText data={Kitting_Imagetext_03} left={false} />
            </div>


            <SectionHeader
                title={"BONNES RAISONS D'ADOPTER LE KITTING DE HANSA-FLEX"}
                subtitle={"Les kits hydrauliques et pneumatiques de HANSA-FLEX comprenant des composants préassemblés offrent des avantages intéressants pour les fabricants."}
            />


            <div>
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <SectionHeader
                title={"UN SAVOIR-FAIRE COMPLET, DE LA CONCEPTION DU PROJET À LA GESTION DE L'ENTREPÔT"}
                subtitle={""}
            />

{/* TODO: redbanner_carrousel */}


<SectionHeader
                title={"CES THÈMES PEUVENT ÉGALEMENT VOUS INTÉRESSER"}
                subtitle={""}
            />




<Container className="custom-container">
        <Row>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgKitting_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Fabrication en usine directement sur le site" desc="Faites appel au savoir-faire de HANSA-FLEX et demandez à nos techniciens expérimentés de mettre à votre disposition les composants nécessaires en juste-à-temps." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                <ImageCard image={imgKitting_Imagecard_02} learnMoreLink="/metal-hoses" title="Livraison Kanban de HANSA-FLEX" desc="Avec la livraison Kanban basée sur le principe de la traction de HANSA-FLEX, vous bénéficiez d'une livraison juste à temps, précisément quand vous en avez besoin, y compris directement dans votre entreprise." />
            </Col>
            
        </Row>
    </Container>









        </div>
    )
}

export default Kitting