import React, { useEffect } from 'react';
import CenterStage from '../../../../Components/CenterStage/CenterStage';
import HFTab from '../../../../Components/HfTabs/HfTabs';
import imgHoseManagement from '../../../../assets/Services/HoseManagement/hosemanagement.jpg';
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import Promoter from '../../../../Components/Promoter/Promoter';
import imgTabletMobile from '../../../../assets/HosesAndHoseLines/HosesAndHoseLine/tabletMobile.png'
import ImageText from '../../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../../assets/mark.png';

import imgFulfillRequirement from '../../../../assets/Services/HoseManagement/fulfil_all_requirements.jpg'
import imgPlanInspection from '../../../../assets/Services/HoseManagement/plan_inspection.jpg'
import imgHoseCoding from '../../../../assets/Services/HoseManagement/x_code_hose_coding.jpg'
import imgHoseSoftware from '../../../../assets/Services/HoseManagement/hose_management_software.jpg'
import imgCertifiedTechnicians from '../../../../assets/Services/HoseManagement/certified_service_technicians.jpg'
import imgCoding from '../../../../assets/Services/HoseManagement/coding.jpg'
import imgInstalation from '../../../../assets/Services/HoseManagement/installation.jpg'
import imgServicingPlanning from '../../../../assets/Services/HoseManagement/servicingPlanning.jpg'
import imgDocumentation from '../../../../assets/Services/HoseManagement/documentation.jpg'
import imgHoseFixedCost from '../../../../assets/Services/HoseManagement/Hose_management_services_fixed_cose.jpg'
import imgProceduralRegulation from '../../../../assets/Services/HoseManagement/ProceduralRegulation.jpg'
import imgLegalRequirement from '../../../../assets/Services/HoseManagement/LegalRequirement.jpg'
import imginspection from '../../../../assets/Services/HoseManagement/inspection.jpg'

//PAGE COMPLETE

const HoseManagement = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    // Données ImageText
    const hoseManagement1 = {
        image: imgFulfillRequirement,
        title: 'Satisfaire de manière fiable toutes les exigences légales',
        description: "L'employeur est personnellement responsable : La manipulation précise des flexibles hydrauliques est définie dans la directive européenne sur l'utilisation des équipements de travail 2009/104/CE, la loi allemande sur la sécurité industrielle (BetrSichV) et d'autres lois. L'employeur est tenu d'évaluer les risques potentiels des flexibles hydrauliques et de prendre des mesures d'atténuation appropriées, de réaliser des inspections régulières et de tenir des registres. Le gestionnaire de flexibles HANSA-FLEX aide dans ces tâches."
    }

    const hoseManagement2 = {
        image: imgPlanInspection,
        title: 'Planifier efficacement et réaliser professionnellement les inspections',
        description: "Le marquage unique, la saisie numérique, la gestion efficace et la documentation conforme à la loi de tous les flexibles en cours d'utilisation signifient que toutes les données sont disponibles et à quelques clics seulement. Avec ces informations, la maintenance peut être planifiée efficacement, exécutée au bon moment et enregistrée. HANSA-FLEX vous soutient dans la planification de vos processus et leur mise en œuvre dans le cadre de vos activités quotidiennes normales - de la fourniture de mesures de service individuelles à des forfaits complets sans soucis."
    }

    const hoseManagement3 = {
        image: imgHoseFixedCost,
        title: 'Services de gestion de flexibles à prix fixe',
        description: "Nos quatre forfaits de service : Basique, Plus, Premium et Pro contiennent divers services pour votre gestion de flexibles. Les forfaits individuels se basent les uns sur les autres et garantissent une sécurité maximale en matière de planification pour un prix journalier fixe. Utilisez notre forfait de gestion de flexibles pour simplifier et améliorer la maintenance de vos systèmes hydrauliques. La gamme de forfaits va de l'utilisation gratuite du logiciel X-CODE Manager (Basique), en passant par le paquet de données et la numérisation (Plus), jusqu'au forfait complet sans soucis, comprenant inspection et documentation conformes à la BetrSichV (Premium). Nous incluons également un service supplémentaire (Pro).",
        buttonName: "VOIR LES FORFAITS DE SERVICE",
        buttonLink: "/forfaits-de-service"
    }

    // Données pour le carousel de cartes de fonctionnalités
    const featuresData = [
        {
            title: "Sécurité accrue",
            description: "Réduit les accidents et assure la sécurité sur le lieu de travail pour les utilisateurs d'équipements.",
            image: imgMark
        },
        {
            title: "Conformité légale améliorée",
            description: "Respectez les intervalles de maintenance et enregistrez les inspections conformément à la loi.",
            image: imgMark
        },
        {
            title: "Réduction des temps d'arrêt",
            description: "Évite les temps d'arrêt machine imprévus et réduit les coûts en prévenant l'usure des composants.",
            image: imgMark
        },
        {
            title: "Plus de transparence",
            description: "Accédez à toutes les informations sur vos flexibles, machines et dates de maintenance à tout moment.",
            image: imgMark
        },
    ];

    // Données pour HFTab 1
    const imageTextData = [
        {
            title: "Fabrication, codage et profil numérique",
            description: "Nous pré-assemblons la ligne de flexible spécifiquement pour votre application et lui attribuons le code de flexible X-CODE inimitable. Le X-CODE forme la base de la gestion de flexible et de la création de profils numériques avec toutes les informations pertinentes. Les flexibles existants sans X-CODE peuvent être codés ultérieurement par notre Service Industriel, afin qu'ils puissent être inclus dans le système de gestion de flexible.",
            image: imgCoding
        },
        {
            title: "Installation et allocation numérique aux machines",
            description: "Le Service Industriel de HANSA-FLEX effectue l'installation professionnelle de vos tuyaux pour vous. Le profil numérique de chaque ligne de flexible est attribué dans le logiciel de gestion de flexible X-CODE Manager directement à la machine ou au composant respectif. Vous pouvez représenter toutes vos machines et équipements dans le X-CODE Manager et structurer les informations selon vos propres critères, afin d'obtenir la meilleure vue d'ensemble de tous les flexibles liés à vos emplacements, machines et composants.",
            image: imgInstalation
        },
        {
            title: "Analyse des risques et classification",
            description: "Malheureusement, nous ne pouvons pas réaliser votre évaluation des risques pour vous, mais nous pouvons vous aider considérablement à la préparer. Une analyse des risques constitue la base de l'évaluation du risque de chaque ligne de flexible par l'opérateur de la machine. L'évaluation des risques détermine la classification, le plan de maintenance approprié et les délais pour les inspections visuelles légalement prescrites.",
            image: imgServicingPlanning
        },
        {
            title: "Inspection visuelle et documentation conforme à la loi",
            description: "Des inspections visuelles prescrites par la loi doivent être effectuées sur tous les tuyaux par un inspecteur officiellement autorisé et documentées conformément à la loi. Une inspection qui n'a pas été correctement documentée est considérée comme n'ayant pas été effectuée. Nous pouvons effectuer toutes les inspections et la documentation pour vous. Une forme de documentation est un dossier d'inspection numérique.",
            image: imgDocumentation
        },
    ];

    const tabTitles = [
        { title: "Codage", eventKey: "Codage" },
        { title: "Installation", eventKey: "Installation" },
        { title: "Planification de la maintenance", eventKey: "Planification de la maintenance" },
        { title: "Documentation", eventKey: "Documentation" },
    ];

    // Données pour HFTab 2
    const imageTextData2 = [
        {
            title: "Exigences légales européennes et nationales",
            description: "Une série de législations européennes et nationales régissent la manipulation sûre et conforme à la loi des lignes de flexible. Par exemple, la loi allemande sur la sécurité industrielle (BetrSichV) oblige les employeurs à réaliser des évaluations des risques de leurs équipements de travail, à effectuer des inspections régulières et à enregistrer les résultats.",
            image: imgLegalRequirement
        },
        {
            title: "Règlements de procédure pour les employeurs",
            description: "Les réglementations nationales en matière de santé et de sécurité au travail, ainsi que les informations techniques, règles et normes, clarifient ces obligations et formulent des règles de procédure pratiques pour les employeurs. Celles-ci incluent l'utilisation correcte, l'inspection systématique et le remplacement planifié des tuyaux.",
            image: imgProceduralRegulation
        },
        {
            title: "Inspection selon la BetrSichV et la DGUV R",
            description: "Seules les personnes officiellement autorisées pour l'inspection peuvent effectuer les inspections des tuyaux (§14 BetrSichV). Le type et la portée des inspections sont définis par l'évaluation des risques, plus d'informations peuvent être trouvées dans la règle DGUV 113-020. En enregistrant l'inspection et ses résultats, l'employeur remplit l'obligation légale de documenter l'inspection (§6 ArbSchG et §14 BetrSichV).",
            image: imginspection
        },
    ];

    const tabTitles2 = [
        { title: "Exigences légales", eventKey: "Exigences légales" },
        { title: "Règlements de procédure", eventKey: "Règlements de procédure" },
        { title: "Inspections", eventKey: "Inspections" },
    ];



    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgHoseManagement}
                    title={"MAINTENEZ VOTRE SYSTÈME HYDRAULIQUE HORS DE LA ZONE ROUGE."}
                    paragraph={"Les tuyaux flexibles hydrauliques sont des équipements de travail soumis à de fortes contraintes. Ils doivent être régulièrement inspectés et remplacés au bon moment pour éviter les risques pour les personnes et les machines. La gestion professionnelle des flexibles fournit tout ce qui est nécessaire pour garantir que ces inspections sont effectuées en temps voulu, dans les délais légalement prescrits, et sont enregistrées."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"LA GESTION DES FLEXIBLES REND L'ENTRETIEN PLUS FACILE"}
                    subtitle={"Utilisez vos flexibles hydrauliques aussi longtemps qu'il est sûr et raisonnable. Avec la gestion des flexibles, vous êtes toujours conscient des dates d'inspection légales pour votre équipement de travail et pouvez planifier et effectuer vos futures inspections et maintenance au bon moment."}
                />
                <ImageText
                    data={hoseManagement1} />
                <ImageText
                    data={hoseManagement2} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"GESTION DES FLEXIBLES À 360°, DE LA FABRICATION À L'ÉLIMINATION"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <Promoter
                    title={"COMMANDEZ DES ENSEMBLES DE FLEXIBLES IDENTIQUES ENCORE PLUS RAPIDEMENT DÈS MAINTENANT"}
                    checklistItems={["Le nouveau configurateur de ligne de flexible de HANSA-FLEX permet de commander encore plus rapidement et plus facilement une ligne de flexible identique en seulement deux clics via X-CODE."]}
                    btnLabel1={"Configurer maintenant"}
                    btnLabel2={"En savoir plus"}
                    btnLabel1Link={"https://shop.hansa-flex.fr/fr_FR/"}
                    btnLabel2Link={"/config-ligne-flex"}
                    imageUrl={imgTabletMobile}
                />
            </div>

            <div>
                <SectionHeader
                    title={"SÛR ET ÉCONOMIQUE"}
                    subtitle={"La gestion des flexibles est rentable. Nos services vous aident à exploiter vos systèmes hydrauliques mobiles et stationnaires en toute sécurité et conformément à la loi. Il protège les personnes et les machines et économise du temps et de l'argent. Faites confiance à la gestion professionnelle des flexibles de HANSA-FLEX et bénéficiez de :"}
                />

                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <ImageText data={hoseManagement3} />
            </div>

            <div>
                <SectionHeader
                    title={"LÉGISLATION RÉGISSANT LA MANIPULATION DES FLEXIBLES"}
                    subtitle={"Les services de gestion des flexibles de HANSA-FLEX sont entièrement basés sur les exigences légales actuelles de la législation européenne et nationale. Les services vous fournissent - en tant qu'employeur, responsable de la maintenance ou opérateur - une sécurité constante !"}
                />
                <HFTab imageTextData={imageTextData2} tabTitles={tabTitles2} />
            </div>

            <div>
                <SectionHeader
                    title={"CODAGE, LOGICIEL ET TECHNICIENS D'ASSISTANCE SUR SITE"}
                    subtitle={"Les services de gestion des flexibles et les forfaits de service de HANSA-FLEX reposent sur trois éléments."}
                />

                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHoseCoding} learnMoreLink={"/x-code-hose-identification-system"}
                                title="Codage des flexibles X-CODE"
                                desc={"Utilisez un codage largement testé avec X-CODE pour identifier de manière unique chaque ligne de flexible."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHoseSoftware} learnMoreLink={"/x-code-manager"}
                                title="Logiciel de gestion de flexible X-CODE Manager"
                                desc={"Utilisez le X-CODE Manager pour obtenir la meilleure vue d'ensemble de vos flexibles, machines et dates de maintenance."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgCertifiedTechnicians} learnMoreLink={"/installation-et-maintenance"}
                                title="Les techniciens de service certifiés installent et inspectent sur site"
                                desc={"Laissez les experts du Service Industriel de HANSA-FLEX effectuer toutes les tâches liées à l'installation, à la maintenance et à l'entretien de vos flexibles."} /></Col>
                        </Row>

                    </Container>
                </div>

            </div>

        </div>
    )

}

export default HoseManagement