import React from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import imgMark from '../../../assets/mark.png'


import Stage from '../../../assets/Services/RepairAndOverHaul/PressureAccu/Stage.jpg'
import Quick from '../../../assets/Services/RepairAndOverHaul/PressureAccu/Quick.jpg'
import RepairKit from '../../../assets/Services/RepairAndOverHaul/PressureAccu/RepairKit.jpg'
import AllRound from '../../../assets/Services/RepairAndOverHaul/PressureAccu/AllRound.jpg'
import AuthorisedPerson from '../../../assets/Services/RepairAndOverHaul/PressureAccu/AuthorisedPerson.jpg'
import Replacement from '../../../assets/Services/RepairAndOverHaul/PressureAccu/Replacement.jpg'
import ChangeOfLocation from '../../../assets/Services/RepairAndOverHaul/PressureAccu/ChangeOfLocation.jpg'
import EquipmentInStorage from '../../../assets/Services/RepairAndOverHaul/PressureAccu/EquipmentInStorage.jpg'
import MoreTips from '../../../assets/Services/RepairAndOverHaul/PressureAccu/MoreTips.jpg'
import Inspection from '../../../assets/Services/RepairAndOverHaul/PressureAccu/Inspection.jpg'
import Gas from '../../../assets/Services/RepairAndOverHaul/PressureAccu/Gas.jpg'
import PreventWear from '../../../assets/Services/RepairAndOverHaul/PressureAccu/PreventWear.jpg'


const PressureAccumulatorsAndAssociatedSystems = () => {


  const QuicklyBack = {
    image: Quick,
    title: 'Rapidement de retour en état de fonctionnement',
    description: "Les experts en fluides sont qualifiés en tant que personnes officiellement autorisées pour les équipements sous pression. Ils sont capables de vous soutenir dans tous les travaux liés au remplacement des vessies défectueuses, des membranes et des joints.",
    listItems: ["Soutien dans la préparation des évaluations des risques. ","Remplacement et réparation d'accumulateurs de pression défectueux",
      "Remplissage d'azote.","Vérification de la pression du gaz et de la soupape d'huile sur la base.","Dépannage après une défaillance soudaine. "]

  }

  const RepairKits = {
    image: RepairKit,
    title: "Excellent choix de machines et d'équipements",
    description: "Le centre de réparation de vérins HANSA-FLEX dispose d'une gamme de machines et d'équipements modernes pour les réparations de vérins. Le sablage et les revêtements de peinture spéciaux font partie de nos services standard",
    listItems: [ 
      "Tour conventionnels et à commande numérique (CNC)",
      "Perçages de montage pour l'installation et le retrait verticaux des vérins",
      "Banc d'essai conforme à la norme DIN 10100 pour des tests fonctionnels complets",
      "Machine de fraisage pour la révision des tubes de vérin endommagés" ]

  }

  const featuresData = [
    {
      title: "Personnel spécialisé qualifié ",
      description: "Tous les travaux sur les accumulateurs hydrauliques sont effectués par des personnes officiellement autorisées pour les équipements sous pression ",
      image: imgMark
    },
    {
      title: "Aucun compromis sur la sécurité. ",
      description: "Toutes les exigences de la loi allemande sur la sécurité industrielle et la santé (BetrSichV) sont respectées. ",
      image: imgMark
    },
    {
      title: " Soutien complet",
      description: "Nous effectuons toutes les tâches telles que le chargement en gaz ou le remplacement de la vessie de l'accumulateur  ",
      image: imgMark
    },
    {
      title: " Conseils d'experts.",
      description: " Nous vous accompagnons dans la préparation des évaluations des risques ainsi que dans l'élaboration de vos plans d'inspection et de test.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];

  const data1 = {
    image: AllRound,
    description: "Nos experts possèdent de nombreuses années d'expérience, un savoir-faire pratique étendu et sont parfaitement familiers avec les exigences spécifiques des différents types de vérins ",
    listItems: [ "Vérins à piston et télescopiques",
      "Vérins de presse, de grue et de moulage",
      "Vérins de lutte contre l'incendie, de génie hydraulique, de mesure et de fermeture de gaz",
      "Vérins hydrauliques et pneumatiques mobiles",
      "Vérins standards et spéciaux" ]
  }

  return (
    <div>

      <div>
        <CenterStage
          imageSrc={Stage}
          title={"ENTRETIEN ET RÉPARATION D'ACCUMULATEURS DE PRESSION."}
          paragraph={"Le fonctionnement sûr et fiable des systèmes d'accumulateurs de pression dépend d'un entretien régulier, notamment des vérifications du niveau de remplissage et de la pression de la charge de gaz. L'entretien et la réparation des accumulateurs de pression nécessitent des connaissances spécifiques. Par conséquent, seules les personnes officiellement autorisées pour les équipements sous pression conformément à la directive européenne sur les équipements sous pression et à la loi allemande sur la sécurité industrielle et la santé sont autorisées à effectuer ce travail."}
        />

      </div>

      <div>
        <SectionHeader
          title={"SERVICES ET PRODUITS POUR L'ENTRETIEN DES ACCUMULATEURS HYDRAULIQUES "}
          subtitle={"Nous recommandons de toujours avoir un accumulateur hydraulique identique en stock comme remplacement. En agissant ainsi, vous pouvez éviter de longs arrêts. Vous pouvez trouver une large sélection d'accumulateurs de pression dans la boutique en ligne de HANSA-FLEX. D'autres types d'accumulateurs peuvent être su "}
        />
        <ImageText data={QuicklyBack} left={false} />
        <ImageText data={RepairKits} />
      </div>


      <SectionHeader
        title={"Les équipes de service de HANSA-FLEX offrent tous ces avantages. "}
        subtitle={"Avec HANSA-FLEX, les experts leaders en technologie des fluides, vous avez toujours un partenaire expérimenté à vos côtés. "}
      />
      <FeatureCardCarousel featuresData={featuresData} />


      <div>
        <SectionHeader
          title={"RÉPARATION DE VÉRINS DE TOUS TYPES ET DE TOUTES FABRICATIONS. "}
          paragraph={" Nos techniciens hydrauliques expérimentés réparent plus de 6 000 vérins hydrauliques de toutes tailles, fabricants et types chaque année."}
        />
        <ImageText
          data={data1} />
      </div>

      <div>
        <SectionHeader
          title={'QUELQUES POINTS À CONSIDÉRER LORS DU REMPLACEMENT DES ACCUMULATEURS HYDRAULIQUES '}
          subtitle={''}
        />

      </div>

      {/* TODO: Client review */}

      <div>
        <SectionHeader
          title={'FORMATIONS ET SÉMINAIRES SUR LES ACCUMULATEURS DE PRESSION DANS LES SYSTÈMES HYDRAULIQUES '}
          subtitle={"En coopération avec l'Académie Internationale d'Hydraulique (IHA), HANSA‑FLEX organise des séminaires destinés aux ingénieurs d'installation et au personnel de maintenance des accumulateurs de pression. Des experts transmettent leur expertise en matière de fonctionnement sûr, de remplissage approprié et de réparation des accumulateurs de pression."}
        />
      </div>

      {/* TODO:HFTab */}

      <div>
        <SectionHeader
          title={"CES SUJETS POURRAIENT AUSSI VOUS INTÉRESSER "}
        />
      </div>
      <div>
        <Container className='custom-container'>
          <Row>
            <Col sm={12} md={6} lg={4} ><ImageCard image={Inspection}
              title=" Inspection de votre accumulateur de pression par une personne officiellement autorisée"
              desc={" Les experts en fluides de HANSA‑FLEX sont des personnes officiellement autorisées pour les équipements sous pression. Ils effectuent des inspections visuelles externes et fournissent des rapports d'inspection"}
              buttonName="READ MORE" /></Col>

            <Col sm={12} md={6} lg={4} ><ImageCard image={Gas}
              title="Accumulateur de pression rempli de gaz de HANSA-FLEX "
              desc={"La gamme HANSA-FLEX comprend de nombreux accumulateurs à membrane et à vessie de haute qualité pour diverses conceptions, provenant de fabricants renommés. "}
              buttonName="READ MORE" /></Col>

            <Col sm={12} md={6} lg={4} ><ImageCard image={PreventWear}
              title="Prévenir l'usure "
              desc={"La propreté du fluide hydraulique affecte la durée de vie des composants. Le nettoyage de l'huile et des analyses régulières vous aident à éviter les pannes du système.  "}
              buttonName="READ MORE" /></Col>
            {/* // TODO: Button Link  */}
          </Row>
        </Container>

      </div>




    </div>
  )
}
export default PressureAccumulatorsAndAssociatedSystems


