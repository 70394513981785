import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import Stage from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/Stage.jpg'
import Intelligent from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/Intelligent.jpg'
import ManagePlant from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/ManagePlant.jpg'
import InstallationOfPipe from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/InstallationOfPipe.jpg'
import OneOff from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/OneOff.jpg'
import PerfectHose from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/PerfectHose.jpg'
import EfficientHose from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/EfficientHose.jpg'
import Connected from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/Connected.jpg'
import Logistics from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/Logistics.jpg'
import Innovation from '../../../assets/Services/AssemblyAndInstallation/AssemblyAndInstallation/Innovation.jpg'

const AssemblyAndInstallation = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const featuresData = [
        {
            title: "Service industriel",
            description: "Nos techniciens de service vous soutiennent dans tous vos travaux de maintenance, d'entretien et d'assemblage.",
            image: imgMark
        },
        {
            title: "Service de fluide",
            description: "Les experts en fluides de HANSA-FLEX utilisent leur savoir-faire pour prendre en charge l'entretien de votre huile hydraulique.",
            image: imgMark
        },
        {
            title: "Service d'urgence",
            description: " Notre équipe d'intervention rapide mobile est toujours prête à vous aider, 24h/24 et 7j/7, 365 jours par an.",
            image: imgMark
        }
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"SERVICES HYDRAULIQUES POUR L'INDUSTRIE"}
                    paragraph={" Nos techniciens de service, parfaitement formés, réalisent des travaux d'assemblage et d'installation sur des systèmes hydrauliques. Que le travail concerne une unité fixe ou mobile, la conception du système ou sa mise en œuvre pratique : vous pouvez bénéficier de la gamme complète de produits et services de notre Service Industriel pour toutes les tâches liées à votre système hydraulique."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"LE SERVICE COMPLET POUR VOS SYSTÈMES HYDRAULIQUES"}
                    subtitle={"Les spécialistes expérimentés en hydraulique du Service Industriel de HANSA-FLEX sont vos partenaires à guichet unique et toujours prêts à vous aider et à vous assister dans tout, de la planification et la conception, à l'exploitation et la maintenance, jusqu'à la rénovation."}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Intelligent} learnMoreLink={"/modernisation-and-optimisation"}
                                title="Rénovation intelligente"
                                desc={"La rénovation ne nécessite pas toujours l'achat d'un tout nouveau système ou d'une installation. Souvent, des mesures de rénovation et d'amélioration intelligentes peuvent entraîner une..."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={ManagePlant} learnMoreLink={"/plant-project-planning-and-management"}
                                title="Gérer la planification de projet d'installation avec expertise"
                                desc={"Pour garantir que tous les composants hydrauliques fonctionnent efficacement ensemble dans des installations ou des systèmes complexes, cela nécessite un grand savoir-faire et une planification minutieuse. Bénéficiez de..."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={InstallationOfPipe} learnMoreLink={"/installation-of-pipe-and-hoseline-systems"}
                                title="Installation de systèmes de tuyauterie et de flexibles"
                                desc={"  Nos techniciens de service et équipes d'installation installent tous les tuyaux et flexibles sur des machines, des systèmes ou des unités hydrauliques mobiles. Des prototypes aux grands projets.  "} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"AUTRES SERVICES HYDRAULIQUES HANSA-FLEX "}
                    subtitle={"Nos véhicules de service mobiles et nos techniciens sont toujours disponibles pour vous assister, par exemple, en fournissant des services hydrauliques industriels, des analyses d'huile, des soins d'huile ou un service d'urgence 24 heures sur 24. "}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"FLEXIBLES ET TUYAUTERIES DE HANSA-FLEX "}
                    subtitle={"En tant que fournisseur de systèmes, HANSA-FLEX peut fournir des lignes de flexibles hydrauliques pour tout, des applications mobiles dans la construction, l'agriculture et la sylviculture jusqu'aux systèmes hydrauliques stationnaires dans les industries chimique, pharmaceutique et alimentaire. "}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={OneOff} learnMoreLink={"/pipelines"}
                                title="Tuyauteries fabriquées en série ou unitairement"
                                desc={" Les tuyaux de précision pliés de HANSA-FLEX sont disponibles dans différents matériaux, y compris l'acier, l'acier inoxydable, le cuivre ou l'aluminium, et avec différents traitements de surface."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={PerfectHose} learnMoreLink={"/hoses-and-hose-lines"}
                                title="Le flexible parfait pour chaque application"
                                desc={"La large gamme de types de flexibles hydrauliques HANSA-FLEX, y compris les flexibles tressés et en plastique, couvre tous les domaines d'utilisation. "} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={EfficientHose} learnMoreLink={"/hose-management"}
                                title="Gestion efficace des flexibles"
                                desc={" Inspection des lignes de flexibles, remplacement programmé et documentation claire : Optimisez votre gestion des flexibles avec X-CODE et la plateforme My.HANSA-FLEX.   "} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"INSPECTIONS FIABLES"}
                    subtitle={"Nos techniciens de service ont le statut de personnes autorisées officielles pour effectuer des inspections DGUV, des inspections de flexibles industriels, des inspections d'accumulateurs de pression, des inspections de levage et de plateformes de chargement."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS POURRAIENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Connected} learnMoreLink={"/hydraulic-connection-technology"}
                                title="Toujours bien connecté "
                                desc={"Les éléments de connexion hydrauliques, tels que les adaptateurs, les raccords et les connecteurs filetés, sont un moyen essentiel de relier les composants dans les systèmes hydrauliques"} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={Logistics} learnMoreLink={"/Logisticssolutions"}
                                title="Support logistique"
                                desc={"Nous proposons des services logistiques tels que la gestion d'entrepôt, le kanban et le kitting. Ils sont conçus pour vous aider à optimiser vos processus."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={Innovation} learnMoreLink={"/Engineering"}
                                title="Innovation et fabrication"
                                desc={"Que vous ayez besoin de développement, de conception ou de fabrication de prototypes : En tant que partenaire système, HANSA-FLEX vous offre une gamme complète de services d'ingénierie, le tout à partir d'un..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )
}

export default AssemblyAndInstallation