import React, { useEffect } from 'react';
import CertificateCard from '../../Components/CertificateCard/CertificateCard';
import imgCert1 from '../../assets/Certificates/2022_12_06_ISO_9001_Declaration_conformite_sites_FST_FGA_FVT_valid_2025_11_21-01.png';
import imgCert2 from '../../assets/Certificates/ISO_9001_valid_2025_11_21_ENG.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook


function Certificates() {

    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);


    useEffect(() => {
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 320, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 300, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
        });
    }, []);

    // data-aos={index % 2 === 0 ? (isMobile ? "fade-up" : "fade-right") : (isMobile ? "fade-up" : "fade-left")}

    return (
        <div data-aos={isMobile ? "fade-up" : "fade-right"}>
            <div data-aos={isMobile ? "fade-up" : "fade-right"}>
                <CertificateCard title={"Declaration of Conformity"} description={"La déclaration de conformité de Hansa Flex AG pour les succursales françaises atteste du respect de normes de qualité rigoureuses en matière de production, de commerce, de conseil technique et de services de technologie des fluides. Cette certification, conforme à la norme EN ISO 9001:2015, est délivrée par TUV Austria Cert GmbH, validant notre engagement à fournir l'excellence dans tous les aspects de nos opérations."} imageUrl={imgCert1} />
            </div>
            <div data-aos={isMobile ? "fade-up" : "fade-left"}>
                <CertificateCard title={"Certification EN ISO 9001:2015"} description={"HANSA-FLEX France SARL, située à Entzheim, France, est certifiée par TUV AUSTRIA CERT pour son adhésion aux normes du système de gestion EN ISO 9001:2015. Cette certification englobe la fabrication, le commerce, le conseil technique et les services liés à la technologie des fluides."} imageUrl={imgCert2} />
            </div>
        </div>
    );
}

export default Certificates;