import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgScanner_Centerspread from '../../../assets/Services/Digital Services/Scanner Solutions/Scanner_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import ImageText from '../../../Components/ImageText/ImageText'
import imgScanner_Imagetext_01 from '../../../assets/Services/Digital Services/Scanner Solutions/Scanner_Imagetext_01.jpg'
import imgScanner_Imagetext_02 from '../../../assets/Services/Digital Services/Scanner Solutions/Scanner_Imagetext_02.jpg'
import imgScanner_Imagetext_03 from '../../../assets/Services/Digital Services/Scanner Solutions/Scanner_Imagetext_03.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import { Container, Row, Col } from 'react-bootstrap';
import imgScanner_Imagecard_01 from '../../../assets/Services/Digital Services/Scanner Solutions/Scanner_Imagecard_01.jpg'
import imgScanner_Imagecard_02 from '../../../assets/Services/Digital Services/Scanner Solutions/Scanner_Imagecard_02.jpg'
import imgScanner_Imagecard_03 from '../../../assets/Services/Digital Services/Scanner Solutions/Scanner_Imagecard_03.jpg'


const Scannersolutions = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
      }, []);




    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    const Scanner_Imagetext_01 = {
        image: imgScanner_Imagetext_01,
        title: "Saisir les exigences du produit avec le scanner et l'application HANSA-FLEX Easy Order",
        listItems: ["HANSA-FLEX propose l'application Scan-to-Order en combinaison avec des scanners Zebra robustes et de haute qualité. Ceux-ci ont la taille d'un téléphone portable et sont donc extrêmement portables. Le client reçoit un scanner préconfiguré avec l'application Scan-to-Order déjà installée. Vous ne devez pas vous préoccuper d'autres détails techniques. Les scanners vous permettent de saisir vos besoins en produits en scannant le code scannable sur le conteneur ou à l'emplacement du produit souhaité sur l'étagère. L'application transmet vos besoins en produits directement à HANSA-FLEX."],
    }

    const Scanner_Imagetext_02 = {
        image: imgScanner_Imagetext_02,
        title: "Contrôle optimal des livraisons Kanban grâce aux scanners et à l'application Kanban ",
        listItems: ["Avec le Kanban, HANSA-FLEX garantit la disponibilité des marchandises en temps réel, directement à l'endroit de votre atelier de production où elles sont nécessaires. Ce système repose sur la méthode des conteneurs multiples. Chaque conteneur ou bac est marqué d'un code scannable indiquant, entre autres, le numéro d'identification et le nombre physique d'articles dans le conteneur lorsqu'il est plein. Lorsque le premier conteneur est vide, les installateurs saisissent le code sur le conteneur vide à l'aide du scanner manuel. L'application Kanban signale immédiatement le besoin correspondant au système HANSA-FLEX. L'étagère ou le conteneur kanban est alors rempli en synchronisation avec la consommation réelle."],
    }


    const Scanner_Imagetext_03 = {
        image: imgScanner_Imagetext_03,
        title: "Déterminer l'état des tuyaux avec l'application X-CODE Manager",
        listItems: ["L'application X-CODE Manager peut être installée très simplement sur votre appareil mobile. Elle se synchronise automatiquement avec les données de votre application web X-CODE Manager. Vous capturez le X-CODE individuel sur l'un de vos tuyaux à l'aide de l'appareil photo de votre appareil mobile. Après avoir scanné le X-CODE, l'application vous montre les détails de la conduite spécifique, tels que la date d'installation et les dates d'inspection à venir. Si nécessaire, vous pouvez commander une inspection par HANSA-FLEX ou un remplacement de la conduite flexible concernée directement à partir de l'application. Pour pouvoir utiliser l'application X-CODE Manager, vous avez besoin de la licence Flexible ou Expert."],
    }

    const featuresData = [
        {
            title: "Mobile et rapide",
            description: "Vous pouvez emporter nos scanners partout où vous allez et capturer des données en quelques secondes.",
            image: imgMark
        },
        {
            title: "Tout est clair",
            description: "Nos applications vous permettent d'avoir à tout moment une vue d'ensemble de vos besoins.",
            image: imgMark
        },
        {
            title: "Toujours correctement capturé",
            description: "Les scanners vous aident à éviter les erreurs de saisie et de transfert de données.",
            image: imgMark
        },
        {
            title: "Vos descriptions matérielles habituelles",
            description: "Affichez vos conteneurs de marchandises en entrepôt avec vos propres descriptions d'articles.",
            image: imgMark
        }
        // Add more objects for additional FeaturesCard data
    ];











    return (
        <div><CenterStage
            imageSrc={imgScanner_Centerspread}
            title={"DÉTERMINER VOS BESOINS DE MANIÈRE SIMPLE ET NUMÉRIQUE PAR LE BIAIS D'UN SCANNER "}
            paragraph={"Oui, nous scannons ! Les solutions de scanner de HANSA-FLEX vous permettent de déterminer plus rapidement vos besoins en marchandises pour la technique des fluides dans l'entrepôt ou sur la ligne de production et de commander confortablement à l'aide de l'application intégrée au scanner. HANSA-FLEX propose également des solutions de scanner adaptées pour les demandes d'état et les commandes de tuyaux directement sur le lieu d'utilisation des installations et des machines."}
        />


            <SectionHeader
                title={"LA COMBINAISON D'UN SCANNER ET D'APPLICATIONS PEUT ÊTRE UTILISÉE POUR LA SAISIE DE DONNÉES ET LA PASSATION DE COMMANDES"}
                subtitle={" Les solutions de scanner HANSA-FLEX combinent la technologie de scanner appropriée avec l'application HANSA-FLEX correspondante. Cela permet de gagner du temps, d'être fiable et de transmettre immédiatement les exigences du produit à HANSA-FLEX."}
            />

            <div >
                <br />
                <ImageText data={Scanner_Imagetext_01} left={false} /> <br /><br />
            </div>

            <div >
                <br />
                <ImageText data={Scanner_Imagetext_02} /> <br /><br />
            </div>

            <div >
                <br />
                <ImageText data={Scanner_Imagetext_03} left={false} /> <br /><br />
            </div>

            <SectionHeader
                title={"CAPTURER ET COMMANDER NUMÉRIQUEMENT AVEC LES SOLUTIONS DE SCANNER HANSA-FLEX"}
                subtitle={"Il est facile et rapide de déterminer vos besoins en biens et services à l'aide de solutions de numérisation. Le transfert direct des données évite les erreurs et réduit les coûts de traitement."}
            />

            <div>
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <SectionHeader
                title={"LES ÉTAPES POUR TROUVER VOTRE SOLUTION SCANNER HANSA-FLEX"}
                subtitle={"En tant que partenaire système, HANSA-FLEX propose des solutions de scanner dans le cadre d'un concept global cohérent afin d'assurer l'efficacité de vos processus de gestion des marchandises. Nos experts vous accompagnent de la conception à la mise en œuvre de votre solution scanner personnalisée."}
            />


            {/* TODO: redbanner_carrousal */}

            <SectionHeader
                title={"SERVICES NUMÉRIQUES SUPPLÉMENTAIRES DE HANSA-FLEX"}
                subtitle={""}
            />



            <Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgScanner_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Gestion des tuyaux avec X-CODE Manager" desc="X-CODE Manager rend la gestion des tuyaux tout simplement plus facile car vous avez toutes les informations pertinentes, telles que les intervalles d'inspection, directement à portée de main." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgScanner_Imagecard_02} learnMoreLink="/metal-hoses" title="Commandez rapidement et facilement des produits de technologie des fluides dans la boutique en ligne HANSA-FLE" desc="Explorez la boutique en ligne HANSA-FLEX avec plus de 80 000 articles dans le domaine de la technologie des fluides et commandez les produits que vous souhaitez en quelques clics de souris." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgScanner_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Votre ligne directe numérique vers HANSA-FLEX" desc="L'interface numérique avec HANSA-FLEX via EDI ou BMEcat permet un traitement efficace et sans erreur des achats." />
                    </Col>

                </Row>
            </Container>


        </div>
    )
}

export default Scannersolutions