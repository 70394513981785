import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import ImageText from '../../Components/ImageText/ImageText'
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import HFTabs from '../../Components/HfTabs/HfTabs'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../Components/ImageCard/ImageCard'

import imgStage from '../../assets/Products/Filtration/Stage.jpg'
import HighQualityFilters from '../../assets/Products/Filtration/HighQualityFilters.jpg'
import SuctionFilters from '../../assets/Products/Filtration/SuctionFilters.jpg'
import PressureFilters from '../../assets/Products/Filtration/PressureFilters.jpg'
import ReturnFlowFilters from '../../assets/Products/Filtration/ReturnFlowFilters.jpg'
import SpinOnFilters from '../../assets/Products/Filtration/SpinOnFilters.jpg'
import ItAllComes from '../../assets/Products/Filtration/ItAllComes.jpg'
import FilterSystem from '../../assets/Products/Filtration/FilterSystem.jpg'
import DevicesAndInstruments from '../../assets/Products/Filtration/DevicesAndInstruments.jpg'
import HydraulicFluid from '../../assets/Products/Filtration/HydraulicFluid.jpg'
import OilCare from '../../assets/Products/Filtration/OilCare.jpg'
import ProtectHydraulicSystem from '../../assets/Products/Filtration/ProtectHydraulicSystem.jpg'
import OilAnalysis from '../../assets/Products/Filtration/OilAnalysis.jpg'
import OilCleaning from '../../assets/Products/Filtration/OilCleaning.jpg'
import ContinuousMonitorOil from '../../assets/Products/Filtration/ContinuousMonitorOil.jpg'

const Filtration = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const Filtration1 = {
        image: HighQualityFilters,
        title: 'Filtres et éléments filtrants de haute qualité',
        description: 'Les filtres hydrauliques HANSA‑FLEX réduisent la contamination par particules solides dans les circuits de fluide et protègent les composants sensibles. Avec les filtres et systèmes de filtration de HANSA‑FLEX, vous pouvez être sûr que votre huile répond au niveau de propreté requis et conserve ses propriétés fluides optimales.',
        listItems: ["Filtres, éléments filtrants et boîtiers de filtre", "Adsorbeur, filtres de pression, de retour, d'aspiration et à visser", "Filtres à ligne, filtres remplaçables et accessoires appropriés"],
    }

    const Filtration2 = {
        image: ItAllComes,
        description: "En tant qu'experts en fluides, nous vous proposons une gamme complète d'éléments filtrants tels que des filtres d'aspiration, de pression, de retour et à visser pour une utilisation dans l'ensemble de vos systèmes de fluides. Nous vous soutenons également dans la conception et le dimensionnement de vos filtres. Nous tenons compte des facteurs importants suivants :",
        listItems: ["Débit volumique et viscosité du fluide", "Propreté de l'huile désirée et quantité de contamination", "Durée de vie du filtre requise, taille et type de système", "Milieu filtrant"],
    }

    const Filtration3 = {
        image: FilterSystem,
        title: 'Systèmes de filtration pour le nettoyage des fluides',
        description: "Les exigences en matière de qualité et de propreté de l'huile dans les systèmes hydrauliques ne cessent de croître. Avec les systèmes de filtration HANSA‑FLEX, vous bénéficiez d'un régime de soins efficace pour votre huile hydraulique, ce qui contribue également au bon fonctionnement sans erreur de vos systèmes hydrauliques.",
        listItems: ["Systèmes de filtres à flux partiel à l'achat ou à la location", "Équipement de service d'huile et accessoires tels que des supports d'équipement pour le transport", "Pompes de remplissage mobiles pour nettoyer l'huile lors du remplissage des réservoirs", "Éléments filtrants remplaçables"],
    }

    const Filtration4 = {
        image: DevicesAndInstruments,
        title: "Dispositifs et instruments pour l'analyse et l'échantillonnage de l'huile",
        description: "En utilisant des capteurs, des dispositifs de test rapide et des ensembles d'analyse d'huile, vous pouvez mettre en œuvre un régime complet de surveillance de l'état des fluides et effectuer d'autres tests.",
        listItems: ["Dispositifs d'analyse d'huile avec accessoires appropriés", "Capteurs de particules, compteurs de particules, dispositifs de filtration sous vide", "Ensembles d'analyse d'huile pour huiles minérales, bio et de transmission", "Ensembles d'échantillonnage de fluide et flacons d'échantillonnage en verre ou en plastique", "Pompe à main sous vide"],
    }

    const Filtration5 = {
        image: HydraulicFluid,
        title: "Fluides hydrauliques et agents de liaison d'huile",
        description: "Nous pouvons vous fournir des huiles hydrauliques et moteur pour les véhicules automobiles, industriels, de construction, agricoles et forestiers. Des agents de liaison d'huile hautement absorbants sont également disponibles pour nettoyer les surfaces où de l'huile a goutté ou fui.",
        listItems: ["Huile de transmission et hydraulique", "Huile moteur à base d'huile minérale", "Huile hydraulique à base d'huiles végétales ou minérales et huile synthétique", "Agents de liaison d'huile (granulés, tapis, fibres naturelles)", "Détachants pour taches d'huile"]
    }

    const Filtration6 = {
        image: OilCare,
        title: "Entretien de l'huile facilité avec les pompes de remplissage mobiles de HANSA‑FLEX",
        description: "Ces pompes de remplissage certifiées CE avec filtration à deux étages nettoient l'huile fraîche ou ancienne lors du remplissage ou de la vidange des réservoirs d'huile. Le moteur haute performance permet un cycle de service continu allant jusqu'à 3 heures.",
        listItems: ["Débit volumique de 15 l/min, moteur 230V AC de qualité de marque", "Bac de rétention d'huile intégré et indicateur visuel de colmatage", "Température de fonctionnement admissible de -20 °C à +110 °C"],
        buttonName: "EN SAVOIR PLUS"
    }

    const Filtration7 = {
        image: ProtectHydraulicSystem,
        title: "Protégez les systèmes hydrauliques contre l'eau de condensation avec des filtres adsorbants",
        description: "Les filtres adsorbants sont boulonnés sur le système et offrent une protection complète dans les environnements avec des humidités relatives élevées, de grandes fluctuations de température ou lorsque des huiles biodégradables sont utilisées.",
        listItems: ["Convient pour une utilisation intérieure et extérieure, pour les réservoirs, les fûts et les conteneurs IBC", "Le gel de silice change de couleur pour montrer le niveau de saturation en eau du filtre", "Un élément filtrant intégré de 3 µm contribue également à l'huile propre"],
        buttonName: "VERS LES FILTRES ADSORBANTS"
    }


    // Données HfTab
    const imageTextData = [
        {
            title: "Filtre d'aspiration utilisé comme filtre de protection",
            listItems: ["Installation : immergé dans le réservoir", "Température de fonctionnement admissible : -25 °C à 110 °C", "Remarque : pas adapté pour la filtration en profondeur"],
            buttonName: "ACHETER MAINTENANT",
            image: SuctionFilters
        },
        {
            title: "Filtre de pression utilisé comme filtre de travail ou de protection",
            description: "Les filtres de pression sont utilisés avec des pressions système élevées. Ils protègent le système et les composants sensibles tels que les vannes proportionnelles et les servovannes contre les contaminants.",
            listItems: ["Conception compacte", "Boîtier de filtre durable et optimisé pour l'écoulement", "Pour des pressions basses, moyennes et élevées (jusqu'à 60, 320 et 450 bar)"],
            buttonName: "ACHETER MAINTENANT",
            image: PressureFilters
        },
        {
            title: "Échangeurs de chaleur pour une régulation efficace de la température",
            description: "Les filtres de retour sont installés dans la ligne de retour en amont ou sur le réservoir hydraulique. Ils filtrent l'huile avant qu'elle ne retourne au réservoir et capturent les particules de saleté créées dans le système. Cela garantit que aucun contaminant du système ne pénètre dans le réservoir et ne circule dans le système. Des clapets de dérivation sont fréquemment intégrés dans les systèmes hydrauliques pour les protéger et protéger le reste des filtres contre les pressions de blocage excessives dans la ligne de retour.",
            buttonName: "ACHETER MAINTENANT",
            image: ReturnFlowFilters
        },
        {
            title: "Filtre à visser utilisé comme filtre d'aspiration ou de retour",
            description: "Les filtres à visser se composent d'une tête de filtre en aluminium moulé sous pression et d'une cartouche filtrante en métal vissable remplaçable.",
            listItems: ["Remplacement facile de l'élément filtrant", "Conception compacte et construction simple", "Excellents résultats coût-efficacité"],
            buttonName: "ACHETER MAINTENANT",
            image: SpinOnFilters
        },
    ];

    const tabTitles = [
        { title: "Filtres d'aspiration", eventKey: "Filtres d'aspiration" },
        { title: "Filtres de pression", eventKey: "Filtres de pression" },
        { title: "Filtres de retour", eventKey: "Filtres de retour" },
        { title: "Filtres à visser", eventKey: "Filtres à visser" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"TECHNOLOGIE DE FILTRATION DE HANSA‑FLEX"}
                    paragraph={"Une filtration efficace est fondamentale pour la fonctionnalité complète et la longue durée de vie des systèmes hydrauliques très sensibles. Avec la technologie de filtration HANSA‑FLEX, vous pouvez être sûr d'avoir de l'huile propre et un fonctionnement sans problème de vos machines et systèmes. Dans la boutique en ligne HANSA‑FLEX, vous trouverez des éléments et systèmes de filtration ainsi que d'autres produits associés tels que des ensembles d'analyse d'huile et des compteurs de particules pour un régime de gestion de fluide hautement efficace."}
                />
            </div>

            <div>
                <ImageText
                    data={Filtration1} />
            </div>

            <div>
                <SectionHeader
                    title={"LA SOLUTION DE FILTRATION PARFAITE POUR TOUTES LES APPLICATIONS HYDRAULIQUES"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"TOUT REPOSE SUR UNE CONCEPTION ET UN DIMENSIONNEMENT CORRECTS"}
                    subtitle={"Nos experts connaissent l'importance d'une conception et d'un dimensionnement corrects pour obtenir les meilleures performances de filtration."}
                />
                <ImageText
                    data={Filtration2} />
            </div>

            <div>
                <SectionHeader
                    title={"FILTRATION HAUTE PERFORMANCE"}
                    subtitle={"En plus des filtres haute performance, le portefeuille de produits HANSA‑FLEX répertorie d'autres produits pour des soins de fluide efficaces, tels que des systèmes de filtration, des ensembles d'analyse d'huile, des compteurs de particules et des agents de liaison d'huile."}
                />
                <ImageText
                    data={Filtration3} />
                <ImageText
                    data={Filtration4} left={false} />
                <ImageText
                    data={Filtration5} />
            </div>

            <div>
                <SectionHeader
                    title={"PRÉVENIR 70 % DE TOUTES LES PANNES DE SYSTÈMES AVEC UNE GESTION DES FLUIDES"}
                    subtitle={"Confiez les soins de l'huile aux mains responsables de nos spécialistes des fluides expérimentés HANSA‑FLEX. Nous vous soutenons dans toutes vos activités de gestion des fluides telles que le nettoyage des fluides ou en effectuant des analyses d'huile efficaces pour résoudre les problèmes dans les systèmes hydrauliques."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"SOLUTIONS HANSA‑FLEX POUR UNE FILTRATION D'HUILE DURABLE"}
                    subtitle={"Notre savoir-faire sur tous les aspects de la technologie des fluides se reflète dans notre historique de développement de produits. Voici quelques détails sur deux de ces produits pour le filtrage des fluides."}
                />
                <ImageText
                    data={Filtration6} />
                <ImageText
                    data={Filtration7} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"GESTION DES FLUIDES À 360° PAR LES SPÉCIALISTES DES FLUIDES HANSA‑FLEX"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={OilAnalysis}
                                title="L'analyse d'huile donne au système hydraulique un examen approfondi"
                                desc={"Notre service fluides effectue des analyses d'huile directement sur site ou ultérieurement en laboratoire. Vous recevez un rapport résumé avec des suggestions d'optimisation basées sur le"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={OilCleaning}
                                title="Le nettoyage de l'huile prolonge la durée de vie et prévient l'usure"
                                desc={"Les experts en fluides de HANSA‑FLEX effectuent un nettoyage d'huile professionnel pour atteindre le niveau de propreté désiré et installer la technologie de filtrage nécessaire."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={ContinuousMonitorOil}
                                title="Surveillez en continu l'état de l'huile avec des capteurs"
                                desc={"Gardez un œil sur vos fluides hydrauliques avec des capteurs de condition d'huile. Les anomalies sont détectées rapidement et les problèmes sont résolus à temps."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )

}


export default Filtration