import React, { useEffect } from 'react';
import ImageText from '../../Components/ImageText/ImageText';
import CenterStage from '../../Components/CenterStage/CenterStage';
import img1962 from '../../assets/History/1962.jpg'
import img1965 from '../../assets/History/1965.jpg'
import img1969 from '../../assets/History/1969.jpg'
import imghistory from '../../assets/History/imgHistory.jpg'
import img1972 from '../../assets/History 1970s/img1972.jpg'
import img1974 from '../../assets/History 1970s/img1974.jpg'
import img1975 from '../../assets/History 1970s/img1975.jpg'
import img1977 from '../../assets/History 1970s/img1977.jpg'
import ShowcaseCarousel from '../../Components/ShowcaseCarousel/ShowcaseCarousel';
import img2022 from '../../assets/History 2020s/img2022.jpg'
import img2010 from '../../assets/History 2020s/img2010.jpg'
import img2012 from '../../assets/History 2020s/img2012.jpg'

function GroupHistory() {

  const isMobile = window.innerWidth <= 768;


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const historyimg1962 = {
    image: img1962,
    title: 'Les débuts 1962-1989',
    description: "Au début des années 1960, l'Allemagne était confrontée à des défis pour trouver des composants hydrauliques. Joachim Armerding a saisi l'opportunité et a commencé à assembler des flexibles hydrauliques dans son garage. En les livrant personnellement aux clients, il a posé les bases de HANSA-FLEX. En 1988, avec le partenariat de Günter Buschmann et la participation du fils du fondateur, l'entreprise s'est étendue à 21 succursales à l'échelle nationale."
  };

  const historyimg1965 = {
    image: img1965,
    title: 'Croissance forte et internationalisation 1989-2009',
    description: "Pendant l'ère de la réunification allemande et de la chute du rideau de fer, HANSA-FLEX a connu une croissance rapide. Il a établi sa première succursale étrangère à Elst, aux Pays-Bas, en 1989, suivi d'expansions à travers l'Europe. Sous une nouvelle direction en 1995, il s'est développé à l'échelle mondiale, avec des succursales en Turquie, en Amérique, en Afrique et en Asie d'ici 2009."
  };

  const historyimg1969 = {
    image: img1969,
    title: "À l'ère de la numérisation - de 2009 à aujourd'hui",
    description: "Au milieu de la numérisation mondiale, HANSA-FLEX renforce son investissement dans l'infrastructure et les services numériques. L'application HANSA-FLEX, la boutique en ligne et le portail client My.HANSA-FLEX offrent une valeur substantielle. De nouvelles succursales internationales émergent dans des pays comme l'Australie, le Royaume-Uni et l'Inde. En 2019, l'initiative de numérisation PHOENIX est lancée, et d'ici 2020, HANSA-FLEX AG compte plus de 150 000 clients dans le monde."
  };

  const historyimg2010 = {
    image: img2010,
    title: '2010',
    description: "La GmbH devient HANSA-FLEX AG. Les actions restent entre les mains des familles fondatrices. Thomas Armerding devient président du conseil d'administration, avec Uwe Buschmann comme vice-président."
  };

  const historyimg2012 = {
    image: img2012,
    title: '2012',
    description: "HANSA-FLEX ouvre sa propre succursale de formation près de Kassel. Toute la succursale est gérée par des stagiaires de HANSA-FLEX AG."
  };

  const historyimg2022 = {
    image: img2022,
    title: '2022',
    description: "Le vice-président du conseil d'administration, Uwe Buschmann, part à la retraite bien méritée après 36 ans dans l'entreprise. Florian Wiedemeyer est nommé au conseil d'administration en tant que directeur financier."
  };

  const carouselItems = [
    {
      imgSrc: img1972,
      title: '1972',
      description: "La rénovation du premier siège social est inaugurée à Brême-Mahndorf. La même année, la troisième succursale ouvre ses portes à Elze, en Basse-Saxe."
    },
    {
      imgSrc: img1974,
      title: '1974',
      description: "Avec l'établissement de la quatrième succursale à Duisbourg, le nom Joachim Armerding Industriebedarf n'est plus approprié. L'entreprise est renommée."
    },
    {
      imgSrc: img1975,
      title: '1975',
      description: "Le premier logo d'entreprise uniforme est introduit et utilisé jusqu'en 1989, mettant en vedette un tuyau avec un raccord standard."
    },
    {
      imgSrc: img1977,
      title: '1977',
      description: "réalise un chiffre d'affaires annuel de plus de dix millions de marks allemands pour la première fois."
    }
  ];

  return (
    <div>
      <div>
        <CenterStage
          imageSrc={imghistory}
          title={"UNE FOCALE SUR LES CLIENTS ET LA FLEXIBILITÉ DEPUIS 1962"}
          paragraph={"L'histoire de HANSA-FLEX a commencé il y a six décennies dans un petit garage à Achim, près de Brême. En 1962, l'homme d'affaires Joachim Armerding commence à fabriquer et à vendre des flexibles hydrauliques, posant les bases de l'actuelle HANSA-FLEX AG. L'entreprise connaît beaucoup de croissance en Allemagne au cours des années suivantes avant de devenir internationale en 1989. Aujourd'hui, HANSA-FLEX est une entreprise familiale mondiale avec des filiales étrangères sur les cinq continents."}
        />
      </div>

      <div className='div-gray'>
        <div>
          <br />
          <ImageText data={historyimg1962} left={false} /> <br /><br />
        </div>

        <div>
          <br />
          <ImageText data={historyimg1965} /> <br /><br />
        </div>

        <div>
          <br />
          <ImageText data={historyimg1969} left={false} /> <br /><br />
        </div>
      </div>

      <div>
        <ShowcaseCarousel carouselItems={carouselItems} isExploreButton={false} descDisplay='block' carouselType={2} />
      </div>

      <div className='div-gray'>
        <div>
          <br />
          <ImageText data={historyimg2010} /> <br /><br />
        </div>

        <div>
          <br />
          <ImageText data={historyimg2012} left={false} /> <br /><br />
        </div>

        <div>
          <br />
          <ImageText data={historyimg2022} /> <br /><br />
        </div>
      </div>
    </div>
  );

}

export default GroupHistory;