import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../../Components/ImageText/ImageText'
import imgMark from '../../../../assets/mark.png';
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../../Components/HfTabs/HfTabs';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import { Col, Container, Row } from 'react-bootstrap';

import imgStage from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/Stage.jpg'
import imgComprehensiveSupport from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/ComprehensiveSupport.jpg'
import imgRightAccessories from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/RightAccessories.jpg'
import imgPurchasePipeline from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/PurchasePipeline.jpg'
import imgShutOffValve from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/ShutOffValve.jpg'
import imgPressureCOntrolValve from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/PressureCOntrolValve.jpg'
import imgDirectionalControlValve from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/DirectionalControlValve.jpg'
import imgChangeOverValve from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/ChangeOverValve.jpg'
import imgCompactvalve from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/Compactvalve.jpg'
import imgCetopValve from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/CetopValve.jpg'
import imgMoreEfficiency from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/MoreEfficiency.jpg'



const PipelineInstallationValve = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Faire défiler vers le haut lors du montage du composant
    }, []);

    const PipelineInstallationValve1 = {
        image: imgComprehensiveSupport,
        title: 'Support complet de la conception à la production',
        description: "Nos spécialistes en technologie des fluides compétents et expérimentés sont ravis de vous aider et de vous conseiller dans le choix de la vanne d'installation de pipeline la plus adaptée aux fonctions requises et à l'usage prévu. Nous disposons de vannes adaptées à l'utilisation avec des fluides à base d'esters ou des mélanges eau-glycol ne répondant pas aux exigences pour les huiles minérales hydrauliques de la norme DIN 51524. En plus du vaste programme de produits standard HANSA‑FLEX, nous pouvons travailler en étroite collaboration avec nos partenaires pour produire des groupes de composants spécifiques aux besoins des clients.",
    }

    const PipelineInstallationValve2 = {
        image: imgRightAccessories,
        title: "Les bons accessoires pour vos vannes d'installation de pipeline",
        description: "La boutique en ligne HANSA‑FLEX contient des accessoires adaptés à nos vannes d'installation de pipeline.Ils ont été conçus pour permettre une installation rapide et un fonctionnement sûr.",
        listItems: ["Prises électriques et bobines de solénoïde", "Vis de réglage, joint, ensemble de boulons et de vis³", "Actionneurs et écrous tels que les contre-écrous en acier"],
        buttonName: "VERS LES ACCESSOIRES"
    }

    const PipelineInstallationValve3 = {
        image: imgPurchasePipeline,
        description: "Notre gamme de produits comprend des pompes à piston radial et des pompes à palettes pour des scénarios d'utilisation spéciaux.Elles sont capables de créer des pressions allant jusqu'à 500 bars par exemple.",
        listItems: ["Revêtement de surface : brunissage, phosphatation, peinture", "Matériaux : fonte, pièces internes en acier ou en aluminium", "Vannes répondant à toutes les normes en vigueur", "Fonctions de commutation : électrique, hydraulique"],
    }

    // Données du carrousel de cartes de fonctionnalités
    const featuresData = [
        {
            title: "Design compact",
            description: "Les vannes d'installation de pipeline sont connues pour leur taille compacte et leur faible poids.",
            image: imgMark
        },
        {
            title: "Facile à installer",
            description: "Leur conception simple rend les vannes d'installation de pipeline faciles à installer.",
            image: imgMark
        },
        {
            title: "Solution efficace",
            description: "Parfait pour contrôler les petits et grands débits dans les circuits hydrauliques.",
            image: imgMark
        },
        {
            title: "Produits de haute qualité",
            description: "La haute qualité de ces vannes garantit qu'elles fonctionnent de manière fiable pendant toute leur durée de vie utile",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données de carte de fonctionnalités supplémentaires
    ];

    // Données HfTab
    const imageTextData = [
        {
            title: "Vannes pour couper le flux",
            description: "Les vannes d'installation de pipeline avec des fonctions d'arrêt sont conçues comme des vannes à siège avec des cônes ou des billes. Elles arrêtent efficacement le flux à l'aide d'un ressort interne.",
            listItems: ["Clapets anti-retour", "Clapets anti-retour à simple et double pilotage", "Clapets de choc", "Clapets navettes"],
            buttonName: "TOUS LES RÉSERVOIRS HYDRAULIQUES",
            image: imgShutOffValve
        },
        {
            title: "Vannes pour réguler la pression du système",
            description: "Les vannes de contrôle de pression de différents types régulent ou limitent la pression dans les systèmes principaux ou secondaires d'un système hydraulique. Elles peuvent également être utilisées pour effectuer des processus de commutation dépendants de la pression du signal.",
            listItems: ["Clapets de décharge et de séquence de pression", "Clapets de maintien de charge", "Clapets de régulation de débit", "Clapets d'étranglement et clapets d'étranglement anti-retour"],
            image: imgPressureCOntrolValve
        },
        {
            title: "Vannes pour contrôler le débit volumétrique",
            description: "Les vannes de contrôle directionnelles sont commandées électriquement, pneumatiquement ou hydrauliquement et dirigent le flux dans des directions spécifiques. Les ports de vanne se connectent ou se déconnectent des actionneurs et contrôlent leurs mouvements.",
            listItems: ["Vanne de commande directionnelle actionnée par solénoïde 3/2 voies (vanne de dérivation)", "Vanne de commande directionnelle actionnée par solénoïde 6/2 voies (vanne de dérivation)", "Vannes de commande directionnelle manuelle monobloc (jusqu'à 7 sections)"],
            image: imgDirectionalControlValve
        },
        {
            title: "Contrôle manuel du débit",
            description: "Contrairement aux vannes de commande directionnelle actionnées par solénoïde, le contrôle du débit à l'aide de déflexions de débit ou de types de vannes de permutation similaires est effectué sans commandes externes. La vanne est actionnée mécaniquement par un levier manuel.",
            listItems: ["Vanne de permutation 3/3 voies", "Vanne de permutation 4/3 voies", "Vanne de permutation 6/3 voies", "Vanne de permutation 8/3 voies"],
            image: imgChangeOverValve
        },
    ];

    const tabTitles = [
        { title: "Vannes d'arrêt", eventKey: "Vannes d'arrêt" },
        { title: "Vannes de contrôle de pression", eventKey: "Vannes de contrôle de pression" },
        { title: "Vannes de commande directionnelle", eventKey: "Vannes de commande directionnelle" },
        { title: "Vannes de permutation", eventKey: "Vannes de permutation" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"VANNES D'INSTALLATION DE PIPELINE COMPACTES ET ÉCONOMIQUES DE HANSA‑FLEX"}
                    paragraph={"Les vannes d'installation de pipeline sont montées directement dans les conduites de système hydraulique et remplissent des fonctions de base telles que la limitation de pression ou le contrôle du débit. Les vannes compactes ne comportent pas de boîtiers ou de plaques de base supplémentaires. HANSA‑FLEX dispose d'un large portefeuille de vannes hydrauliques standardisées fabriquées à partir de matériaux de haute qualité avec différents revêtements pour l'installation de pipeline pour des plages de pression allant jusqu'à 350 bars."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"PRODUITS ET SERVICES POUR LA TECHNOLOGIE DES VANNES"}
                />
                <ImageText
                    data={PipelineInstallationValve1} />

                <ImageText
                    data={PipelineInstallationValve2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"POUR LES FONCTIONS DE CONTRÔLE DE BASE DANS LES SYSTÈMES HYDRAULIQUES"}
                    subtitle={"Les vannes d'installation de pipeline sont faciles à installer, peu coûteuses et simples à régler, ce qui les rend adaptées à de nombreux types de circuits de commande et d'applications."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"ACHETEZ DES VANNES D'INSTALLATION DE PIPELINE DE HANSA‑FLEX EN LIGNE"}
                    subtitle={"Notre boutique en ligne propose un large choix de différentes vannes d'installation de pipeline, y compris des vannes de débit et de choc, ainsi que des accessoires adaptés."}
                />
                <ImageText
                    data={PipelineInstallationValve3} />
            </div>

            <div>
                <SectionHeader
                    title={"VANNES D'INSTALLATION DE PIPELINE HANSA‑FLEX POUR TOUTES LES APPLICATIONS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCompactvalve}
                                title="Vannes de cartouche compactes pour les espaces d'installation restreints"
                                desc={"Étant installée directement dans le bloc de soupape, une vanne de cartouche est un moyen d'économiser de l'espace pour mettre en œuvre des commandes complexes sans boîtiers de soupape supplémentaires."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCetopValve}
                                title="Implémentez une gamme diverse de fonctions de valve avec des vannes CETOP"
                                desc={"Avec leur conception de montage sur plaque standardisée, les vannes CETOP peuvent être combinées de toutes sortes de façons pour mettre en œuvre une large gamme de fonctions."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgMoreEfficiency}
                                title="Plus d'efficacité pour vos systèmes et machines"
                                desc={"Le Service Industriel HANSA‑FLEX vous aide à optimiser votre technologie de valve afin que vos systèmes et machines existants fonctionnent de manière plus efficace."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )

}

export default PipelineInstallationValve