import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import ImageText from '../../Components/ImageText/ImageText'
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import HFTabs from '../../Components/HfTabs/HfTabs'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../Components/ImageCard/ImageCard'
import imgMark from '../../assets/mark.png'


import imgStage from '../../assets/Products/Pneumatics/Stage.jpg'
import EfficientConnection from '../../assets/Products/Pneumatics/Efficient Connection.jpg'
import COmpressedAir from '../../assets/Products/Pneumatics/COmpressedAir.jpg'
import GeneratingAndTreating from '../../assets/Products/Pneumatics/GeneratingAndTreating.jpg'
import Cylinder from '../../assets/Products/Pneumatics/Cylinder.jpg'
import HosesAndPipes from '../../assets/Products/Pneumatics/HosesAndPipes.jpg'
import VaccumTech from '../../assets/Products/Pneumatics/VaccumTech.jpg'
import AirBlastGun from '../../assets/Products/Pneumatics/AirBlastGun.jpg'
import MaintenanceDevices from '../../assets/Products/Pneumatics/MaintenanceDevices.jpg'
import OperatingPneumatics from '../../assets/Products/Pneumatics/OperatingPneumatics.jpg'
import EnergyEfficiency from '../../assets/Products/Pneumatics/EnergyEfficiency.jpg'
import Productivity from '../../assets/Products/Pneumatics/Productivity.jpg'
import OccupationalSafety from '../../assets/Products/Pneumatics/OccupationalSafety.jpg'
import SuperbCleaning from '../../assets/Products/Pneumatics/SuperbCleaning.jpg'
import PremiumCompressedAir from '../../assets/Products/Pneumatics/PremiumCompressedAir.jpg'
import NonInterchnageable from '../../assets/Products/Pneumatics/NonInterchnageable.jpg'
import Install from '../../assets/Products/Pneumatics/Install.jpg'
import VaccumTech2 from '../../assets/Products/Pneumatics/VaccumTech2.jpg'
import Tracing from '../../assets/Products/Pneumatics/Tracing.jpg'



const Pneumatics = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const Data1 = {
        image: EfficientConnection,
        title: 'Connexions efficaces pour les systèmes pneumatiques',
        listItems: ["Large sélection de raccords rapides", "Vannes à boisseau sphérique avec actionneurs rotatifs pneumatiques", "Raccords filetés et joints élastomères", "Disponible dans divers matériaux"],
    }

    const Data2 = {
        image: COmpressedAir,
        title: 'Air comprimé distribué de manière optimale',
        listItems: ["Tuyau de compresseur, de frein et ignifuge", "Systèmes de tuyauterie et de ligne d'air comprimé librement configurables", "Vannes de commande directionnelle électriques, pneumatiques ou manuelles", "Vannes conformes à la norme NAMUR", "Vannes solénoïdes et terminaux de valve"],
    }

    const Data3 = {
        image: GeneratingAndTreating,
        title: "Production et traitement de l'air comprimé",
        listItems: ["Unités de maintenance modulaires conformes à la directive Machines", "Large gamme de filtres avec séparateurs d'eau", "Manomètres avec indicateur de colmatage", "Composants de préparation de l'air comprimé, régulateurs de pression et compresseurs"],
    }

    const Data4 = {
        image: OperatingPneumatics,
        listItems: ["Éléments de connexion conformes au DVGW", "Conformité aux normes de l'OSHA américaine", "Produits conformes aux directives Machines et Équipements sous pression", "Respect de la loi allemande sur la santé et la sécurité au travail (BetrSichV) et de l'ordonnance sur les lieux de travail (ArbStättV)", "Travail effectué par des spécialistes des fluides expérimentés"],
    }

    const Data5 = {
        image: SuperbCleaning,
        title: "Performance de nettoyage exceptionnelle jusqu'à 10 bar avec le pistolet de soufflage Typhoon",
        listItems: ["Conception optimisée pour l'écoulement et buse à canaux multiples", "Performance de nettoyage réglable en continu", "Excellente manipulation grâce à la poignée en caoutchouc ergonomiquement formée"],
        buttonName: "COMMANDER MAINTENANT"
    }

    const Data6 = {
        image: PremiumCompressedAir,
        title: "Raccords d'air comprimé haut de gamme pour une sécurité accrue",
        listItems: ["Débit élevé de 1 600 l/min avec une faible consommation d'air et d'énergie", "Accouplement facile, sûr et intuitif à l'aide d'une seule main", "Joint en 3 parties pour beaucoup moins de fuites"],
        buttonName: "ACHETER DES PRODUITS"
    }

    const Data7 = {
        image: NonInterchnageable,
        title: 'Raccords non interchangeables pour une plus grande sécurité opérationnelle',
        listItems: ["Les mêmes marquages de couleur indiquent les moitiés de couplage interchangeables", "Les moitiés doivent avoir la même forme pour être correctement couplées", "Disponible dans différentes couleurs"],
        buttonName: "PRODUITS"
    }

    // données du carrousel de cartes de fonctionnalités
    const featuresData = [
        {
            title: "Fiabilité en utilisation",
            description: "Le portefeuille pneumatique HANSA‑FLEX répond aux normes les plus élevées et est soumis à des tests complets.",
            image: imgMark
        },
        {
            title: "Conçu pour l'efficacité",
            description: "Nos composants pneumatiques sont conçus pour une efficacité maximale et optimisés pour des débits élevés.",
            image: imgMark
        },
        {
            title: "Aucun compromis sur la sécurité",
            description: "Nous fournissons des produits liés à la sécurité tels que les clapets anti-retour de tuyau et les raccords de sécurité.",
            image: imgMark
        },
        {
            title: "Respecte toutes les normes courantes",
            description: "Par exemple : conformité aux directives Machines, Équipements sous pression et ATEX, et certification conformément à RoHS.",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données de carte de fonctionnalités supplémentaires
    ];

    // données HfTab
    const imageTextData = [
        {
            title: "Vérins pneumatiques",
            listItems: ["Vérins pneumatiques conformes à la norme ISO 6432, ISO 15552 et ISO 21287", "Vérins ronds, à course courte, standard et compacts", "Vérins sans tige"],
            buttonName: "TOUS LES VÉRINS",
            image: Cylinder
        },
        {
            title: "Tuyaux et conduites pneumatiques",
            listItems: ["Tuyaux de compresseur et de frein", "Tuyaux PA, PE, PUR, PVDF et PVC", "Tuyauterie, brides, fixations et raccordements"],
            buttonName: "PRODUITS",
            image: HosesAndPipes
        },
        {
            title: "Technologie de vide pour générer des vides",
            listItems: ["Éjecteurs de vide incluant des accessoires appropriés", "Interrupteurs, vannes de débit et capteurs de vide", "Soufflets et ventouses plates"],
            buttonName: "ACHETER MAINTENANT",
            image: VaccumTech
        },
        {
            title: "Pistolets de soufflage économes en énergie",
            listItems: ["Pistolets de soufflage fabriqués en plastique et en aluminium moulé sous pression", "Buses et buses de sécurité", "Accessoires et pièces de rechange"],
            buttonName: "PLUS SUR LES PISTOLETS DE SOUFFLAGE",
            image: AirBlastGun
        },
        {
            title: "Régulateurs de pression, filtres et unités de maintenance",
            listItems: ["Unités de maintenance et dispositifs de maintenance combinés", "Divers régulateurs de pression, filtres spéciaux et vannes proportionnelles", "Séparateurs d'huile et d'eau et détecteurs de fuites"],
            buttonName: "TOUS LES DISPOSITIFS DE MAINTENANCE",
            image: MaintenanceDevices
        },
    ];

    const tabTitles = [
        { title: "Vérins", eventKey: "Vérins" },
        { title: "Tuyaux et conduites", eventKey: "Tuyaux et conduites" },
        { title: "Technologie de vide", eventKey: "Technologie de vide" },
        { title: "Pistolets de soufflage", eventKey: "Pistolets de soufflage" },
        { title: "Dispositifs de maintenance", eventKey: "Dispositifs de maintenance" },
    ];

    const imageTextData2 = [
        {
            title: "Économisez de l'énergie et protégez l'environnement",
            listItems: ["Optimisation énergétique pour les systèmes pneumatiques", "Repérage et scellement des fuites", "Réduction de la pression du système pour soulager les composants"],
            image: EnergyEfficiency
        },
        {
            title: "Nettoyage de l'air comprimé et garantie de la disponibilité du système",
            listItems: ["Unités de maintenance modulaires conformes à la directive Machines", "Mesure de la pression et de la température (numérique et analogique)", "Séparateurs huile-eau et filtres"],
            image: Productivity
        },
        {
            title: "Prévenir les accidents et protéger les employés",
            listItems: ["Protection contre les ruptures de tuyau pour éviter les fouets incontrôlés", "Des tuyaux de couleur vive réduisent le risque de trébuchement", "Pistolets de soufflage et buses à faible bruit pour réduire le bruit", "Les raccords de sécurité empêchent les fouets [Lien d'ancrage vers 5. “Produits en vedette > Raccords de sécurité”]", "Les raccords non interchangeables éliminent les erreurs de connexion [Lien d'ancrage vers 5. “Produits en vedette > Raccords non interchangeables”]"],
            image: OccupationalSafety
        },
    ];

    const tabTitles2 = [
        { title: "Efficacité énergétique", eventKey: "Efficacité énergétique" },
        { title: "Productivité", eventKey: "Productivité" },
        { title: "Sécurité au travail", eventKey: "Sécurité au travail" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"UTILISER LE PNEUMATIQUE POUR DÉPLACER, CONTRÔLER ET POSITIONNER"}
                    paragraph={"Le pneumatique est au cœur des processus automatisés dans les centres logistiques, les entrepôts de petites pièces et l'industrie automobile. L'air comprimé est également utilisé dans la technologie du vide et est indispensable pour les opérations de levage et de positionnement numériques. La qualité et la conception des composants utilisés font toute la différence pour la productivité et l'efficacité des systèmes pneumatiques. HANSA‑FLEX propose des solutions efficaces et des services sur mesure."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"TECHNOLOGIE DE L'AIR COMPRIMÉ DE HANSA‑FLEX"}
                    subtitle={"La gamme d'équipements d'air comprimé HANSA‑FLEX comprend plus de 18 000 composants pneumatiques de haute qualité tels que des vérins, des vannes de commande, des outils et des accessoires adaptés à tous les secteurs d'activité et aux environnements d'exploitation les plus exigeants."}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
                <ImageText
                    data={Data3} />
            </div>

            <div>
                <SectionHeader
                    title={"VOUS NE POUVEZ PAS VOUS TROMPER AVEC LES COMPOSANTS PNEUMATIQUES HANSA‑FLEX"}
                    subtitle={"Lors du déplacement de l'air comprimé, chaque détail compte. Une installation compacte est aussi importante qu'un fonctionnement efficace et rentable."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"TECHNOLOGIE DE L'AIR COMPRIMÉ DE HANSA‑FLEX DANS LA BOUTIQUE EN LIGNE"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"UTILISATION SÉCURITAIRE DES SYSTÈMES PNEUMATIQUES"}
                    subtitle={"Avec des composants éprouvés de HANSA‑FLEX, vous êtes toujours du bon côté. Notre équipe d'experts hautement expérimentés vous aide à entretenir et à exploiter votre système pneumatique."}
                />
                <ImageText
                    data={Data4} />
            </div>

            <div>
                <SectionHeader
                    title={"LA SOLUTION DE FILTRAGE PARFAITE POUR TOUTES LES APPLICATIONS HYDRAULIQUES"}
                />
                <HFTabs imageTextData={imageTextData2} tabTitles={tabTitles2} />
            </div>

            <div>
                <SectionHeader
                    title={"VOTRE PARTENAIRE À SERVICE COMPLET POUR TOUT CE QUI CONCERNE LES SYSTÈMES PNEUMATIQUES"}
                    subtitle={"Nos experts possèdent une vaste expérience dans tous les domaines de la technologie de l'air comprimé. De la planification et de la conception à l'installation et au fonctionnement continu, nous vous soutenons dans toutes les tâches requises sur votre système pneumatique et sommes toujours disponibles pour répondre à vos questions sur n'importe quel sujet, y compris l'efficacité énergétique."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"PRODUITS SÉLECTIONNÉS DE LA GAMME DE TECHNOLOGIE D'AIR COMPRIMÉ HANSA‑FLEX"}
                />
                <ImageText
                    data={Data5} />
                <ImageText
                    data={Data6}
                    left={false} />
                <ImageText
                    data={Data7} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={Install}
                                title="Installation et tuyauterie"
                                desc={"HANSA‑FLEX Industrial Service installe des systèmes pneumatiques comprenant des tuyauteries et des connexions entre tous les composants."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={VaccumTech2}
                                title="Technologie du vide pour les tâches de positionnement"
                                desc={"La boutique en ligne HANSA‑FLEX propose une large sélection d'éléments d'aspiration, d'unités de commande et de capteurs ainsi que des équipements pour la création de vide local à l'aide d'éjecteurs en ligne."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Tracing}
                                title="Traçage du sifflement"
                                desc={"Les experts de HANSA‑FLEX vous conseillent et vous aident à moderniser et à optimiser vos systèmes pneumatiques, par exemple lorsque vous devez repérer et sceller des fuites."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )

}


export default Pneumatics