import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import HFTab from '../../../../Components/HfTabs/HfTabs'
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../../assets/mark.png'
import ImageText from '../../../../Components/ImageText/ImageText'

import Stage from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/Stage.jpg'
import HFGuarantees from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/HFGuarantees.jpg'
import HFCarriesOut from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/HFCarriesOut.jpg'
import HFSimplifiesTask from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/HFSimplifiesTask.jpg'
import CorrectStorage from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/CorrectStorage.jpg'
import Coding from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/Coding.jpg'
import RiskAnalysis from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/RiskAnalysis.jpg'
import Disposal from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/Disposal.jpg'
import Testing from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/Testing.jpg'
import HoseLine from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/HoseLine.jpg'
import RapidHelp from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/RapidHelp.jpg'

const DguvInspection = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        image: HFGuarantees,
        title: 'HANSA-FLEX garantit la sécurité conformément aux réglementations.',
        description: "Les lignes de tuyaux hydrauliques sont considérées comme des équipements de travail conformément à la Loi allemande sur la sécurité industrielle (BetrSichV) et à la Directive européenne sur l'utilisation des équipements de travail 2009/104/CE. De plus, les lignes de tuyaux sont soumises à des charges élevées telles que des changements de pression dynamique et des pics pendant leur fonctionnement quotidien. Par conséquent, les tuyaux et les raccords doivent être régulièrement inspectés pour vérifier qu'ils fonctionnent correctement. La sécurité du personnel, des systèmes et des machines dépend du fait que les lignes de tuyaux soient exemptes de défauts et de défauts. L'inspection tient compte des règles de la DGUV et de la Loi allemande sur la sécurité industrielle (BetrSichV) ainsi que des directives en matière de machines pertinentes. Les experts de HANSA-FLEX sont familiers avec toute la législation et les directives applicables, de sorte que toutes les inspections sont conformes aux exigences légales.",
    }

    const data2 = {
        image: HFCarriesOut,
        title: 'HANSA-FLEX effectue toutes les inspections nécessaires. ',
        description: "Les inspections des lignes de tuyaux hydrauliques doivent être effectuées à intervalles réguliers conformément à l'évaluation des risques opérationnels applicable, mais au moins tous les 12 mois. Les inspections ne peuvent être effectuées que par des personnes officiellement autorisées pour les inspections. Les experts de HANSA-FLEX possèdent cette qualification et peuvent vous soutenir dans les tâches suivantes :",
        listItems: [
            "Inspections visuelles et tests de fonctionnement",
            "Inspection prenant en compte les changements nécessitant une nouvelle inspection",
            "Inspections récurrentes aux intervalles définis"

        ]
    }

    const data3 = {
        image: HFSimplifiesTask,
        title: 'HANSA-FLEX simplifie la tâche de documentation.',
        description: "Chaque rapport d'inspection doit être enregistré et conservé en toute sécurité jusqu'à la prochaine inspection. Selon le nombre de lignes de tuyaux hydrauliques à inspecter, l'obligation de préparer et de conserver les rapports d'inspection peut représenter beaucoup de travail pour une entreprise. En tant que partenaire de service, HANSA-FLEX prend en charge l'enregistrement et la gestion des résultats d'inspection. En utilisant la plateforme de gestion des tuyaux numériques X-CODE Manager, l'opérateur de la machine et le technicien de service peuvent consulter les informations les plus récentes sur chaque inspection pour chaque système, machine et ligne de tuyaux.",
    }

    const data4 = {
        image: CorrectStorage,
        description: "Nos experts recommandent :",
        listItems: ["Frais, sec, sans poussière, à l'abri de la lumière directe du soleil", "Les élastomères ne doivent pas descendre en dessous de -10 °C",
        "Idéalement entre 15 et 25 °C à 65 % d'humidité relative",
        "Stockez horizontalement et sans tension",
        "La durée maximale de stockage des lignes de tuyaux est de 2 ans",
        "La durée maximale de stockage du matériau de tuyau est de 4 ans" ]

    }

    const featuresData = [
        {
            title: "Sécurité opérationnelle améliorée",
            description: " Protection pour les utilisateurs, les systèmes, les machines et la réputation de votre entreprise.",
            image: imgMark
        },
        {
            title: "Conformité légale fiable ",
            description: "Respectez les intervalles prescrits par la loi et conservez une documentation conforme à la législation. ",
            image: imgMark
        },
        {
            title: "Disponibilité accrue ",
            description: "Évitez les arrêts imprévus et réduisez l'usure des composants. ",
            image: imgMark
        },
        {
            title: "Plus de transparence ",
            description: "Disposez de toutes vos données de tuyaux et de dates d'entretien à portée de main avec la plateforme de gestion des tuyaux X-CODE Manager. ",
            image: imgMark
        }
    ];


    //HfTab data
    const imageTextData = [
        {
            description: "Pour un suivi complet des intervalles d'inspection et des résultats, il est avantageux que chaque ligne de tuyau ait un code individuel. Le X-CODE de HANSA-FLEX est une solution éprouvée en pratique pour le codage lorsqu'une nouvelle ligne de tuyau est incluse et pour le codage de suivi des lignes de tuyau existantes. ",
            buttonName: "Découvrez-en plus ",
            image: Coding
        },
        {
            description: " HANSA-FLEX dispose de décennies d'expérience à mettre à profit lors de la consultation des clients sur les principes de préparation d'un rapport d'évaluation des risques. Ces conseils incluent naturellement l'analyse des risques conformément à la directive européenne sur les machines 2006/42/CE. ",
            image: RiskAnalysis
        },
        {
            description: "Ce qui doit être pris en considération lors de l'inspection des lignes de tuyaux hydrauliques.",
            image: Disposal
        },
    ];

    const tabTitles = [
        { title: "Codage et codage de suivi ", eventKey: "Codage et codage de suivi " },
        { title: "Analyse des risques et classification ", eventKey: "Analyse des risques et classification " },
        { title: "Élimination des lignes de tuyaux hydrauliques ", eventKey: " Élimination des lignes de tuyaux hydrauliques " },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"INSPECTION DES LIGNES DE TUYAUX HYDRAULIQUES "}
                    paragraph={"Les lignes de tuyaux hydrauliques sont des composants fondamentaux des machines et des systèmes. Elles nécessitent des soins complets et doivent être inspectées professionnellement par des personnes autorisées tous les 12 mois après leur première utilisation. HANSA FLEX se charge de la planification, effectue les inspections et prépare le dossier documentaire de l'inspection visuelle et du test de fonctionnement prescrits légalement. Cela allège une partie de la charge pour vous, l'opérateur, et augmente la sécurité de votre équipement de travail.  "}
                />
            </div>
            <div>
                <SectionHeader
                    title={"INSPECTION PAR DES EXPERTS DES LIGNES DE TUYAUX HYDRAULIQUES "}
                    subtitle={" En tant que fournisseur de systèmes de technologie des fluides, HANSA-FLEX sait ce qu'il faut prendre en compte lors de l'inspection des lignes de tuyaux hydrauliques et propose un service complet pour tout ce qui concerne la gestion des tuyaux, effectué par du personnel spécialisé expérimenté et formé. "}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
            </div>

            <div>
                <SectionHeader
                    title={" "}
                    subtitle={"Les informations les plus importantes pour inspecter les lignes de tuyaux hydrauliques et entretenir les systèmes hydrauliques et les machines sont expliquées clairement et de manière concise dans notre brochure 'Gestion des tuyaux - Utilisation sécuritaire et légale de l'équipement hydraulique'"}
                />
            </div>

            <div>
                <SectionHeader
                    title={"L'inspection par HANSA-FLEX offre une sécurité avec une valeur ajoutée. "}
                    subtitle={" Vous pouvez compter sur un haut niveau d'expertise et une connaissance approfondie des processus pour les inspections avec HANSA-FLEX en tant que partenaire de service. "}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            {/* TODO: Carousel */}

            <div>
                <SectionHeader
                    title={"Le stockage correct des lignes de tuyaux hydrauliques. "}
                    subtitle={"En plus de l'inspection, HANSA-FLEX fournit des conseils complets sur le stockage optimal des flexibles hydrauliques et des lignes de tuyaux conformément à la norme DIN 20066:2021-01."}
                />
                <ImageText
                    data={data4} />
            </div>

            <div>
                <SectionHeader
                    title={"La documentation conforme à la législation basée sur les enregistrements d'inspection."}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            {/* TODO:CLient Review */}

            <div>
                <SectionHeader
                    title={"Plus de services pour vos systèmes hydrauliques."}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Testing}
                                title="Test de fluides hydrauliques. "
                                desc={"  Le service des fluides HANSA-FLEX analyse l'huile hydraulique pour un entretien optimal des fluides. De cette manière, les problèmes sont détectés à temps et les arrêts coûteux sont évités. "} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={HoseLine}
                                title="Gestion des lignes de tuyaux avec X-CODE. "
                                desc={" Le système de gestion numérique des tuyaux de HANSA-FLEX contient toutes vos lignes de tuyaux hydrauliques, ce qui permet de réaliser des inspections de manière plus rapide et efficace.  "} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={RapidHelp}
                                title=" Aide rapide en cas d'urgence. "
                                desc={" Les arrêts imprévus doivent être traités le plus rapidement possible. Le service d'urgence HANSA-FLEX est prêt à intervenir à tout moment, 365 jours par an. "} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default DguvInspection 