import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import Stage from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/Stage.jpg'
import Chalenges from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/Chalenges.jpg'
import MaintainanceOfHydraulic from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/MaintainanceOfHydraulic.jpg'
import OperateSystems from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/OperateSystems.jpg'
import SimplyUnique from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/SimplyUnique.jpg'
import XCode from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/XCode.jpg'
import Support from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/Support.jpg'
import KeepOn from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/KeepOn.jpg'
import HydraulicHoseLine from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/HydraulicHoseLine.jpg'
import PressureAccumulator from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/PressureAccumulator.jpg'
import ndustrialHoses from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/ndustrialHoses.jpg'
import LiftingPlatform from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/LiftingPlatform.jpg'
import HFFluid from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/HFFluid.jpg'
import itInTheory from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/itInTheory.jpg'
import WeBring from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/WeBring.jpg'

const PreventataiveMaintainance = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        image: Chalenges,
        title: 'Challenges and advantages of preventive maintenance',
        description: 'Whether safety at work, system availability or cost savings, conservation of natural resources, energy efficiency or digitalisation: the requirements for maintenance today are as numerous as they are complex. System maintenance increasingly relies on proactive and forward-looking strategies to ensure as few malfunctions as possible in production processes. Systematically planning servicing and inspections as part of preventive maintenance can reduce the number of unplanned machine breakdowns and make an important contribution to overall success on the production floor.',
    }

    const data2 = {
        image: MaintainanceOfHydraulic,
        title: 'Preventive maintenance of hydraulic and other components',
        description: "The special characteristics of hydraulic components such as hose lines or pressure accumulators and the associated legally prescribed inspection and replacement intervals applying to them mean they are prime examples of equipment requiring professionally designed preventive maintenance plans. Benefit from the tools and know-how of HANSA‑FLEX hydraulics specialists in the conception, implementation and the legally compliant, practical implementation of your individual maintenance strategy. Your machines will operate more reliably for longer and your team will have more time for dealing with unplanned events.",
    }

    const data3 = {
        image: OperateSystems,
        title: 'Operate systems and work equipment safely and in compliance with the law',
        description: 'Hydraulic hose lines have a limited service life and must be professionally inspected at regular intervals to ensure your machines and systems operate safely and in compliance with the law. The inspections must take place in accordance with the legally prescribed risk assessment in each case and be properly documented. We support you from A to Z in fulfilling these obligations with 360° hose management. This leaves you in a better position to concentrate on your core business.',
        buttonName: "HOSE MANAGEMENT"
    }

    const data4 = {
        image: KeepOn,
        description: "We inspect the following for you:",
        listItems: [
            "Hydraulic hose lines in accordance with DGUV and BetrSichV",
            "Industrial hose lines in accordance with TRBS 1201",
            "Pressure accumulators in accordance with the Pressure Equipment Directive (PED) and BetrSichV",
            "Lifting platforms and loading bridges in accordance with DGUV and BetrSichV"
        ]
    }

    //HfTab data
    const imageTextData = [
        {
            title: "Inspection des lignes de tuyaux hydrauliques conformément ",
            description: "Nous effectuons des inspections légalement requises de vos lignes de tuyaux hydrauliques conformément aux réglementations BetrSichV / DGUV et gérons la documentation légale conforme des résultats pour vous. Les inspections sont réalisées directement sur site par du personnel officiellement autorisé pour inspecter l'équipement de lignes hydrauliques. Chaque inspection peut être planifiée comme un service autonome ou comme partie intégrante de notre forfait de gestion complète des tuyaux dans le cadre de notre service de gestion des tuyaux à 360 degrés. ",
            image: HydraulicHoseLine,
            buttonName: "READ MORE"
        },
        {
            title: "  Les accumulateurs de pression ou hydrauliques sont des équipements de travail susceptibles de présenter des risques particuliers. En tant que conteneurs sous pression, ils font partie des systèmes qui doivent être surveillés conformément à la législation sur la sécurité des produits et sont classés dans l'une des quatre catégories conformément à la Directive sur les équipements sous pression (PED). Nous pouvons effectuer les inspections requises avant la mise en service des accumulateurs hydrauliques de catégorie I et les inspections récurrentes des accumulateurs hydrauliques des catégories I et II. Une personne officiellement autorisée pour les équipements sous pression avec des qualifications supplémentaires réalise ces inspections pour vous.",
            description: "",
            buttonName: " LIRE LA SUITE ",
            image: PressureAccumulator
        },
        {
            title: "Inspection of industrial hose lines in accordance",
            description: "We inspect all types of hose lines for chemicals, foodstuff, pharmaceuticals and other media. Inspections are carried out in our specialist centres for hose technology inspection or directly on site for you. For the mobile inspection service, an officially authorised person for inspections in accordance with TRBS 1203 in a fully equipped inspection vehicle performs all the prescribed inspections. Inspection certificates, identification label strips and inspection stickers can be produced on the vehicle and immediately applied.",
            buttonName: "LIRE LA SUITE",
            image: ndustrialHoses
        },
        {
            title: " Inspection des plates-formes élévatrices conformément ",
            description: " Nous effectuons régulièrement des inspections récurrentes des plates-formes élévatrices et des ponts de chargement conformément aux normes européennes DIN EN 1493, DIN EN 1494 et DIN EN 1495 pour vous. Nous préparons également toute la documentation légalement requise des inspections conformément à la loi allemande sur la sécurité industrielle (BetrSichV). Nos techniciens de service sont des experts certifiés dans les plates-formes élévatrices conformément à la règle DGUV 308-002 régissant l'inspection des plates-formes élévatrices. Ils réalisent les inspections visuelles et les tests de fonctionnement conformément aux exigences de la règle GUV R-500 'Exploitation des équipements de travail",
            buttonName: "LIRE LA SUITE",
            image: LiftingPlatform
        },
    ];

    const tabTitles = [
        { title: "Hydraulic hose lines", eventKey: "Hydraulic hose lines" },
        { title: "Pressure accumulators", eventKey: "Pressure accumulators" },
        { title: "Industrial hoses", eventKey: "Industrial hoses" },
        { title: "Lifting platforms and loading bridges", eventKey: "Lifting platforms and loading bridges" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"ÉVITEZ LES RISQUES, MAINTENEZ LES MACHINES EN BON ÉTAT "}
                    paragraph={"La maintenance préventive aide à éviter les risques et les dommages tout en prolongeant la durée de vie de vos machines et systèmes. HANSA-FLEX est un gestionnaire de systèmes et peut vous soutenir avec de nombreux services précieux. Bénéficiez de nos services complets dans le domaine de la gestion des tuyaux de vos lignes de tuyaux hydrauliques et dans l'exécution et la documentation des inspections légalement prescrites de divers équipements de travail et systèmes par des personnes autorisées officiellement. "}
                />
            </div>

            <div>
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
            </div>

            <div>
                <SectionHeader
                    title={"LA GESTION DES TUYAUX À LA FAÇON DE HANSA-FLEX "}
                    subtitle={"La gestion professionnelle des tuyaux comprend plusieurs éléments essentiels. Découvrez tout sur notre système de codage polyvalent des tuyaux, notre logiciel de gestion des tuyaux unique et les services disponibles auprès de nos techniciens d'installation spécialisés.  "}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={SimplyUnique}
                                title="Simplement unique : identification des tuyaux avec X-CODE "
                                desc={"Un système de codage testé et éprouvé des millions de fois. Un seul code contient toutes les informations. Attaché en tant que marqueur Tip Tag, étiquette auto-adhésive ou plaque d'identification enveloppante, avec code QR. "} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={XCode}
                                title="X-CODE Manager, le logiciel de gestion des tuyaux facile à utiliser."
                                desc={ "Logiciel efficace pour tous les responsables de maintenance de système. Bénéficiez de la meilleure vue d'ensemble de vos lignes de tuyaux, machines et dates de maintenance."} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Support}
                                title="Assistance à prix fixe : forfait de service de gestion des tuyaux "
                                desc={" Certitude de planification pour un tarif journalier fixe. Découvrez nos forfaits de service pour la numérisation, la maintenance des données, l'installation, les inspections sur site, et bien plus encore. "} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div>
                <SectionHeader
                    title={'Restez du bon côté avec HANSA-FLEX. '}
                    subtitle={" Des spécialistes expérimentés, qualifiés en tant que personnes autorisées officiellement, vous soutiennent dans l'inspection de vos systèmes et équipements de travail conformément aux exigences légales actuellement en vigueur. Profitez de notre expertise et de notre expérience! "}
                />
                <ImageText
                    data={data4} />
            </div>

            <div>
                <SectionHeader
                    title={" Absolument, les inspections jouent un rôle crucial dans le maintien de la sécurité et de la conformité sur divers équipements et systèmes.  "}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={" That's the spirit! Whether you want to delve into the mysteries of the universe, uncover the latest technological advancements, explore the depths of history, or anything else that piques your interest, just say the word, and we'll embark on an exciting exploration together! "}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={HFFluid}
                                title="  Le service de fluides HANSA-FLEX révèle les secrets de votre huile. "
                                desc={"  De nombreuses pannes de machines peuvent être attribuées à des contaminants dans l'huile hydraulique. L'analyse professionnelle de l'huile par HANSA-FLEX permet de découvrir en temps utile les causes possibles de ce problème. "} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={itInTheory}
                                title= "Théorie et pratique réunies : des séminaires pour les responsables de la maintenance des systèmes."
                                desc={"L'Académie internationale de l'hydraulique (IHA) est le prestigieux fournisseur européen de séminaires spécialisés en hydraulique et de certifications reconnues. "} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={WeBring}
                                title="Nous redonnons vie à vos machines."
                                desc={"Des techniciens de maintenance spécialisés vous accompagnent sur site pour la réparation et la remise à neuf de vos systèmes hydrauliques. "} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default PreventataiveMaintainance