import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgmissionStatement from '../../assets/MissionStatement/imgMissionStatement.jpg'
import ImageText from '../../Components/ImageText/ImageText';
import imgTogetherness from '../../assets/MissionStatement/imgToghetherness.jpg'
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import imgresponsibiliy from '../../assets/MissionStatement/imgResponsibility.jpg'
import imgReliability from '../../assets/MissionStatement/imgReliability.jpg'
import imgEnviromentalAwarness from '../../assets/MissionStatement/imgEnvironmentalawareness.png'
import imgRD from '../../assets/MissionStatement/imgR&D.png'
import imgFlexibility from '../../assets/MissionStatement/imgFlexibility.png'

function GroupMissionStatement() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const Ensemble = {
        image: imgTogetherness,
        title: 'Précision scellée : Tétons filetés HANSA-FLEX',
        listItems: ["Nous nous traitons mutuellement avec appréciation et respect.", "Nous agissons de manière coopérative et nous nous soutenons mutuellement.", "Nous communiquons ouvertement et honnêtement les uns avec les autres.", "Nous veillons à ce que nous et les autres soyons toujours bien informés.", "Nous faisons partie d'un tout plus grand."],
    };

    const Responsabilité = {
        image: imgresponsibiliy,
        title: 'Responsabilité',
        listItems: ['Nous livrons de la qualité et attendons la même chose de nos partenaires.', 'Nous encourageons notre personnel à agir de sa propre initiative.', 'Nous remettons en question les choses de manière critique et respectons les opinions des autres.', 'Nous sommes conscients de nos responsabilités envers nos collègues et partenaires et mettons en œuvre ces responsabilités.'],
    };

    const Fiabilité = {
        image: imgReliability,
        title: 'Fiabilité',
        listItems: ['Nous livrons de la qualité et attendons la même chose de nos partenaires.', 'Nous encourageons notre personnel à agir de sa propre initiative.', 'Nous remettons en question les choses de manière critique et respectons les opinions des autres.', 'Nous sommes conscients de nos responsabilités envers nos collègues et partenaires et mettons en œuvre ces responsabilités.'],
    };

    const SensibilisationEnvironnementale = {
        image: imgEnviromentalAwarness,
        title: 'Sensibilisation environnementale',
        listItems: ["Nous préservons les ressources naturelles.", "Nous promouvons l'efficacité énergétique dans l'hydraulique en ce qui concerne nos clients, nos produits et services.", "Nous veillons à ce que nos processus soient durables."],
    };

    const RD = {
        image: imgRD,
        title: 'R&D',
        listItems: ['Nous développons des produits et services innovants.', 'Nous élargissons continuellement notre expertise professionnelle en matière de produits et services.', 'Nous encourageons et stimulons notre personnel.', 'Nous soutenons nos partenaires par des conseils et des formations.', 'Nous travaillons tous ensemble pour garantir le succès de HANSA‑FLEX.'],
    };

    const Flexibilité = {
        image: imgFlexibility,
        title: 'Flexibilité',
        listItems: ["Nous répondons aux exigences individuelles de nos partenaires.", "Nous offrons à nos collègues des possibilités de travail flexibles dans l'ensemble du groupe.", "Nous identifions les changements à un stade précoce et y répondons immédiatement."]
    };

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgmissionStatement}
                    title={"UN FOCUS SUR L'UNITÉ"}
                    paragraph={"La déclaration de mission de HANSA-FLEX constitue le cadre d'orientation pour les actions quotidiennes de tous les employés dans le monde entier. Les valeurs et les principes sous-jacents ont été développés lors d'ateliers conjoints par les employés, les managers et les familles propriétaires. Avec notre Code de conduite HANSA-FLEX, nous disposons d'un cadre de valeurs stable constituant la base de notre culture d'entreprise."}
                />
            </div>

            <div className='div-gray'>
                <SectionHeader
                    title={"NOTRE IMPULSION EST DANS NOTRE ADN"}
                    subtitle={"Les valeurs et les principes de notre déclaration de mission illustrent l'engagement de HANSA-FLEX envers ses clients, nous guidant dans nos opérations quotidiennes tant internes qu'externes."}
                />

                <div >
                    <br />
                    <ImageText data={Ensemble} left={false} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={Responsabilité} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={Fiabilité} left={false} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={RD} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={Flexibilité} left={false} /> <br /><br />
                </div>
            </div>
        </div>
    );
}


export default GroupMissionStatement;