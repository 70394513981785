import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../../Components/ImageText/ImageText'
import imgMark from '../../../../assets/mark.png';
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../../Components/HfTabs/HfTabs';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import { Col, Container, Row } from 'react-bootstrap';

import imgStage from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/Stage.jpg'
import CetopDirectional from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/CetopDirectional.jpg'
import CetopProportional from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/CetopProportional.jpg'
import CetopPlate from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/CetopPlate.jpg'
import CetopValve from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/CetopValve.jpg'
import ReplacementSolenoidCoil from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/ReplacementSolenoidCoil.jpg'
import SealKits from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/SealKits.jpg'
import ScrewSets from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/ScrewSets.jpg'
import StandardisedCetop from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/StandardisedCetop.jpg'
import RobustDesign from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/RobustDesign.jpg'
import DiverseRange from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/DiverseRange.jpg'
import ValveTech from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/ValveTech.jpg'
import MadeToMeasure from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/MadeToMeasure.jpg'
import Purchase from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/Purchase.jpg'


const CetopValves = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const CetopValve1 = {
        image: CetopDirectional,
        title: 'Vannes de commande directionnelle CETOP de différents types',
        description: "Les vannes de commande directionnelle CETOP arrêtent ou dévient le flux de fluide vers les différents consommateurs dans le circuit hydraulique. Ces vannes de commutation binaires sont soit complètement ouvertes, soit fermées et n'ont aucun réglage intermédiaire.HANSA‑FLEX vous propose un large portefeuille de vannes de commande directionnelle CETOP de différents types: ",
        listItems: ["Divers agencements de commutation : vannes de commande directionnelle 4/2 et 4/3 voies", "Actionnées électriquement, hydrauliquement, pneumatiquement ou manuellement", "Commandées directement ou par pilote"],
        buttonName: "TOUTES LES VANNES DE COMMANDE DIRECTIONNELLE"
    }

    const CetopValve2 = {
        image: CetopProportional,
        title: 'Vannes proportionnelles CETOP pour un réglage infini du débit',
        description: "Les vannes proportionnelles CETOP sont des vannes réglables en continu et permettent le contrôle infini du débit. Elles peuvent être placées dans n'importe quel réglage intermédiaire entre ouvert et fermé, ce qui les rend idéales pour les fonctions de commutation hydraulique complexes et les commandes électroniques fréquemment rencontrées dans les systèmes modernes.Dans la boutique en ligne HANSA‑FLEX, vous pouvez trouver un certain nombre de vannes proportionnelles telles que des vannes de commande directionnelle actionnées par solénoïde, des vannes de réduction de pression et des vannes de sécurité.",
        buttonName: "TOUTES LES VANNES PROPORTIONNELLES"
    }

    const CetopValve3 = {
        image: CetopPlate,
        title: 'Vannes de montage sur plaque CETOP pour des arrangements de vannes empilées',
        description: "Plusieurs vannes de montage sur plaque CETOP avec différentes fonctions de commutation, telles que des vannes de commande directionnelle et de régulation de pression, des vannes d'arrêt et de débit, peuvent être combinées dans un arrangement empilé.Des fonctions de commutation complexes dans les systèmes hydrauliques peuvent être mises en œuvre de manière compacte et économique de cette manière.",
        listItems: ["Vannes de montage sur plaque prêtes à être installées avec des vannes vissées", "Grand choix de différents types de vannes", "Vannes de montage sur plaque dans les tailles NG 6, NG 10, NG 16 et NG 25"],
    }

    const CetopValve4 = {
        image: CetopValve,
        listItems: ["Vannes d'arrêt, de pression, directionnelles, de commutation et proportionnelles", "Actionnées électriquement, hydrauliquement, pneumatiquement ou manuellement", "Plaques de base de connexion pour jusqu'à 10 vannes parallèles", "Plaques de base fournies en option avec une valve de limitation de pression", "Plaques de réduction et plaques de recouvrement", "Arrangements de vannes empilées prêts à être installés pour répondre aux besoins individuels"],
    }

    const CetopValve5 = {
        image: StandardisedCetop,
        title: "Vannes CETOP standardisées dans diverses tailles nominales (NG)",
        description: "HANSA‑FLEX fournit directement des vannes CETOP dans les tailles nominales NG 6, 10, 16 et 25. Alors que nous fournissons des vannes de taille nominale NG 6 pour de nombreuses applications nécessitant des débits volumétriques allant jusqu'à 80 l / min, notre gamme contient également des vannes CETOP de taille NG 25 pour contrôler des débits volumétriques allant jusqu'à 650 l/min, ce qui est idéal pour les applications industrielles avec des charges hautement dynamiques telles que celles rencontrées sur des presses hydrauliques. Les deux tailles nominales intermédiaires NG 10 et 16 complètent la partie centrale de la gamme avec des débits maximaux de 140 l/min et 300 l/min respectivement.",
    }

    const CetopValve6 = {
        image: RobustDesign,
        title: 'Conception robuste',
        description: 'Toutes les vannes CETOP et plaques intermédiaires de HANSA‑FLEX sont fabriquées en acier moulé robuste avec un revêtement résistant à la corrosion. Pour les applications avec des milieux agressifs, nous proposons également des variantes en acier inoxydable. Des vannes spécialement conçues pour une utilisation dans des atmosphères potentiellement explosives conformément à la directive ATEX complètent la gamme de produits.',
        listItems: ["Matériau : acier moulé de haute qualité ou acier inoxydable", "Revêtement de surface : bruni ou phosphaté", "Disponible dans les couleurs choisies par le client sur demande"],
    }

    const CetopValve7 = {
        image: DiverseRange,
        title: "Large gamme d'arrangements de vannes empilées pré- assemblées",
        description: "L'empilement de vannes CETOP est une manière flexible de combiner une très large gamme de fonctions de commande dans un module peu encombrant.HANSA‑FLEX configure et assemble des arrangements de vannes empilées avec tous les composants nécessaires tels que des vannes, des plaques de base, intermédiaires, de réduction et de recouvrement pour répondre à vos besoins spécifiques.",
        listItems: ["Illustration d'arrangements de commutation hydraulique complexes", "Arrangements de vannes empilées prêts à être installés pour répondre à vos besoins", "Approvisionnement en unités de puissance avec des arrangements de vannes empilées"],
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Qualité de première classe",
            description: "Chez HANSA‑FLEX, vous pouvez trouver des produits de haute qualité de fabricants bien connus basés en Europe et aux États-Unis",
            image: imgMark
        },
        {
            title: "Disponibilité maximale",
            description: "Chez HANSA‑FLEX, vous recevez toute la gamme de vannes CETOP directement depuis notre entrepôt",
            image: imgMark
        },
        {
            title: "Solutions sur mesure",
            description: "Conseil, conception et fabrication sous le même toit garantissent une mise en œuvre rapide.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Bobines solénoïdes de remplacement pour actionneurs solénoïdes",
            description: "Lorsque la bobine responsable du contrôle d'une vanne actionnée par solénoïde cesse de fonctionner, elle doit être remplacée rapidement. Chez HANSA‑FLEX, vous recevez des bobines CC et CA dans les plus brefs délais directement depuis notre entrepôt.",
            listItems: ["Tensions nominales 12, 24 et 230 V, tensions spéciales sur demande", "Conçues pour d'excellentes performances de commutation", "Connecteurs électriques disponibles en accessoires"],
            buttonName: "TOUTES LES BOBINES SOLÉNOÏDES",
            image: ReplacementSolenoidCoil
        },
        {
            title: "Joints pour tous les domaines d'utilisation",
            description: "En plus des joints de remplacement pour les vannes CETOP, HANSA‑FLEX propose également des joints dans d'autres matériaux pour des applications spéciales, telles que celles avec des milieux particulièrement chauds ou agressifs.",
            listItems: ["Ensembles de joints de remplacement standard en caoutchouc nitrile (NBR)", "Joints résistants à la température", "Joints pour produits chimiques tels que les acides, les alcalis et les solvants"],
            buttonName: "TOUS LES JOINTS",
            image: SealKits
        },
        {
            title: "Vis pour une installation rapide et fiable",
            description: "Dans notre boutique en ligne, vous pouvez trouver une multitude de vis hexagonales standardisées et de tiges filetées pour l'assemblage d'arrangements de vannes CETOP empilées.",
            listItems: ["Standardisé selon la norme DIN 912", "Peut être raccourci pour différentes hauteurs d'installation", "Disponible dans de nombreuses versions"],
            buttonName: "TOUS LES ENSEMBLES DE VIS",
            image: ScrewSets
        },
    ];

    const tabTitles = [
        { title: "Bobines solénoïdes de remplacement", eventKey: "Bobines solénoïdes de remplacement" },
        { title: "Kits de joints", eventKey: "Kits de joints" },
        { title: "Ensembles de vis", eventKey: "Ensembles de vis" },
    ];



    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"VANNES CETOP POUR DES COMMANDES HYDRAULIQUES COMPLEXES"}
                    paragraph={"Même les commandes hydrauliques les plus complexes peuvent être intégrées à l'aide de vannes CETOP peu encombrantes. Avec leurs alésages de connexion normalisés et leur conception de montage sur plaque, leur grande possibilité de combinaison avec des plaques de montage intermédiaires et collectives, les vannes CETOP peuvent être configurées pour une grande variété de fonctions de commutation dans un espace d'installation relativement compact."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"ACHETEZ DES VANNES DE MONTAGE SUR PLAQUE EN LIGNE CHEZ LES SPÉCIALISTES DE LA TECHNOLOGIE FLUIDE"}
                    subtitle={"En tant que partenaire unique, HANSA‑FLEX propose non seulement une large gamme de vannes CETOP immédiatement disponibles, mais dispose également d'experts expérimentés pour vous donner des conseils complets dans le choix de solutions de vannes adaptées."}
                />
                <ImageText
                    data={CetopValve1} />
                <ImageText
                    data={CetopValve2}
                    left={false} />
                <ImageText
                    data={CetopValve3} />
            </div>

            <div>
                <SectionHeader
                    title={"VANNES SELON LA NORME CETOP CHEZ HANSA‑FLEX"}
                    subtitle={"HANSA‑FLEX propose une large sélection de vannes CETOP standardisées avec différentes fonctions de commutation et composants pour une installation peu encombrante de commandes hydrauliques complexes dans des arrangements de vannes empilées, tout provenant du même fournisseur."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"LE PROGRAMME DE VANNES CETOP CHEZ HANSA‑FLEX"}
                    subtitle={"HANSA‑FLEX dispose d'une large sélection de vannes CETOP avec des motifs de trous de connexion normalisés selon la norme ISO 4401 dans quatre tailles nominales différentes (NG 6, NG 10, NG 16 et NG 25)."}
                />
                <ImageText
                    data={CetopValve4} />
            </div>

            <div>
                <SectionHeader
                    title={"VANNES À CARTOUCHES VISSÉES DANS LA BOUTIQUE EN LIGNE HANSA‑FLEX"}
                    subtitle={"HANSA‑FLEX propose une large gamme de vannes à cartouches vissées pour des applications dans les hydrauliques mobiles et stationnaires. Trouvez des vannes adaptées à vos fonctions de commutation requises dans notre boutique en ligne. Nos experts en technologie des fluides sont toujours ravis de vous conseiller."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"ACCESSOIRES POUR LES VANNES CETOP"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"CONSEILS DES EXPERTS EN FLUIDES"}
                    subtitle={"Quelles vannes CETOP vous permettront d'atteindre la fonction de commutation requise de la manière la plus efficace ? Et quel est le meilleur agencement pour les vannes empilées nécessaires ? Nos spécialistes expérimentés vous conseillent par téléphone, e-mail ou même directement sur place afin que vous puissiez trouver la solution la plus adaptée."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"HANSA‑FLEX A LA BONNE VANNE POUR VOUS"}
                    subtitle={"Une qualité de produit impressionnante combinée à un choix énorme et un service de première classe font de nous les principaux spécialistes en technologie des fluides en Europe."}
                />
                <ImageText
                    data={CetopValve5} />
                <ImageText
                    data={CetopValve6}
                    left={false} />
                <ImageText
                    data={CetopValve7} />
            </div>

            <div>
                <SectionHeader
                    title={"CES PRODUITS ET SERVICES PEUVENT AUSSI VOUS INTÉRESSER"}
                    subtitle={"Autres solutions pour la technologie des vannes et le contrôle hydraulique disponibles chez HANSA‑FLEX."}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={ValveTech}
                                title="Technologie de vanne pour tous les domaines d'application"
                                desc={"Avec la technologie des vannes de HANSA‑FLEX, vous bénéficiez de matériaux de haute qualité, d'une construction robuste et d'une longue durée de vie à des prix attractifs."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={MadeToMeasure}
                                title="Solutions sur mesure"
                                desc={"HANSA‑FLEX utilise son savoir-faire et son expérience pour fabriquer vos composants de commande, tels que des blocs de vannes, des arrangements de vannes empilées ou des..."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Purchase}
                                title="Achetez des pompes hydrauliques directement en ligne"
                                desc={"Nous vous proposons une large gamme de pompes hydrauliques, telles que des pompes à engrenages et à pistons axiaux, pour tous les domaines d'utilisation dans les hydrauliques industrielles et mobiles."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>

    )
}

export default CetopValves