import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageText from '../../../Components/ImageText/ImageText'
import HFTabs from '../../../Components/HfTabs/HfTabs'


import Stage from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/Stage.jpg'
import HighFlow from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/HighFlow.jpg'
import ForAllApplication from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/ForAllApplication.jpg'
import RobustMaterial from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/RobustMaterial.jpg'
import ScrewCoupling from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/ScrewCoupling.jpg'
import PlugInCoupling from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/PlugInCoupling.jpg'
import TemperatureControl from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/TemperatureControl.jpg'
import MultiCoupling from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/MultiCoupling.jpg'
import CouplingForAgri from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/CouplingForAgri.jpg'
import SafetyBonus from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/SafetyBonus.jpg'
import PipeCoupling from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/PipeCoupling.jpg'
import Accessories from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/Accessories.jpg'
import ThaLarge from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/ThaLarge.jpg'
import BallValve from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/BallValve.jpg'
import RightConnection from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/RightConnection.jpg'
import LeakProof from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/LeakProof.jpg'


const Couplings = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const featuresData = [
        {
            title: "Conception robuste",
            description: "Les raccords hydrauliques à joint plat sont idéaux pour une utilisation dans des conditions difficiles.",
            image: imgMark
        },
        {
            title: "Qualité certifiée",
            description: "Les raccords hydrauliques HANSA‑FLEX répondent aux exigences des normes ISO 7241, ISO 16028 et ISO 14541.",
            image: imgMark
        },
        {
            title: "Installation rapide",
            description: "Les raccords sont rapides à installer, ce qui permet aux utilisateurs de bénéficier de courts temps de rééquipement.",
            image: imgMark
        },
        {
            title: "Disponibilité excellente",
            description: "Nous maintenons un stock important de produits et d'accessoires adaptés à votre convenance.",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données supplémentaires de FeatureCard
    ];

    const Data1 = {
        title: 'Débit élevé et perte de pression minimale',
        description: "Les raccords hydrauliques de HANSA‑FLEX permettent de connecter rapidement, en toute sécurité et sans fuite l'équipement hydraulique. Les manchons et bouchons de couplage peuvent être enfoncés ou vissés ensemble en fonction du type de couplage. Lorsque la connexion hydraulique est séparée, les vannes des deux côtés ferment automatiquement les connexions ouvertes. Même les flexibles hydrauliques remplis peuvent être détachés de cette manière respectueuse de l'environnement et propre sans perte d'huile hydraulique. Avec leur section transversale optimisée pour l'écoulement, les raccords rapides HANSA‑FLEX évitent également les pertes de pression, le développement de chaleur, l'usure par friction, la cavitation et le vieillissement prématuré du fluide sous pression.",
        image: HighFlow
    }

    const Data2 = {
        title: 'Raccords pour toutes les applications',
        description: "Les raccords sont un élément essentiel de la technologie de connexion dans les usines, dans les champs et les forêts ainsi que sur les chantiers de construction. Le raccord le plus adapté dépend de l'application individuelle. Les experts en hydraulique de HANSA‑FLEX vous aident et vous conseillent sur tous les aspects de la sélection et de la conception de tous vos raccords hydrauliques jusqu'aux questions d'installation.",
        listItems: ["Tous les paramètres techniques, tels que les débits de conception, sont pris en compte", "Large portefeuille de produits pour de nombreux domaines d'utilisation", "Pour les applications en hydraulique stationnaire et mobile"],
        image: ForAllApplication
    }

    const Data3 = {
        title: 'Matériaux robustes',
        description: "Les raccords hydrauliques sont continuellement accouplés et désaccouplés, ce qui les soumet à de lourdes charges. HANSA‑FLEX ne fait donc aucun compromis en matière de respect des normes de qualité les plus élevées pour tous ses raccords hydrauliques, qui impressionnent les utilisateurs par leur longue durée de vie et leur faible fuite.",
        listItems: ["Fabriqué en acier pour une fonctionnalité supérieure même à des pressions élevées", "Disponible également en acier inoxydable ou en laiton pour s'adapter à l'usage prévu", "Un revêtement de surface offre une grande résistance à la corrosion"],
        image: RobustMaterial
    }

    const Data4 = {
        title: 'Avantage de sécurité des raccords rapides',
        description: "Les raccords rapides se désaccouplent automatiquement si la charge de traction longitudinale devient trop importante, empêchant ainsi que les lignes de tuyaux ne soient arrachées et la fuite incontrôlée d'huile hydraulique qui en résulte.",
        listItems: ["Sécurité passive accrue lors des manœuvres sur les rails ou des manœuvres sur l'eau", "Protège les lignes de tuyaux de faible résistance à la traction", "Fabriqué en acier ou en fonte malléable, revêtement de surface en zinc galvanisé", "Convient pour les huiles minérales et bio"],
        image: SafetyBonus
    }

    const Data5 = {
        title: 'Raccords de tuyauterie pour hautes pressions',
        description: "Les raccords de tuyauterie sont conçus pour des pressions élevées et des impulsions de pression. Les plongeurs d'étanchéité à face plate empêchent l'entrée d'air et garantissent de faibles pertes de fuite d'huile. Leur construction robuste rend les raccords de tuyauterie adaptés aux conditions d'utilisation exigeantes avec de grandes fluctuations de pression. Ils trouvent donc une application généralisée dans l'industrie automobile.",
        listItems: ["Connexion et déconnexion sans fuite ni entrée d'air", "Accouplés en vissant ensemble le manchon et le bouchon", "Accouplement et désaccouplement possible jusqu'à 20 bar"],
        image: PipeCoupling
    }

    const Data6 = {
        title: 'Accessoires parfaitement adaptés à vos raccords hydrauliques',
        description: "Les accessoires pour raccords hydrauliques tels que les capuchons de protection contre la poussière ou les joints protègent vos raccords hydrauliques de la saleté et empêchent les particules étrangères de pénétrer et de circuler dans vos systèmes hydrauliques. HANSA‑FLEX propose des brides de fixation et des supports soudés disponibles pour un placement optimal et une fixation sécurisée des raccords sur les tracteurs ou les machines.",
        listItems: ["Dispositifs de protection contre la poussière et la saleté tels que des capuchons de protection contre la poussière et des couvercles", "Brides soudées et à fixation pour une fixation sécurisée des moitiés fixes de raccord rapide"],
        image: Accessories
    }

    const Data7 = {
        listItems: [
            "Les variantes comprennent des moitiés de raccord fixes et libres, des manchons et des bouchons",
            "Fermeture à une ou aux deux extrémités, joint à face plate et peut être accouplé / désaccouplé sous pression",
            "Les capuchons de protection contre la poussière captifs ne peuvent pas être perdus accidentellement",
            "Disponible dans tous les types de filetage commercialement disponibles, tels que BSP, NPT et UNF",
            "Matériaux : acier, acier inoxydable et laiton",
            "Surface : revêtements sans chrome-VI et galvanisés"
        ],
        image: ThaLarge
    };

    // Données de HfTab
    const imageTextData = [
        {
            title: 'Les raccords vissés sont la solution pour une utilisation extrême',
            description: "Leur construction robuste permet aux raccords vissés de supporter des pressions et des impulsions de pression élevées. Ils sont conçus pour les conditions les plus difficiles dans l'industrie et l'agriculture.",
            listItems: ["Peut être accouplé à des outils avec un côté sous pression jusqu'à 100 bars", "Raccords vissés hydrauliques normalisés conformes à la norme ISO 14541", "Protégés contre l'usure prématurée potentiellement causée par les impulsions de pression élevée", "Les filetages auto-sécurisants empêchent le désaccouplement involontaire", "Excellente étanchéité d'une valve conique avec un joint élastomère"],
            image: ScrewCoupling
        },
        {
            title: 'Accouplement et désaccouplement à faible goutte-à-goutte avec un accouplement enfichable',
            description: "Les vannes scellent les connexions de raccord enfichable lorsque le manchon de raccord est tiré en arrière et empêchent la perte d'huile hydraulique. Le double joint torique assure une performance d'étanchéité maximale pour le raccord. Un anneau de verrouillage de sécurité empêche le désaccouplement involontaire. Les versions à étanchéité à face plate des raccords enfichables sont disponibles chez HANSA‑FLEX. Ces raccords hydrauliques limitent l'entrée d'air au minimum lors du couplage et évitent ainsi les fuites d'huile.",
            image: PlugInCoupling
        },
        {
            title: 'Raccords de contrôle de température pour le chaud et le froid',
            description: "Les raccords de contrôle de température sont spécialement conçus pour une utilisation avec des milieux liquides à des températures de -20 °C à +160 °C tels que ceux que l'on trouve dans les systèmes de moulage par injection de plastique. Les raccords rapides disposent d'un dispositif de protection de verrouillage pour garantir la sécurité des personnes et empêcher les dommages à l'environnement dus à une fuite involontaire de médias chauds ou froids tels que l'huile de transfert de chaleur, l'eau chaude ou froide.",
            listItems: [
                "Convient pour une utilisation à des températures de -20 °C à 160 °C",
                "Un dispositif de protection de verrouillage empêche la perte de fluide ou de pression",
                "Fermeture à une ou aux deux extrémités"
            ],
            image: TemperatureControl
        },
        {
            title: "Tout sous contrôle à l'aide de multi- raccords",
            description: "Les multi-raccords permettent de connecter ou de déconnecter rapidement plusieurs lignes et sont fréquemment utilisés avec des accessoires amovibles dans l'agriculture, la sylviculture et la construction.",
            listItems: [
                "Échange rapide et facile des accessoires amovibles",
                "Utilisation facile avec levier à main",
                "Accouplement sans problème même si le système est sous pression",
                "Aucune confusion des connexions ou des voies de circulation"
            ],
            image: MultiCoupling
        },
        {
            title: 'Raccords agricoles HANSA‑FLEX pour une excellente performance',
            description: "Le système de couplage développé par HANSA‑FLEX pour des débits allant jusqu'à 240 l/min est destiné aux machines puissantes utilisées en agriculture, telles que les bennes haute performance. Les bouchons, les tailles de clé et les variantes de connexion sont conformes à la norme ISO-7241, série A (BG3), ce qui signifie que le manchon de couplage à une main ne nécessite aucune modification pour être compatible avec d'autres raccords montés rigides disponibles sur le marché.",
            listItems: [
                "Le couplage sous pleine pression de travail nécessite très peu d'effort physique",
                "L'unité de vanne à grand débit empêche l'érosion",
                "Protection contre les arrachements fiables et système d'engagement mécanique",
                "Dimensions compactes"
            ],
            image: CouplingForAgri
        }
    ];

    const tabTitles = [
        { title: "Raccords vissés", eventKey: "Raccords vissés" },
        { title: "Raccords enfichables", eventKey: "Raccords enfichables" },
        { title: "Raccords de contrôle de température", eventKey: "Raccords de contrôle de température" },
        { title: "Multi-raccords", eventKey: "Multi-raccords" },
        { title: "Raccords pour l'agriculture", eventKey: "Raccords pour l'agriculture" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"RACCORDEMENTS HYDRAULIQUES SÛRS DE HANSA‑FLEX"}
                    paragraph={"Les raccords sont la meilleure option dans les situations où les accessoires doivent être rapidement échangés, les machines connectées en toute sécurité ou les flexibles hautement chargés sur les systèmes hydrauliques mobiles et fixes remplacés. Chez HANSA‑FLEX, vous pouvez trouver des raccords rapides parfaits pour votre application prévue dans divers matériaux tels que l'acier ou l'acier inoxydable et dans une large gamme de tailles et avec divers types de joints d'étanchéité."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"COUPLAGE ET DÉCOUPLAGE FIABLES"}
                    subtitle={"Avec les raccords hydrauliques rapides de HANSA‑FLEX, vous pouvez connecter et déconnecter simplement, rapidement et en toute sécurité des composants hydrauliques."}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
                <ImageText
                    data={Data3} />
            </div>

            <div>
                <SectionHeader
                    title={"COUPLAGE RAPIDE ET SÉCURISÉ"}
                    subtitle={"Les raccords hydrauliques HANSA‑FLEX sont à leur avantage là où une solution simple de connexion et de déconnexion de composants hydrauliques est nécessaire. Ils sont étanches et ne gouttent pas, permettent des débits élevés et des pressions résiduelles dans les conduites hydrauliques."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"UTILISATION SIMPLE, FIABLE EN FONCTIONNEMENT"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"PLUS DE RACCORDS DE HANSA‑FLEX"}
                    subtitle={"Raccords hydrauliques sûrs pour chaque application"}
                />
                <ImageText
                    data={Data4} />
                <ImageText
                    data={Data5}
                    left={false} />
                <ImageText
                    data={Data6} />
            </div>

            <div>
                <SectionHeader
                    title={"LA LARGE GAMME DE RACCORDS DE HANSA‑FLEX"}
                    subtitle={"Vous trouverez une large sélection de raccords hydrauliques fiables de différents types pour chaque usage dans notre boutique en ligne."}
                />
                <ImageText
                    data={Data7} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS POURRAIENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={BallValve}
                                title="Vannes à bille peu d'entretien"
                                desc={"Les vannes à bille HANSA‑FLEX sont utilisées pour fermer et dévier l'écoulement à des pressions allant jusqu'à 500 bar, évitant ainsi la nécessité de convertir ou de désinstaller des composants."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={RightConnection}
                                title="Le bon raccord avec les adaptateurs hydrauliques"
                                desc={"HANSA‑FLEX propose un large portefeuille d'adaptateurs hydrauliques, y compris des adaptateurs filetés, de transition ou d'extrémité de flexible dans toutes les formes de connexion courantes et répondant à tous..."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={LeakProof}
                                title="Raccordement positif étanche avec des raccords de tuyauterie normalisés"
                                desc={"Les raccords de tuyauterie de HANSA‑FLEX reposent sur un ajustement positif serré les uns aux autres pour créer une connexion sûre même lorsqu'ils sont soumis à des pressions de travail élevées, des pics de pression et..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )

}

export default Couplings