import React, { useEffect } from 'react';
import HFTab from '../../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import imgMark from '../../../../assets/mark.png'

import Stage from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/Stage.jpg'
import OrderReplacement from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/OrderReplacement.jpg'
import UniquelyIdentify from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/UniquelyIdentify.jpg'
import PrincipleOfHose from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/PrincipleOfHose.jpg'
import Versatile from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/Versatile.jpg'
import Rapid from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/Rapid.jpg'
import Basic from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/Basic.jpg'
import XCode from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/XCode.jpeg'
import ImageText from '../../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';


const XCodeIdentification = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        image: OrderReplacement,
        title: 'Commandez des pièces de rechange sans erreur et aussi rapidement que possible. ',
        description:"Chaque X-CODE contient la spécification précise d'une ligne de tuyau unique dans un environnement d'utilisation spécifique. Après avoir fourni le code alphanumérique à six chiffres dans n'importe quelle succursale HANSA-FLEX dans le monde, vous pouvez faire fabriquer et livrer aussi rapidement que possible une pièce de rechange identique à l'endroit d'utilisation. La commande de pièces de rechange ne pourrait pas être plus facile, plus rapide ou plus précise.",
    }

    const data2 = {
        image: UniquelyIdentify,
        title: ' Identifiez de manière unique chaque ligne de tuyau. ',
        description: "Pour rendre chaque ligne de tuyau unique et identifiable, le X-CODE est attaché de manière permanente dans une position facilement visible. Selon la situation d'installation et les exigences du client, HANSA-FLEX peut fournir différents types d'identification attachée telles que des étiquettes de pointe, des étiquettes autocollantes ou des plaques en acier inoxydable. Des codes QR et des puces RFID peuvent être intégrés pour permettre leur lecture par une machine. ",
    }

    const data3 = {
        image: PrincipleOfHose,
        title: 'Principes de gestion numérique des tuyaux ',
        description: ' Chaque X-CODE est une sorte de profil numérique contenant toutes les informations pertinentes sur le composant auquel il est attaché. Ces données sont stockées dans une base de données X-CODE personnalisée, que nous mettons gratuitement à la disposition de nos clients dans le portail client My.HANSA-FLEX. En utilisant notre logiciel de gestion des lignes de tuyaux X-CODE Manager, vous pouvez ajouter ou modifier ces données et les utiliser pour la maintenance préventive de vos systèmes hydrauliques.',
    }

    const data4 = {
        image: Versatile,
        title: ' Polyvalent, efficace et axé sur le service ',
        description: "L'étiquette autocollante X-CODE est particulièrement adaptée aux situations d'installation étroites. Elle se fixe directement à la ligne de tuyau et est protégée par un film transparent contre les influences externes telles que l'huile, le frottement et les intempéries. Les informations obligatoires telles que le numéro de série, la date de fabrication et la pression de service maximale ne sont plus embouties comme d'habitude sur l'embout, mais imprimées de manière très lisible sur l'étiquette dans l'un des quatre blocs d'informations. En intégrant les numéros de document et d'article, les descriptions spécifiques au client et les numéros de matériau ainsi que le code QR de la version X-CODE, cette méthode d'identification remplit des fonctions pratiques de service pour le client. ",
    }

    const featuresData = [
        {
            title: "Rapide, simple et précis ",
            description: "Rapide, simple et précis ",
            image: imgMark
        },
        {
            title: "Description individualisée ",
            description: " Les informations spécifiques au client peuvent être intégrées sous forme d'étiquette autocollante au moment de la création. ",
            image: imgMark
        },
        {
            title: "lisible par machine",
            description: "Les codes QR et les puces RFID sont utilisés pour permettre la capture numérique sans erreur des données. ",
            image: imgMark
        },
        {
            title: "Universellement utilisé ",
            description: "Les X-CODEs peuvent être utilisés pour d'autres composants ou groupes de composants complets. ",
            image: imgMark
        }
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"Aussi incontestable qu'une empreinte digitale."}
                    paragraph={"Le X-CODE, le système de codage et d'identification de HANSA-FLEX, a été prouvé en pratique des millions de fois. Chaque ligne de tuyau individuelle est identifiée de manière unique sur la base de son X-CODE, et une pièce de rechange appropriée peut être obtenue dans les plus brefs délais n'importe où dans le monde. Le X-CODE attribue à chaque ligne de tuyau un profil numérique contenant toutes les informations pertinentes. Il constitue la base de la planification de service dans le cadre de la maintenance préventive. "}
                />
            </div>

            <div>
                <SectionHeader
                    title={"Un code pour chaque instance. "}
                    subtitle={"  Le X-CODE unique combine toutes les informations concernant une ligne de tuyau spécifique, constitue la base pour commander un remplacement et pour la gestion des tuyaux. "}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
            </div>

            {/* TODO: Carousel */}

            <div>
                <SectionHeader
                    title={" Le X-CODE facilite votre travail de plusieurs façons. "}
                    subtitle={"Le X-CODE est un outil clé qui simplifie considérablement la maintenance préventive en hydraulique. Découvrez les avantages du X-CODE en un coup d'œil. "}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"L'étiquette auto-adhésive X-CODE "}
                    subtitle={" L'étiquette auto-adhésive X-CODE est particulièrement adaptée aux situations d'installation étroites. "}
                />
                <ImageText
                    data={data4} />
            </div>

            <div>
                <SectionHeader
                    title={"Le codage de vos lignes de tuyaux existantes "}
                    subtitle={" Si vous avez des lignes de tuyaux en cours d'utilisation qui ne sont pas encore codées et que vous souhaitez utiliser le système X-CODE de HANSA-FLEX, parlez-en à votre conseiller HANSA-FLEX concernant le codage ultérieur de vos lignes de tuyaux. Si vous n'avez pas encore de personne de contact désignée, rendez-vous dans votre agence la plus proche ou contactez-nous. "}
                />
            </div>

            <div>
                <SectionHeader
                    title={"X-CODE en pratique"}
                    subtitle={" Le X-CODE rend de nombreux services de HANSA-FLEX encore plus précieux pour vous. "}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Rapid}
                                title="Service hydraulique rapide : encore plus rapide grâce au X-CODE. "
                                desc={"Indiquez le X-CODE lors de la passation de votre commande et nos techniciens de service auront généralement les pièces appropriées à bord immédiatement. "} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={Basic}
                                title="La base pour la gestion des tuyaux "
                                desc={" Planifiez l'inspection et l'entretien réguliers de vos lignes de tuyaux pour garantir le bon fonctionnement sécuritaire de vos systèmes hydrauliques et les conformer à la réglementation en tout temps. "} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={XCode}
                                title="Lignes de tuyaux industriels X-CODE "
                                desc={"De plus en plus de clients utilisent le système d'identification et de codage largement éprouvé pour l'hydraulique pour leurs lignes de tuyaux industriels. "} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default XCodeIdentification