import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import ImageText from '../../Components/ImageText/ImageText'
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import HFTabs from '../../Components/HfTabs/HfTabs'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../Components/ImageCard/ImageCard'
import imgMark from '../../assets/mark.png'

import imgStage from '../../assets/Products/SealingTechnology/Stage.jpg'
import Minimise from '../../assets/Products/SealingTechnology/Minimise.jpg'
import SpecialTurnedComponents from '../../assets/Products/SealingTechnology/SpecialTurnedComponents.jpg'
import CylinderRepairCeals from '../../assets/Products/SealingTechnology/CylinderRepairCeals.jpg'
import SealTechnology from '../../assets/Products/SealingTechnology/SealTechnology.jpg'
import AssortementBox from '../../assets/Products/SealingTechnology/AssortementBox.jpg'
import HydraulicHose from '../../assets/Products/SealingTechnology/HydraulicHose.jpg'
import HydraulicComponent from '../../assets/Products/SealingTechnology/HydraulicComponent.jpg'
import CylinderRepair from '../../assets/Products/SealingTechnology/CylinderRepair.jpg'


const SealingTechnology = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const sealingTech1 = {
        image: Minimise,
        title: "Minimiser les temps d'arrêt avec HANSA‑FLEX",
        description: 'Avec un accès rapide à plus de 11 000 joints, HANSA‑FLEX peut généralement satisfaire la plupart des demandes immédiatement. En faisant appel à plusieurs partenaires très compétents, HANSA‑FLEX peut augmenter ce nombre à 25 000. La majorité des commandes sont expédiées dans les quelques jours qui suivent.',
        listItems: ["Conseils professionnels de la part d'experts expérimentés", "Livraison rapide à partir de stocks étendus", "Fabrication immédiate de joints spéciaux"],
    }

    const sealingTech2 = {
        image: SpecialTurnedComponents,
        title: 'Composants spéciaux tournés produits rapidement et avec une extrême précision',
        listItems: ["Fabrication de joints avec des diamètres de 5 à 700 mm", "Tous les matériaux populaires tels que NBR, HPU, FPM, POM et PTFE disponibles en stock", "Conseils professionnels de la part du personnel expérimenté"],
    }

    const sealingTech3 = {
        image: CylinderRepairCeals,
        title: "Réparation de vérins et joints - tout d'un seul fournisseur",
        description: "Le remplacement rapide des pièces d'usure et des accessoires garantit une longue durée de vie pour vos vérins hydrauliques et des temps d'arrêt réduits pour l'entretien et la maintenance.",
        listItems: ["Réparation de vérins en interne", "Coopération étroite entre notre atelier de vérins et le département de joints", "Conseils spécialisés complets sur l'ensemble du processus"],
    }

    const sealingTech4 = {
        image: SealTechnology,
        listItems: ["Joints standard et spéciaux", "Joints hydrauliques et pneumatiques", "Joints statiques", "Bagues d'étanchéité, joints plats et pièces moulées", "Aides à la mesure pour déterminer le bon joint", "Outil de montage"],
    }

    const sealingTech5 = {
        image: AssortementBox,
        title: "Boîte d'assortiment avec joints toriques aux normes AS / BS",
        description: "Les boîtes d'assortiment en plastique pratiques et robustes contiennent une bonne sélection de joints toriques de haute qualité de différentes tailles pour une large gamme d'applications",
        listItems: ["Utilisable pour des températures de fonctionnement de -30 °C à 100 °C", "328 joints toriques dans 30 tailles", "Jusqu'à 150 bars (ou jusqu'à 500 bars avec bague de support)"],
        buttonName: "VERS LA BOÎTE D'ASSORTIMENT"
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Gamme étendue",
            description: "HANSA‑FLEX propose une gamme extrêmement large de produits de fabricants bien connus",
            image: imgMark
        },
        {
            title: "Conseils professionnels",
            description: "Vous recevez des conseils techniques solides de la part de notre personnel hautement expérimenté",
            image: imgMark
        },
        {
            title: "Large gamme de matériaux",
            description: "Les joints sont disponibles dans de nombreux matériaux tels que NBR, FPM, EPDM, PTFE ou PUR",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"HANSA‑FLEX A LE BON JOINT POUR LE TRAVAIL"}
                    paragraph={"Les systèmes hydrauliques et pneumatiques ne sont aussi bons que leurs joints. Un seul joint défectueux peut entraîner de longs temps d'arrêt et des coûts élevés. HANSA‑FLEX aide à prévenir cela avec son large stock de joints, ses conseils d'experts et le traitement rapide des commandes. Si une conception spéciale est requise, HANSA‑FLEX peut la fabriquer en tant que pièce unique ou en petite série. Vous pouvez trouver un large choix de joints dans la boutique en ligne HANSA‑FLEX."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"LIVRAISON RAPIDE, CONSEILS PROFESSIONNELS ET FABRICATION PRÉCISE"}
                />
                <ImageText
                    data={sealingTech1} />
                <ImageText
                    data={sealingTech2}
                    left={false} />
                <ImageText
                    data={sealingTech3} />
            </div>

            <div>
                <SectionHeader
                    title={"LE PORTFOLIO DE TECHNOLOGIE DE JOINTS HANSA‑FLEX"}
                    subtitle={"HANSA‑FLEX stocke une gamme étendue de joints et exploite un centre de fabrication où des joints spéciaux peuvent être produits rapidement en interne."}
                />
                <ImageText
                    data={sealingTech4} />
            </div>

            <div>
                <SectionHeader
                    title={"JOINTS DE HAUTE QUALITÉ DE HANSA‑FLEX"}
                    subtitle={"Investir dans des produits de haute qualité pour la technologie d'étanchéité est rentable. Les joints sont un élément fonctionnel fondamental dans la technologie des fluides et sont cruciaux pour un fonctionnement sans problème."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"SOLUTIONS HANSA‑FLEX POUR DES PERFORMANCES D'ÉTANCHÉITÉ MAXIMALES"}
                    subtitle={"Notre savoir-faire et notre passion pour la technologie d'étanchéité se reflètent dans nos produits. Nous illustrons cela avec deux exemples sélectionnés."}
                />
                <ImageText
                    data={sealingTech5} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={HydraulicHose}
                                title="Flexibles hydrauliques pour toutes les gammes de pression"
                                desc={"HANSA‑FLEX propose un large spectre de flexibles hydrauliques de haute qualité couvrant toutes les gammes de pression pour une utilisation dans les systèmes hydrauliques mobiles et stationnaires."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={HydraulicComponent}
                                title="Composants hydrauliques d'un fournisseur de services complet"
                                desc={"HANSA‑FLEX dispose d'une large gamme de plus de 4 500 composants hydrauliques tels que des pompes, des moteurs, des accumulateurs de pression, des vannes, des commutateurs et des capteurs pour l'hydraulique"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={CylinderRepair}
                                title="La réparation de vérins est une alternative économique"
                                desc={"Le service de réparation de vérins interne de HANSA‑FLEX restaure une fonctionnalité complète au composant révisé. Les temps d'arrêt sont minimisés et les coûts réduits."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )

}


export default SealingTechnology