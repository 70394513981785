import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';

import imgStage from '../../../assets/Products/HosesAndHoseLines/PtfeHoses/stage.jpg'
import imgSuitable from '../../../assets/Products/HosesAndHoseLines/PtfeHoses/suitable.jpg'
import imgExtensive from '../../../assets/Products/HosesAndHoseLines/PtfeHoses/extensive.jpg'
import imgExtensiveRangeOfServices from '../../../assets/Products/HosesAndHoseLines/PtfeHoses/ExtensiveRangeOfServices.jpg'
import imgMetalHoses from '../../../assets/Products/HosesAndHoseLines/PtfeHoses/MetalHoses.jpg'
import imgIndustrialHoses from '../../../assets/Products/HosesAndHoseLines/PtfeHoses/IndustrialHoses.jpg'
import imgOneOff from '../../../assets/Products/HosesAndHoseLines/PtfeHoses/OneOff.jpg'


const PtfeHoses = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const PtfeHose1 = {
        image: imgSuitable,
        title: 'Convient pour presque tous les milieux',
        description: "Les tuyaux en PTFE de HANSA‑FLEX conviennent aux milieux liquides, solides et gazeux sur une large plage de températures allant de -60 °C à +260 °C. Ils sont particulièrement recommandés pour une utilisation dans les industries chimiques, automobiles, électriques et médicales. Les tuyaux en PTFE sont également utilisés dans la construction navale et dans l'industrie alimentaire.",
        listItems: ["Le matériau de base est approuvé par la FDA", "Résistance élevée à la charge", "Résistant au vieillissement, à l'ozone et aux rayons UV"]
    }

    const PtfeHose2 = {
        image: imgExtensive,
        title: 'Large gamme de produits',
        description: 'Les tuyaux en PTFE de HANSA‑FLEX sont disponibles dans des diamètres nominaux de DN 4 mm à DN 100 mm. Ces tuyaux sont disponibles en variantes gainées de métal, à double paroi ou coudées. Les tuyaux en PTFE antistatiques conviennent à une utilisation dans les zones EX. Nous pouvons répondre à des exigences spécifiques en matière de raccordements à partir de notre gamme de raccords spéciaux.',
        listItems: ["Diamètres nominaux de DN 4 mm à DN 100 mm", "Composants de connexion sertis ou évasés", "Raccords spéciaux de HANSA‑FLEX"]
    }

    const PtfeHose3 = {
        image: imgExtensiveRangeOfServices,
        title: 'Large gamme de services de HANSA‑FLEX',
        description: 'Notre personnel qualifié vous conseille sur la sélection et la conception des tuyaux en PTFE. Les exigences spécifiques des clients sont satisfaites en tant que pièces fabriquées en série, en petite quantité ou en pièces uniques.',
        listItems: ["Conseils complets dans toutes les phases du projet, par téléphone ou sur site", "Utilisation des dernières méthodes de test en assurance qualité", "Solutions hautement flexibles pour les lignes de tuyaux et les raccords"]
    }

    const certifiedQuality = [
        {
            title: "Convient pour l'alimentation (FDA)",
            description: "Neutre en goût et en odeur, facile à nettoyer et bactériologiquement sûr.",
            image: imgMark
        },
        {
            title: "Approuvé pour la construction navale",
            description: "HANSA‑FLEX répond aux exigences de DNV, Lloyd’s Register et de l'American Bureau of Shipping.",
            image: imgMark
        },
        {
            title: "Exceptionnellement durable",
            description: "Longue durée de vie grâce à la résistance aux UV et aux vibrations, incombustible.",
            image: imgMark
        },
        {
            title: "Propriétés anti-adhésives exceptionnelles",
            description: "Des propriétés anti-adhésives exceptionnelles permettent le transport sans problème de substances collantes.",
            image: imgMark
        },

        // Ajouter plus d'objets pour des données de carte supplémentaires
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"TUYAUX EN PTFE POUR DES APPLICATIONS CHIMIQUEMENT EXIGEANTES"}
                    paragraph={"Les tuyaux en PTFE de HANSA‑FLEX se distinguent par leur excellente résistance chimique à la plupart des milieux, leur grande flexibilité et leur longue durée de vie. Dans les industries alimentaire et pharmaceutique, leur neutralité en goût et en odeur ainsi que leur sécurité bactériologique en font des candidats idéaux pour le transport de produits potentiellement problématiques. Dans la construction navale ou l'industrie aérospatiale, les tuyaux en PTFE transportent en toute sécurité du carburant ou de l'eau de refroidissement."}
                //TODO bouton
                />
            </div>

            <div>
                <SectionHeader
                    title={"TUYAUX EN PTFE DE HANSA‑FLEX POUR UNE LARGE GAMME D'APPLICATIONS"}
                />
                <div>
                    <ImageText
                        data={PtfeHose1} />

                    <ImageText
                        data={PtfeHose2}
                        left={false} />

                    <ImageText
                        data={PtfeHose3} />
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"LIGNES DE TUYAUX EN PTFE AVEC DES CARACTÉRISTIQUES DE PRODUIT DE PREMIER ORDRE"}
                    subtitle={"Leur résistance élevée à la température et aux produits chimiques garantit que ces produits PTFE de haute qualité de HANSA‑FLEX peuvent fonctionner comme de véritables polyvalents parmi les lignes de tuyaux dans de nombreux domaines d'application."}
                />
                <FeatureCardCarousel
                    featuresData={certifiedQuality} />
            </div>

            {/* TODO: Vidéo */}
            {/* TODO: Carrousel */}
            <div>
                <SectionHeader
                    title={"TUYAUX INDUSTRIELS DE PREMIER ORDRE DES SPÉCIALISTES DES FLUIDES"}
                    subtitle={"Notre vaste portefeuille de produits contient des tuyaux industriels pour tous les domaines d'utilisation, de l'industrie alimentaire aux tuyaux pour carburant et huile. Nous avons le tuyau adapté pour vous dans le bon matériau, que ce soit du Viton, de l'EPDM, du PUR ou du NBR."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"CES PRODUITS HANSA-FLEX POURRAIENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgMetalHoses} learnMoreLink={"/metal-hoses"}
                                title="Tuyaux métalliques avec d'excellentes propriétés de résistance"
                                desc={"La tolérance élevée à la température et la résistance aux milieux agressifs sont à l'origine des capacités de performance spéciales des tuyaux métalliques."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgIndustrialHoses} learnMoreLink={"/Industrial-hoses"}
                                title="Tuyaux industriels de la plus haute qualité"
                                desc={"HANSA‑FLEX peut produire des tuyaux industriels selon les exigences spécifiques des clients rapidement et compétemment dans son propre atelier de tuyauterie spécialisé."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgOneOff} learnMoreLink={"/Customisedassembly"}
                                title="Solutions spéciales uniques"
                                desc={"Les solutions doivent être aussi diverses et variées que les applications. Les solutions HANSA‑FLEX peuvent aller des produits uniques et uniques aux produits produits en série..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );

}

export default PtfeHoses