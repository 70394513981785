import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgLogistics_Centerspread from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import { Container, Row, Col } from 'react-bootstrap';
import imgLogistics_Imagecard_01 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_01.jpg'
import imgLogistics_Imagecard_02 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_02.jpg'
import imgLogistics_Imagecard_03 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_03.jpg'
import imgLogistics_Imagecard_04 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_04.jpg'
import imgLogistics_Imagecard_05 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_05.jpg'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import imgLogistics_Imagetext_01 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagetext_01.jpg'
import imgLogistics_Imagetext_02 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagetext_02.jpg'
import imgLogistics_Imagecard_06 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_06.jpg'
import imgLogistics_Imagecard_07 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_07.jpg'
import imgLogistics_Imagecard_08 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_08.jpg'



const Logisticssolutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);


  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;



  const featuresData = [
    {
      title: "Utilisation optimisée des ressources",
      description: "Vous gagnez du temps sur la logistique et la production tout en réduisant les coûts de matériel et d'entreposage.",
      image: imgMark
    },
    {
      title: "La plus haute compétence en matière de technologie des fluides",
      description: "En tant que fabricant et revendeur dans le domaine de la technologie des fluides, nous vous proposons des solutions pratiques et éprouvées.",
      image: imgMark
    },
    {
      title: "Excellente disponibilité",
      description: "Nous livrons toujours dans les délais et sommes en mesure de faire face à des pics de demande soudains.",
      image: imgMark
    },
    {
      title: "Flexibilité maximale",
      description: "Nous pouvons travailler avec vous pour redéfinir le type et l'étendue de nos services quand vous le souhaitez.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];

  const Logistics_Imagetext_01 = {
    image: imgLogistics_Imagetext_01,
    title: 'Conseils en face à face et planification à long terme',
    listItems: ["Nous attachons une grande importance aux bonnes relations à long terme avec nos clients. La première étape pour nous est toujours une consultation en face à face, au cours de laquelle nous vous rencontrons sur place pour convenir ensemble de la solution optimale pour votre situation actuelle. Nous avons toujours une longueur d'avance et nous travaillons avec vous pour concevoir des solutions flexibles et évolutives. Lorsque votre entreprise se développe au fil du temps et que votre volume de commandes augmente progressivement, nous discutons de la situation avec vous et modifions votre concept logistique pour en tenir compte. Cette approche vous permet de disposer de la meilleure solution à tout moment et dans n'importe quelle situation."],
  }

  const Logistics_Imagetext_02 = {
    image: imgLogistics_Imagetext_02,
    title: 'Solutions numériques',
    listItems: ["Les processus numérisés sont essentiels pour les entreprises qui ont un volume de commandes élevé si elles souhaitent obtenir des dispositions plus efficaces pour l'approvisionnement en matériel. Nous vous soutenons dans ces objectifs, entre autres avec des solutions de scanner et des apps capables d'accélérer votre processus de commande. En savoir plus sur nos services numériques."],
  }










  return (
    <div>

      <CenterStage
        imageSrc={imgLogistics_Centerspread}
        title={"SOLUTIONS LOGISTIQUES PERSONNALISÉES DE HANSA-FLEX"}
        paragraph={"Gagnez du temps et économisez des coûts grâce aux solutions logistiques personnalisées de HANSA-FLEX. Nous élaborons avec vous un concept logistique optimal pour l'approvisionnement en composants de la technologie des fluides, qui s'adapte de manière flexible à l'évolution de vos besoins. Nous tenons compte de votre stock actuel, de vos besoins et de vos objectifs de développement afin de vous apporter le meilleur soutien possible."}
      />

      <SectionHeader
        title={"L'APPROVISIONNEMENT OPTIMAL EN COMPOSANTS"}
        subtitle={"En tant que revendeur et partenaire système, HANSA-FLEX dispose des connaissances nécessaires pour adapter votre logistique de manière optimale aux besoins de votre entreprise."}
      />






      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={8} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgLogistics_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Optimiser la gestion des entrepôts avec HANSA-FLEX" desc="Optimisez vos stocks de marchandises et mettez de l'ordre dans vos magasins d'équipement grâce à un système de gestion d'entrepôt conçu de manière systématique. Éliminez les pertes de temps dues à des recherches et à des tris inutiles. Réduisez les coûts de matériel et d'approvisionnement en travaillant avec des listes d'articles standardisées." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgLogistics_Imagecard_02} learnMoreLink="/metal-hoses" title="Mise en place d'une livraison Kanban" desc="Le système Kanban HANSA-FLEX est la solution idéale pour un système de production structuré de manière cohérente et un besoin très important de pièces. Il vous aidera à réduire" />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgLogistics_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Service de kitting pour les composants de la technologie des fluides" desc="Économisez du temps d'assemblage et d'installation sur votre site de production en demandant à HANSA-FLEX de vous fournir les composants hydrauliques et pneumatiques dont vous avez besoin, préassemblés dans des kits d'installation personnalisés." />
          </Col>






        </Row>
      </Container>

      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgLogistics_Imagecard_04} learnMoreLink="/hydraulic-hoses" title="Fabrication de tuyaux mobiles dans des conteneurs-ateliers hydrauliques" desc="Vous pouvez fabriquer vous-même des lignes de tuyaux sur mesure à tout moment et en tout lieu grâce à nos conteneurs-ateliers hydrauliques afin de réduire au minimum les temps d'arrêt. Les conteneurs sont équipés d'outils, de tuyaux" />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgLogistics_Imagecard_05} learnMoreLink="/metal-hoses" title="HANSA-FLEX est présent sur place avec son service de travaux en usine" desc="Nos employés, avec leur atelier de fabrication intégré, sont disponibles pour travailler dans vos locaux. Nos techniciens hautement qualifiés fabriquent les" />
          </Col>
        </Row>
      </Container>

      <SectionHeader
        title={"DES SOLUTIONS LOGISTIQUES PARFAITEMENT ADAPTÉES À VOS BESOINS"}
        subtitle={"Chacune de nos solutions est conçue individuellement pour maximiser l'efficacité de vos processus de commande et d'entreposage des composants de la technologie des fluides et ainsi augmenter votre productivité."}
      />


<SectionHeader
        title={"DES SOLUTIONS LOGISTIQUES QUI ÉVOLUENT AVEC VOUS"}
        subtitle={"HANSA-FLEX vous soutient dès le début avec des solutions logistiques flexibles capables de s'adapter sans cesse à vos besoins changeants au fur et à mesure que votre entreprise se développe."}
      />

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>

      <div >
        <br />
        <ImageText data={Logistics_Imagetext_01} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Logistics_Imagetext_02} />
      </div>


      <SectionHeader
        title={"CE QUE DISENT LES CLIENTS SATISFAITS"}
        subtitle={"En tant que partenaire système, HANSA-FLEX impressionne les clients en Allemagne et à l'étranger avec des composants de haute qualité dans le domaine de la technologie des fluides et des processus logistiques bien pensés."}
      />


[4/2 10:39 AM] 90000692
{/* TODO: QUOTE_Carrousel */}

<SectionHeader
        title={"CES THÈMES PEUVENT ÉGALEMENT VOUS INTÉRESSER"}
        subtitle={""}
      />

<Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgLogistics_Imagecard_06} learnMoreLink="/hydraulic-hoses" title="Assemblage et installation" desc="Découvrez la gamme de services HANSA-FLEX pour le montage et l'installation, la rénovation, l'optimisation et la planification de nouveaux systèmes hydrauliques." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgLogistics_Imagecard_07} learnMoreLink="/metal-hoses" title="Maintenance préventive" desc="Des inspections régulières de vos systèmes et de leurs composants par les experts de HANSA-FLEX Industrial Service sont un moyen sûr d'éviter les pannes et les arrêts." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgLogistics_Imagecard_08} learnMoreLink="/Industrial-hoses" title="Aide rapide en cas d'urgence" desc="Les arrêts imprévus doivent être résolus le plus rapidement possible. Le service d'urgence HANSA-FLEX est prêt à intervenir 24 heures sur 24, 365 jours par an." />
          </Col>






        </Row>
      </Container>












    </div>
  )
}

export default Logisticssolutions