import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgEngineering_Centerspread from '../../../assets/Services/Engineering/Engineering_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import ImageText from '../../../Components/ImageText/ImageText'

import { Container, Row, Col } from 'react-bootstrap';
import imgEngineering_Imagecard_01 from '../../../assets/Services/Engineering/Engineering_Imagecard_01.jpg'
import imgEngineering_Imagecard_02 from '../../../assets/Services/Engineering/Engineering_Imagecard_02.jpg'
import imgEngineering_Imagecard_03 from '../../../assets/Services/Engineering/Engineering_Imagecard_03.jpg'
import imgEngineering_Imagecard_04 from '../../../assets/Services/Engineering/Engineering_Imagecard_04.jpg'
import imgEngineering_Imagecard_05 from '../../../assets/Services/Engineering/Engineering_Imagecard_05.jpg'
import imgEngineering_Imagecard_06 from '../../../assets/Services/Engineering/Engineering_Imagecard_06.jpg'




import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import imgEngineering_Imagetext_01 from '../../../assets/Services/Engineering/Engineering_Imagetext_01.jpg'
import imgEngineering_Imagetext_02 from '../../../assets/Services/Engineering/Engineering_Imagecard_02.jpg'
import imgEngineering_Imagetext_03 from '../../../assets/Services/Engineering/Engineering_Imagecard_03.jpg'
import imgEngineering_Imagetext_04 from '../../../assets/Services/Engineering/Engineering_Imagetext_04.jpg'
import imgEngineering_Imagetext_05 from '../../../assets/Services/Engineering/Engineering_Imagetext_05.jpg'


const Engineering = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
      }, []);
    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;



    const featuresData = [
        {
            title: "Des performances optimales",
            description: "Les produits conçus par HANSA-FLEX sont conçus pour fonctionner parfaitement ensemble.",
            image: imgMark
        },
        {
            title: "Des conseils d'experts dès le début",
            description: "L'équipe de fabrication de prototypes de HANSA-FLEX offre des connaissances spécialisées depuis le premier jour jusqu'à la préparation de la production en série.",
            image: imgMark
        },
        {
            title: "Tous de la même source ",
            description: "Les groupes de composants pré-équipés peuvent être gérés comme des pièces individuelles dans l'inventaire Kanban.",
            image: imgMark
        },
        {
            title: "Vos données en 3D",
            description: "Conteneurs réutilisables, pas d'utilisation de boîtes en carton, réduction des besoins de transport et du poids.",
            image: imgMark
        }
        // Add more objects for additional FeaturesCard data
    ];



    const Engineering_Imagetext_01 = {
        image: imgEngineering_Imagetext_01,
        title: 'Modèles 3D pour la conception et la simulation',
        description: ["Utilisez nos modèles 3D pour la conception de votre système. Nous mettons à votre disposition des modèles numériques en 3D de nos composants de tuyaux et de canalisations que vous pouvez télécharger. Vous pouvez intégrer ces modèles 3D et les informations produit CAO des composants très facilement dans vos modèles et dessins de conception. Vous gagnez ainsi du temps car vous n'avez pas à modéliser ou dessiner nos composants à partir des données du catalogue et vous pouvez vérifier immédiatement les situations d'installation et de connexion."],
    }

    const Engineering_Imagetext_02 = {
        image: imgEngineering_Imagetext_02,
        title: 'CAO et ECAD pour la conception théorique des composants',
        description: ["HANSA-FLEX utilise des logiciels CAD et ECAD très performants pour créer des dessins de conception et des schémas de circuit pour les systèmes électriques et hydrauliques. Ces systèmes sont conçus pour répondre aux exigences exactes du client. Chaque dessin fait l'objet d'un contrôle de faisabilité virtuel qui permet aux concepteurs de voir si le projet peut être réalisé dans la réalité. Ce n'est que lorsque tous les contrôles théoriques ont été effectués avec succès que les données sont transmises à l'atelier de production."],
    }


    const Engineering_Imagetext_03 = {
        image: imgEngineering_Imagetext_03,
        title: 'Normes de conception les plus élevées pour la production en série',
        description: ["Les dessins en 3D réalisés par nos techniciens définissent le standard de qualité élevé de HANSA-FLEX auquel les fournisseurs doivent se conformer pour la fabrication de tous les composants. Nous obtenons ainsi une grande sécurité des processus et pouvons vous offrir un rapport qualité-prix optimal. Quel que soit le lieu de fabrication d'un composant, celui-ci présente toujours un aspect identique. La production standardisée permet de réduire considérablement le risque d'erreurs de tolérance lors de la fabrication de machines et d'installations hydrauliques."],
    }


    const Engineering_Imagetext_04 = {
        image: imgEngineering_Imagetext_04,
        title: 'Conception de pièces de rechange',
        description: "Vous avez besoin de remplacer une vanne sur mesure, un cylindre, une canalisation ou un autre composant, mais ils ne sont plus disponibles ? Nous scannons la géométrie de votre composant à l'aide de la technologie laser, créons un nouveau dessin de conception et fabriquons le produit pour vous.",
        listitems: ['Conception et fabrication de tous types de composants pour la technologie des fluides', "Géométrie exacte des composants capturée à l'aide d'un bras de mesure de haute précision", 'Peut également être réalisé rapidement, si nécessaire.'],
    }

    const Engineering_Imagetext_05 = {
        image: imgEngineering_Imagetext_05,
        title: 'Amélioration de la conception',
        description: ["HANSA-FLEX utilise des logiciels CAD et ECAD très performants pour créer des dessins de conception et des schémas de circuit pour les systèmes électriques et hydrauliques. Ces systèmes sont conçus pour répondre aux exigences exactes du client. Chaque dessin fait l'objet d'un contrôle de faisabilité virtuel qui permet aux concepteurs de voir si le projet peut être réalisé dans la réalité. Ce n'est que lorsque tous les contrôles théoriques ont été effectués avec succès que les données sont transmises à l'atelier de production. "],
    }




    return (
        <div>

            <CenterStage
                imageSrc={imgEngineering_Centerspread}
                title={"INGÉNIERIE ET PLANIFICATION DE PROJETS AVEC HANSA-FLEX"}
                paragraph={"Les spécialistes expérimentés de HANSA-FLEX vous assistent dans toutes les tâches liées à l'ingénierie et à la planification de projets de systèmes et de composants hydrauliques, de la conception à la fabrication. Vous pouvez compter sur nos connaissances spécialisées en matière de technologie des fluides pour la réalisation de prototypes, le développement d'unités hydrauliques et d'autres développements de produits en interne."}
            />


            <SectionHeader
                title={"CONÇU ET FABRIQUÉ POUR VOUS PAR HANSA-FLEX"}
                subtitle={""}
            />


            <Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgEngineering_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Séries de produits internes de HANSA-FLEX" desc="Les composants de haute qualité développés en interne par nos ingénieurs présentent des avantages décisifs en matière d'installation et d'entretien." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgEngineering_Imagecard_02} learnMoreLink="/metal-hoses" title="Fabrication de prototypes selon les exigences individuelles du client" desc="HANSA-FLEX conçoit et développe des prototypes répondant aux exigences individuelles du client sous la forme de spécifications, de dessins ou d'échantillons et prend en charge l'ensemble du processus de fabrication." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgEngineering_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Fabrication de centrales hydrauliques sur mesure" desc="HANSA-FLEX conçoit et fabrique des centrales hydrauliques de haute qualité pour répondre aux souhaits individuels du client, en tenant compte des exigences particulières." />
                    </Col>

                </Row>
            </Container>


            <SectionHeader
                title={"UNE GAMME COMPLÈTE DE SERVICES D'INGÉNIERIE"}
                subtitle={"HANSA-FLEX Engineering assure en interne le développement, la fabrication de prototypes et d'unités de puissance, ainsi que la conception de systèmes. Nous sommes en mesure d'aider nos clients à chaque étape du développement de produits de haute qualité, de la planification à la production en série."}
            />



            <div>
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <SectionHeader
                title={"CONÇU SELON L'ÉTAT LE PLUS RÉCENT DE LA TECHNOLOGIE"}
                subtitle={"Design is elemental to engineering. Our precisely prepared digital design drawings and 3D models make system design much easier and ensure the highest manufacturing quality."}
            />










            <div >
                <br />
                <ImageText data={Engineering_Imagetext_01} left={false} />
            </div>

            <div >
                <br />
                <ImageText data={Engineering_Imagetext_02} />
            </div>

            <div >
                <br />
                <ImageText data={Engineering_Imagetext_03} left={false} />
            </div>

            <SectionHeader
                title={"EN SAVOIR PLUS SUR LES DÉVELOPPEMENTS INTERNES CHEZ HANSAFLEX"}
                subtitle={""}
            />


            {/* TODO: Imagetext_navigation bar */}

            <SectionHeader
                title={"REFABRIQUER DES VERSIONS IDENTIQUES DE COMPOSANTS ET AMÉLIORER LEUR CONCEPTION"}
                subtitle={""}
            />

            <div >
                <br />
                <ImageText data={Engineering_Imagetext_04} left={false} />
            </div>

            <div >
                <br />
                <ImageText data={Engineering_Imagetext_05} />
            </div>

            <Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgEngineering_Imagecard_04} learnMoreLink="/hydraulic-hoses" title="Tuyaux hydrauliques HANSA-FLEX" desc="Les tuyaux hydrauliques HANSA-FLEX, dont certains sont développés en interne, répondent aux normes de qualité les plus élevées de l'industrie." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgEngineering_Imagecard_05} learnMoreLink="/metal-hoses" title="Raccords de tuyauterie de haute qualité" desc="Les raccords de tuyauterie ont été l'un des premiers développements internes de HANSA-FLEX. Aujourd'hui encore, ces composants hydrauliques permettent de raccorder les canalisations en toute sécurité." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgEngineering_Imagecard_06} learnMoreLink="/hydraulic-hoses" title="Une technologie d'étanchéité fiable" desc="La qualité d'un système hydraulique dépend de celle de ses joints. Un seul joint défectueux peut entraîner de longs temps d'arrêt. HANSA-FLEX permet d'éviter cela." />
                    </Col>


                </Row>
            </Container>







        </div>
    );
}

export default Engineering;