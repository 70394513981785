import React, { useEffect } from 'react';

import Stage from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/Stage.jpeg'
import Dispence from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/Dispence.png'
import UnMistakable from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/UnMistakable.png'
import YouHaveNever from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/YouHaveNever.png'
import YourPersonalControl from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/YourPersonalControl.png'
import DivideYourMachine from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/DivideYourMachine.png'
import Depict from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/Depict.png'
import FindEverything from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/FindEverything.png'
import Inspect from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/Inspect.png'
import Legally from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/Legally.png'
import FourRoles from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/FourRoles.png'
import UseApp from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/UseApp.png'
import SuitableSolution from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/SuitableSolution.png'


import CenterStage from '../../../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../../../Components/ImageText/ImageText';
import imgMark from '../../../../../assets/mark.png'
import FeatureCardCarousel from '../../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';

const XCodeManager = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data = {
        image: Dispence,
        description: "X-CODE Manager peut faire bien plus pour vous que simplement agir comme un tableur. La solution logicielle basée sur navigateur a été spécialement développée chez HANSA-FLEX pour la maintenance et vous offre toutes les fonctionnalités importantes pour gérer vos lignes de tuyaux hydrauliques. Vous pouvez utiliser X-CODE Manager dans sa version bureau ou sous forme d'application mobile. Bénéficiez d'un profil numérique individuel avec toutes les informations pertinentes pour chaque ligne de tuyau. Décrivez vos machines et équipements dans votre propre structure de dossiers et saisissez les données hors ligne directement sur la machine. Effectuez les inspections visuelles régulières conformément à la DGUV et à la BetrSichV vous-même à l'aide du module d'inspection et gérez les enregistrements d'inspection numériques à un emplacement central. Vous découvrirez qu'il existe de nombreuses autres fonctions pratiques à utiliser dans vos processus de gestion des tuyaux.",
    }

    const featuresData = [
        {
            title: " Chaque détail",
            description: "Recueillez toutes les informations concernant vos lignes de tuyaux de manière numérique en un seul endroit. ",
            image: imgMark
        },
        {
            title: "Chaque machine ",
            description: "Organisez les emplacements, les machines et les composants dans des structures de dossiers personnalisées. ",
            image: imgMark
        },
        {
            title: "Chaque date programmée",
            description: " Organisez tous vos intervalles de maintenance et d'inspection dans des aperçus pratiques.",
            image: imgMark
        },
        {
            title: " Chaque exigence légale",
            description: " Bénéficiez lors de l'exécution des inspections visuelles conformément à la BetrSichV dans notre module d'inspection conforme à la législation.",
            image: imgMark
        }
    ];


    const data1 = {
        image: UnMistakable,
        title: 'Incontestable et sans erreur. Prouvé des millions de fois. ',
        description: "  L'identification des tuyaux avec le X-CODE constitue la base de votre maintenance préventive en hydraulique. Le X-CODE est un code alphanumérique unique représentant toutes les caractéristiques de votre ligne de tuyaux hydrauliques et, à l'avenir, de vos filtres, accumulateurs de pression et autres composants. X-CODE Manager stocke tous les X-CODEs pour vos composants ainsi que toutes les données pertinentes et fonctionnalités pour la maintenance et la commande de pièces de rechange. ",
    }

    const data2 = {
        image: YouHaveNever,
        title: "Vous n'avez jamais vu vos lignes de tuyaux de cette manière. ",
        description: "  X-CODE Manager vous offre de nombreuses options pour une gestion rapide et facile de vos lignes de tuyaux hydrauliques. Combinez toutes les fonctionnalités pertinentes pour chaque ligne de tuyau individuelle dans un profil numérique personnalisé et ajoutez des informations telles que l'affectation de la machine, les dates et les enregistrements d'inspection. Organisez votre maintenance préventive exactement comme vous le souhaitez et selon vos besoins. Structurez votre base de données personnalisée précisément comme vous le souhaitez. ",
    }

    const data3 = {
        image: YourPersonalControl,
        title: 'Votre centre de contrôle personnel pour la maintenance',
        description: "  La page de démarrage clairement structurée est un bon point de départ pour travailler avec X-CODE Manager. Trouvez intuitivement votre chemin dans les différentes zones pour accéder rapidement aux informations que vous souhaitez. Obtenez la toute dernière vue d'ensemble mise à jour quotidiennement de toutes vos lignes de tuyaux, machines et dates de maintenance. Organisez toutes les informations pertinentes sur les inspections prescrites par la loi et la documentation conforme à la loi. ",
    }

    const data4 = {
        image: DivideYourMachine,
        title: 'Divisez vos machines en unités pratiques à des fins de maintenance. ',
        description: " Utilisez la partie des machines du logiciel pour gérer efficacement et commodément l'ensemble de votre collection de machines et d'équipements. Divisez vos machines en différents composants selon le niveau de détail souhaité. Attribuez chaque ligne de tuyau avec précision à vos composants pour optimiser la planification de vos dates de maintenance. ",
    }

    const data5 = {
        image: Depict,
        title: 'Représentez la structure de vos opérations dans le logiciel exactement comme elle est dans la réalité. ',
        description: " Organisez vos dates de maintenance dans des dossiers clairement définis et personnalisés. Triez vos informations au niveau de détail souhaité, par exemple, selon l'emplacement, le département, l'installation de production, la machine ou l'équipement. Représentez la structure de votre organisation comme vous le souhaitez dans votre base de données personnalisée X-CODE Manager.   ",
    }

    const data6 = {
        image: FindEverything,
        title: ' Trouvez tout rapidement, à chaque fois. ',
        description: "  Utilisez la recherche en direct pratique dans toutes les zones pour trouver rapidement et directement toutes les informations dont vous avez besoin, telles que les X-CODEs, les dossiers, les machines ou les dates. Recherchez dans votre vue d'ensemble des X-CODEs les caractéristiques qui vous intéressent, telles que les numéros d'inventaire, les centres de coûts ou les numéros de document, et utilisez des recherches contextuelles dans les résultats filtrés. Recherchez de manière fiable à travers des fragments individuels de résultats pour obtenir toutes les informations sur les X-CODEs défectueux ou incomplets. ",
    }

    const data7 = {
        image: Inspect,
        title: 'Inspecter et réorganiser au bon moment ne se fait pas par hasard ',
        description: "  Optimiser votre planification de maintenance en fonction de l'évaluation des risques prescrite par la loi et de la classification de chaque ligne de tuyau individuelle. Planifiez et enregistrez vos futures dates de maintenance et d'entretien. Anticipez et commandez les lignes de tuyau de remplacement requises en quelques clics seulement. ",
    }

    const data8 = {
        image: Legally,
        title: 'Inspection conforme à la législation selon la BetrSichV et la DGUV ',
        description: "Utilisez le module d'inspection dans la licence EXPERT pour effectuer des inspections visuelles récurrentes conformément à la BetrSichV et à la DGUV en interne sans support externe. Le logiciel guide les personnes officiellement autorisées pour la technologie des lignes hydrauliques étape par étape à travers l'inspection, permettant une saisie pratique et directe des données et évitant ainsi les erreurs de transcription ultérieures. Le module d'inspection est continuellement mis à jour afin que vous soyez toujours informé de la dernière situation en ce qui concerne les exigences légales. (Licence EXPERT et supérieure) ",
    }

    const data9 = {
        image: FourRoles,
        title: 'Quatre rôles pour chaque instance  ',
        description: "  Définissez des concepts de permissions utilisateur adaptés à vos données et utilisez-les comme base pour optimiser vos processus et séquences de maintenance de l'équipement hydraulique. Attribuez à vos utilisateurs l'un des quatre rôles avec des permissions utilisateur différentes : de lecture seule à une administration complète. Définissez des permissions de lecture individuelles pour chaque utilisateur et chaque ordinateur. (Licence EXPERT et supérieure) ",
    }

    const data10 = {
        image: UseApp,
        title: "Utilisez l'application à tout moment, sur la route et hors ligne. ",
        description: "  Utilisez X-CODE Manager avec la licence FLEXIBLE sur votre tablette ou smartphone directement à la machine. Modifiez vos données en mode hors ligne pratique si vous n'avez pas accès à Internet. Cela vous évite d'avoir à écrire laborieusement les codes et les erreurs de transcription éventuelles. (Licence FLEXIBLE et supérieure) ",
    }


    const data11 = {
        image: SuitableSolution,
        description: "Vous pouvez utiliser X-CODE Manager dans divers packages de licence dans le cadre d'un accord de logiciel en tant que service. Votre conseiller clientèle HANSA-FLEX se fera un plaisir de vous aider à choisir la solution la plus adaptée à votre entreprise. Contactez-nous par e-mail à l'adresse my@hansa-flex.de ou appelez-nous au +49 (0)421 48907 766 si vous n'avez pas encore de partenaire de contact désigné chez HANSA-FLEX. Un de nos conseillers clients vous contactera dès que possible. Vous souhaitez autoriser plus d'utilisateurs que ceux indiqués ci-dessus pour X-CODE Manager ? Vous pouvez ajouter autant de modules complémentaires, chacun pour 10 utilisateurs supplémentaires, que vous le souhaitez.",
    }


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={" Offrez-vous la meilleure vue d'ensemble. À partir de 0,00 euro par mois. "}
                    paragraph={" X-CODE Manager dans le portail client My.HANSA-FLEX est la solution logicielle pratique pour la gestion de vos systèmes hydrauliques. Créez et gérez votre base de données personnalisée avec toutes les informations pertinentes sur vos lignes de tuyaux hydrauliques dans X-CODE Manager. Le logiciel vous aide à exploiter vos systèmes en toute sécurité et en conformité avec la loi en tout temps. Bénéficiez de la meilleure vue d'ensemble des machines, des composants et des dates planifiées, tout en optimisant la planification future de vos tâches de maintenance. "}
                />
            </div>

            <div>
                <SectionHeader
                    title={" Dispensez-vous des tableurs maison. "}
                />
                <ImageText
                    data={data} />
            </div>

            <div>
                <SectionHeader
                    title={"Seul X-CODE Manager peut faire tout cela."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={" DÉCOUVREZ-EN PLUS SUR X-CODE MANAGER"}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
                <ImageText
                    data={data4} left={false} />
                <ImageText
                    data={data5} />
                <ImageText
                    data={data6} left={false} />
                <ImageText
                    data={data7} />
                <ImageText
                    data={data8} left={false} />
                <ImageText
                    data={data9} />
                <ImageText
                    data={data10} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"Nous sommes heureux de faire encore plus pour vous. "}
                    subtitle={"Forfait de service de gestion des tuyaux avec de nombreux services pour la maintenance préventive et l'entretien de vos systèmes. "}
                />
            </div>

            <div>
                <SectionHeader
                    title={"Une solution adaptée pour chaque entreprise. "}
                />
                <ImageText
                    data={data11} />
            </div>
        </div>
    )
}

export default XCodeManager