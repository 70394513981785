import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import ImageText from '../../Components/ImageText/ImageText'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../Components/ImageCard/ImageCard'

import imgStage from '../../assets/Products/Approvals/Stage.jpg'
import CertifiedReliability from '../../assets/Products/Approvals/CertifiedReliability.jpg'
import HoseLines from '../../assets/Products/Approvals/HoseLines.jpg'
import Precision from '../../assets/Products/Approvals/Precision.jpg'



const Approvals = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        listItems: ["Bureau Américain de la Classification (ABS)", "Office Fédéral de l'Équipement de la Bundeswehr, de la Technologie de l'Information et du Soutien en Service (BAAINBw)", "Société de Classification de la Chine (CCS)", "Class NK", "Deutsche Bahn (DB)", "DNV Det Norske Veritas (DNV)", "DVGW - Association Technique et Scientifique Allemande pour le Gaz et l'Eau (DVGW)", "Lloyd's Register (LR)", "Directive Relative aux Équipements Marins (MED)", "RINA", "RINA Militaire", "Registre Maritime Russe (RMRS)", "Entreprise Spécialisée Conformément à la Loi Allemande sur les Ressources en Eau (WHG)"]
    }

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"APPROBATIONS NATIONALES ET INTERNATIONALES"}
                    paragraph={"HANSA‑FLEX dispose d'un grand nombre d'approbations nationales et internationales ainsi que de qualifications de fournisseur. Les autorités, les clients gouvernementaux, les associations industrielles et les sociétés de classification renommées confirment et documentent régulièrement la haute qualité et l'adéquation de nos offres en tant que fabricant, fournisseur et prestataire de services au cours de divers audits et procédures d'approbation."}
                />
            </div>
            <div>
                <SectionHeader
                    title={"TESTÉ ET APPROUVÉ"}
                    subtitle={"technik@hansa-flex.com"}
                />
                <ImageText data={data1} left={false} />
            </div>

            <div>
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={4} ><ImageCard image={CertifiedReliability}
                            title="Fiabilité et qualité certifiées"
                            desc={"HANSA‑FLEX AG dispose de systèmes de gestion certifiés ISO pour la gestion de la qualité, la gestion de l'environnement et la sécurité au travail."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={HoseLines}
                            title="Lignes de tuyaux pour tous les usages"
                            desc={"HANSA‑FLEX propose des lignes de tuyaux hydrauliques de haute qualité pour tous types d'applications, de la production individuelle à la production en série pour l'équipement d'origine."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={Precision}
                            title="Précision et variété : Conduites de HANSA‑FLEX"
                            desc={"HANSA‑FLEX fabrique des conduites coudées en divers matériaux en tant qu'articles individuels ou en série pour tous les types de raccords courants."} /></Col>
                    </Row>
                </Container>
            </div>

        </div>
    )

}

export default Approvals