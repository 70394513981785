import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageText from '../../../Components/ImageText/ImageText'
import HFTabs from '../../../Components/HfTabs/HfTabs'

import Stage from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/Stage.jpg'
import SAE from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/SAE.jpg'
import CetopFlange from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/CetopFlange.jpg'
import PumpFlange from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/PumpFlange.jpg'
import PurchaseFlange from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/PurchaseFlange.jpg'
import Compliment from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/Compliment.jpg'
import Quality from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/Quality.jpg'
import Universal from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/Universal.jpg'
import leakProof from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/eakProof.jpg'
import SpecialFittings from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/SpecialFittings.jpg'
import MadeToMeasure from '../../../assets/Products/HydraulicConnectionTechnology/Flanges/MadeToMeasure.jpg'

const Flanges = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const Data1 = {
        title: 'Bride SAE',
        description: 'Les brides SAE sont disponibles dans deux classes de pression : 3000 PSI pour les diamètres nominaux de ½” à 5” et 6000 PSI de ½” à 3”. Toutes les brides haute pression SAE sont fabriquées en acier ou en acier inoxydable de type 1.4404.',
        listItems: ["Pression de travail jusqu'à 420 bars", "Pour les applications standard et haute pression", "Surface non traitée ou galvanisée"],
        buttonName: "VOIR TOUT",
        image: SAE,
    }

    const Data2 = {
        title: 'Brides ISO 6164 et CETOP',
        description: 'Nous pouvons vous fournir nos brides ISO 6164 et les raccords de bride CETOP HANSA‑FLEX dans des diamètres nominaux de ½” à 5”. Toutes les brides ISO 6164 et CETOP sont disponibles en acier de type 1.4404.',
        listItems: ["Pression de travail jusqu'à 420 bars", "Diamètre nominal entre DN 13 (1/2”) et DN 127 (5”)", "Acier inoxydable et matériaux d'étanchéité alternatifs sur demande"],
        image: CetopFlange
    }

    const Data3 = {
        title: 'Brides de pompe à engrenages',
        description: "En plus d'un filetage externe métrique conformément à la norme DIN 3901 dans les tailles L8 – L42 et S6 – S38, nous proposons également des brides de pompe avec filetages internes en pouces dans deux variantes de construction(rondes, carrées).",
        listItems: ["Revêtement de zinc-nickel de haute qualité", "Versions droites et à 90°, chacune avec des cercles de pas de 30 à 55", "En acier ou en aluminium"],
        image: PumpFlange,
        buttonName: "EN SAVOIR PLUS"
    }

    const Data4 = {
        listItems: ["Brides à quatre trous, brides haute pression, brides de pompe", "Demi-brides, brides pleines vissées et soudées", "Pour des applications jusqu'à 420 bars et disponibles dans toutes les tailles courantes", "Huile noire, acier, acier inoxydable, métrique, pouce", "Électroplaqué", "Tous les filetages et connexions SAE, NPT, BSPP, UNF, JIC, CETOP"],
        image: PurchaseFlange,
    };

    const featuresData = [
        {
            title: "Matériau robuste",
            description: "Convient pour des pressions de travail élevées et des charges hautement dynamiques.",
            image: imgMark
        },
        {
            title: "Conception résistante à la pression",
            description: "La surface d'étanchéité a une face plate, scellée de manière fiable à l'aide de joints toriques",
            image: imgMark
        },
        {
            title: "Connexion libérable",
            description: "Les deux moitiés de la bride peuvent être démontées et remontées sans endommager les composants",
            image: imgMark
        },
        {
            title: "Opération sécurisée",
            description: "Connexion étanche des tuyaux et des composants dans les systèmes hydrauliques",
            image: imgMark
        },
        // Ajoutez plus d'objets pour des données supplémentaires de FeaturesCard
    ];

    // Données de HfTab
    const imageTextData = [
        {
            title: 'Conforme à toutes les normes et directives',
            description: "Les raccords de bride de HANSA‑FLEX répondent aux exigences des normes pertinentes telles que l'ISO 6162, la norme SAE J 518 C, la norme DIN 3901 / 3902, l'ISO/DIS 6164 et d'autres directives internationales. Toutes les brides sont conçues en tenant compte des exigences du marché international. Par conséquent, nous ajustons régulièrement le portefeuille de produits pour permettre des développements spécifiques supplémentaires.",
            listItems: [
                "Fabriqué conformément aux normes et directives internationales",
                "Développement du portefeuille en fonction des exigences du marché",
                "Livraison fiable à temps et, si nécessaire, en séquence"
            ],
            image: Compliment
        },
        {
            title: 'Haute qualité des produits',
            description: "Les raccords de bride ISO 6164, CETOP et SAE de HANSA‑FLEX sont disponibles en acier et en acier inoxydable de type 1.4404, avec un revêtement de zinc galvanisé. Les brides de pompe à engrenages sont disponibles en aluminium ou en acier avec un revêtement de zinc-nickel de haute qualité. Cela offre une résistance à la corrosion de 720 heures contre la rouille rouge.",
            listItems: [
                "Matériaux : acier, acier inoxydable et aluminium",
                "Finition de surface : galvanisé au zinc, revêtement de zinc-nickel",
                "Version soudée ou forgée selon le type de bride"
            ],
            image: Quality
        },
        {
            title: "Pour tous les domaines d'application",
            description: "Les brides sont principalement utilisées là où les raccords de tuyauterie sont inappropriés en raison de leur taille, de la pression de travail requise ou du manque d'espace disponible. Nous avons un large portefeuille de produits pour chaque domaine d'application en technologie des fluides, tels que l'hydraulique industrielle, mobile ou maritime.",
            listItems: [
                "Usines de moulage par injection plastique et de moulage sous pression d'aluminium",
                "Presses hydrauliques, équipements de levage et de convoyage",
                "Machines de construction, agricoles et forestières"
            ],
            image: Universal
        }
    ];

    const tabTitles = [
        { title: "Conforme aux normes", eventKey: "Conforme aux normes" },
        { title: "Qualité", eventKey: "Qualité" },
        { title: "Universel", eventKey: "Universel" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"RACCORDEMENTS DE BRIDE DE HANSA‑FLEX"}
                    paragraph={"Les raccords de bride HANSA‑FLEX sont idéaux pour les applications à gros diamètre, à haute pression et dans des situations d'installation restreintes. Les raccords de bride sont intrinsèquement résistants aux chocs et aux vibrations. De plus, ils peuvent être installés sans grand effort, même ceux avec de grands diamètres nominaux, et dans les espaces d'installation les plus restreints. Ils offrent de nombreux avantages par rapport aux raccords vissés de même taille."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"BRIDES CONFORMES AUX EXIGENCES SAE, ISO, CETOP ET BRIDES DE POMPE À ENGRENAGES"}
                    subtitle={"Tous les raccords de bride HANSA‑FLEX sont disponibles dans divers types, tels que adaptateur et bride fixe, bloc, connexions soudées et brasées."}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
                <ImageText
                    data={Data3} />
            </div>

            <div>
                <SectionHeader
                    title={"LES BRIDES SONT UNE CLASSE SUPÉRIEURE DE RACCORDEMENT DE TUYAUTERIE"}
                    subtitle={"C'est la technique de connexion la plus adaptée pour les pipelines soumis à des charges hautement dynamiques, à des pressions de travail élevées et dans des espaces d'installation restreints"}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"BRIDES SPÉCIALES DES EXPERTS HYDRAULIQUES"}
                    subtitle={"Notre large sélection de raccords de bride n'inclut pas le bon produit pour votre application ? Si c'est le cas, parlez-nous directement ! Nous pouvons fabriquer des raccords de bride selon vos besoins individuels."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"ACHETEZ DES BRIDES EN LIGNE SUR LE MAGASIN EN LIGNE DE HANSA‑FLEX !"}
                    subtitle={"Découvrez le portefeuille de produits de connexion de bride du fournisseur de systèmes HANSA‑FLEX"}
                />
                <ImageText
                    data={Data4} />
            </div>

            <div>
                <SectionHeader
                    title={"VOUS NE POUVEZ PAS VOUS TROMPER AVEC LES RACCORDEMENTS DE BRIDE HANSA‑FLEX"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"PLUS DE COMPOSANTS HYDRAULIQUES DE HANSA‑FLEX"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={leakProof}
                                title="Connexion positive étanche avec des raccords de tuyauterie normalisés"
                                desc={"Les raccords de tuyauterie HANSA‑FLEX reposent sur un ajustement positif serré les uns aux autres pour créer une connexion sûre, même lorsqu'ils sont soumis à des pressions de travail élevées, des pics de pression et..."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={SpecialFittings}
                                title="Raccords spéciaux et tuyaux en tant que pièces fabriquées sur mesure, petites séries ou séries."
                                desc={"Dans le centre de fabrication de tuyaux et raccords spéciaux de HANSA‑FLEX, les composants sont fabriqués avec précision, contrôlés par ordinateur pour les applications OEM ou de pièces de rechange."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={MadeToMeasure}
                                title="Tuyaux coudés sur mesure"
                                desc={"Le centre de cintrage de tuyaux HANSA‑FLEX peut produire des tuyaux coudés individuellement ou par lots. Ses services comprennent également des tests de faisabilité assistés par ordinateur et 3D."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )

}

export default Flanges