import React, { useEffect } from 'react';
import imgPerformedHoses from '../../../assets/Products/HosesAndHoseLines/performed Hoses/performedHoses.jpg'
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgHFfrom from '../../../assets/Products/HosesAndHoseLines/performed Hoses/HFfrom.jpg'
import imgIndividual from '../../../assets/Products/HosesAndHoseLines/performed Hoses/individual.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../Components/ImageText/ImageText';
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgHighPerformance from '../../../assets/Products/HosesAndHoseLines/performed Hoses/highPerformance.jpg'
import imgPreformedHoseRequirement from '../../../assets/Products/HosesAndHoseLines/performed Hoses/PreformedHoseRequirement.jpg'
import imgMadeToMeasure from '../../../assets/Products/HosesAndHoseLines/performed Hoses/MadeToMeasure.jpg'
import imgHoseForApplication from '../../../assets/Products/HosesAndHoseLines/performed Hoses/HoseForApplication.jpg'
import imgXtrHighPressureHose from '../../../assets/Products/HosesAndHoseLines/performed Hoses/XtrHighPressureHose.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';



function PerformedHoses() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const MadeTOMeasure1 = {
        image: imgHFfrom,
        title: 'De la conception à la préparation à la production en série chez HANSA-FLEX',
        description: "HANSA-FLEX dispose d'un large portefeuille de tuyaux préformés standard et propose des solutions complètes pour les clients ayant besoin de tuyaux préformés individuellement fabriqués selon leurs besoins spécifiques :",
        listItems: ["Conçus par l'équipe d'ingénierie de HANSA-FLEX", "De la prototypage à la production en série", "Détermination numérique facultative des données XYZ"]
    }

    const MadeToMesure2 = {
        image: imgIndividual,
        title: 'Tuyaux préformés individuels pour toutes les géométries',
        description: "Les tuyaux préformés doivent être conçus et fabriqués parfaitement pour s'adapter à la situation d'installation prévue. Par conséquent, la première étape consiste à fabriquer des mandrins moulés sur la base de la géométrie finale requise du tuyau en acier ou en acier inoxydable. Le matériau du tuyau préformé est ensuite enroulé ou étiré et prend la géométrie exacte du mandrin moulé. Plusieurs couches peuvent être appliquées de sorte que, par exemple, la couche interne du tuyau préformé réponde aux exigences de résistance du milieu spécifiées par le client. Les tuyaux préformés peuvent être renforcés avec des tresses textiles ou métalliques. Des composants métalliques peuvent être vulcanisés dans les couches ou des composants en caoutchouc peuvent être vulcanisés sur eux pour permettre encore plus de possibilités de conception de tuyaux et d'arrangements de fixation.",
    }

    const MadeToMesure3 = {
        image: imgPreformedHoseRequirement,
        listItems: ["Solutions personnalisées pour presque tous les degrés de flexion", "Extrudé ou enroulé sur mandrin pour s'adapter à une application spécifique", "Renforcé avec des inserts de tissu en polyester ou en rayonne", "Les tuyaux préformés peuvent incorporer plusieurs entrées/sorties"]
    }

    const HighPerformance1 = {
        image: imgHighPerformance,
        title: 'Tuyaux préformés haute performance',
        description: "HANSA-FLEX installe des tuyaux préformés pour l'alimentation en carburant, l'eau de refroidissement et l'air du turbocompresseur sur le jet ski Marine Hypercraft de 320 ch fabriqué par Belassi. Les tuyaux préformés doivent non seulement résister aux températures élevées directement dans le moteur, à l'eau de mer corrosive et au rayonnement solaire, mais aussi aux charges mécaniques qui leur sont appliquées pendant les déplacements. Des vitesses pouvant atteindre 120 km/h et des sauts pouvant atteindre 5 mètres de hauteur posent des exigences particulières aux tuyaux.",
    }

    const performedHosesFrom = [
        {
            title: "Forme libre",
            description: "HANSA-FLEX produit des solutions individuelles avec des courbes de presque tous les rayons.",
            image: imgMark
        },
        {
            title: "Résistance élevée aux charges",
            description: "Des couches de renforcement peuvent être incorporées pour des applications à haute pression ou sous vide sur demande.",
            image: imgMark
        },
        {
            title: "Résistance aux températures élevées",
            description: "Plage de températures de fonctionnement de -40°C à +320°C, selon le mélange de matériaux.",
            image: imgMark
        },
        {
            title: "Qualités de mélange de matériaux confirmées lors des tests",
            description: "Par exemple, des mélanges certifiés selon DIN 73411 ou les normes de protection contre l'incendie DIN 5510 / EN 45545.",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données de FeaturesCard supplémentaires
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgPerformedHoses}
                    title={"TUYAUX PRÉFORMÉS HANSA-FLEX POUR UNE GRANDE FLEXIBILITÉ DANS LES ESPACES LES PLUS RESTREINTS"}
                    paragraph={"Les tuyaux préformés sont généralement nécessaires lorsque des tuyaux conventionnels ne peuvent pas être utilisés en raison de l'espace d'installation limité. Par exemple, dans des situations où un tuyau transportant du carburant ou du liquide de refroidissement dans des moteurs ou des batteries devrait être fortement plié, les tuyaux préformés HANSA-FLEX constituent une solution efficace et économique. Ils sont très élastiques, très robustes et peuvent être fabriqués dans presque toutes les formes."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"TUYAUX PRÉFORMÉS SUR MESURE DE HANSA-FLEX"}
                />
                <div>
                    <ImageText
                        data={MadeTOMeasure1} />
                    <ImageText
                        data={MadeToMesure2}
                        left={false} />
                </div>
            </div>

            <div>
                <div>
                    <SectionHeader
                        title={"TUYAUX PRÉFORMÉS HANSA-FLEX, VOTRE PARTENAIRE POUR DES SYSTÈMES INNOVANTS"}
                        subtitle={"Les experts de HANSA-FLEX, avec leurs années d'expérience et leur connaissance approfondie de l'industrie, peuvent toujours déterminer la forme et le mélange de matériaux parfaits pour n'importe quel besoin complexe du client."}
                    />

                    <FeatureCardCarousel
                        featuresData={performedHosesFrom} />
                </div>
            </div>

            {/* TODO: Carousal */}
            {/* TODO: Avis client */}

            <div>
                <ImageText
                    data={HighPerformance1} />
            </div>

            <div>
                <SectionHeader
                    title={"TUYAUX PRÉFORMÉS PRÉCISÉMENT ADAPTÉS AUX BESOINS DU CLIENT"}
                    subtitle={"HANSA-FLEX adapte parfaitement les tuyaux préformés commandés par le client aux dimensions, aux exigences matérielles et au domaine d'application."}
                />
                <ImageText
                    data={MadeToMesure3}
                    left={false}
                />
            </div>

            <div>
                <SectionHeader
                    title={"CES PRODUITS HANSA-FLEX PEUVENT ÉGALEMENT VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgMadeToMeasure} learnMoreLink={"/pipelines"}
                                title="Tuyaux personnalisés en tant qu'articles uniques, petites séries ou séries"
                                desc={"HANSA-FLEX fabrique des tuyaux spécifiques à l'application en tant qu'articles uniques ou en séries, avec des tolérances dimensionnelles selon la norme DIN ISO 2768-1."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHoseForApplication} learnMoreLink={"/Industrial-hoses"}
                                title="Tuyaux industriels pour toutes les applications"
                                desc={"L'atelier de tuyaux spécialisés de HANSA-FLEX produit des tuyaux pour toutes les applications. Les produits sont naturellement fabriqués avec la plus haute qualité et livrés rapidement."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgXtrHighPressureHose} learnMoreLink={"/ext-high-pfm-hoses"}
                                title="Tuyaux extrêmement haute pression pour répondre aux exigences les plus élevées"
                                desc={"Avec des pressions de travail allant jusqu'à 3 200 bars, les tuyaux extrêmement haute pression de HANSA-FLEX pour les applications hydrauliques, de sablage d'eau, d'huile et de gaz offrent une sécurité même sous"} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    );

}

export default PerformedHoses;
