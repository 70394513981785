import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageText from '../../../Components/ImageText/ImageText'
import HFTabs from '../../../Components/HfTabs/HfTabs'

import Stage from '../../../assets/Products/HydraulicConnectionTechnology/BallValves/Stage.jpg'
import TwoWay from '../../../assets/Products/HydraulicConnectionTechnology/BallValves/TwoWay.jpg'
import MultiWay from '../../../assets/Products/HydraulicConnectionTechnology/BallValves/MultiWay.jpg'
import LowAndHigh from '../../../assets/Products/HydraulicConnectionTechnology/BallValves/LowAndHigh.jpg'
import Safe from '../../../assets/Products/HydraulicConnectionTechnology/BallValves/Safe.jpg'
import Right from '../../../assets/Products/HydraulicConnectionTechnology/BallValves/Right.jpg'
import LeakProof from '../../../assets/Products/HydraulicConnectionTechnology/BallValves/LeakProof.jpg'

const BallValves = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const Data1 = {
        title: 'Vannes à boisseau sphérique droites à deux voies',
        description: "Les vannes à boisseau sphérique droites à deux voies sont utilisées là où il est nécessaire de couper de manière fiable le fluide sous pression. En tournant l'arbre de commande, le flux dans les vannes 2/2 voies peut être fermé et ouvert. Une manière simple et sûre de couper le flux dans les systèmes hydrauliques.",
        listItems: ["Fonctions de commutation à 2 voies, L ou T", "Pour couper le flux"],
        image: TwoWay
    }

    const Data2 = {
        title: 'Vanne à boisseau sphérique multi-voies',
        description: "Les vannes à boisseau sphérique multi-voies ont un alésage en L, T ou X. En déplaçant la sphère, le flux peut être divisé entre plusieurs sorties ou le flux vers diverses parties d'un système de canalisation peut être coupé.",
        listItems: ["Fonctions de commutation à 3 et 4 voies, L, T ou X", "Pour le contrôle et la direction", "Diviser le flux entre plusieurs sorties"],
        image: MultiWay
    }

    const Data3 = {
        listItems: [
            "Vannes à boisseau sphérique droites et multi-voies à alésages L, T ou X",
            "Convient pour des températures de -20 °C à +60 °C",
            "Nombreuses options de connexion, telles que les filetages SAE et les raccords de tuyauterie 24°",
            "Sous forme bloc et forgée sans réduction du débit",
            "Zinc plaqué, sans Cr-VI ou galvanisé",
            "Entraînement pneumatique et électro-mécanique motorisé sur demande"
        ],
        image: LowAndHigh
    }

    const featuresData = [
        {
            title: "Flexible à utiliser",
            description: "Large gamme d'options de connexion, également utilisables comme raccords de fin de ligne.",
            image: imgMark
        },
        {
            title: "Résistant à la pression",
            description: "Très bonne étanchéité pour un fonctionnement à long terme à différentes plages de pression.",
            image: imgMark
        },
        {
            title: "Opération conviviale",
            description: "Commutation simple sans outils, conception robuste de la vanne à boisseau sphérique",
            image: imgMark
        },
        {
            title: "Sécurité fiable",
            description: "Performances de sécurité et d'étanchéité testées, fournies avec des capuchons de protection.",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données supplémentaires de FeatureCard
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"VANNES À BOISSEAU SPHÉRIQUE HANSA‑FLEX POUR UNE ÉTANCHÉITÉ MAXIMALE ET UNE FIABILITÉ"}
                    paragraph={"Les vannes à boisseau sphérique HANSA‑FLEX sont utilisées pour couper et dévier le flux dans les systèmes de tuyauterie hydraulique à des pressions allant jusqu'à 500 bar, évitant ainsi la nécessité de convertir ou de désinstaller des composants. Les vannes ont une sphère flottante entre les joints, ce qui signifie qu'elles sont peu d'entretien et n'ont que de faibles pertes grâce à un grand alésage. Les vannes à boisseau sphérique HANSA‑FLEX répondent aux exigences de toutes les normes et réglementations en matière de résistance et de performance d'étanchéité."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"NOTRE PORTFOLIO DE PRODUITS DANS LE DOMAINE DES VANNES À BOISSEAU SPHÉRIQUE"}
                    subtitle={"Les vannes à boisseau sphérique coupent et dévient de manière fiable le flux de fluide"}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"COUPER ET DÉVIER LE FLUIDE SOUS PRESSION"}
                    subtitle={"Les vannes à boisseau sphérique de HANSA‑FLEX fonctionnent parfaitement dans les systèmes de tuyauterie hydraulique."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            {/* TODO: Carousel */}

            <div>
                <SectionHeader
                    title={"VANNES À BOISSEAU SPHÉRIQUE POUR BASSES ET HAUTES PRESSIONS"}
                    subtitle={"Notre portefeuille couvre des vannes à boisseau sphérique de tailles allant de DN 4 à DN 40 dans divers matériaux tels que l'acier ou l'acier inoxydable, différents designs et variantes, y compris des versions basse pression conformes à la norme DVGW."}
                />
                <ImageText
                    data={Data3} />
            </div>

            <div>
                <SectionHeader
                    title={"D'AUTRES COMPOSANTS HYDRAULIQUES DE HANSA‑FLEX"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={Safe}
                                title="Raccords hydrauliques sûrs et fiables"
                                desc={"Les raccords de HANSA‑FLEX vous permettent de connecter et déconnecter rapidement et facilement les lignes de tuyaux. Cela vous fait gagner du temps dans l'assemblage, la réparation et le remplacement des composants."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Right}
                                title="La bonne connexion avec des adaptateurs hydrauliques"
                                desc={"Les adaptateurs HANSA‑FLEX s'adaptent parfaitement à tous les types de connexion et normes disponibles sur le marché pour produire une connexion étanche de différents filetages."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={LeakProof}
                                title="Connexion positive étanche avec des raccords de tuyauterie"
                                desc={"Les raccords de tuyauterie normalisés de HANSA‑FLEX créent une connexion sûre même lorsqu'ils sont soumis à des pressions de travail élevées, des pics de pression extrêmes et des vibrations."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )

}

export default BallValves