import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import Stage from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/Stage.jpg'
import Assessment from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/Assessment.jpg'
import Planning from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/Planning.jpg'
import Implementation from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/Implementation.jpg'
import Services from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/Services.jpg'
import HFIndustrialService from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/HFIndustrialService.jpg'
import HFFluidService from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/HFFluidService.jpg'
import Innovation from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/Innovation.jpg'


const ModernisationAndOptimisation = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        image: Assessment,
        title: 'Évaluation de votre système hydraulique',
        description: "La rénovation peut souvent, mais pas toujours, être une alternative rapide et rentable à l'achat d'un système entièrement neuf. Ce n'est que si le reste de l'équipement est en bon état qu'il vaut la peine de rénover ou de mettre à niveau l'hydraulique. Cependant, ce n'est pas la seule raison pour laquelle nous évaluons soigneusement votre système hydraulique. Nous vérifions également des facteurs tels que la consommation d'énergie et la productivité. Après avoir pris en compte tous ces aspects, un jugement prudent peut être porté sur la question de savoir si la modernisation promet une meilleure valeur que l'achat de nouveaux équipements. L'évaluation montre également quelles parties du système ont le potentiel d'être optimisées et comment cela pourrait être fait",
    }

    const data2 = {
        image: Planning,
        title: 'Planification de votre projet de rénovation',
        description: " Nos experts travaillent avec vous pour établir une liste des exigences. Quels sont les gains d'efficacité possibles ? Quelles normes doivent être respectées ? Combien d'énergie devrait être économisée ? Y a-t-il des valeurs de paramètres de performance que vous aimeriez ou devez atteindre ? Sur la base des réponses à ces questions, nous planifions les travaux de rénovation et de modification, sélectionnons des composants appropriés et établissons un calendrier de temps et de coûts. Vous pouvez toujours compter sur les connaissances spécialisées de nos experts dans les domaines de la technologie de contrôle, de la tuyauterie et de l'installation. ",
    }

    const data3 = {
        image: Implementation,
        title: ' Mise en œuvre des projets de rénovation',
        description: "Vous pouvez entièrement compter sur les techniciens de service expérimentés et parfaitement formés du Service Industriel HANSA-FLEX pour la mise en œuvre de tous les projets de rénovation et de modernisation. Ils possèdent une expertise spécialisée approfondie et des années d'expérience pratique. Une formation professionnelle continue garantit la qualité constamment élevée du travail qu'ils réalisent. Que ce soit pour la technologie de contrôle ou l'installation de tuyaux et de flexibles, tout le travail est réalisé selon le niveau de qualité HANSA-FLEX habituel. Nous faisons ce que nous promettons, et la qualité parle d'elle-même.",
    }

    const data4 = {
        image: Services,
        listItems: ["Localisation et analyse des défauts", "Analyse économique des projets de rénovation", "Accord sur le développement d'une solution personnalisée adaptée", "Planification et conseils concernant le projet de rénovation", "Exécution de tous les travaux liés à la technologie des fluides"
        ]
    }

    const featuresData = [
        {
            title: "Évaluation et conseils transparents ",
            description: "Nos experts évaluent correctement votre système et offrent des conseils justes et compétents. ",
            image: imgMark
        },
        {
            title: "Planification méticuleuse ",
            description: " Avant le début des travaux de rénovation, nous établissons un plan de projet détaillé comprenant des jalons. ",
            image: imgMark
        },
        {
            title: "Normes et qualité ",
            description: "Nos experts respectent toutes les normes et offrent la qualité éprouvée de HANSA-FLEX.  ",
            image: imgMark
        },
        {
            title: "Tout sous un même toit",
            description: "Des techniciens parfaitement formés et une disponibilité de matériaux optimale. ",
            image: imgMark
        }
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"MODERNISATION ET RÉNOVATION DES SYSTÈMES HYDRAULIQUES "}
                    paragraph={" La modernisation ou la rénovation d'un système hydraulique peut souvent être une alternative efficace à l'achat d'un nouveau système. C'est également un moyen d'améliorer l'efficacité énergétique et la productivité, ou de se conformer à de nouvelles normes. Nos experts expérimentés vous conseillent et vous accompagnent depuis l'évaluation initiale de votre système existant jusqu'à la conception et la mise en œuvre de votre projet de modernisation ou de rénovation."}
                />
            </div>

            <div>
                <SectionHeader
                    title={'MODERNISATION ET OPTIMISATION DANS UN PACKAGE COMPLET '}
                    subtitle={"De l'analyse à la mise en œuvre : Profitez d'une mise à niveau ou d'une optimisation de votre système avec tout fourni par le même fournisseur "}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
            </div>

            <div>
                <SectionHeader
                    title={" Les arrêts de production peuvent être évités au moment de l'achat."}
                    subtitle={"Le rythme rapide de l'innovation et de l'électrification dans le domaine des machines et équipements signifie que des composants clés, tels que les pièces de rechange ou les logiciels, ne sont plus disponibles seulement quelques années après l'achat. Dans le cadre de la gestion de l'obsolescence, nous cherchons à maximiser la durée de vie utile de vos systèmes hydrauliques."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"MODERNISATION DES SYSTÈMES HYDRAULIQUES AUX DERNIÈRES NORMES TECHNOLOGIQUES"}
                    subtitle={"La modernisation et la rénovation des systèmes hydrauliques avec les conseils et le soutien de HANSA-FLEX offrent de nombreux avantages et apportent une véritable valeur ajoutée à votre entreprise. "}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            {/* TODO: Carousel */}

            <div>
                <SectionHeader
                    title={"NOS SERVICES POUR LA MODERNISATION ET L'OPTIMISATION"}
                    subtitle={"En tant que partenaire système, nous vous apportons un soutien complet sur tous les aspects de l'optimisation et de la rénovation de vos machines et systèmes, dans le but d'augmenter l'efficacité et la productivité."}
                />
                <ImageText
                    data={data4} />
            </div>

            <div>
                <SectionHeader
                    title={"CE QUE TOUT LE MONDE DEVRAIT SAVOIR SUR LA GESTION DES FLUIDES"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={HFIndustrialService} learnMoreLink={"/assembly-and-installation"}
                                title="Service Industriel HANSA-FLEX"
                                desc={" Notre équipe de techniciens de service expérimentés du Service Industriel HANSA-FLEX vous soutient dans tous les travaux de maintenance, d'entretien et d'installation sur les équipements mobiles et "} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={HFFluidService} learnMoreLink={"/fluid-service"}
                                title="Service de Fluides HANSA-FLEX "
                                desc={"Des soins réguliers de l'huile sont un aspect clé pour garantir le bon fonctionnement sans problème des systèmes et des installations hydrauliques. "} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Innovation} learnMoreLink={"/Engineering"}
                                title="Innovation et fabrication "
                                desc={"En tant que partenaire système, HANSA-FLEX vous fournit une gamme complète de services d'ingénierie, du développement et de la conception de systèmes jusqu'à leur mise en œuvre, le tout à partir d'un "} /></Col>
                        </Row>
                    </Container>
                </div>

            </div>

        </div>
    )
}

export default ModernisationAndOptimisation