import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgMark from '../../../assets/mark.png';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import imgHydraulicHosesForEvery from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/hydraulicHosesForEvery.jpg'
import imgInThermoplastic from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/InThermoplastic.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import imgSteelOrTextile from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/steelOrTextile.jpg'
import imgTestHydraulic from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/testedHydraulic.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgOnsiteAdvice from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/onSiteAdvice.jpg'
import imgProducedInProven from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/producedInProven.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import imgSafetyEquipment from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/safetyEquipment.jpg'
import imgHighQuality from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/highQuality.jpg'
import imgSafeAndDependable from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/safeAndDependable.jpg'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import Promoter from '../../../Components/Promoter/Promoter';
import imgTabletMobile from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/tabletMobile.png'
import imgHydraulicHoseLine from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/HydraulicHoseLine.jpg'
import HFTabs from '../../../Components/HfTabs/HfTabs';
import imgSeriesProduction from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/SeriesProduction.jpg'
import imgPlantInPlantProduction from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/PlantInPlantProduction.jpg'
import imgHoseManagement from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/HoseManagement.jpg'
import imgHydraulicLineInstallation from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/HydraulicLineInstallation.jpg'
import imgHoseTesting from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/HoseTesting.jpg'






function HydraulicHose() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const InThermoplastic1 = {
        image: imgInThermoplastic,
        title: 'En matériaux thermoplastiques ou élastomères',
        description: "Les tuyaux hydrauliques élastomères se distinguent par leur bonne résistance aux huiles et au vieillissement et conservent leur flexibilité, même à des températures aussi basses que -40 °C. Les avantages des tuyaux thermoplastiques incluent leur faible poids, leur excellente résistance chimique et leur flexibilité. Les tuyaux élastomères et thermoplastiques sont extrêmement résistants aux rayonnements UV et ont une grande résistance aux charges d'impulsion.",
    }

    const InThermoplastic2 = {
        image: imgSteelOrTextile,
        title: 'Supports de pression en acier ou en textiles',
        description: "Deux types différents d'enroulement sont utilisés pour le renforcement en acier dans la couche de support de pression. Des fils d'acier tressés sont utilisés pour les tuyaux tressés. La tresse autour de la couche de base du tuyau est formée en tissant le fil alternativement dans deux directions différentes. Dans les tuyaux renforcés par spiral, le fil d'acier est enroulé autour de la couche interne. En utilisant jusqu'à six couches, cette forme de construction est adaptée aux pressions élevées allant jusqu'à 500 bar. Dans le cas des tubes renforcés de textiles, des fibres synthétiques de haute technologie telles que l'aramide assurent une très bonne stabilité de pression. De plus, ces tuyaux sont flexibles et légers et sont utilisés dans des applications à basse pression jusqu'à 100 bar.",
    }

    const InThermoplastic3 = {
        image: imgTestHydraulic,
        title: 'Tuyaux hydrauliques testés avec une excellente disponibilité',
        description: "Dans le cadre de notre système d'assurance qualité, tous les tuyaux hydrauliques de la gamme de produits HANSA- FLEX sont régulièrement testés sur nos propres bancs d'essai de pression d'impulsion et de rupture.De plus, HANSA - FLEX est capable de tester des tuyaux hydrauliques et des combinaisons tuyau / équipement, qui sont fabriqués selon des exigences spécifiques des clients, telles que la compatibilité avec des pressions plus élevées ou des scénarios d'utilisation alternatifs. En termes de disponibilité, nous ne décevons pas non plus. En tant que plus grand revendeur et fournisseur de systèmes hydrauliques en Europe, nous pouvons compter sur nos deux principaux entrepôts et notre réseau de succursales pour assurer une excellente disponibilité.",
    }

    const OnsiteAdvice1 = {
        image: imgOnsiteAdvice,
        title: 'Conseils sur site par des techniciens expérimentés',
        description: "Ce n'est qu'avec de l'expérience et du savoir-faire qu'il est possible de déterminer de manière fiable quels tuyaux hydrauliques sont les plus adaptés à un usage spécifique. Les spécialistes des fluides HANSA-FLEX offrent des conseils complets et sont heureux de vous montrer vos options d'optimisation grâce au choix des matériaux et à la conception du système. Vous pouvez contacter nos équipes dans l'une des succursales HANSA-FLEX du monde entier. Nos techniciens de service sur site sont également heureux de vous rencontrer directement sur place et de vous conseiller sur l'installation de vos tuyaux hydrauliques.",
    }

    const OnsiteAdvice2 = {
        image: imgProducedInProven,
        title: "Produit avec la qualité éprouvée de HANSA-FLEX",
        description: "Dans plus de 450 succursales à travers l'Allemagne, les techniciens de HANSA- FLEX fabriquent des tuyaux hydrauliques pré-assemblés et prêts à installer selon les données X - CODE ou vos échantillons.Les tuyaux sont sertis, testés et nettoyés professionnellement.Les normes de qualité les plus élevées s'appliquent à ces tuyaux hydrauliques pré-assemblés. Le résultat est une connexion positive étanche entre le tuyau hydraulique et l'accessoire connecté, assurant la sécurité d'utilisation, même à des pressions élevées.",
    }

    const OnsiteAdvice3 = {
        image: imgHydraulicHoseLine,
        listItems: ["En matériau élastomère ou thermoplastique", "Supports de pression avec une ou deux couches de tresse en acier", "Supports de pression jusqu'à six couches de fil d'acier enroulé en spirale", "Supports de pression textiles pour la flexibilité et l'allègement du poids", "Diamètres nominaux de 5 à 76 mm, plages de pression jusqu'à 500 bar", "Composants conçus pour fonctionner parfaitement ensemble"]
    }

    const hoseLinesAdvantagesfeaturesData = [
        {
            title: "Excellente disponibilité",
            description: "En tant que plus grand revendeur d'équipements hydrauliques en Europe, nous pouvons compter sur la flexibilité et la disponibilité des produits.",
            image: imgMark
        },
        {
            title: "Assurance qualité interne",
            description: "Les tuyaux hydrauliques et les lignes de tuyaux sont testés sur nos propres bancs d'essai internes.",
            image: imgMark
        },
        {
            title: "Conseils et service",
            description: "Avec nous, vous pouvez être sûr que la ligne de tuyaux hydrauliques que vous recevez est parfaite pour votre application",
            image: imgMark
        },
        {
            title: "Tuyaux pré-assemblés selon les souhaits du client",
            description: "Les lignes de tuyaux hydrauliques sont fabriquées dans les succursales de HANSA-FLEX.",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données supplémentaires de FeaturesCard
    ];

    const imageTextData = [
        {
            title: "Production en série de lignes de tuyaux",
            description: "Notre unité centrale de production en série de lignes de tuyaux à Brême fabrique des tuyaux hydrauliques, haute pression, PTFE et industriels pré-assemblés et prêts à installer, en grandes ou petites séries, pour les fournisseurs OEM. Avec nous, vous pouvez vous attendre à une excellente fiabilité des processus et au respect des normes de qualité. Nous adaptons également nos procédures de gestion des entrepôts et de la logistique à vos besoins.",
            buttonName: "EN SAVOIR PLUS",
            buttonLink: "/SeriesProduction",
            image: imgSeriesProduction
        },
        {
            title: 'Production plant-in-plant pour une efficacité de processus',
            description: "Avec la production Plant-in-plant de HANSA-FLEX, vous bénéficiez de tuyaux hydrauliques et d'autres composants fabriqués en harmonie avec vos processus internes.",
            listItems: ["Les composants requis sont fournis séquentiellement", "Toutes les adaptations imprévues nécessaires sont rapidement effectuées sur place", "Disponibilité immédiate des composants, sans frais de stockage"],
            buttonName: "EN SAVOIR PLUS",
            buttonLink: "/Worksinworks",
            image: imgPlantInPlantProduction
        },
        {
            title: "Gestion des tuyaux avec X-CODE",
            description: "Chaque ligne de tuyaux hydrauliques est attribuée un X-CODE individuel pour l'identifier de manière unique. N'importe quelle succursale de HANSA-FLEX peut utiliser les informations X-CODE pour fabriquer l'article respectif. En combinaison avec le portail client MY.HANSA-FLEX, le X-CODE est l'outil idéal pour nos clients pour gérer tous leurs tuyaux. Les clients peuvent voir les informations les plus importantes, telles que la prochaine date d'inspection ou de remplacement, en un coup d'œil dans une base de données facilement compréhensible.",
            buttonName: "EN SAVOIR PLUS",
            buttonLink: "/hose-management",
            image: imgHoseManagement
        },
        {
            title: "Installation de tuyaux et de tuyaux pour des installations complètes",
            description: "Nos techniciens de service et nos équipes d'installation installent tous les tuyaux et tuyaux sur des machines, des systèmes ou des unités hydrauliques mobiles. Ils commencent par identifier le potentiel d'optimisation, en tenant compte des positions, des dimensions, de la conception, du choix des matériaux et de l'efficacité. La prochaine étape consiste à convenir d'un délai pour les travaux, puis à mettre en œuvre les mesures nécessaires.",
            buttonName: "EN SAVOIR PLUS",
            buttonLink: "/installation-of-pipe-and-hoseline-systems",
            image: imgHydraulicLineInstallation
        },
        {
            title: "Sécurité accrue grâce à des tests professionnels de tuyaux",
            description: "Les tuyaux hydrauliques et les raccords doivent être régulièrement inspectés. La sécurité du personnel, des systèmes hydrauliques et des machines dépend du fait que les tuyaux hydrauliques sont exempts de défauts et de défauts. Les tests tiennent compte des normes DGUV et de la loi allemande sur la sécurité et la santé au travail (BetrSichV), ainsi que des normes de produits et de tests pertinentes. Les experts de HANSA-FLEX sont familiers avec toutes les lois et directives applicables, garantissant que tous les tests répondent aux exigences légales.",
            buttonName: "EN SAVOIR PLUS",
            buttonLink: "/dguv-inspection",
            image: imgHoseTesting
        },
    ];

    const tabTitles = [
        { title: "Production en série", eventKey: "Production en série" },
        { title: "Production plant-in-plant", eventKey: "Production plant-in-plant" },
        { title: "Gestion des tuyaux", eventKey: "Gestion des tuyaux" },
        { title: "Installation de lignes hydrauliques", eventKey: "Installation de lignes hydrauliques" },
        { title: "Tests de tuyaux", eventKey: "Tests de tuyaux" },
    ];

    return (
        <div>
            <CenterStage
                imageSrc={imgHydraulicHosesForEvery}
                title={"TUYAUX HYDRAULIQUES POUR TOUTES LES APPLICATIONS"}
                paragraph={"Les tuyaux hydrauliques transfèrent en toute sécurité le fluide à travers le système. Leur flexibilité nécessite très peu d'espace d'installation. La gamme HANSA-FLEX comprend des tuyaux élastomères et thermoplastiques avec des supports de pression en tissu ou en acier ou diverses couches de fil d'acier enroulé en spirale. Cela leur permet de transporter des fluides sous des pressions allant jusqu'à 500 bar. Les tuyaux sont utilisés dans de nombreuses applications dans des systèmes hydrauliques mobiles et stationnaires."}
            //TODO: button (Tous les produits)
            />
            <div>
                <SectionHeader
                    title={"TUYAUX HYDRAULIQUES DE HAUTE QUALITÉ DE HANSA-FLEX"}
                    subtitle={"Tous les tuyaux hydrauliques pour applications à basse, moyenne et haute pression, ainsi que les tuyaux d'aspiration, sont fabriqués à partir de matériaux de haute qualité et subissent des tests approfondis."}
                />
            </div>

            <div>
                <ImageText
                    data={InThermoplastic1} />

                <ImageText
                    data={InThermoplastic2}
                    left={false} />

                <ImageText
                    data={InThermoplastic3} />
            </div>

            <div>
                <Promoter
                    title={"CONFIGUREZ FACILEMENT VOUS-MÊME DES ASSEMBLAGES DE TUYAUX"}
                    checklistItems={["Largeurs nominales de 6 à 32, plage de température de -40°C à +100°C.", "Types de connexion : buse de pression, raccords à pression Interlock"]}
                    btnLabel1={"Configure now"}
                    btnLabel2={"Learn more"}
                    btnLabel1Link={"https://shop.hansa-flex.de/en_GB/configurator"}
                    btnLabel2Link={"/hose-line-config"}
                    imageUrl={imgTabletMobile}
                />
            </div>

            <div>
                <SectionHeader
                    title={"LES LIGNES DE TUYAUX HANSA-FLEX ONT LES AVANTAGES SUIVANTS"}
                    subtitle={"Avec notre propre laboratoire d'essais, deux entrepôts centraux et un vaste réseau de succursales, HANSA-FLEX peut répondre rapidement et de manière fiable à tous les souhaits des clients."}
                />

                <FeatureCardCarousel
                    featuresData={hoseLinesAdvantagesfeaturesData} />
            </div>

            <div>
                <ImageText
                    data={OnsiteAdvice1} />

                <ImageText
                    data={OnsiteAdvice2}
                    left={false} />

            </div>

            <div>
                <SectionHeader
                    title={"LIGNES DE TUYAUX HYDRAULIQUES DE HAUTE QUALITÉ POUR TOUTES LES APPLICATIONS"}
                    subtitle={"Le portefeuille HANSA-FLEX comprend des tuyaux hydrauliques dans de nombreux modèles et combinaisons de matériaux pour toutes les applications dans les systèmes hydrauliques mobiles et stationnaires."}
                />
                <ImageText
                    data={OnsiteAdvice3}
                />
            </div>

            {/*TODO red CTA*/} or {/* TODO: TextPoster */}

            <div>
                <SectionHeader
                    title={"SERVICES LIÉS AUX TUYAUX"}
                    subtitle={"En plus de la fabrication de lignes de tuyaux dans des installations de production en série, HANSA-FLEX propose également diverses solutions pour la fabrication de solutions personnalisées et pré-assemblées directement sur site. De plus, nos spécialistes des fluides expérimentés peuvent vous assister et vous conseiller."}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"PRODUITS SÉLECTIONNÉS DE HANSA-FLEX POUR VOS TUYAUX HYDRAULIQUES"}

                />

                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgSafetyEquipment} learnMoreLink={"/hose-accessories"}
                                title="Équipement de sécurité et accessoires"
                                desc={"Les lignes de tuyaux HANSA-FLEX excellent dans de nombreuses applications en raison de leur excellente qualité. Les produits du portefeuille d'accessoires HANSA-FLEX protègent non seulement les parties vulnérables des tuyaux dans les situations où des charges mécaniques particulièrement élevées ou des effets de température élevée se produisent, mais protègent également les personnes, par exemple, des blessures causées par des jets d'huile. Une large gamme de produits et des conseils d'experts garantissent la sélection et la mise en œuvre de solutions de protection efficaces et efficientes."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgHighQuality} learnMoreLink={"/hose-fitting"}
                                title="Raccords de tuyaux de haute qualité"
                                desc={"Les accessoires conçus et fabriqués par HANSA-FLEX sont parfaits pour une utilisation avec le type approprié de tuyau. Ils garantissent une connexion sûre et étanche. La gamme comprend tous les diamètres nominaux et les types de connexion couramment demandés et respecte les normes internationales (BS, SAE, DIN et NF). Les raccords sont revêtus de zinc-nickel et répondent aux exigences les plus élevées en termes de durabilité et de résistance à la corrosion."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgSafeAndDependable} learnMoreLink={"/coupling"}
                                title="Accouplements hydrauliques sûrs et fiables"
                                desc={"Les accouplements sont le meilleur choix dans les situations où les accessoires doivent être rapidement changés, les machines doivent être solidement connectées ou les tuyaux fortement chargés doivent être remplacés dans les systèmes hydrauliques mobiles et stationnaires. Chez HANSA-FLEX, vous trouverez des raccords rapides parfaits pour l'application prévue dans divers matériaux, tels que l'acier ou l'acier inoxydable, dans une large gamme de tailles et avec divers types de joints d'étanchéité."} /></Col>


                        </Row>

                    </Container>
                </div>

            </div>


        </div>
    );

}

export default HydraulicHose;


