import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import Promoter from '../../../Components/Promoter/Promoter';
import ImageText from '../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';
import HFTabs from '../../../Components/HfTabs/HfTabs';

import imgStage from '../../../assets/Products/HosesAndHoseLines/Accessories/Stage.jpg'
import imgProtectionAgainstMechanicalLoads from '../../../assets/Products/HosesAndHoseLines/Accessories/ProtectionAgainstMechanicalLoads.jpg'
import imgProtectionFromOilJetInjury from '../../../assets/Products/HosesAndHoseLines/Accessories/ProtectionFromOilJetInjury.jpg'
import imgAccidentPrevention from '../../../assets/Products/HosesAndHoseLines/Accessories/AccidentPrevention.jpg'
import imgHoseBundling from '../../../assets/Products/HosesAndHoseLines/Accessories/HoseBundling.jpg'
import imgShrinkHoses from '../../../assets/Products/HosesAndHoseLines/Accessories/ShrinkHoses.jpg'
import imgInstallation from '../../../assets/Products/HosesAndHoseLines/Accessories/Installation.jpg'
import imgPtfeHoses from '../../../assets/Products/HosesAndHoseLines/Accessories/PtfeHoses.jpg'
import imgMetalHose from '../../../assets/Products/HosesAndHoseLines/Accessories/MetalHose.jpg'
import imgIndustrialHose from '../../../assets/Products/HosesAndHoseLines/Accessories/IndustrialHose.jpg'



const Accessories = () => {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const accessories1 = {
        image: imgProtectionAgainstMechanicalLoads,
        title: 'Protection contre les charges mécaniques et la chaleur',
        description: "Les conduites de tuyaux peuvent être exposées à des charges extrêmes. HANSA‑FLEX propose des solutions de protection contre le frottement, le pliage et la chaleur. Ces solutions évitent les dommages dus au frottement, au pliage et aux températures élevées.",
        listItems: ["Protection contre le frottement et le pliage à l'aide de différents matériaux", "Protection contre la chaleur jusqu'à 260 °C à l'aide de silicate, de silicone ou de fibre de verre", "Tuyau de climatisation en fibre de verre protège de la condensation"],
        buttonName: "TOUS LES PRODUITS",
        buttonLink: "https://shop.hansa-flex.fr/fr_FR/hoses/hose-protection/c/webcat_HF_SCH_1090"
    }

    const accessories2 = {
        image: imgProtectionFromOilJetInjury,
        title: 'Protection des personnes contre les blessures par jet d\'huile',
        description: "Un jet d'huile s'échappant d'une conduite de tuyau hydraulique défectueuse peut entraîner des blessures graves. Un tuyau de protection textile extrêmement résistant aux déchirures bloque ces jets et conduit l'huile qui s'échappe en toute sécurité.",
        listItems: ["Tuyau de protection en nylon haute résistance", "Extrêmement résistant à l'abrasion et ignifuge", "Diamètres internes allant jusqu'à 127 mm"],
        buttonName: "TOUS LES PRODUITS",
        buttonLink: "https://shop.hansa-flex.fr/fr_FR/hoses/safety-technology/textile-protection/tgf-iso/p/MAS_TGF_ISO"

    }

    const accessories3 = {
        image: imgAccidentPrevention,
        title: "Prévention des accidents à l'aide de dispositifs de retenue de tuyaux",
        description: "En plus des dispositifs de retenue de tuyaux traditionnels, HANSA‑FLEX propose également son système de retenue de tuyaux STOP LOCK pour une protection efficace contre les tuyaux arrachés et fouettants. Ces boucles de corde en acier peuvent être ajustées pour s'adapter au diamètre du tuyau, sont simples à installer et offrent un niveau élevé de protection.",
        listItems: ["Système de retenue de tuyaux pour la protection contre les tuyaux fouettants", "Le système STOP LOCK a la capacité d'être ajusté pour s'adapter au diamètre du tuyau"],
    }

    // Données HfTab
    const imageTextData = [
        {
            title: "Regroupement de tuyaux",
            listItems: ["Sangle d'arrimage en une seule pièce avec verrouillage par pince", "Bande de retenue avec fermeture velcro et œillet de montage", "Regroupement de tuyaux ignifuge avec fermeture velcro"],
            buttonName: "PRODUITS",
            buttonLink: "https://shop.hansa-flex.fr/fr_FR/hoses/hose-protection/hose-bundling/c/webcat_HF_SCH_1090_3950",
            image: imgHoseBundling
        },
        {
            title: "Tuyaux thermorétractables",
            listItems: ["Avec ou sans revêtement adhésif", "Noir ou transparent", "Température de rétraction entre 90 °C et 125 °C"],
            buttonName: "PRODUITS",
            buttonLink: "https://shop.hansa-flex.fr/fr_FR/hoses/hose-protection/shrink-hoses/c/webcat_HF_SCH_1090_3960",
            image: imgShrinkHoses
        },
        {
            title: "Autres accessoires pour l'installation",
            listItems: ["Kit d'installation pour système de retenue de tuyaux", "Ruban adhésif pour système de retenue de tuyaux", "Ruban d'emballage en caoutchouc silicone pour tuyau de protection contre la chaleur"],
            buttonName: "PRODUITS",
            buttonLink: "https://shop.hansa-flex.fr/fr_FR/hoses/safety-technology/hose-retention-system/stop-lock-kit/p/MAS_STOP_LOCK-KIT",
            image: imgInstallation
        },
    ];

    const tabTitles = [
        { title: "Regroupement de tuyaux", eventKey: "Regroupement de tuyaux" },
        { title: "Tuyaux thermorétractables", eventKey: "Tuyaux thermorétractables" },
        { title: "Installation", eventKey: "Installation" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"UNE PROTECTION PARFAITE POUR LES TUYAUX AVEC LES ACCESSOIRES DE HANSA‑FLEX"}
                    paragraph={"Les conduites de tuyaux de HANSA‑FLEX impressionnent dans de nombreuses applications grâce à leur excellente qualité. Les produits du portefeuille d'accessoires de HANSA‑FLEX protègent non seulement les parties de tuyaux sensibles dans des situations où des exigences de charge mécanique particulièrement élevées ou les effets de températures élevées posent problème, mais protègent également les personnes, par exemple des blessures par jet d'huile. Une large gamme de produits et des conseils d'experts garantissent la sélection et la mise en œuvre de solutions de protection efficaces et efficientes."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"DIFFÉRENTS TYPES DE PROTECTION ET DOMAINES D'APPLICATION"}
                />
                <ImageText
                    data={accessories1} />
                <ImageText
                    data={accessories2} left={false} />
                <ImageText
                    data={accessories3} />
            </div>

            <div>
                <SectionHeader
                    title={"CONSEIL DE PREMIÈRE CLASSE DE LA PART D'EXPERTS EXPÉRIMENTÉS"}
                    subtitle={"Les experts de HANSA‑FLEX sont les personnes à contacter si vous souhaitez des conseils sur la sécurité des conduites de tuyaux. En discutant avec vous, ils trouveront le produit optimal pour votre application prévue. Si vous le souhaitez, nous pouvons également produire des solutions spéciales pour vos besoins spécifiques."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"AUTRES SOLUTIONS DANS LE DOMAINE DE LA PROTECTION ET DE LA SÉCURITÉ DES TUYAUX"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgPtfeHoses} learnMoreLink={"/Ptfe-hoses"}
                                title="Tuyaux PTFE avec une haute résistance chimique"
                                desc={"En raison de leur haute résistance chimique contre la plupart des milieux, les tuyaux PTFE de HANSA‑FLEX sont le premier choix pour toutes les applications exigeantes."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgMetalHose} learnMoreLink={"/metal-hoses"}
                                title="Tuyaux métalliques avec une haute tolérance à la température"
                                desc={"Les tuyaux métalliques se distinguent notamment par leur capacité à résister à des charges mécaniques élevées, leur résistance sous vide et leur haute tolérance à la température."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgIndustrialHose} learnMoreLink={"/Industrial-hoses"}
                                title="Tuyaux industriels de la plus haute qualité"
                                desc={"HANSA‑FLEX peut produire rapidement et compétemment des tuyaux industriels selon les exigences individuelles du client dans son propre atelier de tuyaux spécialisés."} /></Col>
                        </Row>

                    </Container>
                </div>

            </div>

        </div>
    );

}


export default Accessories