import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../Components/HfTabs/HfTabs';


import imgStage from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/Stage.jpg'
import imgHydraulicPumps from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/HydraulicPumps.jpg'
import imgHighPerformanceHydraulicHose from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/HighPerformanceHydraulicHose.jpg'
import imgOverhaulOfHydraulic from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/OverhaulOfHydraulic.jpg'
import imgAnalogueAndDigital from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/AnalogueAndDigital.jpg'
import imgCartridgeAndCetop from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/CartridgeAndCetop.jpg'
import imgMeasuringTech from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/MeasuringTech.jpg'
import imgHighPressureHydraulics from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/HighPressureHydraulics.jpg'
import imgPressureAccumulatorsimg from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/PressureAccumulators.jpg'
import imgCustomerSolution from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/CustomerSolution.jpg'
import imgTanks from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/Tanks.jpg'
import imgElectricMotor from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/ElectricMotor.jpg'
import imgHeatExchangers from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/HeatExchangers.jpg'
import imgGearFlowDividers from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/GearFlowDividers.jpg'
import imgAccessories from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/Accessories.jpg'
import imgDesignOfHydraulicUnit from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/DesignOfHydraulicUnit.jpg'
import imgAdviceAndAssistance from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/AdviceAndAssistance.jpg'
import imgAlwaysThereForYou from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/AlwaysThereForYou.jpg'
import imgPerfectHoseLine from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/PerfectHoseLine.jpg'
import imgBentPipelines from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/BentPipelines.jpg'
import imgCertifiedSafety from '../../../assets/Products/DriveAndControlTechnology/DriveAndControlTechnology/CertifiedSafety.jpg'

const DriveAndControlTechnology = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    const DriveAndControlTechnology1 = {
        image: imgCustomerSolution,
        listItems: ["Développement de pièces spéciales spécifiques au client", "Conseils étendus et assistance dans la construction de prototypes", "Conçu à l'aide de modèles 3D", "Préassemblage de conduites hydrauliques et de pipelines", "Fabrication en série de composants et de groupes de composants", "Conception et fabrication d'unités hydrauliques"]
    }

    const DriveAndControlTechnology2 = {
        image: imgDesignOfHydraulicUnit,
        title: "Conception d'unités hydrauliques et développement de solutions spéciales",
        description: "La consultation avec nos experts est un processus bidirectionnel dans lequel nous développons avec vous la solution parfaite pour votre application et vous accompagnons de la conception à la production. Vos avantages des solutions développées avec HANSA‑FLEX :",
        listItems: ["Développement de pièces spéciales spécifiques au client", "Commandes d'appel à court terme", "Inspection et essais approfondis"],
    }

    const DriveAndControlTechnology3 = {
        image: imgAdviceAndAssistance,
        title: 'Conseils et assistance par des experts expérimentés',
        description: "Le service industriel HANSA‑FLEX peut vous conseiller et vous aider pour toutes les tâches nécessaires sur vos systèmes :",
        listItems: ["Installation et assemblage", "Entretien et maintenance", "Optimisation, mise à jour et rétrofitage"],
        buttonName: "EN SAVOIR PLUS"
    }

    const DriveAndControlTechnology4 = {
        image: imgAlwaysThereForYou,
        title: "Le service toujours là pour vous",
        description: "Avec environ 200 véhicules de service dans toute l'Allemagne, notre service hydraulique mobile rapide est toujours là pour vous, disponible 24h/24 et 7j/7 et 365 jours par an pour minimiser vos temps d'arrêt.",
        listItems: ["Ateliers mobiles sur roues, pièces de rechange fabriquées directement sur site", "Large gamme de pièces de rechange à bord", "Alimentation électrique indépendante"],
        buttonName: "EN SAVOIR PLUS"
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Qualité éprouvée",
            description: "Tous les composants hydrauliques fournis par HANSA‑FLEX proviennent de fabricants bien connus.",
            image: imgMark
        },
        {
            title: "Disponibilité rapide",
            description: "Vous recevez vos produits directement depuis l'entrepôt. Les produits spécialement fabriqués sont livrés dès que possible.",
            image: imgMark
        },
        {
            title: "Conseils avisés",
            description: "Conseils d'experts en fluides sur toutes les questions de sélection, de conception et de maintenance.",
            image: imgMark
        },
        {
            title: "Services complets",
            description: "Nos techniciens vous aident et vous assistent dans l'assemblage, la maintenance et l'optimisation de vos systèmes",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Moteurs électriques de qualité industrielle",
            description: "HANSA‑FLEX fournit des moteurs à courant continu (12, 24 et 48 V) pour des unités de puissance compactes avec une puissance de 0,5 à 3,0 kW et des moteurs alternatifs triphasés, dans les types B3/B14, B3/B5 et B5 et les classes de rendement énergétique (IE2, IE3) avec une puissance de 0,54 à 63 kW. D'autres variantes avec des tensions spéciales, des puissances supérieures, des variateurs de fréquence et dans d'autres classes d'efficacité énergétique sont disponibles sur demande.",
            buttonName: "Moteurs électriques",
            image: imgElectricMotor
        },
        {
            title: "Réservoirs hydrauliques pour un stockage efficace des fluides",
            description: "HANSA‑FLEX fournit des réservoirs en aluminium coulé, en tôle d'acier soudée ou emboutie avec des couvercles en acier et des joints. Notre centre de fabrication d'unités peut également fabriquer d'autres variantes selon vos besoins spécifiques.",
            listItems: ["Réservoirs en aluminium : volume effectif de 3,0 l à 123 l", "Réservoirs en acier : volume effectif de 6,0 l à 375 l", "Large gamme d'accessoires : indicateurs de niveau de fluide, couvercles étanches, filtres de remplissage et chauffages de réservoir"],
            buttonName: "TOUS LES RÉSERVOIRS HYDRAULIQUES",
            image: imgTanks
        },
        {
            title: "Échangeurs de chaleur pour une régulation efficace de la température",
            description: "Les échangeurs de chaleur maintiennent les fluides à la température optimale. Nous fournissons des refroidisseurs huile-air directement en stock et des variantes telles que des refroidisseurs avec ventilateurs entraînés hydrauliquement ou des échangeurs de chaleur à plaques sur demande.",
            listItems: ["Entraînement de ventilateur électrique 230V / 400V", "Capacité de refroidissement de 0,02 kW à 1,1 kW", "Thermostats disponibles en accessoires"],
            buttonName: "ÉCHANGEURS DE CHALEUR",
            image: imgHeatExchangers
        },
        {
            title: "Distributeurs de débit hydraulique à engrenages avec un",
            description: "Les distributeurs de débit hydraulique permettent de faire fonctionner synchroniquement les moteurs et les vérins. La boutique en ligne HANSA‑FLEX propose des distributeurs de débit hydraulique en option avec des soupapes de limitation de pression et de correction de phase.",
            listItems: ["En tailles 0 à 2", "Versions 2 voies et 4 voies", "Débit par élément de 0,7 l/min à 25,9 l/min"],
            buttonName: "TOUS LES DISTRIBUTEURS DE DÉBIT À ENGRENAGES",
            image: imgGearFlowDividers
        },
        {
            title: "Supports de pompe, accouplements et éléments d'amort",
            description: "En tant que fournisseur de systèmes, nous pouvons également fournir des composants adaptés pour connecter des moteurs électriques et des pompes. Nous fournissons des supports de pompe et des accouplements en acier ou en aluminium pour une large gamme d'applications. Les supports de pompe et les accouplements pour pompes à engrenages et pompes à piston axial de notre liste de produits sont fournis directement depuis le stock, d'autres versions pour d'autres types de pompes peuvent être fournies sur demande. Nous avons toujours en stock des pignons pour accouplements comme pièces de rechange pour vous. Notre boutique en ligne propose une large sélection de rails et anneaux d'amortissement disponibles pour une fixation peu vibrante des moteurs électriques et des supports de pompe sur les unités hydrauliques.",
            image: imgAccessories
        },
    ];

    const tabTitles = [
        { title: "Moteurs électriques", eventKey: "Moteurs électriques" },
        { title: "Réservoirs", eventKey: "Réservoirs" },
        { title: "Échangeurs de chaleur", eventKey: "Échangeurs de chaleur" },
        { title: "Distributeurs de débit", eventKey: "Distributeurs de débit" },
        { title: "Accessoires", eventKey: "Accessoires" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"TECHNOLOGIE D'ENTRAÎNEMENT ET DE COMMANDE DE HANSA‑FLEX"}
                    paragraph={"Les composants hydrauliques répondent aux normes de qualité les plus élevées chez HANSA‑FLEX, où la gestion de la qualité est certifiée DIN ISO 9001. La disponibilité est assurée par notre entrepôt, qui stocke plus de 4 500 composants hydrauliques. Nous avons même une sélection spéciale de composants pour la plage de 700 bar. Les systèmes hydrauliques sont de plus en plus connectés avec des commandes électroniques. Par conséquent, le portefeuille de produits de HANSA‑FLEX comprend également des composants des domaines de la technologie des vannes proportionnelles, des capteurs et de la métrologie."}
                />
            </div>

            <SectionHeader
                title={"COMPOSANTS HYDRAULIQUES DES SPÉCIALISTES DES FLUIDES"}
                subtitle={"HANSA‑FLEX offre une large gamme de composants hydrauliques de fabricants bien connus et peut produire ses propres versions spéciales en interne pour vous."}
            />
            <div>
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={8} ><ImageCard image={imgHydraulicPumps}
                            title="Pompes hydrauliques pour tous types d'applications"
                            desc={"La gamme de produits HANSA‑FLEX comprend des pompes à engrenages, à piston axial, à piston radial et à palettes pour l'hydraulique industrielle et mobile."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgHighPerformanceHydraulicHose}
                            title="Moteurs hydrauliques haute performance"
                            desc={"Découvrez notre vaste gamme de moteurs à gerotor et à geroller disponibles en variantes premium ou économiques économiques dans notre qualité éprouvée habituelle."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgOverhaulOfHydraulic}
                            title="Vente, fabrication et révision de vérins hydrauliques"
                            desc={"HANSA‑FLEX propose une large gamme de vérins standards à simple ou double effet, fabrique des vérins hydrauliques avec des courses allant jusqu'à 8 000 mm et fournit..."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgAnalogueAndDigital}
                            title="Interrupteurs et capteurs analogiques et numériques"
                            desc={"HANSA‑FLEX est un partenaire unique fournissant une large sélection d'interrupteurs de pression, de température et de niveau durables et de capteurs pour les systèmes hydrauliques."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgCartridgeAndCetop}
                            title="Vannes d'installation cartouche, CETOP et pipeline"
                            desc={"Nous vous proposons une large sélection de vannes directionnelles, d'arrêt et de régulation de pression, de vannes de débit et proportionnelles avec différents types de commande pour des pressions allant jusqu'à 350 bars."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgMeasuringTech}
                            title="Technologie de mesure et instruments d'affichage"
                            desc={"Notre boutique en ligne propose une large gamme de produits de technologie de mesure pour déterminer et surveiller la pression, la température et le débit volumétrique dans les systèmes hydrauliques."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgHighPressureHydraulics}
                            title="Hydraulique haute pression jusqu'à 700 bars"
                            desc={"HANSA‑FLEX dispose d'une sélection spéciale de composants pour la plage de fonctionnement de 700 bars."} /></Col>
                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgPressureAccumulatorsimg}
                            title="Accumulateurs de pression hydraulique"
                            desc={"En plus de fournir des accumulateurs à membrane et à vessie de fabricants bien connus et des accessoires adaptés, nous vous accompagnons dans votre entretien, vos tests et..."} /></Col>
                    </Row>
                </Container>
            </div>

            <div>
                <SectionHeader
                    title={'DÉVELOPPEMENT DE SOLUTIONS SPÉCIFIQUES AU CLIENT'}
                    subtitle={"Nous fabriquons des solutions spéciales pour répondre à vos besoins précis. Nos experts pensent que donner des conseils implique à la fois de parler et d'écouter, suivi de votre soutien dans tout, de la conception à la fabrication."}
                />
                <ImageText
                    data={DriveAndControlTechnology1} />
            </div>

            <div>
                <SectionHeader
                    title={"NOS ENTREPÔTS STOCKENT TOUJOURS ENVIRON 4 500 PRODUITS DE QUALITÉ HANSA‑FLEX ÉPROUVÉE"}
                    subtitle={"Tous les composants de technologie d'entraînement et de commande fournis par HANSA‑FLEX répondent aux normes de qualité les plus élevées, sont disponibles en gammes complètes de produits et livrés rapidement."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"AUTRES COMPOSANTS D'UNITÉ HYDRAULIQUE"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"LIVRAISON KANBAN DE HANSA‑FLEX"}
                    subtitle={"Nous fournissons nos produits pour s'intégrer dans les processus logistiques et de production de nos clients. Avec la livraison Kanban basée sur le principe de traction de HANSA‑FLEX, vous bénéficiez de la livraison Just-In-Time, précisément lorsque vous en avez besoin, sur demande directement dans votre chaîne de production."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"SERVICES POUR VOS SYSTÈMES HYDRAULIQUES PAR LES EXPERTS EN FLUIDES DE HANSA‑FLEX"}
                    subtitle={"En tant que partenaire système polyvalent et expérimenté, nous fournissons des conseils pratiques et un soutien à nos clients dans tous les domaines de l'hydraulique."}
                />
                <ImageText
                    data={DriveAndControlTechnology2} />

                <ImageText
                    data={DriveAndControlTechnology3}
                    left={false} />
                <ImageText
                    data={DriveAndControlTechnology4} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgPerfectHoseLine}
                                title="La ligne de tuyaux parfaite pour chaque application"
                                desc={"HANSA‑FLEX fabrique des lignes de tuyaux hydrauliques et industriels de haute qualité pour le transport de fluides, de produits chimiques, de denrées alimentaires, d'air, d'eau et de milieux abrasifs."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgBentPipelines}
                                title="Tuyauteries cintrées avec différents traitements de surface"
                                desc={"Bénéficiez de notre expérience de plusieurs années dans le cintrage, le formage, l'évasement, le rétrécissement et le bridage des tuyauteries en acier, en acier inoxydable, en cuivre et en aluminium."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCertifiedSafety}
                                title="Sécurité et fiabilité certifiées"
                                desc={"Le personnel autorisé effectue des tests en interne ou sur vos lieux, y compris la documentation des tests et des résultats."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )

}

export default DriveAndControlTechnology