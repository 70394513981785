import React, { useEffect } from 'react';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import TextCaption from '../Components/TextCaption/TextCaption';

function PrivayPolicyPage() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <SectionHeader title={"NOTICE D’INFORMATION"} subtitle={"Relative aux données personnelles des salariés (RGDP)"} />
            <TextCaption paragraph={"A l’occasion de l’embauche, un certain nombre de données personnelles nécessaires à l'exécution du contrat de travail sont recueillies auprès du salarié, données qui donnent lieu par la suite à un traitement dans ce même cadre."} />

            <TextCaption paragraph={"Une donnée à caractère personnel désigne toute information concernant une personne physique identifiée ou identifiable (personne concernée).  Est réputée identifiable une personne qui peut être identifiée, directement ou indirectement, notamment par référence à un nom, un numéro d'identification ou à un ou plusieurs éléments spécifiques, propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale."} />

            <TextCaption paragraph={"Les données personnelles susceptibles d’être collectées sont les suivantes : nom, prénoms, date de naissance, numéro de Sécurité sociale, adresse postale, numéro de téléphone, adresse e-mail, situation familiale, coordonnées bancaires, horaires de travail."} />

            <TextCaption paragraph={"Les données personnelles collectées ont pour objet de permettre l'exécution du contrat de travail conclu et notamment :"}
                bulletPoints={["embauche et suivi administratif du salarié ;", "mise en place des garanties salariales obligatoires et facultatives ;", "gestion de la durée du travail et de la paie."]} />

            <TextCaption paragraph={"Vos données personnelles sont conservées durant votre période d’emploi et jusqu’à cinq années après la rupture de votre contrat de travail en ce qui concerne les données relatives à la paie ou à votre durée de travail, sauf si vous exercez, dans les conditions prévues ci-après, l’un des droits qui vous sont reconnus par la législation."} />

            <TextCaption paragraph={"L'accès à vos données personnelles est strictement limité aux salariés de la société HANSA-FLEX France SARL chargés de la gestion du personnel, à votre hiérarchie ainsi qu’aux éventuels représentants du personnel au sein de l’entreprise."} />

            <TextCaption paragraph={"Cependant, les données collectées pourront éventuellement être communiquées à des sous-traitants chargés contractuellement de la gestion de la paie et de la gestion du personnel, sans que vous n’ayez à donner votre autorisation. Il est précisé que, dans le cadre de l’exécution de leurs prestations, les sous-traitants ont une obligation contractuelle d’utiliser vos données en conformité avec les dispositions de la législation applicable en matière de protection des données personnelles."} />

            <TextCaption paragraph={"Conformément aux dispositions légales et règlementaires applicables, en particulier la loi n°78-17 du 6 janvier 1978 modifiée relative à l'informatique, aux fichiers et aux libertés et du Règlement Européen n°2016/679/UE du 27 avril 2016, vous disposez des droits suivants :"}
                bulletPoints={["Exercer votre droit d’accès, pour connaître les données personnelles qui vous concernent ;", "Demander la mise à jour ou la rectification de vos données, si celles-ci sont inexactes ;", "Demander la portabilité ou la suppression de vos données ;", "Demander la portabilité ou la suppression de vos données ;", "Vous opposer, pour des motifs légitimes, au traitement de vos données."]} />

            <TextCaption paragraph={"Ces différents droits sont à exercer soit par courrier postal à l'adresse suivante : "} />

            <TextCaption paragraph={"SARL HANSA FLEX, 5, rue Pégase – ENTZHEIM (67960), "} />

            <TextCaption paragraph={"soit par courriel à l'adresse suivante : comptabilite.fr@hansa-flex.com"} />

            <TextCaption paragraph={"Pour toute information complémentaire, vous pouvez consulter le site internet de la Commission Nationale de l’Informatique et des Libertés : www.cnil.fr."} />
            <br /><br /><br />
        </div>
    );
}

export default PrivayPolicyPage;