import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgmanagement from '../../assets/Mangement-group/imgManagement.jpg'
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import ContactBubble from '../../Components/ContactBubble/ContactBubble';
import imgThomas from '../../assets/Mangement-group/imgThomas.jpg';
import imgChristian from '../../assets/Mangement-group/imgChristian.jpg';
import imgFlorian from '../../assets/Mangement-group/imgFlorian.jpg'

function GroupManagement() {


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);
  return (
    <div>
      <div>
        <CenterStage
          imageSrc={imgmanagement}
          title={"INDÉPENDANT ET DIRIGÉE PAR UNE FAMILLE"}
          paragraph={"Le nom HANSA‑FLEX est synonyme de qualité et d'innovation depuis 1962. Même en tant qu'entreprise active à l'échelle mondiale avec une expansion mondiale et un portefeuille de produits de plus en plus diversifié, HANSA‑FLEX reste toujours – et se considérera toujours – comme une entreprise familiale. La gestion stratégique de l'entreprise est entre les mains du conseil d'administration à trois membres, dirigé par Thomas Armerding, fils du fondateur de l'entreprise, Joachim Armerding"}
        />
      </div>

      <div className='div-gray center-all-items'>
        <SectionHeader
          title="LE CONSEIL D' ADMINISTRATION DE HANSA‑FLEX AG" />

        <ContactBubble
          imageSrc={imgThomas}
          title="Thomas Armerding"
          subTitle="Président du Conseil d'Administration"
          description="Thomas Armerding dirige la société HANSA‑FLEX depuis le milieu des années 1990. Banquier de formation, il est le fils du fondateur de l'entreprise, Joachim Armerding. Il a rejoint l'entreprise en tant que stagiaire en 1986 et a travaillé dans tous les départements du siège de Brême. En 1989, il a été nommé directeur de succursale à Oldenburg. Il est ensuite devenu directeur général avec Uwe Buschmann en 1994. L'établissement et l'expansion de HANSA‑FLEX sur la scène internationale sont largement dus à son initiative"
          departments={["Développement de l'entreprise", 'Affaires étrangères', 'Ventes', 'Marketing']}
        />

        <ContactBubble
          imageSrc={imgChristian}
          title="Christian-Hans Bültemeier"
          subTitle="Directeur Général Adjoint"
          description="Christian-Hans Bültemeier est Directeur Général Adjoint et COO depuis 2022. Diplômé en économie, il a rejoint HANSA‑FLEX en 2010 en tant que responsable de l'administration et des finances. Auparavant, il était membre du conseil d'administration d'une filiale allemande d'une société américaine de technologie des sciences de la vie. En janvier 2013, il a été nommé au conseil d'administration de HANSA‑FLEX AG en tant que directeur financier et confié à la gestion des départements Finance, Ressources Humaines, IT et Services Commerciaux"
          departments={["Chaîne d'approvisionnement", "Production", "Achats et Qualité", "Recherche et Développement"]}
        />

        <ContactBubble
          imageSrc={imgFlorian}
          title="Florian Wiedemeyer"
          subTitle="Directeur Commercial & Directeur Financier"
          description="Florian Wiedemeyer a été nommé au conseil d'administration en tant que Directeur Financier en janvier 2022. Diplômé en économie, il a commencé chez HANSA‑FLEX en octobre 2000 dans le cadre d'un programme d'études en alternance. Après des études complémentaires en cours d'emploi, il a mis en place un système de reporting à l'échelle de l'entreprise et a été chargé de mettre en place et de gérer le département de contrôle de gestion. Depuis 2013, il contribue de manière significative à la récente success story de l'entreprise en tant que Responsable de la Comptabilité et du Contrôle de Gestion"
          departments={["Finance d'entreprise", "Ressources Humaines et Technologies de l'Information", "Comptabilité et Contrôle de Gestion", "Services Commerciaux"]}
        />
      </div>
    </div>

  );
}

export default GroupManagement;