import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import Promoter from '../../../Components/Promoter/Promoter';
import ImageText from '../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';
import HFTabs from '../../../Components/HfTabs/HfTabs';


import imgStage from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/Stage.jpg'
import ManyAreas from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/ManyAreas.jpg'
import HydraulicHoseVarients from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/HydraulicHoseVarients.jpg'
import FittingsAndConnections from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/FittingsAndConnections.jpg'
import HoseProtectionAndSafety from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/HoseProtectionAndSafety.jpg'
import HoseManagementRequired from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/HoseManagementRequired.jpg'
import XCodeManager from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/XCodeManager.jpg'
import ClickAndCollect from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/ClickAndCollect.jpg'


//TODO: TextPoster

function HoseLineConfigurator() {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    // features card carousel data
    const featuresData = [
        {
            title: "Sélectionnez et personnalisez",
            description: "Choisissez parmi environ 90 tuyaux et 600 raccords et configurez-les individuellement.",
            image: imgMark
        },
        {
            title: "Également utilisable sur mobile",
            description: "Configurez facilement en déplacement avec votre smartphone ou tablette.",
            image: imgMark
        },
        {
            title: "Option Click & Collect",
            description: "Collectez dans les 60 minutes suivant la commande dans l'une de nos 200 succursales.",
            image: imgMark
        },
        {
            title: "Réapprovisionnez en deux clics",
            description: "Avec le X-CODE, vous pouvez réapprovisionner les ensembles de tuyaux en deux clics.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Nombreux domaines d'application",
            description: "La large gamme de définition des paramètres dans le configurateur de lignes de tuyaux permet la configuration individuelle de lignes de tuyaux pour la plupart des applications hydrauliques courantes.",
            listItems: ["Environ 90 matériaux de tuyaux au total", "Diamètres nominaux de 6 mm à 32 mm", "Plage de température de -40° C à +100° C", "Longueurs entre 400 mm et 40 000 mm", "Pression de fonctionnement dynamique maximale jusqu'à 500 bar"],
            image: ManyAreas
        },
        {
            title: "Variantes de tuyaux hydrauliques",
            description: "Le configurateur de tuyaux propose automatiquement les variantes de tuyaux hydrauliques appropriées à sélectionner en fonction de la largeur nominale et de la pression de fonctionnement dynamique maximale. La meilleure option de tuyau dans chaque cas dépend des exigences individuelles.",
            listItems: ["Tuyaux hydrauliques en élastomère pour les besoins les plus courants", "Tuyaux compacts pour les espaces d'installation restreints, par exemple dans l'hydraulique mobile", "Tuyaux thermoplastiques pour un poids particulièrement faible"],
            image: HydraulicHoseVarients
        },
        {
            title: "Raccords et types de connexion",
            description: "Le configurateur de lignes de tuyaux compile automatiquement une sélection claire de raccords et de types de connexion à partir d'environ 600 matériaux qui correspondent de manière optimale au tuyau configuré. Pour une identification plus rapide du produit souhaité, à la fois la désignation standard du marché et la désignation HANSA‑FLEX sont répertoriées dans le menu de sélection.",
            listItems: ["Tétines de pression pour les applications moyennes et à haute pression", "Raccords à pression de type Interlock pour des charges extrêmes", "Cadres de pression non pelables ainsi que cadres de pression pelables"],
            image: FittingsAndConnections
        },
        {
            title: "Accessoires de protection des tuyaux et de sécurité",
            description: "Les lignes de tuyaux hydrauliques peuvent être endommagées par des contraintes thermiques ou mécaniques. Les accessoires de protection des tuyaux qui peuvent être sélectionnés en option dans le configurateur de lignes de tuyaux protègent les lignes de tuyaux contre les contraintes d'abrasion et aident à prévenir les blessures personnelles.",
            listItems: ["Bobine de protection contre les frottements en plastique résistant à la chaleur jusqu'à 120°C", "Tuyau de protection en nylon pour protéger contre les blessures par jet d'huile", "Bobine de protection contre les frottements particulièrement robuste en fil d'acier à ressort"],
            image: HoseProtectionAndSafety
        },
    ];

    const tabTitles = [
        { title: "Nombreux domaines d'application", eventKey: "Nombreux domaines d'application" },
        { title: "Variantes de tuyaux hydrauliques", eventKey: "Variantes de tuyaux hydrauliques" },
        { title: "Raccords et types de connexion", eventKey: "Raccords et types de connexion" },
        { title: "Accessoires de protection des tuyaux et de sécurité", eventKey: "Accessoires de protection des tuyaux et de sécurité" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"PLUS RAPIDE VERS LE PRODUIT DÉSIRÉ AVEC LE CONFIGURATEUR DE LIGNES DE TUYAUX"}
                    paragraph={"Le configurateur de lignes de tuyaux HANSA‑FLEX est une véritable étape décisive. Avec notre configurateur de tuyaux hydrauliques numérique, vous pouvez facilement configurer vous-même des lignes de tuyaux (hydrauliques). En cinq étapes, vous pouvez créer votre produit personnalisé en ligne à partir d'environ 90 tuyaux et 600 raccords en seulement quelques minutes. Commandez avec livraison ou en click & collect et passez des commandes répétées en seulement deux clics grâce au X-CODE."}
                />

            </div>

            <div>
                <SectionHeader
                    title={"ENSEMBLES DE TUYAUX INDIVIDUELS DE LA MEILLEURE QUALITÉ HANSA‑FLEX"}
                    subtitle={"Profitez du configurateur de lignes de tuyaux numérique et bénéficiez du savoir-faire analogique, car chacune de vos lignes de tuyaux configurées individuellement est assemblée à la main par nos assembleurs de qualité."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            {/* TODO: Steps */}

            <div>
                <SectionHeader
                    title={"OPTIONS DE CONFIGURATION ÉTENDUES"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"DÉCOUVREZ LES POSSIBILITÉS DU CONFIGURATEUR DE LIGNES DE TUYAUX"}
                />
            </div>

            {/* TODO: Steps */}

            {/* <div>
            <VideoPlayer videoUrl="https://www.youtube.com/embed/4coN3gVRfRA?si=gPPZSqGg-2IyrDVJ" />
        </div> */}

            <div>
                <SectionHeader
                    title={"HANSA‑FLEX OFFRE UNE GAMME COMPLÈTE DE SERVICES HYDRAULIQUES"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={HoseManagementRequired} learnMoreLink={"/hose-management"}
                                title="Gestion des tuyaux entièrement selon vos besoins"
                                desc={"HANSAFLEX vous soutient avec une gestion complète des tuyaux de 360°, de la fabrication à l'élimination, des services individuels aux forfaits tout compris."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={XCodeManager} learnMoreLink={"/x-code-manager"}
                                title="Gestion numérique des tuyaux avec X-CODE Manager"
                                desc={"Le X-CODE Manager facilite et clarifie la gestion des tuyaux. Le logiciel pratique affiche clairement les niveaux de stock et les intervalles d'inspection."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={ClickAndCollect} learnMoreLink={"/"}
                                title="Click & Collect rend la commande encore plus pratique"
                                desc={"Click & Collect combine les processus pratiques de commande en ligne avec des options de contact personnel et de consultation sur place pour le retrait en succursale."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );

}

export default HoseLineConfigurator;