import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgPrototype_Centerspread from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import imgPrototype_Imagetext_01 from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Imagetext_01.jpg'
import imgPrototype_Imagetext_02 from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Imagetext_02.jpg'
import imgPrototype_Imagetext_03 from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Imagetext_03.jpg'
import imgPrototype_Imagetext_04 from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Imagetext_04.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import imgPrototype_Imagecard_01 from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Imagecard_01.jpg'
import imgPrototype_Imagecard_02 from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Imagecard_02.jpg'
import imgPrototype_Imagecard_03 from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Imagecard_03.jpg'
import { Container, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook



const Prototypemanufacture = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);
  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Prototype_Imagetext_01 = {
    image: imgPrototype_Imagetext_01,
    title: 'Individuelle Prototypen nach Kundenspezifikationen',
    description: ["Si vous avez besoin d'un composant ou d'un groupe de composants hydrauliques qui n'existe nulle part sur le marché, HANSA-FLEX n'a pas besoin de connaissances techniques particulières ni d'un dessin. Nous prenons en compte vos exigences individuelles pour le composant ou le groupe de composants, nous le concevons et nous préparons le dessin final. Nous sommes en mesure de répondre précisément à vos exigences spécifiques en matière de fonctionnalité, de dimensionnement et de poids. Bien entendu, nous poursuivons le développement du dessin en collaboration avec vous jusqu'à ce que nous obtenions la solution optimale pour votre projet."],
  }

  const Prototype_Imagetext_02 = {
    image: imgPrototype_Imagetext_02,
    title: "Fabrication, assurance qualité et respect des normes",
    description: ["Pour la fabrication de prototypes, nous nous appuyons sur notre vaste savoir-faire, nos installations de production ultramodernes, nos nombreuses certifications et notre système de gestion de la qualité qui a fait ses preuves"],
  }


  const Prototype_Imagetext_03 = {
    image: imgPrototype_Imagetext_03,
    title: "Mise en service, conseil et assistance jusqu'à la production en série",
    description: ["Aucun prototype ne quitte nos installations de production sans avoir fait l'objet d'un processus d'approbation individuel approfondi. Si le prototype doit être produit en série, nous sommes là pour vous conseiller et vous assister jusqu'à ce qu'il soit prêt pour la production en série et nous pouvons entreprendre sa fabrication en interne chez HANSA-FLEX ou dans les locaux de l'un de nos partenaires experts."],
    
  }


  const Prototype_Imagetext_04 = {
    image: imgPrototype_Imagetext_04,
    title: "Normes de conception les plus élevées pour la production en série",
    description: ["Les dessins en 3D réalisés par nos techniciens définissent le standard de qualité élevé de HANSA-FLEX auquel les fournisseurs doivent se conformer pour la fabrication de tous les composants. Nous obtenons ainsi une grande sécurité des processus et pouvons vous offrir un rapport qualité-prix optimal. Quel que soit le lieu de fabrication d'un composant, celui-ci présente toujours un aspect identique. La production standardisée permet de réduire considérablement le risque d'erreurs de tolérance lors de la fabrication de machines et d'installations hydrauliques."],
  }


  const featuresData = [
    {
      title: "Connaissances spécialisées spécifiques dans tous les domaines",
      description: "HANSA‑FLEX has extensive experience in all disciplines of hydraulics.",
      image: imgMark
    },
    {
      title: "Individually made to the customer’s wishes",
      description: "We are completely familiar with the process of prototype manufacture and implement your wishes.",
      image: imgMark
    },
    {
      title: "Compliant with standards and certified",
      description: "We manufacture using certified employees and in accordance with all relevant standards.",
      image: imgMark
    },
    {
      title: "Advice and assistance right up to series production",
      description: "We manufacture products as one off items or in small or large batch series production.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];

  return (
    <div>
      <CenterStage
        imageSrc={imgPrototype_Centerspread}
        title={"HERSTELLUNG VON PROTOTYPEN UND PRODUKTION ALS EINZELSTÜCKE ODER IN KLEINEN ODER GROSSEN SERIEN"}
        paragraph={"Wenn Sie spezielle Hydraulikkomponenten oder Komponentengruppen benötigen, die nicht von der Stange erhältlich sind, steht Ihnen der HANSA-FLEX Prototypenbau zur Verfügung. Wir fertigen Einzelteile nach Kundenspezifikation, Zeichnung oder Muster, bringen den Prototyp in den Ersteinsatz und beraten und begleiten Sie bei Bedarf mit dem Produkt bis zur Serienreife. Mit Fachwissen, Zertifizierungen und modernsten Maschinen schaffen wir effiziente Lösungen für jede Herausforderung.Übersetzt mit DeepL.com (kostenlose Version)"}
      />



      <div >
        <SectionHeader
          title={"HERSTELLUNG VON PROTOTYPEN, DER GESAMTE PROZESS"}
          subtitle={"Die Prototypenfertigung mit HANSA-FLEX umfasst Konstruktion, Fertigung, Qualitätskontrolle, Inbetriebnahme und Beratung bis zur Serienreife."}
        />
      </div>

      <div >
        <br />
        <ImageText data={Prototype_Imagetext_01} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Prototype_Imagetext_02} />
      </div>

      <div >
        <br />
        <ImageText data={Prototype_Imagetext_03} left={false} />
      </div>

      <div >
        <SectionHeader
          title={"HERSTELLUNG VON PROTOTYPEN UNTER EINSATZ VON FÄHIGKEITEN AUS VERSCHIEDENEN DISZIPLINEN"}
          subtitle={"Für den Prototypenbau greift HANSA-FLEX auf seine hochspezialisierten Hydraulik-Teams zurück."}
        />
      </div>


      <div >
        <br />
        <ImageText data={Prototype_Imagetext_04} />
      </div>


      <div >
        <SectionHeader
          title={"IHRE VORTEILE MIT HANSA-FLEX PROTOTYPENBAU"}
          subtitle={"Profitieren Sie von unserem umfassenden Hydraulik-Know-how bei der Konzeption, Planung und Umsetzung Ihres Projekts."}
        />
      </div>

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>






      <div >
        <SectionHeader
          title={"HERSTELLUNG VON PROTOTYPEN NACH DEN BEDÜRFNISSEN DES KUNDEN"}
          subtitle={"Die Prototypenfertigung bei HANSA-FLEX beginnt mit einer unvoreingenommenen Beratung zwischen gleichberechtigten Partnern, an deren Ende ein Produkt steht, das in der gewünschten Anwendung höchste Funktions- und Qualitätsansprüche erfüllt."}
        />
      </div>

      <div >
        <SectionHeader
          title={"DAS KÖNNTE SIE AUCH INTERESSIEREN"}
          subtitle={""}
        />
      </div>

      <Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgPrototype_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="HANSA-FLEX Hydraulikschläuche" desc="Wir bieten eine große Auswahl an Hydraulikschläuchen aus elastomeren und thermoplastischen Werkstoffen für nahezu alle Einsatzbereiche der Mobil- und Industriehydraulik." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgPrototype_Imagecard_02} learnMoreLink="/metal-hoses" title="Präzise gebogene Rohre" desc="Im HANSA-FLEX Rohrbiegezentrum werden Rohrleitungen individuell nach Maß oder in Serienfertigung hergestellt. Unsere Produktpalette in diesem Bereich ist" />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgPrototype_Imagecard_03} learnMoreLink="/hydraulic-hoses" title="Dichtungstechnik immer verfügbar" desc="Mit großen Lagerbeständen, einer breiten Palette an Materialien und Fertigungsverfahren, kompetenter Beratung und schneller Auftragsabwicklung lässt HANSA-FLEX keine Wünsche offen." />
                    </Col>


                </Row>
            </Container>
















    </div>
  )
}

export default Prototypemanufacture