import React, { useEffect } from 'react'
import SectionHeader from '../Components/SectionHeader/SectionHeader'
import TextCaption from '../Components/TextCaption/TextCaption'

const TermsAndConditions = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <SectionHeader title={" CONDITIONS GENERALES DE VENTE HANSA-FLEX FRANCE"} />
            <TextCaption title={"1. Champ d'application des conditions générales de vente "} paragraph={"Les présentes conditions générales de vente s'appliquent de plein droit à toute vente des produits suivants : flexibles, raccords hydrauliques, coupleurs, composants hydrauliques et plus généralement toute la gamme des produits commercialisés par la société HANSA FLEX FRANCE. La vente est réputée conclue à la date d'acceptation de la commande par le Vendeur. Préalablement à cette date, les présentes conditions de vente ont été mises à la disposition de l'Acheteur, comme visé à l'article 33 de l'ordonnance n° 86-1243 du 1er décembre 1986 modifiée. Toute commande implique l'adhésion sans réserves aux présentes conditions générales de vente qui prévalent sur toutes autres conditions, à l'exception de celles qui ont été acceptées expressément par le Vendeur. "} />
            <TextCaption title={"2. Commande "} paragraph={"Toute commande, pour être prise en compte, doit être passée par écrit ou communiquée par télécopie ou tout moyen technologique équivalent permettant de s'assurer de la preuve de l'accord contractuel. L'acceptation de la commande par le vendeur résulte de la confirmation qu'il l'a bien reçue, en accepte les modalités et confirme les termes par courrier, courriel ou tout procédé équivalent; l'établissement et l'envoi de la facture suivront ladite acceptation dans les meilleurs délais. Toute modification du fait de l'Acheteur peut entraîner facturation complémentaire et déterminer un nouveau délai de livraison. "} />
            <TextCaption title={"3. Livraison "} paragraph={"Les risques du bien commandé sont supportés par l'Acheteur à compter de ladite livraison. Le délai de livraison est donné à titre indicatif et sans garantie. Le dépassement de ce délai ne peut donner lieu à aucune retenue ou indemnité. Le délai indiqué est en outre de plein droit suspendu par tout événement indépendant du contrôle du Vendeur et ayant pour conséquence de retarder la livraison. En toute hypothèse, la livraison n'interviendra dans le délai que si l'Acheteur est à jour de toutes ses obligations et notamment de paiement à l'égard du Vendeur. En cas de ventes successives, faute de paiement d'une échéance, les autres livraisons peuvent être suspenders jusqu'à ce que paiement intervienne du solde dû. "} />
            <TextCaption title={"4. Réception des produits "} paragraph={"L'Acheteur doit vérifier à la réception la conformité des produits livrés aux produits commandés et l'absence de vice apparent. Si aucune réclamation ni réserve n'est formulée à ce titre par l'Acheteur par écrit au jour de la réception des produits, lesdits produits ne pourront plus être ni repris ni échangés, en application des dispositions de l'article 1642 du Code civil. En cas de vice apparent ou de non-conformité des produits livrés aux produits commandés relevés par l'Acheteur au jour de la réception, le Vendeur s'oblige au remplacement des produits livrés par des produits neufs et identiques à la commande. Les frais occasionnés par la reprise et la livraison des nouveaux produits sont à la charge exclusive du Vendeur. "} />
            <TextCaption title={"5. Prix et modalités de paiement "} paragraph={"Les sommes versées dès signature d'un bon de commande sont un ACOMPTE conformément à la Loi, le contrat étant conclu définitivement. "} />
            <TextCaption title={"5.1 Prix "} paragraph={"Sauf conditions particulières expresses propres à la vente, les prix des produits vendus sont ceux figurant dans le catalogue et la liste des prix au jour de la commande. "} />
            <TextCaption title={"5.2 Modalités de paiement "} paragraph={"Sauf autres modalités prévues expressément par des conditions particulières à préciser par écrit avant livraison, le prix de vente est payable à réception de la facture. "} />
            <TextCaption paragraph={"En aucun cas, les paiements qui sont dus au Vendeur ne peuvent être suspendus ni faire l'objet d'une quelconque réduction ou compensation sans accord écrit de la part du Vendeur. Tout paiement qui est fait au Vendeur s'impute sur les sommes dues quelle que soit la cause, en commençant par celles dont l'exigibilité est la plus ancienne. A défaut de tout paiement (en tout ou partie) du prix à son échéance, le Vendeur pourra de plein droit résoudre la vente, 5 jours après une mise en demeure restée sans effet, sans préjudice de tous dommages-intérêts susceptibles d'être demandés par le Vendeur. "} />
            <TextCaption paragraph={"Par application de la loi 2008-776 du 04/08/2008 il sera appliqué des pénalités de retard calculées sur les sommes exigibles et non payées à la bonne date au taux minimum prévu par la loi, à savoir trois fois le taux d'intérêt légal en vigueur au jour de la date d'exigibilité des dites sommes. Tout retard de paiement entraînera également une indemnité forfaitaire de 40 € pour frais de recouvrement (décret 2012-1115 du 02/10/2012). "} />
            <TextCaption title={"6. Réserve de propriété "} paragraph={"Les produits sont vendus sous réserve de propriété : conformément aux dispositions de la Loi du 12/5/1980 et de la Loi du 25/01/1985 modifiée le 10/06/1994, le vendeur se réserve expressément la propriété des produits livrés jusqu'au paiement intégral du prix des ventes, frais et accessoires. Toutefois, les risques sont transférés comme indiqué supra au Client dès livraison des marchandises. "} />
            <TextCaption title={""} paragraph={"A défaut de paiement par l'acheteur d'une seule fraction du prix aux échéances convenues, la vente sera résolue de plein droit 8 jours après mise en demeure par simple lettre RAR demeurée infructueuse ; en pareille hypothèse, le vendeur reprendra les marchandises si bon lui semble et les sommes versées par le client resteront acquises à titre de dommages- intérêts, sans préjudice de toute demande en restitution des sommes reçues par l'acheteur en paiement de leur prix suite à une revente. Pour se prévaloir de ladite clause, le vendeur fera connaître sa volonté formelle à l'acheteur ou son mandataire judiciaire en cas de procédure collective de se voir restituer les marchandises par simple lettre RAR. Les chèques et lettres de change ne sont considérés comme des paiements qu'à compter de leur encaissement effectif. Jusqu'à cette date, la clause réserve de propriété conserve son plein effet. Ces dispositions ne font pas obstacle comme indiqué supra au transfert, dès livraison, des risques des produits vendus. "} />
            <TextCaption title={""} paragraph={"L'Acheteur s'engage jusqu'à complet paiement du prix, sous peine de revendication immédiate des produits par le vendeur, à ne pas transformer ni incorporer lesdits produits, ni à les revendre ou les mettre en gage. "} />
            <TextCaption title={"7. Garantie "} paragraph={"Les produits vendus sont couverts par la garantie légale des vices cachés, au sens de l'article 1641 du Code civil. L'acheteur a 8 jours pour dénoncer au Vendeur par lettre RAR tout problème de garantie, sous peine d'irrecevabilité ensuite. Ledit article dispose: „Le Vendeur est tenu de la garantie à raison des défauts cachés de la chose vendue qui la rendent impropre à l'usage auquel on la destine, ou qui diminuent tellement cet usage, que l'Acheteur ne l'aurait pas acquise, ou n'en aurait donné qu'un moindre prix, s'il les avait connus. Aucune demande d'indemnité n'est recevable pour dommages dits indirects au sens usuel de la jurisprudence. "} />
            <TextCaption title={"8. Juridiction compétente - Droit applicable "} paragraph={"Tous différends relatifs à l'interprétation et à l'exécution des présentes Conditions de vente de produits seront de la compétence exclusive du Tribunal de Grande Instance de Strasbourg. Le droit applicable est le droit français incluant la Convention de Vienne de 1980 sur la vente internationale de marchandises. "} />
            <br /><br /><br />
        </div>
    )
}

export default TermsAndConditions