import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import Stage from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Stage.jpg'
import Comprehensive from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Comprehensive.jpg'
import PreciseTime from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/PreciseTime.jpg'
import Professional from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Professional.jpg'
import Safety from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Safety.jpg'
import Design from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Design.jpg'
import HoseManagement from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/HoseManagement.jpg'
import Availability from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Availability.jpg'
import HFIndustrial from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/HFIndustrial.jpg'
import HFFluid from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/HFFluid.jpg'
import Innovation from '../../../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Innovation.jpg'

const InstallationOfPipeAndHoseLineSystems = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    const data1 = {
        image: Comprehensive,
        title: "Bien sûr, je peux vous fournir une évaluation complète et des conseils. Que souhaitez-vous évaluer et quel type de conseils recherchez-vous ?",
        description: "Nos experts hydrauliques commencent par réaliser une évaluation spécialisée de la machine ou de l'installation, identifiant ainsi le potentiel initial d'optimisation, pouvant être lié à la position, à la dimension, au choix des matériaux et à l'efficacité. Ensuite, ils estiment le coût prévu du projet. S'ils constatent une usure excessive des conduites ou des tuyaux, ils recherchent la cause et développent des solutions pour remédier au problème. Les réglementations de sécurité applicables aux systèmes hydrauliques sont sujettes à des amendements continus. Nos experts veillent à ce que leurs conseils aboutissent à des systèmes hydrauliques conformes à la loi et représentant l'état de l'art en matière de sécurité et de technologie.",
    }

    const data2 = {
        image: PreciseTime,
        title: 'La planification précise du temps et des ressources est essentielle pour garantir le succès de tout projet.',
        description: "Nos conseillers calculent le temps et les ressources nécessaires pour le projet, en tenant compte de vos exigences et suggestions. En coopération avec vous, nous établissons une fenêtre de temps pour les travaux qui s'adapte à vos besoins opérationnels. Souvent, il n'y a que quelques jours dans l'année où les machines ou l'équipement peuvent être complètement arrêtés pour la maintenance des lignes hydrauliques. Par conséquent, nous élaborons un plan de travail détaillé et utilisons notre expérience en gestion de projet pour garantir que les jalons et les délais sont respectés. Si le client le souhaite, nous prenons également en charge la coordination d'autres métiers sur les grands chantiers de construction, afin que tous les travaux puissent être réalisés de manière efficace et sûre.",
    }

    const data3 = {
        image: Professional,
        title: 'Une mise en œuvre professionnelle et respectant les délais est essentielle pour garantir le succès de tout projet',
        description: "Nous pouvons faire appel à notre pool de techniciens de service dans toute l'Allemagne pour mettre en œuvre des projets. Cela signifie que nous disposons toujours d'un personnel suffisant pour faire face aux pics de commandes pendant nos périodes chargées ou autour de Noël, ainsi que pour les grands projets. Un personnel hautement qualifié réalise tous nos travaux. Nous pouvons fabriquer des conduites et des flexibles sur mesure directement sur site dans nos ateliers mobiles. HANSA‑FLEX exécute des commandes à grande échelle nécessitant un grand nombre de conduites dans son centre de cintrage de tuyaux. Une zone de production interne pour les raccords spéciaux fabrique des solutions spécifiques aux clients de manière flexible et rapide,"
    }

    const featuresData = [
        {
            title: "Tried and tested designs",
            description: "We optimize the design of our products to make the most of the space available, while achieving high efficiency and safety.",
            image: imgMark
        },
        {
            title: " Gestion de projet efficace",
            description: " Une planification et gestion précises de projet garantissent que nous sommes toujours dans les délais et dans le budget prévu",
            image: imgMark
        },
        {
            title: "Spécialistes pour chaque tâche",
            description: "Nous pouvons faire appel à du personnel spécialisé de tout le pays pour gérer des projets de toute taille",
            image: imgMark
        },
        {
            title: "Les matériaux sont toujours facilement disponibles.",
            description: "Les composants sont toujours disponibles dans les entrepôts centraux et les succursale",
            image: imgMark
        }
    ];


    //HfTab data
    const imageTextData = [
        {
            title: "Sécurité et santé au travail fiables",
            description: "La sécurité au travail dans le contexte de l'hydraulique est un environnement spécialisé hautement dynamique. Seuls les experts immergés dans le sujet au quotidien peuvent éventuellement rester à jour. Nos spécialistes en hydraulique connaissent les exigences de la législation actuelle en matière de santé et de sécurité au travail, en particulier les règles pertinentes de la DGUV. Nous disposons de spécialistes dans notre entreprise qui sont familiarisés avec les règles spécifiques des industries individuelles. Ils sont parfaitement compétents pour choisir correctement le matériau conformément à l'EN 45545 dans le secteur ferroviaire ou pour mettre en œuvre les réglementations complexes de la DNV GL pour les applications maritimes. Nous mettons notre expertise spécialisée à votre disposition et vous conseillons afin que vous soyez toujours du bon côté de la sécurité.",
            image: Safety
        },
        {
            title: "Axé sur l'efficacité et la durabilité",
            description: "Nous ne remplaçons pas seulement les conduites de tuyaux et de flexibles existantes ; nous augmentons également l'efficacité de votre système. Nous tirons un parfait parti de l'espace restreint disponible dans les situations d'installation serrées. Nous utilisons toujours les rayons de courbure optimaux et évitons de tordre les conduites. Les contraintes de torsion sont l'une des principales raisons de l'usure accrue des lignes de flexibles hydrauliques. Dans les situations où les machines sont en mouvement dynamique, nous veillons à monter les flexibles parfaitement et, si nécessaire, à fournir une protection contre le frottement des flexibles. Cela augmentera la fiabilité et la disponibilité de votre machine. ",
            image: Design
        },
        {
            title: "Naviguez à travers la jungle des flexibles avec X-CODE",
            description: "Le système X-CODE vous donne un aperçu de vos lignes de flexibles hydrauliques. Le code à six chiffres sur l'étiquette jaune identifie de manière unique les caractéristiques du produit des éléments de connexion hydrauliques. Le X-CODE est non seulement utile lors du remplacement complet de toutes les lignes de flexibles sur un système, il facilite également la commande de pièces de rechange depuis n'importe où dans le monde. Vous pouvez également stocker les détails de toutes vos lignes de flexibles dans un format facilement gérable dans le portail de gestion des flexibles My.HANSA-FLEX. De cette façon, vous avez toujours un aperçu complet des dates d'inspection à venir et des intervalles de remplacement. ",
            image: HoseManagement
        },
        {
            title: "Flexibles, raccords et pièces spéciales ",
            description: " En tant que plus grand distributeur d'équipements hydrauliques en Europe et partenaire système en technologie des fluides, nous disposons d'un vaste portefeuille de composants hydrauliques pour les flexibles et les tuyauteries. Nous sommes également des experts dans leur installation. Avec deux entrepôts centraux et environ 250 succursales dans toute l'Europe, nous pouvons toujours fournir les composants nécessaires, même en grandes quantités. En utilisant notre réseau international et nos installations de production internes, nous sommes également en mesure de fournir des composants spéciaux et des pièces fabriquées rapidement. ",
            image: Availability
        },
    ];

    const tabTitles = [
        { title: "Safety", eventKey: "Safety" },
        { title: "Design", eventKey: "Design" },
        { title: "Hose management", eventKey: "Hose management" },
        { title: "Availability", eventKey: "Availability" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"Installation de tuyaux et de flexibles pour des installations complètes"}
                    paragraph={"Les tuyaux et flexibles des machines et systèmes hydrauliques doivent être remplacés régulièrement pour des raisons de sécurité. Mais d'autres projets tels que l'augmentation de l'efficacité peuvent également être la raison d'un remplacement complet. Avec des techniciens de service parfaitement formés, nous réalisons de telles commandes de manière fiable, selon des normes élevées et également dans des délais serrés. "}
                />
            </div>

            <div>
                <SectionHeader
                    title={'Optimisation du système par le remplacement régulier des lignes hydrauliques'}
                    subtitle={"Le remplacement des tuyaux et des flexibles sur les machines et les équipements n'est pas seulement une question de sécurité, il contribue également de manière cruciale à l'optimisation des systèmes hydrauliques."}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
            </div>

            <div>
                <SectionHeader
                    title={"VOS AVANTAGES EN UN COUP D'ŒIL "}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            {/* TODO: Carousel */}
            {/* TODO: Client Review */}

            <div>
                <SectionHeader
                    title={"Fluide contaminé"}
                    subtitle={"Ces cinq facteurs endommagent l'huile hydraulique. "}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={HFIndustrial}
                                title="Service Industriel HANSA-FLEX   "
                                desc={"  Notre équipe de techniciens de service expérimentés du Service Industriel HANSA-FLEX vous soutient dans tous les travaux de maintenance, d'entretien et d'installation sur les équipements mobiles et "} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={HFFluid}
                                title="Service des Fluides HANSA-FLEX "
                                desc={" Des soins réguliers de l'huile sont un aspect clé pour garantir le bon fonctionnement sans problème des systèmes et des installations hydrauliques. "} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Innovation}
                                title=" Innovation et fabrication"
                                desc={"  En tant que partenaire système, HANSA-FLEX vous propose une gamme complète de services d'ingénierie, du développement et de la conception de systèmes à leur mise en œuvre, le tout à partir d'un"} /></Col>
                        </Row>
                    </Container>
                </div>

            </div>
        </div>
    )
}

export default InstallationOfPipeAndHoseLineSystems