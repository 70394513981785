import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../Components/HfTabs/HfTabs';
import { Col, Container, Row } from 'react-bootstrap';

import imgStage from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/Stage.jpg'
import DigitalMeasuring from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/DigitalMeasuring.jpg'
import AnalogueMeasuring from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/AnalogueMeasuring.jpg'
import MeasuringConnection from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/MeasuringConnection.jpg'
import ProvenMeasuring from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/ProvenMeasuring.jpg'
import Preasure from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/Preasure.jpg'
import Volume from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/Volume.jpg'
import Temperature from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/Temperature.jpg'
import ReadyToInstall from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/ReadyToInstall.jpg'
import Gauges from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/Gauges.jpg'
import Troubleshooting from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/Troubleshooting.jpg'
import Stock from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/Stock.jpg'


const MeasuringTechnology = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const MeasuringTech1 = {
        image: DigitalMeasuring,
        title: 'Technologie de mesure numérique',
        description: 'La technologie de mesure numérique se révèle utile lorsque des mesures très précises sont nécessaires ou lorsque des données de mesure doivent être enregistrées sur de longues périodes. Cette technologie utilise des capteurs pour évaluer clairement toute situation.',
        listItems: ["Appareil de mesure manuel numérique à 2 et 5 canaux", "Capteurs numériques de pression, de température et de vitesse de rotation", "Accessoires associés tels que des câbles de mesure"],
    }

    const MeasuringTech2 = {
        image: AnalogueMeasuring,
        title: 'Technologie de mesure analogique',
        description: "Toutes les mesures n'ont pas besoin d'être précises à trois décimales près. Dans ces cas, la technologie de mesure analogique est un bon choix. Ces dispositifs sont économiques et fournissent des données de mesure fiables.",
        listItems: ["Manomètres avec montages à bague frontale et à bague de serrage", "Débitmètres, débitmètres volumétriques et débitmètres", "Accessoires tels que des tuyaux de mesure, des raccords et des vannes d'arrêt"],
    }

    const MeasuringTech3 = {
        image: MeasuringConnection,
        title: 'Connexions de mesure, raccords et accessoires',
        description: "Les connexions et raccords de mesure permettent d'installer des points de mesure dans le système hydraulique.Les raccords permettent de connecter rapidement et facilement les composants lors du diagnostic et de l'entretien.",
        listItems: ["Connexions de mesure M16x2, M16x1.5 et S12.65x1.5", "Raccords à bague de serrage pour applications de diagnostic et d'entretien", "Raccords à bride de serrage en acier et en acier inoxydable"],
    }

    const MeasuringTech4 = {
        image: ReadyToInstall,
        listItems: ["Ensembles de dispositifs de mesure analogiques", "Ensembles de dispositifs de mesure numériques", "Pour une utilisation lors de l'entretien et pour des mesures à long terme"],
    }

    // Données du carrousel de cartes de fonctionnalités
    const featuresData = [
        {
            title: "Gamme de produits complète",
            description: "Dispositifs de mesure et systèmes d'analyse pour la mesure de la pression, du débit et de la température",
            image: imgMark
        },
        {
            title: "Meilleure qualité",
            description: "Produits largement testés avec des homologations internationales pour une précision maximale",
            image: imgMark
        },
        {
            title: "Solutions personnalisées",
            description: "Fournisseur de systèmes de mesure analogiques et numériques adaptés aux besoins spécifiques du client",
            image: imgMark
        },
        {
            title: "Assistance des experts",
            description: "Sélection et installation de la technologie de mesure et surveillance de l'état des systèmes hydrauliques",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données supplémentaires de cartes de fonctionnalités
    ];

    // Données de HfTab
    const imageTextData = [
        {
            title: "Dispositifs de mesure de pression pour tous les domaines d'utilisation",
            description: "La surveillance de la pression dans les systèmes hydrauliques est essentielle pour que les machines et les équipements fonctionnent de manière fluide et fiable. En plus des simples manomètres pour mesurer la pression, HANSA‑FLEX fournit également des capteurs de pression pour un équipement de mesure très précis à incorporer dans les commandes électriques.",
            listItems: ["Pression de travail admissible : jusqu'à 600 bar", "Précision de mesure : +/- 0,5 % de l'échelle complète", "Plages de mesure de nos capteurs de pression : de 0 à 10 bar et de 0 à 600 bar"],
            image: Preasure
        },
        {
            title: "Débitmètres de HANSA‑FLEX",
            description: "Les débitmètres mesurent le volume d'huile s'écoulant par unité de temps. HANSA‑FLEX fournit des débitmètres, des moniteurs de débit, des débitmètres à turbine et à engrenages.",
            listItems: ["Volume mesuré : jusqu'à 600 l par minute", "Précision de mesure : +/- 0,5 % de la lecture", "Plages de mesure : de 1 à 20 l/min et de 40 à 600 l/min"],
            image: Volume
        },
        {
            title: "Dispositifs de mesure de température fiables",
            description: "Les simples dispositifs de mesure de température tels que les thermomètres ou les capteurs résistants à la pression mesurent la température de l'huile dans les systèmes hydrauliques, dans le réservoir ou à des points de mesure séparés. Ils permettent également à l'utilisateur de surveiller la qualité du fluide, car des températures supérieures à 60 °C entraînent un vieillissement prématuré de l'huile hydraulique et ont des effets néfastes sur la viscosité.",
            listItems: ["Mesure de température avec des capteurs électroniques", "Température la plus élevée admissible : 200 °C", "Précision de mesure : +/- 2 Kelvin"],
            image: Temperature
        },
    ];

    const tabTitles = [
        { title: "Pression", eventKey: "Pression" },
        { title: "Débit", eventKey: "Débit" },
        { title: "Température", eventKey: "Température" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"TECHNOLOGIE DE MESURE POUR LES SYSTÈMES HYDRAULIQUES DE HANSA‑FLEX"}
                    paragraph={"La technologie de mesure dans les systèmes hydrauliques doit être très précise. La pression, la température et le débit volumétrique doivent être mesurés avec précision pour garantir le bon fonctionnement des systèmes et des machines. La surveillance continue de l'état peut être un moyen efficace de prévenir l'usure des composants hydrauliques. Les instruments de mesure numériques deviennent de plus en plus populaires pour le contrôle et la régulation des processus automatiques."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"TECHNOLOGIE DE MESURE ANALOGIQUE ET NUMÉRIQUE"}
                    subtitle={"Notre boutique en ligne propose une technologie de mesure éprouvée et des systèmes de mesure innovants de fabricants renommés pour divers domaines d'application. Nos experts vous conseillent sur le choix et la conception des appareils de mesure, des capteurs et des logiciels d'évaluation."}
                />
                <ImageText
                    data={MeasuringTech1} />
                <ImageText
                    data={MeasuringTech2}
                    left={false} />
                <ImageText
                    data={MeasuringTech3} />
            </div>

            <div>
                <SectionHeader
                    title={"ACHETEZ LA TECHNOLOGIE DE MESURE AUPRÈS DES SPÉCIALISTES DES FLUIDES"}
                    subtitle={"La technologie de mesure précise fournie par HANSA‑FLEX constitue la base de la surveillance, du contrôle et de l'optimisation du fonctionnement des systèmes hydrauliques."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"TECHNOLOGIE DE MESURE POUR L'ENREGISTREMENT DE LA PRESSION, DU DÉBIT VOLUMÉTRIQUE ET DE LA TEMPÉRATURE"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"Ensembles de technologie de mesure prêts à installer dans un coffret de transport pratique"}
                    subtitle={"Notre boutique en ligne vous propose des systèmes de mesure et d'analyse complets ainsi que des ensembles prêts à l'emploi comprenant des capteurs, des connexions, des tuyaux et des câbles de mesure dans un coffret de transport robuste. Nous sommes également heureux de configurer des ensembles de technologie de mesure personnalisés selon vos besoins spécifiques."}
                />
                <ImageText
                    data={MeasuringTech4} />
            </div>

            {/* TODO: Carrousel */}

            <div>
                <SectionHeader
                    title={"CES PRODUITS ET SERVICES PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={Gauges}
                                title="Manomètres et thermomètres pour les systèmes pneumatiques"
                                desc={"Nous vous proposons différents systèmes de mesure analogiques et numériques pour une mesure fiable de la pression et de la température pour un nombre quelconque d'applications pneumatiques."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Troubleshooting}
                                title="Diagnostic de pannes et optimisation des installations"
                                desc={"Nos experts en service industriel vous accompagnent pour trouver, analyser et rectifier les défauts ou les points faibles de vos installations afin que votre équipement fonctionne de manière optimale."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Stock}
                                title="Plus de 4 500 composants hydrauliques différents en stock"
                                desc={"Les composants hydrauliques de HANSA‑FLEX répondent aux normes de qualité les plus élevées. La large gamme de produits en stock vous garantit de les recevoir dans les plus brefs délais."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )

}

export default MeasuringTechnology