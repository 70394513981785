import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import Stage from '../../../assets/Services/AssemblyAndInstallation/PlantProjectPlanningAndManagement/Stage.jpg'
import WeCanDesign from '../../../assets/Services/AssemblyAndInstallation/PlantProjectPlanningAndManagement/WeCanDesign.jpg'
import DetailedProjectPlanning from '../../../assets/Services/AssemblyAndInstallation/PlantProjectPlanningAndManagement/DetailedProjectPlanning.jpg'
import Implementation from '../../../assets/Services/AssemblyAndInstallation/PlantProjectPlanningAndManagement/Implementation.jpg'
import Renovation from '../../../assets/Services/AssemblyAndInstallation/PlantProjectPlanningAndManagement/Renovation.jpg'
import Installation from '../../../assets/Services/AssemblyAndInstallation/PlantProjectPlanningAndManagement/Installation.jpg'
import TrainingProgramme from '../../../assets/Services/AssemblyAndInstallation/PlantProjectPlanningAndManagement/TrainingProgramme.jpg'

const PlantProjectPlanningAndManagement = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        image: WeCanDesign,
        title: 'Nous pouvons concevoir un système répondant à vos exigences ou vérifier vos dessins',
        description: 'Nous concevons votre système hydraulique pour répondre à vos besoins. Si vous avez déjà produit vos propres dessins, nous vérifions vos propositions. Lors de cette vérification, nous accordons une attention particulière aux points suivants :',
        listItems: [
            "Utilisation optimale de l'espace d'installation et de l'énergie hydraulique",
            "Sélection du matériau idéal pour les exigences spécifiques ",
            "Respect des rayons de courbure autorisés sans imposition de torsion ",
            "Respect des normes pertinentes telles que la DGUV, la DNV-GL et l'EN 45545",
            "Assurer la disponibilité continue des pièces de rechange (gestion de l'obsolescence"
        ]
    }

    const data2 = {
        image: DetailedProjectPlanning,
        title: 'Planification détaillée du projet pour garantir le respect des délais',
        description: "Nos experts en hydraulique ont non seulement une vaste expertise spécialisée, mais sont également des chefs de projet expérimentés. Ils vous aident à coordonner le projet et travaillent à l'achever dans les délais et dans le respect du budget.",
        listItems: [" Création d'un programme de projet",
            "Définir des jalons réalistes et vérifiables",
            "Des délais pour maintenir le projet dans les délais"
        ]
    }

    const data3 = {
        image: Implementation,
        title: 'Mise en œuvre par des spécialistes expérimentés',
        description: "En plus de la gestion du temps, nos spécialistes s'occupent également de la gestion des ressources, veillant à ce que toute la main-d'œuvre et les matériaux nécessaires à chaque package de travail soient disponibles",
        listItems: [ "Personnel spécialisé pour tous les packages de travail","Experts dans les tâches nécessitant des qualifications ou une expérience spécifiques","Disponibilité assurée des matériaux","Des conteneurs d'atelier peuvent être mis à disposition si nécessaire"]
 }

    const featuresData = [
        {
            title: "Évaluation et conseils transparents",
            description: " Nos experts évaluent correctement votre système et offrent des conseils justes et compétents. ",
            image: imgMark
        },
        {
            title: " Planification minutieuse ",
            description: "Avant le début des travaux de rénovation, nous élaborons un plan de projet détaillé comprenant des jalons. ",
            image: imgMark
        },
        {
            title: "Normes et qualité ",
            description: " Nos experts respectent toutes les normes et fournissent la qualité HANSA-FLEX éprouvée.",
            image: imgMark
        },
        {
            title: "Tout sous un même toit ",
            description: "Des techniciens parfaitement formés et une disponibilité de matériaux optimale. ",
            image: imgMark
        }
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"PLANIFICATION DE PROJET POUR LES SYSTÈMES HYDRAULIQUES "}
                    paragraph={"La conception et la construction de systèmes hydrauliques nécessitent des connaissances spécialisées. Les normes et réglementations applicables telles que la DGUV changent continuellement. Par conséquent, les entreprises concevant leurs propres systèmes hydrauliques sont confrontées à un grand défi pour s'assurer qu'elles sont techniquement à jour et conformes aux dernières exigences légales. Nos experts ont des années d'expérience dans ce domaine et vous soutiennent dans la gestion de projet de votre système hydraulique prévu.  "}
                />
            </div>

            <div>
                <SectionHeader
                    title={"DE L'AVIS INITIAL À LA MISE EN ŒUVRE"}
                    subtitle={"Nous vous soutenons dans toutes les phases du projet. Nous vous conseillons pendant la phase de planification et répondons à toutes vos questions concernant la sécurité et l'efficacité énergétique, nous vous apportons un soutien actif dans la conception, vérifions vos dessins et mettons en œuvre vos exigences de manière professionnelle"}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
            </div>

            <div>
                <SectionHeader
                    title={" MODERNISATION DES SYSTÈMES HYDRAULIQUES AUX DERNIÈRES NORMES TECHNOLOGIQUES "}
                    subtitle={"La modernisation et la rénovation des systèmes hydrauliques avec les conseils et le soutien de HANSA-FLEX offrent de nombreux avantages et apportent une véritable valeur ajoutée à votre entreprise "}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            {/* TODO: Carousel */}
            {/* TODO: Client Review */}

            <div>
                <SectionHeader
                    title={"CE QUE TOUT LE MONDE DEVRAIT SAVOIR SUR LA GESTION DES FLUIDES "}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Renovation}
                                title="Rénovation et modernisation "
                                desc={"Nous rénovons d'anciennes machines et systèmes, les mettant à jour avec les technologies actuelles en termes d'efficacité et de sécurité."} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Installation}
                                title="Installation de systèmes de conduites et de tuyaux. "
                                desc={"L'installation d'un système complet de conduites et de tuyaux sur des machines et des installations est complexe et chronophage. Nous pouvons réaliser cette tâche pour vous."} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={TrainingProgramme}
                                title="Notre programme de formation."
                                desc={" Gardez vos connaissances spécialisées à jour et élargissez-les grâce à notre gamme complète de cours de formation et de séminaires sur les fluides...  "} /></Col>
                        </Row>
                    </Container>
                </div>

            </div>
        </div>
    )
}

export default PlantProjectPlanningAndManagement