import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../../Components/ImageText/ImageText'
import imgMark from '../../../../assets/mark.png';
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';

import imgStage from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/Stage.jpg'
import imgPipeline from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/Pipeline.jpg'
import imgCompactCartridge from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/CompactCartridge.jpg'
import imgCetopValves from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/CetopValves.jpg'
import imgSeriesProduces from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/SeriesProduces.jpg'
import imgPrototypeDev from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/PrototypeDev.jpg'
import imgCompact from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/Compact.jpg'
import imgOptimised from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/Optimised.jpg'
import imgSafety from '../../../../assets/Products/DriveAndControlTechnology/ValveTechnology/Safety.jpg'



const ValveTechnology = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const valveTech1 = {
        image: imgSeriesProduces,
        title: 'Groupes de composants hydrauliques produits en série',
        description: 'Nous travaillons pour répondre à vos processus logistiques et de production. Sur demande, HANSA‑FLEX fabrique et installe des groupes de composants complets et peut fournir des composants pré-triés sous forme de kits. Nous vous offrons :',
        listItems: ["Conception et production en série par un seul fournisseur", "Production d'articles individuels et de groupes de composants hydrauliques", "Précision maximale et livraison Just-In-Sequence"],
        buttonName: "EN SAVOIR PLUS"
    }

    const valveTech2 = {
        image: imgPrototypeDev,
        title: 'Développement de prototypes',
        description: "La consultation avec nos experts est un processus bilatéral dans lequel nous développons avec vous la solution parfaite pour votre application et vous soutenons de la conception à l'approvisionnement.Vos avantages des solutions développées avec HANSA‑FLEX: ",
        listItems: ["Développement de pièces spéciales spécifiques aux clients sur demande", "Appels de commande à court terme", "Assistance client complète 24/7 avec le service hydraulique rapide mobile"],
        buttonName: "EN SAVOIR PLUS"
    }

    // données du carrousel de cartes de fonctionnalités
    const featuresData = [
        {
            title: "Composants hydrauliques",
            description: "Un vaste portefeuille allant des pompes et moteurs aux valves et filtres.",
            image: imgMark
        },
        {
            title: "Pompes",
            description: "Nous avons des pompes à palettes, axiales, radiales et à main dans nos entrepôts.",
            image: imgMark
        },
        {
            title: "Équipements de mesure et systèmes d'analyse",
            description: "L'optimisation des systèmes hydrauliques et pneumatiques repose sur la mesure.",
            image: imgMark
        },
        // Ajouter plus d'objets pour des données de cartes de fonctionnalités supplémentaires
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"TECHNOLOGIE DES VANNES DE HANSA‑FLEX"}
                    paragraph={"Les vannes sont au cœur de tout système hydraulique. Elles commutent, régulent et contrôlent le flux de fluide. HANSA‑FLEX dispose d'un vaste portefeuille de produits pour le domaine de la technologie des vannes. Les exemples incluent les vannes de commande directionnelle avec des commandes manuelles, électriques ou pneumatiques, et les vannes d'arrêt, de pression, de débit et de contrôle proportionnel pour des plages de pression allant jusqu'à 350 bars. Nous disposons d'une large gamme de produits en stock, vous pouvez donc généralement vous attendre à des délais de livraison courts."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"VANNES PARFAITEMENT ADAPTÉES À VOTRE APPLICATION"}
                    subtitle={"Profitez des avantages des vannes HANSA‑FLEX avec leur haute dynamique et leurs temps de réaction courts combinés à d'excellentes capacités de contrôle pour toutes les applications et situations d'installation."}
                />
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgPipeline}
                            title="Vannes d'installation de pipeline avec des fonctions de base simples"
                            desc={"Ces vannes d'installation de pipeline compactes remplissent des fonctions de base pour le contrôle des fluides et sont installées entre les tuyaux directement dans le circuit hydraulique."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgCompactCartridge}
                            title="Vannes de cartouche compactes pour des espaces d'installation restreints"
                            desc={"Étant installée directement dans le bloc de soupape, une vanne de cartouche est un moyen d'économiser de l'espace pour mettre en œuvre des commandes complexes sans boîtiers de soupape supplémentaires."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgCetopValves}
                            title="Implémentez une gamme diverse de fonctions de valve avec des vannes CETOP"
                            desc={"Avec leurs connexions et fonctions de commutation standardisées, les vannes CETOP peuvent être combinées de toutes sortes de façons pour mettre en œuvre une large gamme de fonctions."} /></Col>
                    </Row>
                </Container>
            </div>

            <div>
                <SectionHeader
                    title={"DE LA CONCEPTION ET DU DÉVELOPPEMENT DE PROTOTYPES JUSQU'À LA PRODUCTION EN SÉRIE"}
                    subtitle={"Nos experts en fluides et en hydraulique fournissent des conseils techniques approfondis sur la technologie des vannes. Ils sélectionnent les composants appropriés et développent la solution optimale pour vous. Des blocs de soupapes spécifiques aux clients peuvent être produits rapidement car HANSA‑FLEX dispose de ses propres ateliers de fabrication."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"NOS ENTREPÔTS STOCKENT GÉNÉRALEMENT ENVIRON 4 500 PRODUITS DE QUALITÉ HANSA‑FLEX ÉPROUVÉE."}
                    subtitle={"La large gamme de composants hydrauliques de HANSA‑FLEX répond aux normes de qualité les plus élevées et est facilement disponible. De plus, nous offrons à nos clients des conseils complets pour qu'ils reçoivent toujours la meilleure solution en technologie de valve et de contrôle."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"SERVICES HYDRAULIQUES DES EXPERTS EN FLUIDE CHEZ HANSA‑FLEX"}
                    subtitle={"En tant que partenaire système polyvalent et largement expérimenté dans la technologie des fluides, nous fournissons des conseils pratiques et un soutien à nos clients avec des applications mobiles dans l'industrie de la construction, le génie des machines et des installations et les systèmes hydrauliques stationnaires."}
                />
                <ImageText
                    data={valveTech1} />
                <ImageText
                    data={valveTech2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCompact}
                                title="Composants toujours disponibles"
                                desc={"Vous pouvez compter sur HANSA‑FLEX ! En tant que client de HANSA‑FLEX, vous bénéficiez de livraisons ponctuelles et d'une large gamme de services liés à la logistique."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgOptimised}
                                title="Conception de système optimisée"
                                desc={"Nos spécialistes en hydraulique vous conseillent et vous aident à planifier votre système hydraulique et s'assurent que l'ensemble du système est conçu pour l'efficacité."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgSafety}
                                title="Sécurité et fiabilité confirmées par des tests approfondis"
                                desc={"Le personnel autorisé effectue des tests en interne ou sur vos sites, y compris la documentation des tests et des résultats."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )

}

export default ValveTechnology