import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgHydra_Centerspread from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/Hydra_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgHydra_Imagetext_01 from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/Hydra_Imagetext_01.jpg'
import imgHydra_Imagetext_02 from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/Hydra_Imagetext_02.jpg'
import imgHydra_Imagetext_03 from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/Hydra_Imagetext_03.jpg'
import { Container, Row, Col } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard'
import imgHydra_Imagecard_01 from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/Hydra_Imagecard_01.jpg'
import imgHydra_Imagecard_02 from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/Hydra_Imagecard_02.jpg'
import imgHydra_Imagecard_03 from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/Hydra_Imagecard_03.jpg'
import { MdDescription } from 'react-icons/md'

const Hydraulicworkshopcontainers = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Hydra_Imagetext_01 = {
    image: imgHydra_Imagetext_01,
    title: 'Entièrement équipé ',
    description: "Les conteneurs de service hydraulique sont parfaitement préparés pour leur utilisation spécifique sur vos lieux : ils sont équipés de machines de découpe, de dénudage et de marquage de tuyaux, d'un dispositif d'insertion de raccords, de dévidoirs de tuyaux, d'un compresseur, d'un système de nettoyage de tuyaux et, sur demande, d'un banc d'essai haute pression. Ils disposent également de suffisamment d'espace pour stocker les matériaux et composants de tuyaux. Dans ces conteneurs, les techniciens assemblent des lignes de tuyaux hydrauliques personnalisées conformément aux normes DIN/EN/ISO et les marquent selon la norme DIN/EN/ISO 4403. Que vous souhaitiez une isolation de conteneur individualisée, une climatisation, un double conteneur ou des machines à plus haute performance : nous pouvons proposer des solutions spécifiques aux besoins des clients pour tous les domaines d'utilisation.",
  };

  const Hydra_Imagetext_02 = {
    image: imgHydra_Imagetext_02,
    title: 'Formation et conseils inclus ',
    description: "Les services de HANSA-FLEX ne se limitent pas à la mise en place des conteneurs hydrauliques : sur demande, nos experts en hydraulique dispensent également des sessions de formation plus intensives directement sur site. Au cours de ces sessions, les employés acquièrent toutes les connaissances et compétences nécessaires pour préassembler des tuyaux et des composants conformément aux normes applicables. La réussite de leur formation est confirmée par un certificat. Le soutien du service de conteneur de service hydraulique se poursuit pendant la durée du projet : les techniciens de la succursale HANSA-FLEX la plus proche sont à votre disposition, ainsi qu'à celle de vos employés, pour répondre à toutes les questions ou effectuer toutes les tâches.",
  };

  const Hydra_Imagetext_03 = {
    image: imgHydra_Imagetext_03,
    title: 'Approvisionnement fiable en matériel ',
    description: "Les services de HANSA-FLEX ne se limitent pas à la mise en place des conteneurs hydrauliques : sur demande, nos experts en hydraulique dispensent également des sessions de formation plus intensives directement sur site. Au cours de ces sessions, les employés acquièrent toutes les connaissances et compétences nécessaires pour préassembler des tuyaux et des composants conformément aux normes applicables. La réussite de leur formation est confirmée par un certificat. Le soutien du service de conteneur de service hydraulique se poursuit pendant la durée du projet : les techniciens de la succursale HANSA-FLEX la plus proche sont à votre disposition, ainsi qu'à celle de vos employés, pour répondre à toutes les questions ou effectuer toutes les tâches.",
  };

  return (
    <div>
      <CenterStage
        imageSrc={imgHydra_Centerspread}
        title={"L'ATELIER MOBILE : CONTENEUR DE SERVICE HYDRAULIQUE"}
        paragraph={"Que ce soit sur une plate-forme de forage en pleine tempête en mer, sur un chantier de construction en montagne glacée, dans une région désertique brûlante, sur un grand projet ou pour des services sur site fournis par le Service Industriel de HANSA-FLEX : un conteneur de service hydraulique est un atelier hydraulique entièrement équipé et peut vous offrir une disponibilité maximale des lignes de tuyaux hydrauliques partout dans le monde, réduisant ainsi considérablement le temps et l'argent perdus en raison des arrêts de production."}
      />

      <SectionHeader
        title={"LIGNES DE TUYAUX PERSONNALISÉES FABRIQUÉES OÙ VOUS LE SOUHAITEZ"}
        subtitle={"Les conteneurs de service hydraulique HANSA-FLEX : flexibles, entièrement équipés, utilisables partout."}
      />

      <div >
        <br />
        <ImageText data={Hydra_Imagetext_01} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Hydra_Imagetext_02} />
      </div>

      <div >
        <br />
        <ImageText data={Hydra_Imagetext_03} left={false} />
      </div>

      <SectionHeader
        title={"VOICI VOS AVANTAGES"}
        subtitle={"Avec les conteneurs de service hydraulique, vous pouvez fabriquer toutes les lignes de tuyaux requises pour votre projet quand vous le souhaitez. Équipés des dernières machines et de composants de haute qualité, les conteneurs de service hydraulique signifient que vous pouvez vous attendre à une fiabilité maximale des processus et à des temps d'arrêt minimaux."}
      />
      {/* TODO: Points */}

      <SectionHeader
        title={"CARACTÉRISTIQUES DU SERVICE – UN APERÇU"}
        subtitle={"Ce que nos conteneurs de service hydraulique peuvent vous offrir."}
      />
      {/* TODO: HFTabs */}

      <SectionHeader
        title={"DANS DES CONDITIONS DIFFICILES OU COMME ATELIER STATIONNAIRE"}
        subtitle={"Les conteneurs de service hydraulique de HANSA-FLEX sont polyvalents et peuvent être utilisés partout dans le monde."}
      />
      {/* TODO: Points */}

      <SectionHeader
        title={"CES SUJETS POURRAIENT AUSSI VOUS INTÉRESSER"}
        subtitle={""}
      />

      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHydra_Imagecard_01} learnMoreLink="/mobile-rapid-hydraulic-service" title="Disponibilité 24/7 : le service d'urgence mobile de HANSA-FLEX" desc="Nous sommes là pour vous en cas d'urgence, 24 heures sur 24 : notre Service Hydraulique Rapide peut rapidement remplacer les composants défectueux tels que les lignes de tuyaux." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgHydra_Imagecard_01} learnMoreLink="/assembly-and-installation" title="De l'installation à l'entretien : Service Industriel HANSA-FLEX" desc="Nous vous offrons un service complet grâce à notre Service Industriel HANSA-FLEX. Les techniciens de service peuvent tout faire pour vous : de l'installation à l'entretien professionnel et." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHydra_Imagecard_01} learnMoreLink="/Worksinworks" title="Fiabilité maximale des processus : fabrication sur site " desc="Si vous le souhaitez, nos employés peuvent fabriquer les composants requis sur place et les fournir Juste-À-Temps à vos lignes de production." />
          </Col>
        </Row>
      </Container>
    </div>
  );

}

export default Hydraulicworkshopcontainers