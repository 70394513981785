import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../Components/HfTabs/HfTabs';

import imgStage from '../../../assets/Products/DriveAndControlTechnology/Pumps/Stage.jpg'
import imgGearPumps from '../../../assets/Products/DriveAndControlTechnology/Pumps/GearPumps.jpg'
import imgAxialPistonPumps from '../../../assets/Products/DriveAndControlTechnology/Pumps/AxialPistonPumps.jpg'
import imgRadialPiston from '../../../assets/Products/DriveAndControlTechnology/Pumps/RadialPiston.jpg'
import imgGearPump from '../../../assets/Products/DriveAndControlTechnology/Pumps/GearPump.jpg'
import imgAxialPistonPumps2 from '../../../assets/Products/DriveAndControlTechnology/Pumps/AxialPistonPumps2.jpg'
import imgRadialPistonPump2 from '../../../assets/Products/DriveAndControlTechnology/Pumps/RadialPistonPump2.jpg'
import imgVanePump from '../../../assets/Products/DriveAndControlTechnology/Pumps/VanePump.jpg'
import imgGearFLowDividers from '../../../assets/Products/DriveAndControlTechnology/Pumps/GearFLowDividers.jpg'
import imgTraining from '../../../assets/Products/DriveAndControlTechnology/Pumps/Training.jpg'
import imgRepairAndOverhaul from '../../../assets/Products/DriveAndControlTechnology/Pumps/RepairAndOverhaul.jpg'
import imgValveTech from '../../../assets/Products/DriveAndControlTechnology/Pumps/ValveTech.jpg'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import { Col, Container, Row } from 'react-bootstrap';




const Pumps = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const pump1 = {
        image: imgGearPumps,
        title: 'Les pompes à engrenages sont économiques et robustes',
        description: "Les pompes à engrenages HANSA‑FLEX sont robustes, économiques, simples dans leur construction et faciles à utiliser. C'est pourquoi on les trouve dans de nombreux domaines de l'hydraulique mobile et industrielle.",
        listItems: ["Pompes à déplacement fixe économiques", "Déplacements de 0,17 cm³ à 74,5 cm³", "Pression de fonctionnement de 240 bars à 280 bars"],
        buttonName: "TOUTES LES POMPES À ENGRENAGES"
    }

    const pump2 = {
        image: imgAxialPistonPumps,
        title: 'Les pompes à piston axial peuvent être utilisées pour des applications allant jusqu\'à la plage de haute pression',
        description: 'Les pompes à piston axial sont disponibles en tant que pompes à déplacement fixe et à déplacement variable. Elles sont capables de créer des pressions élevées et se distinguent par leur conception compacte.',
        listItems: ["Création de pressions élevées jusqu'à 350 bars", "Grand déplacement de 12 cm³ à 130 cm³", "Faible pulsation dans le débit"],
        buttonName: "TOUTES LES POMPES À PISTON AXIAL"
    }

    const pump3 = {
        image: imgRadialPiston,
        title: 'Pompes à pistons radiaux et pompes à palettes',
        description: "Notre gamme de produits comprend des pompes à pistons radiaux et des pompes à palettes pour des scénarios d'utilisation spéciaux.Elles sont capables de créer des pressions jusqu'à 500 bars par exemple.",
        listItems: ["Pompes à pistons radiaux", "Pompes à palettes", "Solutions spéciales supplémentaires sur demande"],
    }

    const pump4 = {
        image: imgGearFLowDividers,
        title: 'Répartiteurs de débit à engrenages pour applications hydrauliques',
        description: "Les répartiteurs de débit fonctionnent sur le même principe qu'une pompe à engrenages.Les engrenages sont connectés les uns aux autres sur un arbre, tournent à la même vitesse et déplacent des quantités égales d'huile. Cela permet, par exemple, d'alimenter deux circuits en huile à partir d'une seule pompe. HANSA‑FLEX propose des répartiteurs de débit dans des tailles de 0 à 2, des versions à 2 et 4 voies, avec des débits de 0,7 à 25,9 l/min par élément et éventuellement avec des soupapes de limitation de pression et de correction de phase.",
        buttonName: "TOUS LES RÉPARTITEURS DE DÉBIT"
    }

    // Données du carrousel de cartes de fonctionnalités
    const featuresData = [
        {
            title: "Qualité éprouvée",
            description: "Avec nous, vous pouvez compter sur la qualité hydraulique éprouvée HANSA‑FLEX.",
            image: imgMark
        },
        {
            title: "Conseils des spécialistes de l'hydraulique",
            description: "Nos experts vous conseillent et trouvent la solution optimale pour vos applications.",
            image: imgMark
        },
        {
            title: "Disponibilité excellente des pièces",
            description: "Notre concept d'entrepôt et de logistique peut fournir des pompes et des accessoires sur une base Just-In-Time.",
            image: imgMark
        },
        // Ajoutez plus d'objets pour des données supplémentaires de cartes de fonctionnalités
    ];

    // Données du tableau HfTab
    const imageTextData = [
        {
            title: "Pompes à engrenages économiques et robustes",
            description: "Le type de conception signifie que le débit n'est pas complètement exempt de pulsations. En revanche, cette forme de construction rend les pompes à engrenages très robustes, économiques et nécessitant peu d'entretien.",
            image: imgGearPump
        },
        {
            title: "Pompes à piston axial efficaces et à faibles vibrations",
            description: "Dans une pompe à piston axial, les tiges de piston sont disposées en cercle sur une plaque de chasse et peuvent se déplacer librement dans un palier à rotule. Lorsque la plaque de chasse tourne, les extrémités opposées des pistons passent devant l'entrée et la sortie d'huile hydraulique. Toutes les tiges de piston se déplacent continuellement en raison de la surface inclinée de la plaque de chasse. Plus l'angle d'inclinaison est grand, plus les tiges de piston descendent dans les cylindres et plus le débit volumétrique résultant est élevé. Les pompes à piston axial sont disponibles en deux versions : les pompes à axe plié avec un débit volumétrique fixe et les pompes à débit variable qui permettent de réguler le débit volumétrique. Leur conception plus technique entraîne des coûts d'approvisionnement plus élevés et une consommation d'énergie plus importante.",
            image: imgAxialPistonPumps2
        },
        {
            title: "Pompes à pistons radiaux pour des pressions élevées",
            description: "Les pompes à pistons radiaux fonctionnent de manière similaire aux pompes à piston axial. Avec les pompes à pistons radiaux, cependant, les pistons ne sont pas disposés parallèlement à l'axe d'entraînement mais radialement en cercle autour de celui-ci. Le bloc-cylindres de la pompe est décalé par rapport à l'axe de l'arbre. Le mouvement excentrique résultant crée l'effet de pompage. Les pompes à pistons radiaux peuvent créer des pressions très élevées et sont très efficaces. Cependant, elles doivent être solidement construites pour résister à la haute pression et nécessitent donc plus d'espace d'installation.",
            image: imgRadialPistonPump2
        },
        {
            title: "Pompes à palettes pour un débit volumétrique élevé",
            description: "Les pompes à palettes fonctionnent de manière efficace, causent seulement de légères pulsations et sont très silencieuses. Comparées à d'autres types de pompes, elles sont sujettes à une usure élevée et nécessitent donc plus d'entretien.",
            image: imgVanePump
        },
    ];

    const tabTitles = [
        { title: "Pompe à engrenages", eventKey: "Pompe à engrenages" },
        { title: "Pompe à piston axial", eventKey: "Pompe à piston axial" },
        { title: "Pompe à piston radial", eventKey: "Pompe à piston radial" },
        { title: "Pompe à palettes", eventKey: "Pompe à palettes" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"LA POMPE HYDRAULIQUE OPTIMALE POUR TOUTE APPLICATION"}
                    paragraph={"Les pompes hydrauliques sont le cœur de chaque système hydraulique. Elles créent la pression dans le système et transportent l'énergie de l'huile là où elle est nécessaire. Notre large gamme de pompes à engrenages et à piston axial, de qualité éprouvée HANSA‑FLEX, couvre un large éventail de besoins en hydraulique industrielle et mobile. Notre portefeuille de produits comprend également des pompes à piston radial et des pompes à palettes pour des applications spéciales."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"LE PORTFOLIO DE POMPES HANSA‑FLEX POUR L'HYDRAULIQUE"}
                    subtitle={"Avec HANSA‑FLEX, vous bénéficiez de solutions fiables et durables et de la livraison rapide de tous les composants requis."}
                />
                <ImageText
                    data={pump1} />

                <ImageText
                    data={pump2}
                    left={false} />
                <ImageText
                    data={pump3} />
            </div>
            <div>
                <SectionHeader
                    title={"QUELLE POMPE HYDRAULIQUE VOUS CONVIENT-ELLE ?"}
                    subtitle={"Nous vous conseillons et vous aidons à choisir la bonne pompe hydraulique. Combien de pulsations votre système peut-il tolérer ? Quel débit et quelles pressions sont nécessaires ? Nos experts se feront un plaisir de vous conseiller et de fournir des solutions spéciales si nécessaire."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"VOS AVANTAGES AVEC LES POMPES HYDRAULIQUES HANSA‑FLEX"}
                    subtitle={"Bénéficiez de notre large portefeuille de produits de pompes hydrauliques, d'une bonne disponibilité et de conseils compétents de nos experts en fluides."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            {/* TODO: Carousel */}

            <div>
                <SectionHeader
                    title={"AUTRES COMPOSANTS D'UNITÉ HYDRAULIQUE"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <ImageText
                    data={pump4} />
            </div>

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgTraining}
                                title="Formation par des experts"
                                desc={"En collaboration avec l'Académie internationale de l'hydraulique (IHA), HANSA‑FLEX propose une large gamme de cours et de séminaires sur la technologie des fluides."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgRepairAndOverhaul}
                                title="Réparation et révision des composants hydrauliques"
                                desc={"Les experts de HANSA‑FLEX peuvent réparer et réviser des moteurs, des pompes et des vérins de toutes tailles et de n'importe quel fabricant."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgValveTech}
                                title="Technologie des valves pour des pressions jusqu'à 350 bars"
                                desc={"Avec des valves de haute qualité de HANSA‑FLEX, vous bénéficiez d'une excellente dynamique et de temps de réaction courts dans une large gamme d'applications et de situations d'installation."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )

}

export default Pumps