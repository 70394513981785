import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgExpansion_centerstage from '../../../assets/Products/Pipelines/Expansion joints/Expansion_centerstage.jpg'
import ImageText from '../../../Components/ImageText/ImageText'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgExpansion_imagetext_01 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagetext_01.jpg'
import imgExpansion_imagetext_02 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagetext_02.jpg'
import imgExpansion_imagetext_03 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagetext_03.jpg'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import imgExpansion_imagecard_01 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagecard_01.jpg'
import imgExpansion_imagecard_02 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagecard_02.jpg'
import imgExpansion_imagecard_03 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagecard_03.jpg'
import { Container, Row, Col } from 'react-bootstrap';

const ExpansionJoints = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);


  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Expansion_imagetext_01 = {
    image: imgExpansion_imagetext_01,
    title: 'Le centre de cintrage des tubes et les agences travaillent main dans la main',
    description: "Les experts du centre de cintrage des tubes HANSA‑FLEX possèdent les compétences et les connaissances nécessaires en matière de cintrage des tubes et de technologie des fluides pour relever les défis les plus complexes. Ils travaillent en étroite collaboration avec les spécialistes des agences HANSA‑FLEX. Vous pouvez vous attendre au meilleur conseil possible et à un chemin optimal de la conception à la fabrication. Sur demande, HANSA‑FLEX se fera un plaisir de fabriquer des ensembles de tuyaux prêts à installer et de les livrer Just-In-Time directement sur le site d'installation du client, de sorte que l'ensemble du processus après le cintrage des tubes se déroule sans problème.",
  }

  const Expansion_imagetext_02 = {
    image: imgExpansion_imagetext_02,
    title: 'Vérification de la forme des coudes de tuyaux',
    description: "Les experts en cintrage chez HANSA‑FLEX déterminent toutes les données requises pour le tuyau cintré à partir de la simulation informatique. Si la simulation montre que le degré d'ovalisation dans la zone de courbure dépasse la limite de 10 % appliquée en hydraulique, alors un mandrin interne est utilisé pendant le cintrage pour empêcher la section transversale du tuyau de se rétrécir. Réduire l'ovalisation réduit les turbulences dans le système de tuyauterie et augmente l'efficacité.",
  }


  const Expansion_imagetext_03 = {
    image: imgExpansion_imagetext_03,
    title: 'Atelier de production bien équipé',
    description: "Pour répondre aux diverses exigences en matière de géométrie des tuyaux, il faut bien plus qu'un seul type de machine.La vaste gamme de machines du centre de cintrage des tubes permet à HANSA‑FLEX d'effectuer une grande variété de tâches de cintrage et de produire des résultats de la plus haute qualité. HANSA‑FLEX acquiert continuellement de nouvelles machines pour garantir non seulement la plus haute précision, mais aussi une flexibilité maximale sur le plancher de production. Environ 20 autres machines CNC viennent compléter une cellule de cintrage entièrement automatisée.",
  }

  return (
    <div>
      <CenterStage
        imageSrc={imgExpansion_centerstage}
        title={"SYSTÈMES SÛRS AVEC JOINTS DE DILATATION DE HANSA‑FLEX"}
        paragraph={"Les joints de dilatation accommodent de manière fiable les changements de longueur et d'angle causés par les différences de température, le tassement des fondations ou l'installation imprécise. Ils protègent les lignes de tuyaux, les machines et les équipements des dommages. Les joints de dilatation jouent également un rôle clé dans l'amortissement des vibrations des moteurs et des turbines en empêchant leur transmission le long des tuyaux d'échappement ou des conduites d'air comprimé."}
      />

      <SectionHeader
        title={"JOINTS DE DILATATION DE HANSA‑FLEX POUR TOUTES LES APPLICATIONS"}
        subtitle={""}
      />

      <div >

        <ImageText data={Expansion_imagetext_01} left={false} />
      </div>

      <div >

        <ImageText data={Expansion_imagetext_02} />
      </div>

      <div >

        <ImageText data={Expansion_imagetext_03} left={false} />
      </div>

      {/* TODO: Carousel */}

      <SectionHeader
        title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
      />

      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgExpansion_imagecard_01} learnMoreLink="/hydraulic-hoses" title="Les raccords à bride HANSA‑FLEX sont les raccords idéaux pour les grands" desc="Les raccords à bride HANSA‑FLEX sont idéaux pour les grands diamètres, les applications à haute pression et les situations d'installation restreintes." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgExpansion_imagecard_02} learnMoreLink="/metal-hoses" title="Tuyaux cintrés sur mesure" desc="Des tuyaux de précision de qualité HANSA‑FLEX éprouvée sont fabriqués sur mesure à partir de divers matériaux dans le centre de cintrage des tubes HANSA‑FLEX." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgExpansion_imagecard_03} learnMoreLink="/Industrial-hoses" title="Connectés par des raccords de tuyaux" desc="Les transitions entre divers composants sont toujours un point à prendre en considération dans les systèmes hydrauliques. Les raccords de tuyaux de HANSA‑FLEX créent une connexion fiable." />
          </Col>

        </Row>
      </Container>

    </div>
  )

}

export default ExpansionJoints