import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import imgEDI_Centerspread from '../../../assets/Services/Digital Services/EDI and BMEcat/EDI_Centerspread.jpg'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import ImageText from '../../../Components/ImageText/ImageText'
import imgEDI_Imagetext_01 from '../../../assets/Services/Digital Services/EDI and BMEcat/EDI_Imagetext_01.jpg'
import imgEDI_Imagetext_02 from '../../../assets/Services/Digital Services/EDI and BMEcat/EDI_Imagetext_02.jpg'
import imgEDI_Imagetext_03 from '../../../assets/Services/Digital Services/EDI and BMEcat/EDI_Imagetext_03.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import { Container, Row, Col } from 'react-bootstrap';
import imgEDI_ImageCard_01 from '../../../assets/Services/Digital Services/EDI and BMEcat/EDI_ImageCard_01.jpg'
import imgEDI_ImageCard_02 from '../../../assets/Services/Digital Services/EDI and BMEcat/EDI_ImageCard_02.jpg'
import imgEDI_ImageCard_03 from '../../../assets/Services/Digital Services/EDI and BMEcat/EDI_ImageCard_03.jpg'

const EDIandBMEcat = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);


  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const EDI_Imagetext_01 = {
    image: imgEDI_Imagetext_01,
    title: "Mise en place d'une interface EDI avec HANSA-FLEX",
    listItems: ["Dans le cadre d'un partenariat à long terme, HANSA-FLEX propose à ses clients ayant des exigences telles qu'une intégration complète des fournisseurs et des processus étroitement intégrés ou des volumes de transfert de données très élevés, une connexion à notre système EDI. En raison de la complexité de la programmation des interfaces EDI, ce service n'est accessible qu'à des clients sélectionnés qui, en raison de leurs volumes de vente élevés avec HANSA-FLEX, consacrent beaucoup de ressources à la gestion de leurs processus d'approvisionnement en marchandises. Lors de la mise en place de l'EDI, HANSA-FLEX peut utiliser des formats standard pour le transfert de données tels que EDIFACT, ODETTE, VDA, X12 et XML, ainsi que des solutions spéciales pour répondre à vos besoins spécifiques."],
  }

  const EDI_Imagetext_02 = {
    image: imgEDI_Imagetext_02,
    title: 'Mise en place de BMEcat par HANSA-FLEX',
    listItems: ["BMEcat est un format standardisé d'échange de données de catalogue conforme aux directives du Bundesverband Materialwirtschaft, Einkauf und Logistik e. V. Les données de catalogue sont extraites du système de données de produits de HANSA-FLEX et intégrées dans le système de votre entreprise. HANSA-FLEX installe cette technologie pour les clients qui souhaitent disposer de données de produits numériques et d'exigences personnalisées dans leur catalogue de produits. Un autre avantage est que vous pouvez continuer à utiliser votre portail d'achat habituel, à partir duquel vous achetez tous les composants spéciaux, et que HANSA-FLEX vous livre directement les composants hydrauliques ou pneumatiques que vous avez commandés."],
  }


  const EDI_Imagetext_03 = {
    image: imgEDI_Imagetext_03,
    title: "L'intégration numérique en trois phases",
    listItems: ["The implementation of an EDI can take a few days or several months, depending on its complexity. The installation process always takes place in three phases. During the first phase, the necessary parameters such as data format and version of the formatting standards are determined. Then there is an initial integration test and a very careful comparison of the master and material data sets between your company and HANSA‑FLEX. In the test phase, we perform extensive tests, which involves interchange of test data. In some cases, corrections have to be made and the comparison of material data repeated. The integration is completed with the go-live phase including monitoring of the data flow."],
  }

  const featuresData = [
    {
      title: "Cartographie flexible",
      description: "S'il n'existe pas de méthode standard appropriée, HANSA-FLEX peut configurer votre format de données en fonction de vos besoins individuels.",
      image: imgMark
    },
    {
      title: "Nombreuses années d'expérience en matière d'EDI",
      description: "Les spécialistes EDI de HANSA-FLEX ont des dizaines d'années d'expérience dans ce type d'interface.",
      image: imgMark
    },
    {
      title: "Programmation des plans de livraison pour l'année",
      description: "Grâce à l'EDI, vous pouvez rapidement et facilement automatiser les processus de commande de routine à moyen ou à long terme.",
      image: imgMark
    },
    {
      title: "Économiser des ressources grâce à l'EDI",
      description: "La commande par EDI réduit considérablement le nombre d'étapes et les coûts de paperasserie dans le processus de commande.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];

  






  return (
    <div>

      <CenterStage
        imageSrc={imgEDI_Centerspread}
        title={"VOTRE INTERFACE NUMÉRIQUE VERS HANSA-FLEX VIA EDI ET BMECAT"}
        paragraph={"L'échange de données informatisées (EDI) permet le transfert rapide de données commerciales normalisées entre les entreprises. L'interface EDI entre votre système de gestion des marchandises et le nôtre vous permet de gérer encore plus efficacement toutes les étapes de l'approvisionnement électronique. En plus d'une connexion EDI, HANSA-FLEX vous offre également la possibilité d'intégrer des articles spécifiques de HANSA-FLEX à l'aide de BMEcat dans votre portail d'achat habituel."}
      />

      <SectionHeader
        title={"ÉCHANGE DE DONNÉES INFORMATISÉES POUR LES PRINCIPAUX CLIENTS"}
        subtitle={"L'EDI facilite l'échange de données pour l'approvisionnement en ligne entre différentes entreprises et garantit des processus d'approvisionnement en marchandises plus rapides et moins erronés."}
      />


      <div >
        <br />
        <ImageText data={EDI_Imagetext_01} left={false} /> <br /><br />
      </div>

      <div >
        <br />
        <ImageText data={EDI_Imagetext_02} /> <br /><br />
      </div>

      <div >
        <br />
        <ImageText data={EDI_Imagetext_03} left={false} /> <br /><br />
      </div>

      <SectionHeader
        title={"EDI EST VOTRE LIGNE DIRECTE AVEC HANSA-FLEX"}
        subtitle={"L'équipe bien formée du service EDI de HANSA-FLEX met en place votre interface numérique de manière optimale pour vos besoins, afin que vous puissiez profiter de tous les avantages de l'échange rapide de données électroniques."}
      />

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>

      <SectionHeader
        title={"AUTRES SERVICES DE HANSA-FLEX"}
        subtitle={""}
      />


<Container className="custom-container">
        <Row>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgEDI_ImageCard_01} learnMoreLink="/hydraulic-hoses" title="Gestion des tuyaux avec X-CODE Manager" desc="X-CODE Manager rend la gestion des tuyaux tout simplement plus facile. Par exemple, le progiciel vous permet de ne jamais perdre de vue l'inventaire et l'inspection de vos tuyaux." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                <ImageCard image={imgEDI_ImageCard_02} learnMoreLink="/metal-hoses" title="La boutique en ligne HANSA-FLEX permet de commander rapidement et facilement les produits nécessaires à la technologie des fluides." desc="Explorez la boutique en ligne HANSA-FLEX avec plus de 80 000 articles relatifs à la technologie des fluides et passez votre commande en quelques clics de souris." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                <ImageCard image={imgEDI_ImageCard_03} learnMoreLink="/Industrial-hoses" title="Solutions de numérisation pour la saisie numérique de la demande" desc="Commandez vos composants pour la technologie des fluides directement depuis l'atelier de production ou dans votre entrepôt, rapidement et facilement grâce à un scanner et à l'application Easy Order de HANSA-FLEX." />
            </Col>

        </Row>
    </Container>







    </div>
  )
}

export default EDIandBMEcat