import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import Assembly_Centerspread from '../../../assets/Services/Production/Customised Assembly/Assembly_Centerspread.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook;
import imgAssembly_Imagetext_01 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagetext_01.jpg'
import imgAssembly_Imagetext_02 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagetext_02.jpg'
import imgAssembly_Imagetext_03 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagetext_03.jpg'
import imgAssembly_Imagetext_04 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagetext_04.jpg'
import imgAssembly_Imagetext_05 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagetext_05.jpg'
import imgAssembly_Imagetext_06 from '../../../assets/Services/Production/Customised Assembly//Assembly_Imagetext_06.jpg'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgAssembly_Imagecard_01 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagecard_01.jpg';
import imgAssembly_Imagecard_02 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagecard_02.jpg';
import imgAssembly_Imagecard_03 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagecard_03.jpg';
import { Container, Row, Col } from 'react-bootstrap';

import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';




const CustomisedAssembly = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
      }, []);




    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    const Assembly_Imagetext_01 = {
        image: imgAssembly_Imagetext_01,
        title: 'Conseils et fabrication par des techniciens expérimentés',
        listItems: ["Le prémontage et l'inspection des conduites hydrauliques sont effectués uniquement par des employés qualifiés. Ils assemblent les composants appropriés pour chaque utilisation envisagée et les raccordent correctement entre eux. En tant qu'interlocuteurs expérimentés, nos collaborateurs techniques considèrent que le conseil aux clients fait partie de leur travail et ont suivi une formation complète. En outre, des personnes officiellement agréées pour la technologie de laquelle ils travaillent inspectent vos machines et installations sur place et déterminent si les conduites flexibles doivent être remplacées et, le cas échéant, lesquelles. Ils vous conseillent également sur la manière d'optimiser votre installation de tuyaux."],
    }

    const Assembly_Imagetext_02 = {
        image: imgAssembly_Imagetext_02,
        title: 'Prémontage professionnel dans la branche HANSA-FLEX',
        listItems: ["Chacune de nos succursales dispose de son propre atelier de fabrication de tuyaux afin de garantir une fabrication sans faille des conduites hydrauliques. C'est là que nos techniciens expérimentés coupent, épluchent et sertissent les tuyaux pour former des conduites prêtes à être installées. Grâce à l'accès à des machines de premier ordre et à une technologie innovante en matière de tuyaux, comme les rouleaux de tuyaux, les coupeurs, les éplucheurs, les sertisseurs, les dispositifs de coupe à longueur, le nettoyage par projectile, les mandrins d'essai et les bancs d'essai de pression, nos techniciens fabriquent dans leurs ateliers des lignes de tuyaux performantes de différentes tailles, selon des normes de qualité élevées et constantes."],
    }

    const Assembly_Imagetext_03 = {
        image: imgAssembly_Imagetext_03,
        title: 'Pré-assemblage des tuyaux combinés',
        listItems: ['Mise en œuvre de géométries de lignes flexibles', 'Conseils pour la planification des systèmes et machines hydrauliques', 'Traitement de haute qualité grâce à des procédures de soudage certifiées'],
        description: ["Outre les conduites hydrauliques, HANSA-FLEX fabrique également des conduites combinées pour un large éventail d'applications dans le domaine de la technologie des fluides. Les spécialistes expérimentés mettent à profit leurs connaissances approfondies pour vous conseiller sur la conception appropriée et vous donner des conseils utiles pour une installation correcte"],
    }

    const Assembly_Imagetext_04 = {
        image: imgAssembly_Imagetext_04,
        title: 'Pré-assemblage des tuyaux métalliques',
        listItems: ["Procédés d'assemblage certifiés, testés à 100 % pour l'étanchéité", "Diamètres nominaux de DN 6 à DN 250 mm", "Exempt d'huile, de graisse et de LABS, selon les besoins"],
        description: ["Nous prémontons des lignes de tuyaux métalliques robustes à partir de tuyaux ondulés de haute qualité. Le soudage conforme à la norme DIN EN ISO 3834-2 garantit une connexion sûre, même dans des conditions extrêmes."],
    }

    const Assembly_Imagetext_05 = {
        image: imgAssembly_Imagetext_05,
        title: 'Pré-assemblage des tuyaux métalliques',
        listItems: ['Fabriqué conformément à la directive sur les équipements sous pression, à la DGUV et à la norme T 002.', 'Pré-assemblage incluant les accessoires et les systèmes de connexion', "Fabrication de tuyaux d'aspiration et de refoulement"],
        description: ['.'],
    }

    const Assembly_Imagetext_06 = {
        image: imgAssembly_Imagetext_06,
        title: 'Pré-assemblage des tuyaux métalliques',
        listItems: [" Fabriqué conformément à la directive sur les équipements sous pression, DGUV et T 002", "Pré-assemblage, y compris les accessoires et les systèmes de connexion'.", "Fabrication de tuyaux d'aspiration et de refoulement"],
        description: ["Nous prémontons des lignes de tuyaux métalliques robustes à partir de tuyaux ondulés de haute qualité. Le soudage conforme à la norme DIN EN ISO 3834-2 garantit une connexion sûre, même dans des conditions extrêmes."],
    }





    const featuresData = [
        {
            title: "Des techniciens expérimentés",
            description: "Chaque tuyau est assemblé et inspecté par du personnel qualifié.",
            image: imgMark
        },
        {
            title: "Un soutien complet",
            description: "Nous discutons de vos besoins et des possibilités d'optimisation avant de fabriquer le tuyau.",
            image: imgMark
        },
        {
            title: "Vaste stock en entrepôt",
            description: "Des stocks importants de raccords et de tuyaux courants garantissent une réalisation rapide. ",
            image: imgMark
        },
        {
            title: "Livraison rapide",
            description: "Nous commençons à travailler sur votre demande dans les plus brefs délais. Vous recevez le tuyau terminé peu après l'achèvement des travaux.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];





    return (
        <div>
            <div >

                <CenterStage imageSrc={Assembly_Centerspread} title={"TUYAUX PRÉ-ASSEMBLÉS, PRÊTS À ÊTRE INSTALLÉS"}
                    paragraph={"Nous prémontons des conduites hydrauliques personnalisées à partir d'échantillons et en fonction des exigences spécifiques du client pour des applications à basse, moyenne et haute pression, ainsi que les raccords correspondants dans nos succursales. Nos spécialistes des tuyaux gardent toujours à l'esprit la sécurité des personnes et de l'environnement lorsqu'ils sélectionnent les matériaux et la méthode d'assemblage du tuyau au raccord. Quels que soient vos besoins, nous pouvons vous conseiller et fabriquer une ligne de tuyaux adaptée."}
                />

                <SectionHeader
                    title={"UNE SOLUTION SYSTÈME ADAPTÉE À VOS BESOINS"}
                    subtitle={"Les normes de qualité les plus élevées s'appliquent à nos tuyaux préassemblés. Il en résulte une connexion positive étanche entre le tuyau et le raccord, garantissant la sécurité d'utilisation, même à des pressions élevées."}
                />

                <br />
                <ImageText data={Assembly_Imagetext_01} left={false} />
            </div>

            <div >
                <br />
                <ImageText data={Assembly_Imagetext_02} />
            </div>

            {/* TODO: Redband_Sectionheader */}

            <SectionHeader
                title={"PRÉASSEMBLAGE PROFESSIONNEL GARANTI"}
                subtitle={"Le préassemblage des conduites flexibles personnalisées commence par la coupe du tuyau hydraulique à la longueur requise, puis le sertissage et le marquage, et enfin un test de pression optionnel."}
            />

            {/* TODO: Redbanner_Carrousel */}

            <SectionHeader
                title={"TUYAUX PRÉ-ASSEMBLÉS DANS VOTRE LOCALITÉ"}
                subtitle={"En tant que spécialiste de la technologie des fluides jouissant d'une longue expérience, HANSA-FLEX offre le plus haut degré de flexibilité, de sécurité, de durabilité et de rentabilité dans la fabrication de lignes de tuyaux directement dans ses succursales locales."}
            />

            <div>
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <SectionHeader
                title={"Plus d'avantages pour vous"}
                // listItems=["High-quality components from HANSA‑FLEX and renowned manufacturers", 'High-quality components from HANSA‑FLEX and renowned manufacturers', 'Personal service from our technicians on site', 'HANSA‑FLEX is your guarantee of professional preassembly', 'The perfect combination of fitting and hose']
            />


            <SectionHeader
                title={"PRÉ-ASSEMBLAGE DE NOMBREUX TYPES DE TUYAUX"}
                subtitle={"Outre les conduites hydrauliques, nous fabriquons également des conduites pour de nombreux autres domaines d'application tels que le transport de fluides chauds ou abrasifs ou l'utilisation dans l'industrie alimentaire."}
            />

            <div >
                <br />
                <ImageText data={Assembly_Imagetext_03} left={false} />
            </div>

            <div >
                <br />
                <ImageText data={Assembly_Imagetext_04} />
            </div>

            <div >
                <br />
                <ImageText data={Assembly_Imagetext_05} left={false} />
            </div>


            <SectionHeader
                title={"UN SERVICE À VALEUR AJOUTÉE"}
                subtitle={"En tant que spécialiste de la technologie des fluides jouissant d'une longue expérience, HANSA-FLEX offre le plus haut degré de flexibilité, de sécurité, de durabilité et de rentabilité dans la fabrication de lignes de tuyaux directement dans ses succursales locales."}
            />

            {/* TODO: Redbanner_Carrousel */}

            <SectionHeader
                title={"LES TUYAUX NEUFS ET DE REMPLACEMENT SONT FABRIQUÉS DANS L'USINE LOCALE DE HANSA-FLEX"}
                subtitle={"Nos techniciens vous conseillent en tant qu'interlocuteurs directs dans nos agences locales et travaillent en étroite collaboration avec vous pour créer la solution de tuyau parfaite pour votre application spécifique."}
                listItems={'Scellage des extrémités des tuyaux, avec des bouchons en plastique, en les enveloppant dans un film transparent'}
            />



            <div >
                <br />
                <ImageText data={Assembly_Imagetext_06} />
            </div>

            <SectionHeader
                title={"SERVICES LIÉS AUX TUYAUX"}
                subtitle={"Outre la fabrication de lignes de tuyaux dans des installations de production en série, nous proposons également diverses solutions pour la fabrication de solutions personnalisées et préassemblées directement sur site. En outre, nos experts en fluides expérimentés peuvent vous conseiller en matière d'hydraulique."}
            />



            {/* TODO: imagetext_carrousel */}


            <SectionHeader
                title={"CES THÈMES PEUVENT ÉGALEMENT VOUS INTÉRESSER"}
                subtitle={""}
            />


            <Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgAssembly_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Fabrication en usine" desc="Avec la fabrication en usine, vous bénéficiez de matériaux et de composants immédiatement disponibles. Nos processus s'intègrent précisément dans vos flux de travail." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgAssembly_Imagecard_02} learnMoreLink="/metal-hoses" title="Tuyaux en kit" desc="Grâce à nos kits de tuyaux configurés avec précision, vous gagnez du temps lors de l'installation et pouvez vous concentrer entièrement sur vos processus de production." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgAssembly_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Livraison selon le principe kanban" desc="HANSA-FLEX propose des livraisons Kanban dans le cadre d'un système logistique personnalisé et configuré de manière optimale." />
                    </Col>

                </Row>
            </Container>












        </div>

    )
}

export default CustomisedAssembly