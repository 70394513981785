import React, { useEffect } from 'react';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgManagementStage from '../../assets/management/Produkte_Rohrboegen_07.jpg';
import person from '../../assets/management/person-circle.svg';
import ContactBubble from '../../Components/ContactBubble/ContactBubble';


function Management() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <CenterStage
                title={"Rencontrez notre direction"}
                imageSrc={imgManagementStage}
                paragraph={"Chez HANSA-FLEX, notre direction est influente dans la conduite de l'entreprise avec une vision stratégique et une excellence opérationnelle. Grâce à un leadership dynamique et une focalisation sur l'innovation, nous donnons la priorité à la satisfaction du client et à la croissance durable. Notre direction favorise une culture de collaboration et d'autonomisation, conduisant au succès dans tous les domaines de l'organisation."}
            />

            <div>
                <ContactBubble
                    imageSrc={person}
                    title="Jean Luc Vonderscher"
                    subTitle="Directeur Général"
                    description="Jean Luc Vonderscher, notre influent Directeur Général, dirige avec une vision stratégique, favorisant la croissance et l'innovation. Fort d'une expérience approfondie, il promeut l'excellence dans toute notre organisation, garantissant le succès et la satisfaction des clients."
                />
            </div>
        </div>

    );
}

export default Management;