import React, { useEffect } from 'react';
import CenterStage from '../Components/CenterStage/CenterStage';
import imgCareerStage from '../assets/Careers/career_stage_1.jpg';
import Promoter from '../Components/Promoter/Promoter';
import { Col, Row } from 'react-bootstrap';
import { MdEmail, MdLocationOn } from 'react-icons/md';
import imgWork from '../assets/Careers/work.png';

function Careers() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const isMobile = window.innerWidth <= 768;

    return (
        <div>
            <CenterStage
                title={"UNE PASSION POUR LA MACHINERIE: VOTRE CARRIÈRE CHEZ HANSA-FLEX"}
                imageSrc={imgCareerStage}
                paragraph={"Chez HANSA-FLEX, nous sommes unis par une passion pour la machinerie et l'ingénierie. Êtes-vous toujours à la recherche de nouveaux défis ? Vous êtes intéressé par l'ingénierie et avez l'instinct de maintenir tout en marche ? Alors vous êtes au bon endroit. Nous sommes fiers de notre culture ouverte de rétroaction, de notre forte cohésion collégiale et de nos tâches passionnantes, ce qui rend le travail — que ce soit au bureau, à l'entrepôt, au département informatique ou à l'atelier — plus qu'un simple emploi. C'est l'expérience HANSA-FLEX."}
            />
            {isMobile ?
                <>
                    <div style={{ backgroundColor: '#30557d', padding: '20px', color: 'white', paddingLeft: '10%' }}>
                        <Row>
                            <Col xs={12} md={6}>
                                <h2>Postulez dès maintenant !</h2>
                                <p>
                                    <MdLocationOn /> Welcome to HANSA-FLEX FRANCE SARL <br />
                                    480, Av. du Château de Jouques, <br />
                                    13420 Gemenos, France (Bouches-du-Rhône)
                                </p>
                                <p>
                                    <MdEmail /> Envoyez-nous un e-mail à <a href="mailto:fga@hansa-flex.com" style={{ color: 'white' }}>fga@hansa-flex.com</a> dès maintenant !
                                </p>
                            </Col>
                        </Row>
                    </div>
                </> : <Promoter title={"Contactez-nous maintenant !"} checklistItems={["Forgez votre chemin avec passion et précision chez HANSA-FLEX, où chaque défi alimente l'innovation et chaque opportunité suscite la croissance"
                    , "Envoyez-nous un e-mail à inp@hansa-flex.com"]} imageUrl={imgWork} />}
        </div>


    );
}

export default Careers;