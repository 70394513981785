import React, { useEffect } from 'react';
import HFTab from '../../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';

import Stage from '../../../../assets/Services/PreventativeMaintainance/ServicePackages/Stage.jpg'
import Beginers from '../../../../assets/Services/PreventativeMaintainance/ServicePackages/Beginers.jpg'
import Software from '../../../../assets/Services/PreventativeMaintainance/ServicePackages/Software.jpg'
import UniqueIdentificaation from '../../../../assets/Services/PreventativeMaintainance/ServicePackages/UniqueIdentificaation.jpg'
import ProfessionalInstallation from '../../../../assets/Services/PreventativeMaintainance/ServicePackages/ProfessionalInstallation.jpg'

const ServicePackages = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);


    //HfTab data
    const imageTextData = [
        {
            title: "Forfait de service de base 1 : Le package de démarrage gratuit ",
            description: "Le forfait de service de base est notre solution pour les clients qui souhaitent effectuer leurs propres inspections, remplacer les lignes de tuyaux et enregistrer les informations dans le logiciel de gestion des tuyaux eux-mêmes. Vous recevez l'accès à notre logiciel X-CODE Manager (licence SMART pour deux utilisateurs) et une formation initiale de l'un de nos experts : nous vous présentons les fonctions et vous soutenons dans la création de votre base de données de lignes de tuyaux personnalisée. Vous et vos employés faites tout le reste vous-mêmes, entièrement sans notre aide. Cela ne coûte rien mais cela vaut beaucoup.  ",
            image: Beginers,
        },
        {
            title: " Forfait Plus : Le package complet de données ",
            description: "Le forfait Plus est notre package de données numériques pour votre maintenance. Nous créons votre base de données de lignes de tuyaux personnalisée dans le logiciel X-CODE Manager, saisissons toutes les données pertinentes pour vous et mettons régulièrement à jour la base de données. Le résultat est votre centre de contrôle numérique pour toutes les lignes de tuyaux hydrauliques. En un clic, vous avez toutes les informations importantes à portée de main. Bien sûr, nous saisissons également les données de vos lignes de tuyaux existantes et leur attribuons un X-CODE.  ",
            image: Beginers
        },
        {
            title: "Forfait Premium : Le package tout compris ",
            description: "  Le forfait de service Premium contient tous les services des forfaits Basic et Plus. De plus, nos personnes officiellement autorisées pour l'inspection effectuent toutes les inspections légalement prescrites et préparent la documentation conforme à la loi pour vous. Vous recevez une impression pour le carnet de bord de votre machine et un enregistrement d'inspection numérique dans votre base de données X-CODE Manager, que vous pouvez rappeler et consulter à tout moment. Ainsi, vous pouvez vérifier l'état de l'hydraulique sur vos systèmes à tout moment et pouvez concentrer vos ressources de maintenance sur d'autres sujets importants",
            image: Beginers
        },
        {
            title: " Forfait Pro : Le service supplémentaire",
            description: "Le forfait de service Pro comprend tous les services des forfaits de service Basic, Plus et Premium. De plus, nous nous mettons à votre disposition pour vous conseiller sur l'analyse des risques et la préparation de l'évaluation des risques légalement prescrite pour chaque ligne de tuyau individuelle. Si nécessaire, nos experts peuvent également vous conseiller sur l'inspection de composants tels que les accumulateurs de pression. Une analyse quotidienne professionnelle de l'huile complète la gamme de services du forfait Pro.",
            image: Beginers
        },
    ];

    const tabTitles = [
        { title: "For beginners ", eventKey: "For beginners " },
        { title: "The data package ", eventKey: "The data package " },
        { title: "All-round carefree ", eventKey: "All-round carefree " },
        { title: "Extra service ", eventKey: "Extra service " },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"  ÉVITEZ LES RISQUES, MAINTENEZ LES MACHINES EN BON ÉTAT "}
                    paragraph={" Le forfait de service de gestion des tuyaux de HANSA-FLEX vous offre des services sur mesure pour le fonctionnement sûr et conforme à la loi de vos systèmes hydrauliques. Que vous optiez pour le package de démarrage, le package numérique ou le package tout compris incluant l'inspection et la documentation, le tarif journalier forfaitaire facilite considérablement la planification et les estimations de coûts. Choisissez les services les plus adaptés pour la gestion de vos lignes de tuyaux. "}
                />
            </div>

            <div>
                <SectionHeader
                    title={"Obtenez uniquement les services dont vous avez besoin. ."}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            {/* TODO:SERVICE PACKAGES */}

            <div>
                <SectionHeader
                    title={"Installation professionnelle sur site "}
                    subtitle={" Des techniciens formés remplacent et installent vos lignes de tuyaux hydrauliques où que vous en ayez besoin.  "}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Software}
                                title="Le meilleur logiciel pour vos systèmes hydrauliques "
                                desc={"X-CODE Manager est le logiciel de gestion des lignes de tuyaux de HANSA-FLEX pour une vue d'ensemble optimale de vos lignes de tuyaux, machines, dates planifiées et  "} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={UniqueIdentificaation}
                                title="Identification et codage uniques "
                                desc={" Le codage des tuyaux avec X-CODE garantit des commandes de remplacement sans erreur et constitue la base de la gestion des tuyaux à l'aide du logiciel X-CODE Manager.  "} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={ProfessionalInstallation}
                                title="nstallation professionnelle sur site "
                                desc={"Des techniciens formés remplacent et installent vos lignes de tuyaux hydrauliques où que vous en ayez besoin. "} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default ServicePackages