import React, { useEffect } from 'react';
import HFTab from '../../Components/HfTabs/HfTabs';
import CenterStage from '../../Components/CenterStage/CenterStage';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../Components/ImageCard/ImageCard';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../assets/mark.png';

import imgMobileService from '../../assets/Services/MobileService/MobileService.jpg'
import img360Hose from '../../assets/Services/MobileService/360HoseManagement.jpg'
import imgHFNeverFar from '../../assets/Services/MobileService/HFNeverFar.jpg'
import imgOilCareEasy from '../../assets/Services/MobileService/OilCareEasy.jpg'
import imgDispatch from '../../assets/Services/MobileService/Dispatch.jpg'
import imgDescription from '../../assets/Services/MobileService/Description.jpg'
import imgSpareParts from '../../assets/Services/MobileService/SpareParts.jpg'
import imgDocumentation from '../../assets/Services/MobileService/Documentation.jpg'
import imgHoseManufacturing from '../../assets/Services/MobileService/HoseManufacturing.jpg'

//TODO: Carousal
//TODO: video
//TODO: Textposter 

const MobileRapidHydraulicService = () => {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    // Données pour le carousel de cartes de fonctionnalités
    const featuresData = [
        {
            title: "Par téléphone",
            description: "Vous pouvez contacter le service d'urgence via la hotline gratuite 0800-77 12345.",
            image: imgMark
        },
        {
            title: "Contactez-nous via l'application mobile",
            description: "Vous pouvez contacter gratuitement le service d'urgence via l'application HANSA-FLEX.",
            image: imgMark
        },
        {
            title: "Formulaire en ligne",
            description: "Vous pouvez demander une assistance auprès du service d'urgence mobile directement via notre formulaire en ligne.",
            image: imgMark
        },
        // Ajoutez plus d'objets pour des données supplémentaires de cartes de fonctionnalités
    ];

    // Données pour HFTab
    const imageTextData = [
        {
            title: "Planification numérique",
            description: "Nos répartiteurs expérimentés sont disponibles 24h/24 pour vous. Grâce à un logiciel haute capacité, ils ont toujours une vue d'ensemble de toutes les commandes, du trajet prévu de chaque véhicule et de leur emplacement actuel. En un clic de souris, ils peuvent révéler l'emplacement du véhicule de service mobile qui peut vous rejoindre le plus rapidement possible. Les répartiteurs envoient toutes les informations importantes sur la demande au technicien de service, qui les voit apparaître sur la tablette du véhicule avant son arrivée sur site, afin que le travail puisse être efficacement effectué. De cette manière, le technicien de service mobile d'urgence est entièrement préparé avant l'arrivée du véhicule sur vos lieux.",
            image: imgDispatch
        },
        {
            title: "Description exacte",
            description: "Nous avons besoin de votre soutien pour que les processus puissent être traités le plus rapidement possible. Assurez-vous que les informations que vous nous fournissez sur le service demandé sont aussi précises que possible :",
            listItems: ["Quels sont les noms de contact ?", "Quelles pièces ou composants sont défectueux ?", "Y a-t-il des photos de la situation d'installation ?", "Quels sont les pièces de rechange susceptibles d'être nécessaires ?"],
            image: imgDescription
        },
        {
            title: "Parfaitement adapté",
            description: "Les véhicules de service mobiles transportent un stock de pièces de rechange et de pièces d'usure les plus fréquemment nécessaires pour leur domaine d'utilisation respectif. Le logiciel utilisé dans notre service mobile Rapid Hydraulic Service est directement lié à notre équipe de gestion des matériaux. Ainsi, le répartiteur peut déjà voir quelles pièces de rechange sont à bord de chaque véhicule lorsque la commande de service est passée. Si d'autres pièces sont nécessaires pour le travail sur site, le véhicule de service mobile peut effectuer un arrêt intermédiaire planifié sur l'itinéraire à la succursale HANSA-FLEX la plus proche.",
            image: imgSpareParts
        },
        {
            title: "Fabrication de flexibles mobiles",
            description: "Les véhicules de la flotte mobile Rapid Hydraulic Service sont des ateliers sur roues. Ils transportent tout l'équipement nécessaire à la fabrication de flexibles hydrauliques sur mesure, y compris des machines de découpe, de dénudage et de sertissage. Le générateur sous le plancher fournit une source d'alimentation fiable et indépendante sur les chantiers ou dans les zones reculées et permet aux techniciens de travailler la nuit. Le générateur de 400 volts rend l'atelier sur roues complètement indépendant des conditions sur site.",
            image: imgHoseManufacturing
        },
        {
            title: "Documentation complète",
            description: "Pendant le travail, les techniciens de service prennent des photos haute résolution des composants endommagés et nouvellement installés avec leurs tablettes et archivent toutes les informations dans le rapport de service. Celui-ci peut être signé directement sur place par le client sur la tablette, qui recevra le rapport ainsi que la facture par e-mail. Si le client utilise le portail de gestion des flexibles My.HANSA-FLEX, le technicien de service peut enregistrer les détails du remplacement de la ligne de flexible directement dans le système.",
            image: imgDocumentation
        },
    ];

    const tabTitles = [
        { title: "Répartition", eventKey: "Répartition" },
        { title: "Description", eventKey: "Description" },
        { title: "Pièces de rechange", eventKey: "Pièces de rechange" },
        { title: "Fabrication de flexibles", eventKey: "Fabrication de flexibles" },
        { title: "Documentation", eventKey: "Documentation" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgMobileService}
                    title={"PRÊT À AGIR À TOUT MOMENT : LE SERVICE HYDRAULIQUE RAPIDE"}
                    paragraph={"Lorsque des composants hydrauliques tombent en panne de manière inattendue, une action rapide est nécessaire. Avec 180 véhicules dans toute l'Allemagne, notre service hydraulique mobile Rapid est toujours à votre disposition, disponible 24h/24, 7j/7, 365 jours par an, pour minimiser les temps d'arrêt. Nos techniciens de service bien formés et expérimentés sont chez vous en moins d'une heure. Ils rectifient les défauts, installent des pièces de rechange et fabriquent des flexibles sur mesure sur place, dans leur atelier embarqué."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"ENVOYER AU SERVICE D'URGENCE MOBILE EST RAPIDE ET FACILE"}
                    subtitle={"Le service d'urgence mobile est là pour vous lorsque les choses tournent mal. Contactez-nous et notre centre de service clientèle prend en charge toute la planification et se coordonne avec les techniciens de service."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"GESTION EFFICACE DE LA RÉPARTITION À LA DOCUMENTATION"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            {/* TODO ici */}

            <div>
                <SectionHeader
                    title={"CES SUJETS PEUVENT AUSSI VOUS INTÉRESSER"}
                />

                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={img360Hose} learnMoreLink={"/x-code-manager"}
                                title="Gestion de flexibles à 360°"
                                desc={"Les pièces nécessaires peuvent être identifiées rapidement et de manière fiable grâce au X-CODE de HANSA-FLEX. Le code à six chiffres est attaché en permanence aux lignes de flexible et est facile à lire."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHFNeverFar} learnMoreLink={"/"}
                                title="HANSA-FLEX n'est jamais loin"
                                desc={"Nous disposons d'un réseau mondial de succursales hautement intégré. C'est ainsi que nous veillons à ce que le soutien des experts de HANSA-FLEX ne soit jamais loin."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgOilCareEasy} learnMoreLink={"/fluid-service"}
                                title="Prendre soin de l'huile n'a jamais été aussi facile"
                                desc={"Le service fluides de HANSA-FLEX réalise des analyses d'huile en laboratoire ou directement sur site, effectue le nettoyage d'huile et fournit des conseils sur toutes les questions liées à l'huile hydraulique."} /></Col>
                        </Row>
                    </Container>
                </div>

            </div>

        </div>
    )


}

export default MobileRapidHydraulicService