import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgProduct_Pipebends_Centerstage from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_centerstage.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgProductPipebendimagetext1 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagetext1.jpg'
import imgProductPipebendimagetext2 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagetext2.jpg'
import imgProductPipebendimagetext3 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagetext3.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import imgProductPipebendimagetext4 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagetext4.jpg'
import imgProductPipebendimagetext5 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagetext5.jpg'
import imgProductPipebendimagetext6 from '../../../assets/Products/Pipelines/PipeBends/SpecialPipes.jpg'
import imgMark from '../../../assets/mark.png'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import { Container, Row, Col } from 'react-bootstrap';
import imgProductPipebendimagecard1 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagecard1.jpg'
import imgProductPipebendimagecard2 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagecard2.jpg'
import imgProductPipebendimagecard3 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagecard3.jpg'


const Pipebends = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Définir le point de rupture pour la taille de l'écran d'ordinateur portable
  const isMobile = window.innerWidth <= 768;

  const ProductPipebendimagetext1 = {
    image: imgProductPipebendimagetext1,
    title: 'Le centre de cintrage des tubes et les agences travaillent main dans la main',
    description: ["Les experts du centre de cintrage des tubes HANSA‑FLEX possèdent les compétences et les connaissances nécessaires en matière de cintrage des tubes et de technologie des fluides pour relever les défis les plus complexes. Ils travaillent en étroite collaboration avec les spécialistes des agences HANSA‑FLEX. Vous pouvez vous attendre au meilleur conseil possible et à un chemin optimal de la conception à la fabrication. Sur demande, HANSA‑FLEX se fera un plaisir de fabriquer des ensembles de tuyaux prêts à installer et de les livrer Just-In-Time directement sur le site d'installation du client, de sorte que l'ensemble du processus après le cintrage des tubes se déroule sans problème."],
  }

  const ProductPipebendimagetext2 = {
    image: imgProductPipebendimagetext2,
    title: 'Vérification de la forme des coudes de tuyaux',
    description: ["Les experts en cintrage chez HANSA‑FLEX déterminent toutes les données requises pour le tuyau cintré à partir de la simulation informatique. Si la simulation montre que le degré d'ovalisation dans la zone de courbure dépasse la limite de 10 % appliquée en hydraulique, alors un mandrin interne est utilisé pendant le cintrage pour empêcher la section transversale du tuyau de se rétrécir. Réduire l'ovalisation réduit les turbulences dans le système de tuyauterie et augmente l'efficacité."],
  }

  const ProductPipebendimagetext3 = {
    image: imgProductPipebendimagetext3,
    title: 'Atelier de production bien équipé',
    description: ["Pour répondre aux diverses exigences en matière de géométrie des tuyaux, il faut bien plus qu'un seul type de machine. La vaste gamme de machines du centre de cintrage des tubes permet à HANSA‑FLEX d'effectuer une grande variété de tâches de cintrage et de produire des résultats de la plus haute qualité. HANSA‑FLEX acquiert continuellement de nouvelles machines pour garantir non seulement la plus haute précision, mais aussi une flexibilité maximale sur le plancher de production. Environ 20 autres machines CNC viennent compléter une cellule de cintrage entièrement automatisée."],
  }

  const ProductPipebendimagetext4 = {
    image: imgProductPipebendimagetext4,
    title: "Paramètres de cintrage définis à l'aide de simulations informatiques",
    description: ["Toutes les demandes de tuyaux cintrés sont d'abord soumises à une vérification de faisabilité par HANSA‑FLEX. Cette simulation est effectuée avec un logiciel de visualisation. Le logiciel prend en compte tous les paramètres tels que la longueur de cintrage, l'étirement et le ressort. À partir de ces valeurs, il calcule la longueur de tuyau sciée précise qui donnera un tuyau cintré prêt à installer après le cintrage. Une machine de cintrage CNC appropriée fabrique le tuyau cintré en utilisant les données de la simulation. Le prototype fabriqué est ensuite vérifié à l'aide d'un bras de mesure et comparé aux données maîtresses. Si toutes les dimensions sont conformes, la vérification de faisabilité est validée sous la supervision du technicien HANSA‑FLEX, qui donne son feu vert pour la production."],
  }

  const ProductPipebendimagetext5 = {
    image: imgProductPipebendimagetext5,
    title: 'Tuyaux et raccords spéciaux de Weixdorf',
    description: ["Des conduites et des raccords spécialement fabriqués sont souvent nécessaires en technologie des fluides. HANSA‑FLEX a mis en place un centre de fabrication spécial pour les conduites et raccords spéciaux à Dresden-Weixdorf spécifiquement pour répondre à cette demande. Tous les composants, qu'il s'agisse de production unique ou en série, sont fabriqués ici avec une précision contrôlée par ordinateur. Les machines de cintrage à gauche et à droite peuvent maîtriser même les géométries de tuyaux complexes. Les machines équipées d'une tourelle peuvent cintrer des tuyaux à différents rayons en une seule opération de travail. Cela permet à HANSA‑FLEX une plus grande flexibilité lors de la création de connexions personnalisées et raccourcit les délais de livraison."],
  }

  const ProductPipebendimagetext6 = {
    image: imgProductPipebendimagetext6,
    description: ["S'appuyant sur leurs nombreuses années d'expérience dans la fabrication de conduites cintrées et de raccords spéciaux, nos experts peuvent réaliser les conceptions spéciales les plus complexes avec précision pour répondre à vos besoins."],
    listItems: ["Tuyaux de précision en combinaison avec des flexibles hydrauliques", "Toutes les variantes de connexion disponibles sur le marché peuvent être fournies", "Tuyaux cintrés multiples, adaptateurs, collecteurs ou collecteurs", "Groupes de composants préassemblés pour une installation rapide"]
  }

  const featuresData = [
    {
      title: "Très haute précision",
      description: "Précisions de fabrication allant jusqu'à 0,3 mm, selon la longueur totale du produit.",
      image: imgMark
    },
    {
      title: "Fabrication exactement à la taille",
      description: "Tuyaux jusqu'à 6 000 mm de long et de 4,00 à 101,60 mm de diamètre.",
      image: imgMark
    },
    {
      title: "Flexibilité de fabrication",
      description: "Un large choix d'outils pour créer le rayon parfait selon vos besoins.",
      image: imgMark
    },
    {
      title: "Tous les matériaux disponibles",
      description: "HANSA‑FLEX cintrage des tuyaux en acier inoxydable ou en cuivre",
      image: imgMark
    }
    // Ajoutez plus d'objets pour des données supplémentaires de FeaturesCard
  ];

  return (
    <div>
      <div>
        <CenterStage
          imageSrc={imgProduct_Pipebends_Centerstage}
          title={"TUYAUX CINTRÉS POUR LA TECHNOLOGIE DES FLUIDES DE HANSA‑FLEX"}
          paragraph={"Les tuyaux cintrés sont utilisés non seulement dans des situations d'installation serrées, mais aussi pour des systèmes de tuyauterie complexes. HANSA‑FLEX a mis en place un centre de cintrage des tubes accessible à toutes les agences de l'entreprise. Le centre de cintrage des tubes HANSA‑FLEX fabrique des tuyaux cintrés de précision à partir de divers matériaux pour toutes nos agences. Les tuyaux sont disponibles avec tous les types de connexion courants et dans la qualité HANSA‑FLEX éprouvée..."}
        />
      </div>

      <div>
        <SectionHeader
          title={"COMPÉTENCE ET QUALITÉ FIABLES"}
          subtitle={"HANSA‑FLEX exploite les synergies entre les départements spécialisés pour répondre aux attentes les plus élevées des clients dans la fabrication de tuyaux et de raccords spéciaux."}
        />
        <ImageText data={ProductPipebendimagetext1} left={false} />
        <ImageText data={ProductPipebendimagetext2} />
        <ImageText data={ProductPipebendimagetext3} left={false} />
      </div>

      <div>
        <SectionHeader
          title={"LES TUYAUX CINTRÉS DE HANSA‑FLEX S'ADAPTENT EXACTEMENT À VOS BESOINS"}
          subtitle={"HANSA‑FLEX s'efforce toujours de répondre à chaque exigence individuelle du client au meilleur niveau possible. Le plancher de production pour les tuyaux et raccords spéciaux est équipé pour offrir aux clients un large choix de moyens possibles de mettre en œuvre tout projet."}
        />
        <FeatureCardCarousel featuresData={featuresData} />
      </div>

      <div>
        <SectionHeader
          title={"VÉRIFICATION DE LA FAISABILITÉ ET COMPOSANTS SUR MESURE"}
          subtitle={"HANSA‑FLEX fabrique des tuyaux cintrés, des tuyaux spéciaux et des raccords spéciaux dans une variété de formes pratiques rapidement, précisément et dans la qualité éprouvée adaptée aux fournisseurs OEM ou comme pièces de rechange.."}
        />
        <ImageText data={ProductPipebendimagetext4} left={false} />
        <ImageText data={ProductPipebendimagetext5} />
      </div>

      <div>
        <SectionHeader
          title={"TUYAUX ET RACCORDS SPÉCIAUX DE HANSA‑FLEX"}
          subtitle={"Vous bénéficiez de plusieurs services lorsque HANSA‑FLEX fabrique des solutions spéciales."}
        />
        <ImageText data={ProductPipebendimagetext6} left={false} />
      </div>

      <div>
        <SectionHeader
          title={"SERVICES ET PRODUITS SUPPLÉMENTAIRES"}
        />
        <Container className="custom-container">
          <Row>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
              <ImageCard image={imgProductPipebendimagecard1} learnMoreLink="/hydraulic-hoses" title="Tuyaux cintrés fabriqués avec précision selon les exigences du client" desc="HANSA‑FLEX dispose de décennies d'expérience dans le cintrage de tuyaux de précision en acier, en acier inoxydable, en cuivre et autres matériaux. Longueurs jusqu'à 6 000 mm et diamètres..." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
              <ImageCard image={imgProductPipebendimagecard2} learnMoreLink="/metal-hoses" title="Traitement personnalisé des extrémités de tuyaux et raccords spéciaux" desc="HANSA‑FLEX propose des options de connexion personnalisées pour chaque connexion de tuyau. Tous les types de processus de formage, tels que l'expansion ou le roulage, en plus de l'anneau de coupe, du fileté..." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
              <ImageCard image={imgProductPipebendimagecard3} learnMoreLink="/Industrial-hoses" title="Protégez les systèmes avec les joints de dilatation HANSA‑FLEX" desc="Les joints de dilatation HANSA‑FLEX en silicone, PTFE, NBR ou en acier inoxydable protègent les machines et les équipements contre les dommages dus aux chocs, à l'expansion et aux vibrations..." />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )

}

export default Pipebends